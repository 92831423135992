import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import produce from "immer";
import { RootState } from "app/store";
import {
  getCourses,
  getCourseConfig,
  getCourseMessages,
  getCourseProgresses,
} from "features/courses/api";
import {
  Course,
  ProgramProgress,
  AlgorithmProgress,
  CourseInitialStateType,
} from "features/courses/types";

export const progressesAlgorithmAdapter = createEntityAdapter<AlgorithmProgress>();
export const {
  selectAll: selectAllProgressesAlgorithm,
  selectById: selectProgressAlgorithmById,
  selectIds: selectProgressAlgorithmIds,
} = progressesAlgorithmAdapter.getSelectors(
  (state: RootState) => state.courses.progresses.algorithm.progress
);

export const progressesProgramAdapter = createEntityAdapter<ProgramProgress>();
export const {
  selectAll: selectAllProgressesProgram,
  selectById: selectProgressProgramById,
  selectIds: selectProgressProgramIds,
} = progressesProgramAdapter.getSelectors(
  (state: RootState) => state.courses.progresses.program.progress
);

const initialState: CourseInitialStateType = {
  config: {
    tangible: {
      deviceId: null,
    },
  },
  message: {},
  courses: {},
  progresses: {
    algorithm: {
      summary: null,
      progress: progressesAlgorithmAdapter.getInitialState(),
    },
    program: {
      summary: null,
      progress: progressesProgramAdapter.getInitialState(),
    },
  },
};

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateCameraDeviceId(
      state: CourseInitialStateType,
      action: PayloadAction<string>
    ) {
      state.config.tangible.deviceId = action.payload;
    },
    allAlgorithmStageClearAnimation(state) {
      state.progresses.algorithm.summary.all_stage_clear_animation = true;
    },
    allProgramStageClearAnimation(state) {
      state.progresses.program.summary.all_stage_clear_animation = true;
    },
    updateAlgorithmLevelTips(
      state,
      action: PayloadAction<{ stageId: number; stepId: number }>
    ) {
      const { stageId, stepId } = action.payload;
      const progressesAlgorithm = progressesAlgorithmAdapter
        .getSelectors()
        .selectById(state.progresses.algorithm.progress, stageId);
      const newProgressesAlgorithmLevel = produce(
        progressesAlgorithm.level,
        (draft) => {
          draft[stepId - 1].tips = true;
        }
      );
      progressesAlgorithmAdapter.updateOne(
        state.progresses.algorithm.progress,
        {
          id: stageId,
          changes: { level: newProgressesAlgorithmLevel },
        }
      );
    },
    updateAlgorithmLevelProgresses(
      state,
      action: PayloadAction<{
        stageId: number;
        stepId: number;
        totalFinished: number;
        answer: string;
        finishedAt: string;
      }>
    ) {
      const {
        stageId,
        stepId,
        totalFinished,
        answer,
        finishedAt,
      } = action.payload;
      const progressesAlgorithm = progressesAlgorithmAdapter
        .getSelectors()
        .selectById(state.progresses.algorithm.progress, stageId);
      const newProgressesAlgorithm = produce(progressesAlgorithm, (draft) => {
        draft.stage.finished += 1;
        draft.level[stepId - 1].answer = answer;
        draft.level[stepId - 1].is_finished = true;
        draft.level[stepId - 1].finished_at = finishedAt;
      });
      progressesAlgorithmAdapter.updateOne(
        state.progresses.algorithm.progress,
        {
          id: stageId,
          changes: newProgressesAlgorithm,
        }
      );
      state.progresses.algorithm.summary.finished = totalFinished;
    },
    updateAlgorithmLevelAnswer(
      state,
      action: PayloadAction<{
        stageId: number;
        stepId: number;
        answer: string;
      }>
    ) {
      const { stageId, stepId, answer } = action.payload;
      const progressesAlgorithm = progressesAlgorithmAdapter
        .getSelectors()
        .selectById(state.progresses.algorithm.progress, stageId);
      const newProgressesAlgorithm = produce(progressesAlgorithm, (draft) => {
        draft.level[stepId - 1].answer = answer;
      });
      progressesAlgorithmAdapter.updateOne(
        state.progresses.algorithm.progress,
        {
          id: stageId,
          changes: newProgressesAlgorithm,
        }
      );
    },
    updateAlgorithmLevelAnimation(
      state,
      action: PayloadAction<{ stageId: number; stepId: number }>
    ) {
      const { stageId, stepId } = action.payload;
      const progressesAlgorithm = progressesAlgorithmAdapter
        .getSelectors()
        .selectById(state.progresses.algorithm.progress, stageId);
      const newProgressesAlgorithmStage = produce(
        progressesAlgorithm.stage,
        (draft) => {
          draft.animation = stepId;
        }
      );
      progressesAlgorithmAdapter.updateOne(
        state.progresses.algorithm.progress,
        {
          id: stageId,
          changes: { stage: newProgressesAlgorithmStage },
        }
      );
    },
    updateProgramLevelProgresses(
      state,
      action: PayloadAction<{
        stageId: number;
        stepId: number;
        totalFinished: number;
        answer: string;
        finishedAt: string;
      }>
    ) {
      const {
        stageId,
        stepId,
        totalFinished,
        answer,
        finishedAt,
      } = action.payload;
      const progressesProgram = progressesProgramAdapter
        .getSelectors()
        .selectById(state.progresses.program.progress, stageId);
      const newProgressesProgram = produce(progressesProgram, (draft) => {
        draft.stage.finished += 1;
        draft.level[stepId - 1].answer = answer;
        draft.level[stepId - 1].is_finished = true;
        draft.level[stepId - 1].finished_at = finishedAt;
      });
      progressesProgramAdapter.updateOne(state.progresses.program.progress, {
        id: stageId,
        changes: newProgressesProgram,
      });
      state.progresses.program.summary.finished = totalFinished;
    },
    updateProgramLevelAnswer(
      state,
      action: PayloadAction<{
        stageId: number;
        stepId: number;
        answer: string;
      }>
    ) {
      const { stageId, stepId, answer } = action.payload;
      const progressesProgram = progressesProgramAdapter
        .getSelectors()
        .selectById(state.progresses.program.progress, stageId);
      const newProgressesProgram = produce(progressesProgram, (draft) => {
        draft.level[stepId - 1].answer = answer;
      });
      progressesProgramAdapter.updateOne(state.progresses.program.progress, {
        id: stageId,
        changes: newProgressesProgram,
      });
    },
    updateProgramLevelAnimation(
      state,
      action: PayloadAction<{ stageId: number; stepId: number }>
    ) {
      const { stageId, stepId } = action.payload;
      const progressesProgram = progressesProgramAdapter
        .getSelectors()
        .selectById(state.progresses.program.progress, stageId);
      const newProgressesProgramStage = produce(
        progressesProgram.stage,
        (draft) => {
          draft.animation = stepId;
        }
      );
      progressesProgramAdapter.updateOne(state.progresses.program.progress, {
        id: stageId,
        changes: { stage: newProgressesProgramStage },
      });
    },
    updateLevelTips(
      state,
      action: PayloadAction<{ course: Course; stageId: number; stepId: number }>
    ) {
      const { course, stageId, stepId } = action.payload;
      state.courses[course].progress[stageId - 1].level[stepId - 1].tips = true;
    },
    updateLevelProgresses(
      state,
      action: PayloadAction<{
        course: Course;
        stageId: number;
        stepId: number;
        totalFinished: number;
        answer: string;
        finishedAt: string;
      }>
    ) {
      const {
        course,
        stageId,
        stepId,
        totalFinished,
        answer,
        finishedAt,
      } = action.payload;
      state.courses[course].progress[stageId - 1].stage.finished += 1;
      state.courses[course].progress[stageId - 1].level[
        stepId - 1
      ].answer = answer;
      state.courses[course].progress[stageId - 1].level[
        stepId - 1
      ].is_finished = true;
      state.courses[course].progress[stageId - 1].level[
        stepId - 1
      ].finished_at = finishedAt;
      state.courses[course].summary.finished = totalFinished;
    },
    updateLevelAnswer(
      state,
      action: PayloadAction<{
        course: Course;
        stageId: number;
        stepId: number;
        answer: string;
      }>
    ) {
      const { course, stageId, stepId, answer } = action.payload;
      state.courses[course].progress[stageId - 1].level[
        stepId - 1
      ].answer = answer;
    },
    updateLevelAnimation(
      state,
      action: PayloadAction<{ course: Course; stageId: number; stepId: number }>
    ) {
      const { course, stageId, stepId } = action.payload;
      state.courses[course].progress[stageId - 1].stage.animation = stepId;
    },
    allStageClearAnimation(state, action: PayloadAction<{ course: Course }>) {
      state.courses[
        action.payload.course
      ].summary.all_stage_clear_animation = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCourseProgresses.fulfilled,
        (state: CourseInitialStateType, action: PayloadAction<any>) => {
          progressesAlgorithmAdapter.upsertMany(
            state.progresses.algorithm.progress,
            action.payload.algorithm.progress
          );
          state.progresses.algorithm.summary = action.payload.algorithm.summary;
          progressesProgramAdapter.upsertMany(
            state.progresses.program.progress,
            action.payload.program.progress
          );
          state.progresses.program.summary = action.payload.program.summary;
        }
      )
      .addCase(
        getCourses.fulfilled,
        (state: CourseInitialStateType, action: PayloadAction<any>) => {
          state.courses = action.payload.course_progress;
        }
      )
      .addCase(
        getCourseMessages.fulfilled,
        (state: CourseInitialStateType, action: PayloadAction<any>) => {
          state.message = action.payload.display;
        }
      )
      .addCase(
        getCourseConfig.fulfilled,
        (state: CourseInitialStateType, action: PayloadAction<any>) => {
          if (
            action.payload.config?.tangible &&
            Object.keys(action.payload.config?.tangible).length > 0
          ) {
            state.config.tangible = {
              ...state.config.tangible,
              ...action.payload.config.tangible,
            };
          }
        }
      );
  },
});
export const actions = { ...coursesSlice.actions };
export default coursesSlice.reducer;
