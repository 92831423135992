import { useRef, useState, useEffect } from "react";
import I18n from "i18n-js";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { InitMsg } from "common/blockly";
import { BlockType } from "features/builder/types";
import {
  InitColourBlock,
  InitControlsBlock,
  InitVariableBlock,
  InitCharacterBlock,
  InitProceduresCallBlock,
} from "./blocks";
import { Toolbox, ToolboxContents } from "./Toolbox";
import { createPreviewBlocklyOptions } from "./BlocklyOptions";

const updateFlyout = (workspace, offset: number, scale: number) => {
  const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
  const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
  const item = blockToolbox.getToolboxItemById(
    "category"
  ) as Blockly.ToolboxCategory;
  blockToolbox.setSelectedItem(item);
  if (!blocklyFlyout.isVisible()) {
    blockToolbox.setSelectedItem(item);
  }
  // @ts-ignore
  blocklyFlyout.positionAt_(
    blocklyFlyout.getWidth(),
    blocklyFlyout.getHeight() - offset - 75 * scale,
    0,
    offset
  );
};

const useInitPreviewWorkspace = (
  answer: string,
  scale: number,
  offset: number,
  locale: string,
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void
) => {
  useEffect(() => {
    InitMsg(locale);
  }, [locale]);

  useEffect(() => {
    InitCharacterBlock();
    InitControlsBlock();
    InitColourBlock();
    InitVariableBlock();
    InitProceduresCallBlock();

    // @ts-ignore
    Blockly.FieldTextInput.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    // @ts-ignore
    Blockly.FieldTextInput.prototype.showPromptEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    // @ts-ignore
    Blockly.FieldNumber.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    Blockly.WorkspaceSvg.prototype.refreshToolboxSelection = function () {
      // disable refresh toolbox
    };

    const workspace: Blockly.WorkspaceSvg = Blockly.inject(
      "blocklyDiv",
      createPreviewBlocklyOptions(scale, Toolbox)
    );
    BlocklyJs.init(workspace);

    const flyoutCallback = function (workspace: Blockly.WorkspaceSvg) {
      const blockList = [];
      blockList.push({
        kind: "block",
        type: BlockType.VARIABLES_SET,
      });
      const varBlocks = workspace.getBlocksByType(
        BlockType.VARIABLES_SET,
        false
      );
      if (varBlocks.length > 0) {
        const variable_id = varBlocks[varBlocks.length - 1].getFieldValue(
          "VAR"
        );
        const variable = workspace.getVariableById(variable_id);
        blockList.push({
          kind: "block",
          type: BlockType.VARIABLES_GET,
          fields: {
            VAR: variable,
          },
        });
      }

      blockList.push({
        kind: "block",
        type: BlockType.PROCEDURES_DEFNORETURN,
        fields: {
          NAME: "なにかする",
        },
      });

      for (const block of workspace.getBlocksByType(
        BlockType.PROCEDURES_DEFNORETURN,
        false
      )) {
        const funcName = block.getFieldValue("NAME");
        block.setMutator(null);
        blockList.push({
          kind: "block",
          type: BlockType.PROCEDURES_CALLNORETURN,
          fields: {
            NAME: funcName,
          },
        });
      }

      const blocks = ToolboxContents.contents.concat(blockList);
      return blocks;
    };

    workspace.registerToolboxCategoryCallback("CUSTOM_FLYOUT", flyoutCallback);

    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const div = toolbox.HtmlDiv as HTMLDivElement;
    div.style.width = "0px";
    div.style.top = `${offset * scale}px`;
    div.style.display = "none";

    workspace.updateToolbox(Toolbox);
    updateFlyout(workspace, offset * scale, scale);

    const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
    blocklyFlyout.autoClose = false;

    const blocklyScrollbarVerticals = document.getElementsByClassName(
      "blocklyScrollbarVertical"
    );
    for (let i: number = 0; i < blocklyScrollbarVerticals.length; i++) {
      let e = blocklyScrollbarVerticals[i] as SVGElement;
      const display = e.getAttribute("display");
      if (display !== "block") {
        e.setAttribute("style", "pointer-events: none;");
      }
    }

    if (!answer) {
      const eventBlock = new Blockly.BlockSvg(workspace, "character_event");
      eventBlock.initSvg();
      eventBlock.render();
      eventBlock.moveBy(blocklyFlyout.getWidth() / scale + 50, offset);
    }
    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};

const useWorkspaceListener = (
  answer: string,
  scale: number,
  offset: number,
  locale: string,
  workspace: Blockly.WorkspaceSvg,
  setEvent: (code: string) => void
) => {
  const [clear, _setClear] = useState(0);
  const clearRef = useRef(clear);
  const setClear = (clear: number) => {
    clearRef.current = clear;
    _setClear(clear);
  };
  const [blockXmlText, setBlockXmlText] = useState(null);

  useEffect(() => {
    if (workspace && answer) {
      const xml = Blockly.Xml.textToDom(answer);
      Blockly.Xml.domToWorkspace(xml, workspace);
    }
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      workspace.setScale(scale);
      updateFlyout(workspace, offset * scale, scale);

      const onWorkspaceChange = (event) => {
        switch (event.type) {
          case Blockly.Events.VAR_RENAME:
            updateFlyout(workspace, offset * scale, scale);
            break;
          case Blockly.Events.BLOCK_CHANGE:
          case Blockly.Events.BLOCK_CREATE:
          case Blockly.Events.BLOCK_DELETE:
          case Blockly.Events.BLOCK_MOVE:
            if (event.type === Blockly.Events.BLOCK_CREATE) {
              const block = workspace.getBlockById(event.blockId);
              if (!block) {
                // スペック低い端末でブロックをdragの後にすぐに手放して削除されると、ここでblockを読み取ることができなくなる。
                return;
              }

              if (block.type === "character_event") {
                workspace.getAllBlocks(true).forEach((block) => {
                  block.contextMenu = false;
                  if (block.type === "character_event") {
                    // 古いブロックも削除できるようにするため。
                    if (!block.isDeletable()) {
                      block.setDeletable(true);
                    }
                  }
                });
              } else {
                if (event.json.type === BlockType.PROCEDURES_DEFNORETURN) {
                  updateFlyout(workspace, offset * scale, scale);
                } else if (event.json.type === BlockType.VARIABLES_SET) {
                  if (
                    workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                      .length > 1
                  ) {
                    const varName = `${I18n.t("MSG_BLOCKLY_VARIABLES")}${
                      workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                        .length
                    }`;
                    const variable = workspace.createVariable(varName);
                    const block = workspace.getBlockById(event.blockId);
                    block.setFieldValue(variable.getId(), "VAR");
                  }

                  updateFlyout(workspace, offset * scale, scale);
                }
                workspace.getAllBlocks(true).forEach((block) => {
                  block.contextMenu = false;
                  if (block.type === "character_event") {
                    // 古いブロックも削除できるようにするため。
                    if (!block.isDeletable()) {
                      block.setDeletable(true);
                    }
                  }
                });
              }
            }

            if (event.type === Blockly.Events.BLOCK_CHANGE) {
              const block = workspace.getBlockById(event.blockId);
              if (!block) {
                // スペック低い端末でブロックをdragの後にすぐに手放して削除されると、ここでblockを読み取ることができなくなる。
                return;
              }

              if (block.type === BlockType.PROCEDURES_DEFNORETURN) {
                const funcName = block.getFieldValue("NAME");
                workspace
                  .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                  .filter(
                    (block) => block.getFieldValue("NAME") === event.oldValue
                  )
                  .forEach((block) => block.setFieldValue(funcName, "NAME"));
                updateFlyout(workspace, offset * scale, scale);
              }
            }

            if (event.type === Blockly.Events.DELETE) {
              if (
                event.oldJson.type === "character_event" &&
                workspace.getBlocksByType("character_event", false).length === 0
              ) {
                const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
                const eventBlock = new Blockly.BlockSvg(
                  workspace,
                  "character_event"
                );
                eventBlock.initSvg();
                eventBlock.render();
                eventBlock.moveBy(
                  blocklyFlyout.getWidth() / scale + 50,
                  offset
                );
              }

              if (event.oldJson.type === BlockType.PROCEDURES_DEFNORETURN) {
                workspace
                  .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                  .filter(
                    (block) =>
                      block.getFieldValue("NAME") === event.oldJson.fields.NAME
                  )
                  .forEach((block) => block.dispose(false));
                updateFlyout(workspace, offset * scale, scale);
              } else if (event.oldJson.type === BlockType.VARIABLES_SET) {
                workspace
                  .getBlocksByType(BlockType.VARIABLES_GET, false)
                  .filter(
                    (block) =>
                      block.getFieldValue("VAR") === event.oldJson.fields.VAR.id
                  )
                  .forEach((block) => block.dispose(false));
                updateFlyout(workspace, offset * scale, scale);
              }

              workspace.trashcan.emptyContents();
            }

            if (!workspace.isDragging()) {
              const code = BlocklyJs.workspaceToCode(workspace).replace(
                /function /g,
                "async $&"
              ); // safari maybe has some problems.
              setEvent(code);
              const xml = Blockly.Xml.workspaceToDom(workspace);
              const blockXmlText = Blockly.Xml.domToText(xml);
              setBlockXmlText(blockXmlText);
            }

            break;
          default:
            break;
        }
      };

      workspace.addChangeListener(onWorkspaceChange);

      if (blockXmlText) {
        const domXml = Blockly.Xml.textToDom(blockXmlText);
        Blockly.Xml.domToWorkspace(domXml, workspace);
      }

      return () => {
        setClear(
          workspace.getBlocksByType("procedures_defnoreturn", true).length
        );
        if (workspace) {
          workspace.removeChangeListener(onWorkspaceChange);
          workspace.clear();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, workspace, scale]);

  useEffect(() => {
    return () => {
      if (workspace) {
        workspace.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useBlocklyInit = (
  scale: number,
  offset: number,
  locale: string,
  answer: string,
  workspace: Blockly.WorkspaceSvg,
  setWorkspace: (workspace: any) => void,
  setEvent: (code: string) => void
) => {
  useInitPreviewWorkspace(answer, scale, offset, locale, setWorkspace);
  useWorkspaceListener(answer, scale, offset, locale, workspace, setEvent);
};
