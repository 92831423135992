import React from "react";
import Blockly from "blockly";

export const GameBgContainer = ({
  workspace,
  children,
}: {
  workspace: Blockly.WorkspaceSvg;
  children: React.ReactNode;
}) => {
  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;

      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
  };

  return (
    <div
      onClick={handleHideChaff}
      className="flex-col-el w-full h-full bg-transparent"
    >
      {children}
    </div>
  );
};
