import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Blockly from "blockly";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { updateFlyout } from "./utils";
import { useInitWorkspace } from "./useInitWorkspace";
import { TOP_OFFSET, COMMAND_PANEL_TAB_WIDTH } from "../constant";

export enum TabIndex {
  NONE = "none",
  EVENT = "event",
  ACTION = "action",
  CONTROL = "control",
  LOGIC = "logic",
  VARIABLE = "variable",
  PROCEDURE = "procedure",
}

export const BlocklyWorkspace = ({
  gameStart,
  workspace,
  setWorkspace,
}: {
  gameStart: boolean;
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: any) => void;
}) => {
  const play = usePlaySound();
  useInitWorkspace(setWorkspace);
  const { scale, height } = useScale();
  const [drag, setDrag] = useState(false);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.EVENT);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (gameStart && config.course_model === CourseModel.COMMAND) {
      setTabIndex(TabIndex.NONE);
      const toolbox = workspace.getToolbox() as Blockly.Toolbox;
      toolbox.setSelectedItem(null);
      workspace.recordDragTargets();
    }
  }, [gameStart]);

  useEffect(() => {
    if (workspace && config.course_model === CourseModel.COMMAND) {
      const flyout = workspace.getFlyout() as Blockly.Flyout;
      // @ts-ignore
      flyout.onDragOver = () => {
        if (flyout.isVisible()) {
          setDrag(true);
        }
      };
      // @ts-ignore
      flyout.onDrop = () => {
        setDrag(false);
      };
      // @ts-ignore
      flyout.onDragExit = () => {
        setDrag(false);
      };
    }
  }, [workspace]);

  const handleTabClick = (index: TabIndex) => () => {
    play();
    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const flyout = toolbox.getFlyout() as Blockly.Flyout;
    const selectedItem = toolbox.getSelectedItem() as Blockly.ToolboxItem;
    if (flyout.isVisible() && selectedItem.getId() === index) {
      setTabIndex(TabIndex.NONE);
      toolbox.setSelectedItem(null);
      workspace.recordDragTargets();
    } else {
      setTabIndex(index);
      const item = toolbox.getToolboxItemById(index.toString());
      toolbox.setSelectedItem(item);
      updateFlyout(workspace, TOP_OFFSET * scale);
    }
  };

  const handleHelp = () => {
    play();
    const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
    const selectedItem = blockToolbox.getSelectedItem() as Blockly.ToolboxItem;
    const selectedItemId = selectedItem.getId();
  };

  return (
    <div className="flex-row-el flex-1 w-full h-full p-2">
      {config.course_model === CourseModel.COMMAND && (
        <div className="flex-row-el relative">
          <div
            className="flex-col-el relative bg-gray2/20 border-solid border-white !box-content overflow-hidden border-[6px] rounded-[16px]"
            style={{
              top: TOP_OFFSET * scale,
              height: height - (TOP_OFFSET + 30) * scale,
              width: COMMAND_PANEL_TAB_WIDTH * scale,
              filter: "drop-shadow(-1px 1px 2px rgba(63, 74, 97, 0.4))",
            }}
          >
            <div
              className="flex-col-el relative origin-top-left"
              style={{
                transform: `scale(${scale})`,
                width: COMMAND_PANEL_TAB_WIDTH,
                height: (height - (TOP_OFFSET + 30) * scale) / scale,
              }}
            >
              <div className="flex-col-el flex-1 invisible-scrollbar gap-2">
                <div
                  onClick={handleTabClick(TabIndex.EVENT)}
                  className={`flex-col-center cursor-pointer pointer-events-auto pt-2 ${
                    tabIndex === TabIndex.EVENT && "bg-pure/orange"
                  }`}
                >
                  <div className="w-[24px] h-[24px] border-0 rounded-full bg-pure/orange" />
                  <p
                    className={`text-body text-[16px] text-textcolor/black ${
                      tabIndex === TabIndex.EVENT && "!text-white"
                    }`}
                  >
                    キャラクター
                  </p>
                </div>
                <div
                  onClick={handleTabClick(TabIndex.ACTION)}
                  className={`flex-col-center cursor-pointer pointer-events-auto pt-2 ${
                    tabIndex === TabIndex.ACTION && "bg-[#00abfb]"
                  }`}
                >
                  <div className="w-[24px] h-[24px] border-0 rounded-full bg-[#00abfb]" />
                  <p
                    className={`text-body text-[16px] text-textcolor/black ${
                      tabIndex === TabIndex.ACTION && "!text-white"
                    }`}
                  >
                    うごき
                  </p>
                </div>
                <div
                  onClick={handleTabClick(TabIndex.CONTROL)}
                  className={`flex-col-center cursor-pointer pointer-events-auto ${
                    tabIndex === TabIndex.CONTROL && "bg-strong/cyan/lime/green"
                  }`}
                >
                  <div className="w-[24px] h-[24px] border-0 rounded-full bg-strong/cyan/lime/green" />
                  <p
                    className={`text-body text-[16px] text-textcolor/black ${
                      tabIndex === TabIndex.CONTROL && "!text-white"
                    }`}
                  >
                    せいぎょ
                  </p>
                </div>
                <div
                  onClick={handleTabClick(TabIndex.VARIABLE)}
                  className={`flex-col-center cursor-pointer pointer-events-auto ${
                    tabIndex === TabIndex.VARIABLE && "bg-vivid/orange"
                  }`}
                >
                  <div className="w-[24px] h-[24px] border-0 rounded-full bg-vivid/orange" />
                  <p
                    className={`text-body text-[16px] text-textcolor/black ${
                      tabIndex === TabIndex.VARIABLE && "!text-white"
                    }`}
                  >
                    へんすう
                  </p>
                </div>
                <div
                  onClick={handleTabClick(TabIndex.PROCEDURE)}
                  className={`flex-col-center cursor-pointer pointer-events-auto ${
                    tabIndex === TabIndex.PROCEDURE && "bg-light/red"
                  }`}
                >
                  <div className="w-[24px] h-[24px] border-0 rounded-full bg-light/red" />
                  <p
                    className={`text-body text-[16px] text-textcolor/black ${
                      tabIndex === TabIndex.PROCEDURE && "!text-white"
                    }`}
                  >
                    かんすう
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            id="game-delete-panel"
            className="flex-col-el flex-center rounded-[10px] bg-white absolute z-[100001] opacity-70"
            style={{
              display: drag ? "flex" : "none",
              top: TOP_OFFSET * scale,
              left: COMMAND_PANEL_TAB_WIDTH * scale + 12,
              width:
                (workspace?.getFlyout() as Blockly.Flyout)?.getWidth() ?? 0,
              height:
                ((workspace?.getFlyout() as Blockly.Flyout)?.getHeight() ??
                  TOP_OFFSET * scale) -
                TOP_OFFSET * scale,
            }}
          >
            <div className="flex-col-center">
              <img
                alt="trash"
                src={`${Constants.assetHost}/assets/images/trash.png`}
              />
            </div>
          </div>

          <div
            className="flex-col-el flex-center absolute z-[100000] pointer-events-none"
            style={{
              display:
                gameStart ||
                !((workspace?.getToolbox() as Blockly.Toolbox)?.getFlyout() as Blockly.Flyout)?.isVisible()
                  ? "none"
                  : "flex",
              top: TOP_OFFSET * scale,
              left: COMMAND_PANEL_TAB_WIDTH * scale + 12,
              width:
                (workspace?.getFlyout() as Blockly.Flyout)?.getWidth() ?? 0,
              height:
                ((workspace?.getFlyout() as Blockly.Flyout)?.getHeight() ??
                  TOP_OFFSET * scale) -
                TOP_OFFSET * scale,
            }}
          >
            <div
              onClick={handleHelp}
              className="flex-col-el flex-center absolute top-6 right-6 cursor-pointer pointer-events-auto active:opacity-70"
            >
              <span className="material-symbols-outlined text-[36px] text-white bg-orange/100 rounded-full">
                help
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="flex-col-el flex-1">
        <div id="blocklyDiv" className="flex-col-el flex-1 w-full h-full" />
      </div>
    </div>
  );
};
