import {
  useState,
  useEffect,
  ChangeEventHandler,
  KeyboardEventHandler,
} from "react";
import I18n from "i18n-js";
import * as Blockly from "blockly";
import { Dialog as Modal } from "@mui/material";
import { CloseBtn, FadeTransition } from "common/elements";
import { isIos, btnDelay, useScale, usePlaySound } from "common/utils";

enum DIALOG_TYPE {
  ALERT = 0,
  CONFIRM = 1,
  PROMPT = 2,
}

export const DialogProgramCourse = (props: {
  isNumber?: number;
  limitLength?: number;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState("");
  const [dialog, setDialog] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [onCallback, setOnCallback] = useState({
    onOkay: null,
    onCancel: null,
  });
  const [initialed, setInitialed] = useState(false);
  const [limitLength, setLimitLength] = useState(
    props.limitLength ? props.limitLength : 6
  );
  const [type, setType] = useState(DIALOG_TYPE.ALERT);

  const disabled =
    type === DIALOG_TYPE.PROMPT
      ? isNumber
        ? isNaN(Number(value))
        : value.length > limitLength
      : false;

  useEffect(() => {
    if (dialog) {
      setTimeout(() => {
        setInitialed(true);
      }, 500);
    } else {
      setInitialed(false);
    }
  }, [dialog]);

  const handleDialog = (type: DIALOG_TYPE, message: string, callback) => {
    setType(type);
    setMessage(message);
    setOnCallback({
      onOkay: callback,
      onCancel: callback,
    });
    setDialog(true);
  };

  Blockly.dialog.setAlert((message, callback) =>
    handleDialog(DIALOG_TYPE.ALERT, message, callback)
  );

  Blockly.dialog.setConfirm((message, callback) =>
    handleDialog(DIALOG_TYPE.CONFIRM, message, callback)
  );

  // @ts-ignore
  Blockly.dialog.customPrompt = (
    field,
    message,
    defaultValue,
    callback,
    limitLength?
  ) => {
    if (field instanceof Blockly.FieldNumber) {
      setIsNumber(true);
    }
    if (limitLength) {
      setLimitLength(limitLength);
    }
    setValue(defaultValue);
    handleDialog(DIALOG_TYPE.PROMPT, message, callback);
  };

  Blockly.dialog.setPrompt((message, defaultValue, callback) => {
    setValue(defaultValue);
    handleDialog(DIALOG_TYPE.PROMPT, message, callback);
  });

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (isNumber && e.target.value.length > 15) return;
    setValue(e.target.value);
  };

  const handleOnCancel = () => {
    btnDelay(() => {
      play();
      setDialog(false);
      setIsNumber(false);
      switch (type) {
        case DIALOG_TYPE.ALERT:
          onCallback.onCancel();
          break;
        case DIALOG_TYPE.CONFIRM:
          onCallback.onCancel(false);
          break;
        case DIALOG_TYPE.PROMPT:
          onCallback.onCancel(null);
          break;
        default:
          break;
      }
    });
  };

  const handleOnOkay = () => {
    if (disabled) return;
    btnDelay(() => {
      play();
      setIsNumber(false);
      switch (type) {
        case DIALOG_TYPE.ALERT:
          onCallback.onOkay();
          break;
        case DIALOG_TYPE.CONFIRM:
          onCallback.onOkay(true);
          break;
        case DIALOG_TYPE.PROMPT:
          onCallback.onOkay(value);
          break;
        default:
          break;
      }
      setDialog(false);
    });
  };

  const handleEnterKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (
      e.keyCode === 13 &&
      !(
        !initialed ||
        disabled ||
        (isNumber && Number(value) === 0) ||
        value?.length === 0
      )
    ) {
      handleOnOkay();
    }
  };

  return (
    <Modal
      open={dialog}
      sx={{ zIndex: 10010 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            filter:
              "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
          }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={handleOnCancel} />

            <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view justify-start w-full py-4">
                <p className="text-body text-textcolor/black text-[28px] w-full">
                  {message}
                </p>
              </div>

              {type === DIALOG_TYPE.PROMPT && (
                <div
                  onKeyDown={handleEnterKeyDown}
                  className="flex-col-el w-full relative my-4"
                >
                  <input
                    type="text"
                    autoFocus={!isIos()}
                    onChange={handleOnChange}
                    placeholder={isNumber ? "0" : ""}
                    pattern={isNumber ? "[0-9]*" : `.{0,${limitLength}}`}
                    value={
                      isNumber ? (Number(value) === 0 ? "" : value) : value
                    }
                    className={`peer input-modal text-body text-textcolor/black text-[26px] invalid:border-danger`}
                  />

                  <label className="text-body text-left text-[14px] h-5 text-danger invisible peer-invalid:visible">
                    {isNumber
                      ? I18n.t("MSG_BLOCKLY_INPUT_NUMBER_LIMIT")
                      : I18n.t("MSG_BLOCKLY_INPUT_LENGTH_LIMIT", {
                          limit: limitLength,
                        })}
                  </label>
                </div>
              )}

              <div className="flex-row-el flex-center alpha">
                <button
                  onClick={handleOnOkay}
                  className="btn btn-primary"
                  disabled={
                    !initialed ||
                    disabled ||
                    (isNumber && Number(value) === 0) ||
                    value?.length === 0
                  }
                >
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[36px] text-white">
                      check
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {I18n.t("MSG_CONFIRM_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
