import { BlockType } from "features/builder/types";

export const Toolbox = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "category",
      expanded: "true",
      custom: "CUSTOM_FLYOUT",
    },
  ],
};

export const ToolboxContents = {
  contents: [
    {
      kind: "block",
      type: BlockType.MOTION_MOVE,
    },
    {
      kind: "block",
      type: BlockType.MOTION_JUMP,
    },
    {
      kind: "block",
      type: BlockType.MOTION_TURN_LEFT,
    },
    {
      kind: "block",
      type: BlockType.MOTION_TURN_RIGHT,
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_REPEAT_INTERNAL,
      extraState: {
        max: 3,
      },
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL,
      inputs: {
        COLOUR: {
          shadow: {
            type: "colour_picker_internal",
            fields: {
              COLOUR: "#71cdfc",
            },
          },
        },
      },
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_IF_COLOUR_INTERNAL,
      inputs: {
        COLOUR: {
          shadow: {
            type: "colour_picker_internal",
            fields: {
              COLOUR: "#71cdfc",
            },
          },
        },
      },
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_REPEAT_VAR_INTERNAL,
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL,
    },
    {
      kind: "block",
      type: BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL,
    },
    {
      kind: "block",
      type: BlockType.MATH_NUMBER,
    },
    {
      kind: "block",
      type: BlockType.COLOUR_PICKER_INTERNAL,
    },
  ],
};
