import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { BackToCourseTop } from "features/courses/components";
import { Levels } from "./Levels";

export const TakushokuLevelMapMainPage = ({ stage }: { stage: number }) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const display = useSelector(
    (state: RootState) =>
      state.courses.message[Course.TAKUSHOKU_BASIC][stage - 1]
  );
  const progresses = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.TAKUSHOKU_BASIC].progress[stage - 1]
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const handleLevelPress = (stepId: number) => {
    btnDelay(() => {
      play();
      navigate(`step/${stepId}`);
    });
  };

  return (
    <div
      className={`w-full h-full flex-col-view ${
        loaded ? "pointer-events-auto" : "pointer-events-none"
      }`}
    >
      <img
        alt="ステージの背景画像"
        src={`${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.webp`}
        onError={(e) =>
          // @ts-ignore
          (e.target.src = `${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.jpg`)
        }
        className="w-full h-full absolute inset-0 z-[-2] object-cover"
      />

      <Levels
        scale={scale}
        stage={stage}
        display={display !== undefined ? display[config.locale] : display}
        progresses={progresses}
        handleLevelPress={handleLevelPress}
      />
    </div>
  );
};

export const TakushokuBasicLevelMapPage = () => {
  const { stageId } = useParams<{ stageId?: string }>();
  const stage = Number(stageId);

  return isNaN(stage) || stage > 4 ? (
    <BackToCourseTop />
  ) : (
    <TakushokuLevelMapMainPage stage={stage} />
  );
};
