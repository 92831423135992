import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitVariableBlock = () => {
  BlocklyJs["variables_set"] = function (block: Blockly.BlockSvg) {
    // Variable setter.
    const argument0 =
      BlocklyJs.valueToCode(block, "VALUE", BlocklyJs.ORDER_ASSIGNMENT) || "0";
    const varName = BlocklyJs.nameDB_.getName(
      block.getFieldValue("VAR"),
      Blockly.VARIABLE_CATEGORY_NAME
    );
    return `
    \n
    util.highlightBlock("${block.id}", true);
    await util.sleep(100);
    ${varName} = ${argument0};\n
    util.highlightBlock("${block.id}", false);
    \n`;
  };
};
