import { TileSetMapType, CHARACTER_DIRECTION } from "common/model";

const landscapeLayer1 = [
  [23, 31, 31, 31, 23],
  [22, 26, 26, 26, 22],
  [31, 31, 31, 31, 22],
  [31, 31, 31, 31, 22],
  [23, 23, 23, 31, 22],
];
const landscapeLayer2 = [
  [42, 0, 0, 0, 35],
  [1, 13, 22, 13, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 8],
  [38, 0, 0, 0, 5],
];

const landscape = [landscapeLayer1, landscapeLayer2];

const map: TileSetMapType = {
  character: {
    direction: CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X,
  },
  landscape: landscape,
};

export default map;
