import produce from "immer";
import {
  ComponentType,
  TextStyleProps,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const TextInput: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_FORM";
    draft.secureTextEntry = false;
    draft.maxLength = 100;

    const initialWidth = 84;
    const initialHeight = 40;

    draft.style.defaultText = TextStyleProps;
    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderWidth = 2;
    draft.style.defaultView.borderRadius = 30;

    draft.style.text = TextStyleProps;
    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderWidth = 2;
    draft.style.view.borderRadius = 15;

    draft.events = ["textinput_value_change_event"];
  }),
};

export default TextInput;
