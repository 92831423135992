import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { actions } from "features/course/slice";

export const ProgramWelcomePage = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [start, setStart] = useState(true);
  const panelAction = useSelector((state: RootState) => state.course.action);

  const handleStart = () => {
    play();
    btnDelay(() => setStart(false));
  };
  const handleAnimationEnd = () => {
    setStart(true);
    dispatch(actions.updateShowWelcomePage(false));
  };

  useEffect(() => {
    if (panelAction.confirm) {
      dispatch(
        actions.updateAction({
          ...panelAction,
          confirm: false,
        })
      );
      setStart(false);
    }
  }, [panelAction.confirm]);

  return (
    <div
      className={`flex-col-el flex-center z-[1000] w-full h-full bg-gray2/100 absolute will-change-transform ${
        start
          ? ""
          : "animate__animated animate__bounceOutUp pointer-events-none"
      }`}
      onAnimationEnd={handleAnimationEnd}
    >
      <img
        alt="コース スタート画面"
        src={`${Constants.assetHost}/assets/images/course_program_welcome.png`}
        className="w-full h-full"
      />

      <div className="flex-col-el flex-center absolute bottom-14 phi">
        <button className="btn btn-vivid" onClick={handleStart}>
          <p className="text-body text-[48px]">
            {I18n.t("MSG_COURSE_START_BTN")}
          </p>
        </button>
      </div>
    </div>
  );
};
