import { useMemo, useState, useEffect } from "react";
import Blockly from "blockly";
import { throttle } from "lodash-es";
import { useScale } from "common/utils";
import { LocalStream, ExecutableBlock } from "common/tangible";
import { generateWorkspaceBlock } from "../blockly";

export const Control = ({
  ready,
  gameStart,
  gameOver,
  workspace,
  handleStart,
  handleGameOver,
  handleCreateCharacter,
}: {
  ready: boolean;
  gameStart: boolean;
  gameOver: boolean;
  workspace: Blockly.WorkspaceSvg;
  handleStart: () => void;
  handleGameOver: () => void;
  handleCreateCharacter: () => void;
}) => {
  const { scale } = useScale();
  const [run, setRun] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [teacher, setTeacher] = useState(false);

  const handleSuccess = (result: string) => {
    const { blocks, isStart, isAiRobotTeacher } = JSON.parse(result) as {
      blocks: ExecutableBlock[][];
      isStart: boolean;
      isAiRobotTeacher: boolean;
    };
    setRun(isStart);
    if (blocks.length > 0) {
      setBlocks(blocks);
    }
    setTeacher(isAiRobotTeacher);
  };

  const handleGameOverThrottle = useMemo(
    () => throttle(handleGameOver, 1000, { trailing: false }),
    [gameStart]
  );

  useEffect(() => {
    if (gameOver) {
      if (!run) {
        handleGameOverThrottle();
      }
    } else if (run) {
      if (!ready && !gameStart) {
        generateWorkspaceBlock(scale, blocks, workspace);
        handleCreateCharacter();
        handleStart();
      }
    } else {
      generateWorkspaceBlock(scale, blocks, workspace);
      handleCreateCharacter();
    }
  }, [run, blocks]);

  useEffect(() => {
    if (!gameOver && teacher) {
      console.log("AI Robot Teacher.");
    }
  }, [teacher]);

  return (
    <div className="flex-col-el">
      {workspace && (
        <LocalStream gameStart={gameStart} success={handleSuccess} />
      )}
    </div>
  );
};
