/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import { Spin } from "common/elements";
import { useCamera } from "common/hook";
import { useScale, isDebugMode, dataURLToBlob } from "common/utils";
import {
  handleExtractLabelFromImage,
  generateExecutableBlockList,
} from "./generator";
import { useProcessing } from "./useProcessing";
import { Label, ExecutableBlock } from "./type";

export const RemoteLive = ({
  open,
  setOpen,
  success,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  success: (result: string) => void;
}) => {
  const [labels, setLabels] = useState([]);
  const [start, setStart] = useState(false);
  const [debug, setDebug] = useState(false);
  const { width, height, scale } = useScale();
  const [loading, setLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamCanvas = useRef<HTMLCanvasElement>(null);
  const recognizeCanvas = useRef<HTMLCanvasElement>(null);
  const { initCamera, switchCamera, cancel } = useCamera(videoRef);
  const { handlePreprocessing, handleEventBlockDetection } = useProcessing();

  const handleShowRectangle = (
    labels: Label[],
    labelCount: { [label: string]: number },
    eventBlocks: { eventStartBlocks: any[]; proceduresStarts: any[] }
  ) => {
    if (!recognizeCanvas.current || !videoRef.current) return;

    recognizeCanvas.current.width = videoRef.current.width;
    recognizeCanvas.current.height = videoRef.current.height;
    let context = recognizeCanvas.current.getContext("2d");
    context.clearRect(0, 0, videoRef.current.width, videoRef.current.height);

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      context.lineWidth = 5;
      context.strokeStyle = "#00FF00";
      context.strokeRect(
        label.boundingPolygon[0].x,
        label.boundingPolygon[0].y,
        label.boundingPolygon[1].x - label.boundingPolygon[0].x,
        label.boundingPolygon[2].y - label.boundingPolygon[0].y
      );
      if (isDebugMode) {
        context.font = "40px Arial";
        context.fillStyle = "red";
        context.fillText(
          `${debug ? label.originalText : label.text}(${
            labelCount[
              `${debug ? label.originalText : label.text}_${
                label.boundingPolygon[0].x
              }_${label.boundingPolygon[0].y}`
            ]
          }/3)`,
          label.boundingPolygon[0].x,
          label.boundingPolygon[0].y
        );
      }
    }

    for (let i = 0; i < eventBlocks?.eventStartBlocks.length; i++) {
      const eventStartBlock = eventBlocks.eventStartBlocks[i];
      context.lineWidth = 5;
      context.strokeStyle = "yellow";
      context.strokeRect(
        eventStartBlock.messageBox[0].x,
        eventStartBlock.messageBox[0].y,
        eventStartBlock.messageBox[1].x - eventStartBlock.messageBox[0].x,
        eventStartBlock.messageBox[2].y - eventStartBlock.messageBox[0].y
      );
    }
  };

  const handleCaptureStream = useCallback(() => {
    // @ts-ignore
    const cap = new cv.VideoCapture(videoRef.current);
    // @ts-ignore
    const cameraFrame = new cv.Mat(
      videoRef.current.height,
      videoRef.current.width,
      // @ts-ignore
      cv.CV_8UC4
    );
    cap.read(cameraFrame);

    handlePreprocessing(cameraFrame, streamCanvas.current);

    cameraFrame.delete();
  }, []);

  const handleDetectEventBlockFromFrame = () => {
    // @ts-ignore
    const cap = new cv.VideoCapture(videoRef.current);
    // @ts-ignore
    const cameraFrame = new cv.Mat(
      videoRef.current.height,
      videoRef.current.width,
      // @ts-ignore
      cv.CV_8UC4
    );
    cap.read(cameraFrame);

    const eventBlocks = handleEventBlockDetection(cameraFrame);

    cameraFrame.delete();

    return eventBlocks;
  };

  const handleDetectLabel = (delay: number) =>
    new Promise<{
      validLabels: Label[];
      eventBlocks: { eventStartBlocks: any[]; proceduresStarts: any[] };
      isStart: boolean;
      isAiRobotTeacher: boolean;
    }>((resolve, reject) =>
      setTimeout(() => {
        handleCaptureStream();
        const dataUrl = streamCanvas.current.toDataURL("image/jpeg", 0.5);
        const fileBlob = dataURLToBlob(dataUrl);
        handleExtractLabelFromImage(
          fileBlob,
          debug,
          (validLabels, isStart, isAiRobotTeacher) => {
            let eventBlocks: {
              eventStartBlocks: any[];
              proceduresStarts: any[];
            };

            // if (
            //   !validLabels.filter((validLabel) =>
            //     validLabel.text.includes("じっこう")
            //   )
            // ) {
            //   eventBlocks = handleDetectEventBlockFromFrame();
            //   if (eventBlocks?.eventStartBlocks.length > 0) {
            //     validLabels.push({
            //       text: "じっこう",
            //       originalText: "じっこう",
            //       boundingPolygon: eventBlocks.eventStartBlocks[0].messageBox,
            //     });
            //   }
            // }

            resolve({
              validLabels,
              eventBlocks,
              isStart,
              isAiRobotTeacher,
            });
          },
          (error) => {
            console.error(error);
            reject();
          },
          () => {
            reject();
          }
        );
      }, delay)
    );

  const handleRecognize = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        Promise.all([
          handleDetectLabel(0),
          handleDetectLabel(300),
          handleDetectLabel(600),
        ])
          .then((results) => {
            console.log(results);

            try {
              var isStart = false;
              var isAiRobotTeacher = false;
              let eventBlocks: any;
              var validLabels: Label[] = [];
              let blocks: ExecutableBlock[][] = [];
              var labelCount: { [label: string]: number } = {};

              for (let i = 0; i < results.length; i++) {
                const result = results[i];

                if (result.eventBlocks) {
                  eventBlocks = result.eventBlocks;
                }

                if (!isStart) {
                  isStart = result.isStart;
                }
                if (!isAiRobotTeacher) {
                  isAiRobotTeacher = result.isAiRobotTeacher;
                }

                for (let j = 0; j < result.validLabels.length; j++) {
                  const label = result.validLabels[j];
                  const existLabels = validLabels.filter(
                    (validBlockLabel) =>
                      Math.abs(
                        label.boundingPolygon[0].x -
                          validBlockLabel.boundingPolygon[0].x
                      ) < 50 &&
                      Math.abs(
                        label.boundingPolygon[0].y -
                          validBlockLabel.boundingPolygon[0].y
                      ) < 50
                  );

                  if (existLabels.length === 0) {
                    validLabels.push(label);
                    labelCount[
                      `${label.text}_${label.boundingPolygon[0].x}_${label.boundingPolygon[0].y}`
                    ] = 1;
                  } else {
                    const existLabel = existLabels.shift();
                    labelCount[
                      `${existLabel.text}_${existLabel.boundingPolygon[0].x}_${existLabel.boundingPolygon[0].y}`
                    ] += 1;
                  }
                }
              }

              if (validLabels.length > 0) {
                setLabels((pre) => [...pre, ...validLabels.map((l) => l.text)]);
                handleShowRectangle(validLabels, labelCount, eventBlocks);
                if (
                  validLabels.filter(
                    (validLabel) =>
                      validLabel.text.includes("じっこう") ||
                      validLabel.text.includes("かんすう")
                  ).length > 0
                ) {
                  blocks = generateExecutableBlockList(validLabels);
                }
                success(JSON.stringify({ blocks, isStart, isAiRobotTeacher }));
              }

              resolve(0);
            } catch (error) {
              console.log(error);
              resolve(0);
            }
          })
          .catch(() => resolve(0))
          .finally(() => resolve(0));
      }),
    []
  );

  useEffect(() => {
    if (open) {
      setStart(true);
    }
  }, [open]);

  useEffect(() => {
    let recognizeTimer: NodeJS.Timeout;
    if (start) {
      setLoading(true);
      initCamera(
        () => {
          setLabels([]);

          setLoading(false);

          const processRecognize = () => {
            handleRecognize().then(() => {
              recognizeTimer = setTimeout(processRecognize, 10);
            });
          };

          processRecognize();
        },
        (error) => {
          console.log(error);
          setTimeout(() => {
            setLoading(false);
            setStart(false);
            setOpen(false);
          }, 500);
        }
      );
    }
    return () => {
      if (recognizeTimer) {
        clearTimeout(recognizeTimer);
      }
    };
  }, [start]);

  const handleCancel = () => {
    cancel();
    setStart(false);
    setOpen(false);
  };

  const handleSave = () => {
    const blob = new Blob([JSON.stringify(labels, null, 2)], {
      type: "application/json",
    });
    const a = document.createElement("a");
    a.download = "result.json";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
    cancel();
    setStart(false);
    setOpen(false);
  };

  return open ? (
    <>
      <div className="flex-col-el flex-center w-full h-full fixed right-0 bottom-0 z-[200000]">
        {loading && (
          <div className="flex-col-el absolute z-[200001]">
            <Spin />
          </div>
        )}

        <div className="flex-col-el flex-center bg-[#000000] pointer-events-auto relative w-full h-full">
          <div
            className={`flex-col-el flex-center absolute aspect-video ${
              width > height ? "h-full" : "w-full"
            }`}
          >
            <div className="flex-row-el flex-center w-full h-full z-50">
              <video
                muted
                playsInline
                ref={videoRef}
                className="w-full h-full hidden"
              />

              <canvas
                ref={streamCanvas}
                className="flex-co-el flex-center absolute top-0 left-0 w-full h-full z-10 "
              />

              <canvas
                ref={recognizeCanvas}
                className="flex-co-el flex-center absolute top-0 left-0 w-full h-full z-40"
              />

              <div
                style={{ bottom: 60 * scale }}
                className="flex-row-el w-full items-center justify-evenly z-50 absolute pointer-events-auto"
              >
                <div
                  className="flex-row-center"
                  style={{ width: 150, height: 50 }}
                >
                  <div
                    onClick={handleCancel}
                    className="flex-row-center cursor-pointer active:opacity-70"
                  >
                    <p className="text text-white">キャンセル</p>
                  </div>
                </div>

                <div
                  className="flex-row-center"
                  style={{ width: 150, height: 50 }}
                >
                  <div
                    onClick={handleSave}
                    className="flex-row-center cursor-pointer active:opacity-70"
                  >
                    <p className="text text-white">保存</p>
                  </div>
                </div>

                <div
                  className="flex-row-center z-50"
                  style={{ width: 150, height: 50 }}
                >
                  <div
                    onClick={switchCamera}
                    className="flex-row-center bg-textcolor/black/60 w-[50px] h-[50px] rounded-[40px] pointer-events-auto cursor-pointer active:opacity-70"
                  >
                    <span className="material-symbols-outlined text-[32px] text-white">
                      cameraswitch
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
