import { Vector3D } from "common/types";

export enum AssetModelType {
  NONE = "none",
  GEM = "gem",
  GOAL = "goal",
  RIVER = "river",
  GROUND = "ground",
  CONDITION = "condition",
  CHARACTER = "character",
  DECORATION = "decoration",
}

export type AssetModel = {
  name: string;
  filename: string;
  type: AssetModelType;
  rotation?: boolean;
  offset?: Vector3D;
  scaling?: Vector3D;
  metadata?: { [key: string]: any };
};

export type MapModel = AssetModel & {
  id: string;
  position: Vector3D;
  direction?: Vector3D;
};

export type TileSet = AssetModel & {
  id: number;
  size?: Vector3D;
  direction?: Vector3D;
};

export type MapTile = AssetModel & {
  position: Vector3D;
  direction?: Vector3D;
};

export interface TileSetMapType {
  character: {
    direction: CHARACTER_DIRECTION;
  };
  landscape: number[][][];
}

export enum CHARACTER_TYPE {
  CHARACTER_TYPE_DUCK,
  CHARACTER_TYPE_DOG,
  CHARACTER_TYPE_BEAR,
}

export enum CHARACTER_DIRECTION {
  CHARACTER_DIRECTION_X,
  CHARACTER_DIRECTION_MINUS_X,
  CHARACTER_DIRECTION_Z,
  CHARACTER_DIRECTION_MINUS_Z,
}
