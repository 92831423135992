import { createAsyncThunk } from "@reduxjs/toolkit";
import throttle from "lodash-es/throttle";
import { RootState } from "app/store";
import { fetchAuthToken } from "app/Auth";
import { client } from "common/apiClient";
import { ProjectType, ProjectEntity } from "features/creator/types";
import { MapType } from "./types";

export const getMapProjects = createAsyncThunk<
  ProjectEntity[],
  {
    uid: string;
  }
>("builder/getMapProjects", async ({ uid }) => {
  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).get(`/v1/projects/user/${uid}?project_type=map`);
  return response.data;
});

export const createMapProject = createAsyncThunk<
  { project: ProjectEntity },
  {
    uid: string;
    name: string;
    type: ProjectType;
  }
>("builder/createProject", async ({ uid, name, type }) => {
  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).post(`/v1/projects/user/${uid}`, {
    type,
    name,
    content: { type: MapType.ALGORITHM, size: { x: 8, y: 8, z: 8 }, map: [] },
  });
  return { ...response.data.data };
});

export const copyMapProject = createAsyncThunk<
  { project: ProjectEntity },
  {
    uid: string;
    copied_project_id: string;
    name: string;
  }
>("builder/copyMapProject", async ({ uid, copied_project_id, name }) => {
  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).post(`/v1/projects/user/${uid}/copy/${copied_project_id}`, { name });
  return { ...response.data.data };
});

export const getMapProjectContent = createAsyncThunk(
  "builder/getMapProjectContent",
  async ({ project_id }: { project_id: string }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).get(`/v1/projects/user/content/${project_id}`);
    return { ...response.data.data };
  }
);

export const updateMapProjectContent = createAsyncThunk(
  "builder/updateMapProjectContent",
  throttle(async ({ uid }: { uid: string }, thunkAPI) => {
    const token = await fetchAuthToken();
    const { builder } = thunkAPI.getState() as RootState;
    try {
      await client({
        token: token,
      }).put(`/v1/projects/user/${uid}/content/${builder.editingProjectId}`, {
        content: {
          size: builder.size,
          type: builder.type,
          map: builder.map.entities,
          blocks: builder.blocks,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, 5000)
);

export const removeMapProject = createAsyncThunk(
  "builder/removeMapProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).delete(`/v1/projects/user/${uid}/${project_id}`);
    return { project_id, deleted_at: response.data.deleted_at };
  }
);

export const deleteMapProject = createAsyncThunk(
  "builder/deleteMapProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).delete(`/v1/projects/user/${uid}/${project_id}`);
    return project_id;
  }
);

export const restoreProject = createAsyncThunk(
  "builder/restoreProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).put(`/v1/projects/user/${uid}/restore/${project_id}`);
    return project_id;
  }
);

export const publishMapProject = createAsyncThunk(
  "builder/publishMapProject",
  async ({
    uid,
    project_id,
    enable_copied,
  }: {
    uid: string;
    project_id: string;
    enable_copied: boolean;
  }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).post(`/v1/projects/published/${uid}/${project_id}`, { enable_copied });
    return { project_id, enable_copied };
  }
);

export const stopPublishMapProject = createAsyncThunk(
  "builder/stopPublishMapProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).delete(`/v1/projects/published/${uid}/${project_id}`);
    return project_id;
  }
);
