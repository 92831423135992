import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { IconAward } from "common/assets";
import {
  IconMap,
  IconHart,
  IconProject,
  IconPublished,
} from "features/user/assets";
import { ProjectType } from "features/creator/types";
import { selectAllProjects } from "features/creator/slice";

export const UserStatus = () => {
  const projects = useSelector(selectAllProjects);
  const courseAlgorithmSummary = useSelector(
    (state: RootState) => state.courses.progresses.algorithm.summary
  );
  const courseProgramSummary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const status = useSelector((state: RootState) => state.user.status);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );

  return (
    <div className="flex-col-center">
      <div className="flex-row-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10">
        <IconAward size={24} />
        <p className="text text-textcolor/black !text-[16px] !px-[8px]">
          {I18n.t("MSG_USER_PROFILE_USER_STATUS")}
        </p>
      </div>

      <div className="flex-row-center mt-[-14px] pt-[20px] pb-[5px] bg-white/40 rounded-[12px]">
        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconMap />
            </div>
            <p className="text text-gray/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_COURSE_CLEARED")}
            </p>
          </div>
          <div className="flex-col-center w-[45px] h-[45px] bg-white rounded-full">
            <p className="text text-textcolor/black !text-[27px]">
              {courseAlgorithmSummary.finished + courseProgramSummary.finished}
            </p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconProject />
            </div>
            <p className="text text-gray/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PROJECTS")}
            </p>
          </div>
          <div className="flex-col-center w-[45px] h-[45px] bg-white rounded-full">
            <p className="text text-textcolor/black !text-[27px]">
              {userProjects.length}
            </p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconPublished />
            </div>
            <p className="text text-gray/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PUBLISHED_PROJECTS")}
            </p>
          </div>
          <div className="flex-col-center w-[45px] h-[45px] bg-white rounded-full">
            <p className="text text-textcolor/black !text-[27px]">
              {status?.project.published}
            </p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconHart />
            </div>
            <p className="text text-gray/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PROJECT_RECEIVED_FAVORITE")}
            </p>
          </div>
          <div className="flex-col-center w-[45px] h-[45px] bg-white rounded-full">
            <p className="text text-textcolor/black !text-[27px]">
              {status?.project.favorited}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
