import Constants from "common/constant";
import { useScale } from "common/utils";

export const LevelClearRequirement = ({ blocks }: { blocks: string[] }) => {
  const { scale } = useScale();
  return (
    <div
      style={{
        top: 116 * scale,
        right: 100 * scale,
      }}
      className="flex-col-el flex-center absolute pointer-events-none z-50"
    >
      <div
        style={{ transform: `scale(${scale})` }}
        className="flex-col-el flex-center w-[300px] h-[150px] p-4 bg-white origin-top-right border-solid border-gray/10 border-[4px] rounded-2xl"
      >
        <div className="flex-col-el flex-center w-full h-full">
          <div className="flex-col-center w-full h-full">
            <img
              alt="*"
              className="w-full h-full object-contain"
              src={`${Constants.assetHost}/assets/blocks/${"move"}.png`}
            />
          </div>

          <div className="flex-col-el absolute -bottom-10 -left-6 w-[120px] h-[60px]">
            <img
              alt="courses blackboard"
              className="w-full h-full clear-stamp-animation"
              src={`${Constants.assetHost}/assets/images/requirement_block.png`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
