import { v4 as uuidv4 } from "uuid";
import { Vector2D } from "common/types";
import Constants from "common/constant";
import { Label, BlockType } from "./type";
import dictionary_jump from "./dictionary_jump.json";
import dictionary_event_start from "./dictionary_event_start.json";

export const wrapPromise = (f: (resolve: (value: any) => void) => void) =>
  new Promise((resolve) => f(resolve));

export const calculateDistance = (P1: Vector2D, P2: Vector2D) => {
  let deltaX = P2.x - P1.x;
  let deltaY = P2.y - P1.y;
  return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
};

export const calculateAngle = (P1: Vector2D, P2: Vector2D) => {
  let deltaX = P2.x - P1.x;
  let deltaY = P2.y - P1.y;
  let radians = Math.atan2(deltaY, deltaX);
  let degrees = radians * (180 / Math.PI);
  if (degrees < 0) {
    degrees += 360;
  }
  return degrees;
};

export const reorderPoints = (points: Vector2D[], angle: number) =>
  angle % 360 >= 0 && angle % 360 < 180
    ? [points[3], points[0], points[1], points[2]]
    : [points[3], points[0], points[1], points[2]];

export const rotateCurrentImage = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  degree: number
) => {
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  // 计算旋转后的图像尺寸
  const radians = (degree * Math.PI) / 180;
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);
  const newWidth = Math.abs(image.width * cos) + Math.abs(image.height * sin);
  const newHeight = Math.abs(image.width * sin) + Math.abs(image.height * cos);
  // 调整canvas大小
  canvas.width = newWidth;
  canvas.height = newHeight;
  // 将原点移动到canvas的中心
  ctx.translate(newWidth / 2, newHeight / 2);
  // 应用旋转
  ctx.rotate(radians);
  // 绘制旋转后的图像
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
};

export const validText = (text: string) => {
  switch (true) {
    case dictionary_event_start.filter((value) => text.includes(value)).length >
      0:
    case dictionary_jump.filter((value) => text.includes(value)).length > 0:
    case text.includes("おしまい"):
    case text.includes("すすむ"):
    case text.includes("ひだり"):
    case text.includes("みぎ"):
    case text.includes("もし"):
    case text.includes("くりかえす"):
    case text.includes("あおいろ"):
    case text.includes("あかいろ"):
    case text.includes("きいろ"):
    case text.includes("ここまで"):
    case text.includes("へんすうにセット"):
    case text.replace(" ", "").includes("かんすうよびだし"):
    case text.includes("かんすう"):
    case text.includes("おしごと"):
    case /\d+/.test(text):
      return true;
    default:
      return false;
  }
};

export const extractValidText = (text: string) => {
  switch (true) {
    case dictionary_event_start.filter((value) => text.includes(value)).length >
      0:
      return "じっこう";
    case dictionary_jump.filter((value) => text.includes(value)).length > 0:
      return "ジャンプ";
    case text.includes("おしまい"):
    case text.includes("すすむ"):
    case text.includes("ひだり"):
    case text.includes("みぎ"):
    case text.includes("もし"):
    case text.includes("くりかえす"):
    case text.includes("あおいろ"):
    case text.includes("あかいろ"):
    case text.includes("きいろ"):
    case text.includes("ここまで"):
    case text.includes("へんすうにセット"):
    case text.replace(" ", "").includes("かんすうよびだし"):
    case text.includes("かんすう"):
    case text.includes("おしごと"):
    case /\d+/.test(text):
      return text;
    default:
      return text;
  }
};

export const convertObjectToBlockByLabel = (label: Label, labels: Label[]) => {
  const id = uuidv4();
  switch (true) {
    case dictionary_event_start.filter((value) => label.text.includes(value))
      .length > 0:
      return {
        id,
        label,
        type: BlockType.EVENT_START,
        args: ["duck", "dog", "bear"],
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("おしまい"):
      return {
        id,
        label,
        type: BlockType.FUNCTION_END,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("すすむ"):
      return {
        id,
        label,
        type: BlockType.MOTION_MOVE,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case dictionary_jump.filter((value) => label.text.includes(value)).length >
      0:
      return {
        id,
        label,
        type: BlockType.MOTION_JUMP,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("ひだり"):
      return {
        id,
        label,
        type: BlockType.MOTION_TURN_LEFT,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("みぎ"):
      return {
        id,
        label,
        type: BlockType.MOTION_TURN_RIGHT,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("もし"):
      // IF block
      const ifBlockAngle = calculateAngle(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: label.boundingPolygon[1].x, y: label.boundingPolygon[1].y }
      );
      const ifCondition = findConditionLabel(
        label,
        labels,
        ifBlockAngle
      ).shift();
      if (ifCondition?.text) {
        if (ifCondition.text.includes("へんすう")) {
          return {
            id,
            label,
            type: BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL_START,
            args: ["へんすう"],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        } else {
          let color: string; //#71cdfc, #fb3a69, #fdd73e
          if (ifCondition.text.includes("あおいろ")) {
            color = "#71cdfc";
          } else if (ifCondition.text.includes("あかいろ")) {
            color = "#fb3a69";
          } else if (ifCondition.text.includes("きいろ")) {
            color = "#fdd73e";
          } else {
            return {
              id,
              label,
              type: BlockType.UNKNOWN,
              args: [],
              angle: calculateAngle(
                {
                  x: label.boundingPolygon[0].x,
                  y: label.boundingPolygon[0].y,
                },
                {
                  x: label.boundingPolygon[3].x,
                  y: label.boundingPolygon[3].y,
                }
              ),
            };
          }
          if (Constants.debugLog) {
            console.log("if block. color is: ", color);
          }
          return {
            id,
            label,
            type: BlockType.CONTROLS_IF_COLOUR_INTERNAL_START,
            args: [color],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        }
      } else {
        return {
          id,
          label,
          type: BlockType.UNKNOWN,
          args: [],
          angle: calculateAngle(
            {
              x: label.boundingPolygon[0].x,
              y: label.boundingPolygon[0].y,
            },
            {
              x: label.boundingPolygon[3].x,
              y: label.boundingPolygon[3].y,
            }
          ),
        };
      }
    case label.text.includes("くりかえす"):
      const loopBlockAngle = calculateAngle(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: label.boundingPolygon[1].x, y: label.boundingPolygon[1].y }
      );
      const loopCondition = findConditionLabel(
        label,
        labels,
        loopBlockAngle
      ).shift();
      if (loopCondition?.text) {
        if (loopCondition.text.includes("へんすう")) {
          return {
            id,
            label,
            type: BlockType.CONTROLS_LOOP_VAR_INTERNAL_START,
            args: ["へんすう"],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        } else {
          const number = loopCondition.text.match(/\d+/);
          if (number) {
            // FOR Block
            if (Constants.debugLog) {
              console.log("for block. number is: ", number ? [number[0]] : "");
            }
            return {
              id,
              label,
              type: BlockType.CONTROLS_REPEAT_INTERNAL_START,
              args: number ? [number[0]] : [],
              angle: calculateAngle(
                {
                  x: label.boundingPolygon[0].x,
                  y: label.boundingPolygon[0].y,
                },
                {
                  x: label.boundingPolygon[3].x,
                  y: label.boundingPolygon[3].y,
                }
              ),
            };
          } else {
            // WHILE Block
            let color: string; //#71cdfc, #fb3a69, #fdd73e
            if (loopCondition.text.includes("あおいろ")) {
              color = "#71cdfc";
            } else if (loopCondition.text.includes("あかいろ")) {
              color = "#fb3a69";
            } else if (loopCondition.text.includes("きいろ")) {
              color = "#fdd73e";
            } else {
              return {
                id,
                label,
                type: BlockType.UNKNOWN,
                args: [],
                angle: calculateAngle(
                  {
                    x: label.boundingPolygon[0].x,
                    y: label.boundingPolygon[0].y,
                  },
                  {
                    x: label.boundingPolygon[3].x,
                    y: label.boundingPolygon[3].y,
                  }
                ),
              };
            }
            console.log("while block. color is: ", color);
            return {
              id,
              label,
              type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL_START,
              args: [color],
              angle: calculateAngle(
                {
                  x: label.boundingPolygon[0].x,
                  y: label.boundingPolygon[0].y,
                },
                {
                  x: label.boundingPolygon[3].x,
                  y: label.boundingPolygon[3].y,
                }
              ),
            };
          }
        }
      } else {
        return {
          id,
          label,
          type: BlockType.UNKNOWN,
          args: [],
          angle: calculateAngle(
            {
              x: label.boundingPolygon[0].x,
              y: label.boundingPolygon[0].y,
            },
            {
              x: label.boundingPolygon[3].x,
              y: label.boundingPolygon[3].y,
            }
          ),
        };
      }
    case label.text.includes("ここまで"):
      return {
        id,
        label,
        type: BlockType.CONTROLS_END,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
    case label.text.includes("へんすうにセット"):
      const variableSetBlockAngle = calculateAngle(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: label.boundingPolygon[1].x, y: label.boundingPolygon[1].y }
      );
      const variableSetCondition = findConditionLabel(
        label,
        labels,
        variableSetBlockAngle
      ).shift();
      if (variableSetCondition?.text) {
        const variable_number = variableSetCondition.text.match(/\d+/);
        if (variable_number) {
          console.log("variables set block. value is: ", variable_number);
          return {
            id,
            label,
            type: BlockType.VARIABLES_SET,
            args: ["へんすう", variable_number[0]],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        } else {
          let color: string; //#71cdfc, #fb3a69, #fdd73e
          if (variableSetCondition.text.includes("あおいろ")) {
            color = "#71cdfc";
          } else if (variableSetCondition.text.includes("あかいろ")) {
            color = "#fb3a69";
          } else if (variableSetCondition.text.includes("きいろ")) {
            color = "#fdd73e";
          } else {
            return {
              id,
              label,
              type: BlockType.VARIABLES_SET,
              args: ["へんすう", ""],
              angle: calculateAngle(
                {
                  x: label.boundingPolygon[0].x,
                  y: label.boundingPolygon[0].y,
                },
                {
                  x: label.boundingPolygon[3].x,
                  y: label.boundingPolygon[3].y,
                }
              ),
            };
          }
          console.log("variables set block. value is: ", color);
          return {
            id,
            label,
            type: BlockType.VARIABLES_SET,
            args: ["へんすう", color],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        }
      } else {
        return {
          id,
          label,
          type: BlockType.VARIABLES_SET,
          args: ["へんすう", ""],
          angle: calculateAngle(
            {
              x: label.boundingPolygon[0].x,
              y: label.boundingPolygon[0].y,
            },
            {
              x: label.boundingPolygon[3].x,
              y: label.boundingPolygon[3].y,
            }
          ),
        };
      }
    case label.text.replace(" ", "").includes("かんすうよびだし"):
      const procedureCallBlockAngle = calculateAngle(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: label.boundingPolygon[1].x, y: label.boundingPolygon[1].y }
      );
      const procedureCallCondition = findConditionLabel(
        label,
        labels,
        procedureCallBlockAngle
      ).shift();
      if (procedureCallCondition?.text) {
        const number = procedureCallCondition.text.match(/\d+/);
        if (Constants.debugLog) {
          console.log(
            `procedure call definition block. name is: ${
              procedureCallCondition.text
            }, number is ${number ? [number[0]] : ""}`
          );
        }
        if (number) {
          return {
            id,
            label,
            type: BlockType.PROCEDURES_CALLNORETURN_CALL,
            args: [[number[0]], procedureCallCondition.text],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        } else {
          return {
            id,
            label,
            type: BlockType.UNKNOWN,
            args: [],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        }
      } else {
        return {
          id,
          label,
          type: BlockType.UNKNOWN,
          args: [],
          angle: calculateAngle(
            {
              x: label.boundingPolygon[0].x,
              y: label.boundingPolygon[0].y,
            },
            {
              x: label.boundingPolygon[3].x,
              y: label.boundingPolygon[3].y,
            }
          ),
        };
      }
    case label.text.includes("かんすう"):
      const procedureDefinitionBlockAngle = calculateAngle(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: label.boundingPolygon[1].x, y: label.boundingPolygon[1].y }
      );
      const procedureDefinitionCondition = findConditionLabel(
        label,
        labels,
        procedureDefinitionBlockAngle
      ).shift();
      if (Constants.debugLog) {
        console.log(
          `procedure definition block. name is: ${procedureDefinitionCondition?.text}`
        );
      }
      if (procedureDefinitionCondition?.text) {
        const number = procedureDefinitionCondition.text.match(/\d+/);
        if (number) {
          return {
            id,
            label,
            type: BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START,
            args: [`おしごと${number}`],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        } else {
          return {
            id,
            label,
            type: BlockType.UNKNOWN,
            args: [],
            angle: calculateAngle(
              {
                x: label.boundingPolygon[0].x,
                y: label.boundingPolygon[0].y,
              },
              {
                x: label.boundingPolygon[3].x,
                y: label.boundingPolygon[3].y,
              }
            ),
          };
        }
      } else {
        return {
          id,
          label,
          type: BlockType.UNKNOWN,
          args: [],
          angle: calculateAngle(
            {
              x: label.boundingPolygon[0].x,
              y: label.boundingPolygon[0].y,
            },
            {
              x: label.boundingPolygon[3].x,
              y: label.boundingPolygon[3].y,
            }
          ),
        };
      }
    default:
      return {
        id,
        label,
        type: BlockType.UNKNOWN,
        angle: calculateAngle(
          {
            x: label.boundingPolygon[0].x,
            y: label.boundingPolygon[0].y,
          },
          {
            x: label.boundingPolygon[3].x,
            y: label.boundingPolygon[3].y,
          }
        ),
      };
  }
};

const tolerance = 10; // 10度
export const findConditionLabel = (
  label: Label,
  labels: Label[],
  angle: number
) =>
  labels
    .filter(
      (row) =>
        row.text !== label.text &&
        Math.abs(
          calculateAngle(
            { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
            { x: row.boundingPolygon[0].x, y: row.boundingPolygon[0].y }
          ) - angle
        ) < tolerance
    )
    .sort((a, b) =>
      calculateDistance(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: a.boundingPolygon[0].x, y: a.boundingPolygon[0].y }
      ) <
      calculateDistance(
        { x: label.boundingPolygon[0].x, y: label.boundingPolygon[0].y },
        { x: b.boundingPolygon[0].x, y: b.boundingPolygon[0].y }
      )
        ? -1
        : 1
    );
