import Constants from "common/constant";
import { Course } from "features/courses/types";
import { CardBg } from "features/creator/assets";

export const CourseCardSlot = ({
  selected,
  course,
  name,
  onClick,
}: {
  selected?: boolean;
  course?: Course;
  name?: string;
  onClick?: () => void;
}) => (
  <div
    onClick={onClick}
    className={`flex-col-el ${onClick ? "cursor-pointer" : "cursor-auto"}`}
  >
    <div className="flex-col-center rounded-[16px] p-2">
      <div className="flex-col-el flex-center w-[168px] h-[186px] absolute -z-0 bg-textcolor/black/15">
        <CardBg />
      </div>

      <div className="flex-col-center w-[144px] h-[178px] ml-4">
        {course && (
          <>
            <div className="flex-col-el flex-center absolute w-[62px] h-[64px]">
              <img
                alt="switch"
                className="w-full h-full"
                src={`${Constants.assetHost}/assets/images/project_card_switch.png`}
              />
            </div>

            <div
              id={`courses-soft-card-${course}`}
              className={`flex-col-center w-full h-full ${
                selected ? "opacity-0" : "opacity-100"
              }`}
            >
              <img
                alt={course}
                src={`${Constants.assetHost}/assets/images/course_soft_${course}.png`}
                className="w-full h-full"
              />
            </div>
          </>
        )}
      </div>
    </div>
    <div className="flex-row-el flex-center w-full h-[56px]">
      <p className="text-body text-center text-[22px] leading-7 text-textcolor/black w-full text-ellipsis whitespace-pre">
        {name}
      </p>
    </div>
  </div>
);
