import { Course } from "./types";

export const string2Course = (course: string): Course => {
  switch (course) {
    case "algorithm":
      return Course.ALGORITHM;
    case "program":
      return Course.PROGRAM;
    case "algorithm_advanced":
      return Course.ALGORITHM_ADVANCED;
    case "group_work":
      return Course.GROUP_WORK;
    case "builder":
      return Course.BUILDER;
    case "nishishinjuku":
      return Course.NISHISHINJUKU;
    case "takushoku_basic":
      return Course.TAKUSHOKU_BASIC;
    case "takushoku_advance":
      return Course.TAKUSHOKU_ADVANCE;
    default:
      return Course.ALGORITHM;
  }
};
