import { useState } from "react";
import I18n from "i18n-js";
import produce from "immer";
import { usePlaySound } from "common/utils";
import { BorderStyleType, ShadowStyleType } from "common/components";
import {
  ColorPicker,
  ItemContainer,
} from "features/creator/property/components";
import { PropertyProps } from "features/creator/property/types";
import style from "./color.module.scss";

enum ColorItemIndex {
  TEXT = "text",
  TEXT_SHADOW = "text_shadow",
  BACKGROUND = "background",
  BORDER = "border",
  SHADOW = "shadow",
}

export const ColorProperty = (props: PropertyProps) => {
  const play = usePlaySound();
  const { scale, updateProperty } = props;
  const { id, property } = props.propertyEntity;

  const TAB_WIDTH = Number(style.TAB_WIDTH);
  const TAB_HEIGHT = Number(style.TAB_HEIGHT);

  const defaultItem = () => {
    if (property.style.view.backgroundColor !== undefined) {
      return ColorItemIndex.BACKGROUND;
    } else if (property.style.view.borderStyle !== BorderStyleType.NONE) {
      return ColorItemIndex.BORDER;
    } else if (property.style.text) {
      return ColorItemIndex.TEXT;
    } else {
      return ColorItemIndex.SHADOW;
    }
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState<ColorItemIndex>(
    defaultItem()
  );

  const handleChangeTextColor = (color: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.color = color;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleTextColorReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.color = draft.style.defaultText.color;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeTextShadowColor = (color: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.textShadowColor = color;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleTextShadowReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.textShadowColor =
        draft.style.defaultText.textShadowColor;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeBackgroundColor = (color: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.backgroundColor = color;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleChangeBackgroundColorReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.backgroundColor =
        draft.style.defaultView.backgroundColor;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeBorderColor = (color: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderColor = color;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleChangeBorderColorReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderColor = draft.style.defaultView.borderColor;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleChangeShadowColor = (color: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.shadow.shadowColor = color;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleShadowColorReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.shadow.shadowColor = draft.style.defaultShadow.shadowColor;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleTabClick = (tabIndex: ColorItemIndex) => () => {
    play();
    setSelectedTabIndex(tabIndex);
  };

  return (
    <div className="flex-row-center !flex-1 !items-start">
      <div
        className="flex-col-view justify-start !flex-1 bg-gray2/20 border-solid border-white overflow-hidden"
        style={{
          marginTop: 44 * scale,
          marginLeft: 12 * scale,
          borderTopLeftRadius: 16 * scale,
          borderBottomLeftRadius: 16 * scale,
          borderWidth: Math.round(4 * scale),
          borderRightWidth: 0,
        }}
      >
        {property.style.view.backgroundColor !== undefined && (
          <div
            className="flex-row-view items-start"
            style={{ width: TAB_WIDTH * scale, height: TAB_HEIGHT * scale }}
          >
            <div
              className={`flex-row-view items-center origin-top-left ${
                style["property__color__panel__tab-button"]
              } ${
                selectedTabIndex === ColorItemIndex.BACKGROUND &&
                style["property__color__panel__tab-button__selected"]
              }`}
              style={{ transform: `scale(${scale})` }}
              onClick={handleTabClick(ColorItemIndex.BACKGROUND)}
            >
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_BACKGROUND_COLOR")}
              </p>
            </div>
          </div>
        )}

        {property.style.view.borderStyle !== BorderStyleType.NONE && (
          <div
            className="flex-row-view"
            style={{ width: TAB_WIDTH * scale, height: TAB_HEIGHT * scale }}
          >
            <div
              className={`flex-row-view items-center origin-top-left ${
                style["property__color__panel__tab-button"]
              } ${
                selectedTabIndex === ColorItemIndex.BORDER &&
                style["property__color__panel__tab-button__selected"]
              }`}
              style={{ transform: `scale(${scale})` }}
              onClick={handleTabClick(ColorItemIndex.BORDER)}
            >
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_COLOR")}
              </p>
            </div>
          </div>
        )}

        {property.style.shadow.shadowStyle !== ShadowStyleType.NONE && (
          <div
            className="flex-row-view"
            style={{ width: TAB_WIDTH * scale, height: TAB_HEIGHT * scale }}
          >
            <div
              className={`flex-row-view items-center origin-top-left ${
                style["property__color__panel__tab-button"]
              } ${
                selectedTabIndex === ColorItemIndex.SHADOW &&
                style["property__color__panel__tab-button__selected"]
              }`}
              style={{ transform: `scale(${scale})` }}
              onClick={handleTabClick(ColorItemIndex.SHADOW)}
            >
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW_COLOR")}
              </p>
            </div>
          </div>
        )}
        {property.style.text && (
          <>
            <div
              className="flex-row-view"
              style={{ width: TAB_WIDTH * scale, height: TAB_HEIGHT * scale }}
            >
              <div
                className={`flex-row-view items-center origin-top-left ${
                  style["property__color__panel__tab-button"]
                } ${
                  selectedTabIndex === ColorItemIndex.TEXT &&
                  style["property__color__panel__tab-button__selected"]
                }`}
                style={{ transform: `scale(${scale})` }}
                onClick={handleTabClick(ColorItemIndex.TEXT)}
              >
                <p className="text text-textcolor/black !text-[16px]">
                  {I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_COLOR")}
                </p>
              </div>
            </div>
            <div
              className="flex-row-view"
              style={{ width: TAB_WIDTH * scale, height: TAB_HEIGHT * scale }}
            >
              <div
                className={`flex-row-view items-center origin-top-left ${
                  style["property__color__panel__tab-button"]
                } ${
                  selectedTabIndex === ColorItemIndex.TEXT_SHADOW &&
                  style["property__color__panel__tab-button__selected"]
                }`}
                style={{ transform: `scale(${scale})` }}
                onClick={handleTabClick(ColorItemIndex.TEXT_SHADOW)}
              >
                <p className="text text-textcolor/black !text-[16px]">
                  {I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW_COLOR")}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {selectedTabIndex === ColorItemIndex.BACKGROUND &&
        property.style.view.backgroundColor !== undefined && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BACKGROUND_COLOR")}
            scale={scale}
            width={280}
            height={304}
            left={0}
            onRest={handleChangeBackgroundColorReset}
          >
            <ColorPicker
              color={property.style.view.backgroundColor}
              onChange={handleChangeBackgroundColor}
            />
          </ItemContainer>
        )}
      {selectedTabIndex === ColorItemIndex.BORDER &&
        property.style.view.borderStyle !== BorderStyleType.NONE && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_COLOR")}
            scale={scale}
            width={280}
            height={304}
            left={0}
            onRest={handleChangeBorderColorReset}
          >
            <ColorPicker
              color={property.style.view.borderColor}
              onChange={handleChangeBorderColor}
            />
          </ItemContainer>
        )}
      {selectedTabIndex === ColorItemIndex.SHADOW &&
        property.style.shadow.shadowStyle !== ShadowStyleType.NONE && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW_COLOR")}
            scale={scale}
            width={280}
            height={304}
            left={0}
            onRest={handleShadowColorReset}
          >
            <ColorPicker
              color={property.style.shadow.shadowColor}
              onChange={handleChangeShadowColor}
            />
          </ItemContainer>
        )}
      {property.style.text && (
        <>
          {selectedTabIndex === ColorItemIndex.TEXT && (
            <ItemContainer
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_COLOR")}
              scale={scale}
              width={280}
              height={304}
              left={0}
              onRest={handleTextColorReset}
            >
              <ColorPicker
                color={property.style.text.color}
                onChange={handleChangeTextColor}
              />
            </ItemContainer>
          )}

          {selectedTabIndex === ColorItemIndex.TEXT_SHADOW && (
            <ItemContainer
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW_COLOR")}
              scale={scale}
              width={280}
              height={304}
              left={0}
              onRest={handleTextShadowReset}
            >
              <ColorPicker
                color={property.style.text.textShadowColor}
                onChange={handleChangeTextShadowColor}
              />
            </ItemContainer>
          )}
        </>
      )}
    </div>
  );
};
