import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import { useUserConfig } from "app/hook";
import { usePlaySound } from "common/utils";
import { ConfirmMessageBox } from "common/elements";
import { PickBtnBg, PickBtnClickedBg } from "features/creator/assets";

export const CourseModelPanel = () => {
  const play = usePlaySound();
  const [click, setClick] = useState(false);
  const [model, setModel] = useState(false);
  const handleChangeUserConfig = useUserConfig();
  const config = useSelector((state: RootState) => state.config.userConfig);

  const handleClickDown = () => {
    play();
    setClick(true);
  };
  const handleClickUp = () => {
    setClick(false);
    setModel(true);
  };
  const handleChangeCourseModel = () => {
    handleChangeUserConfig(
      "course_model",
      config.course_model === CourseModel.COMMAND
        ? CourseModel.TANGIBLE
        : CourseModel.COMMAND
    );
    setModel(false);
  };
  const handleCloseChangeCourseModel = () => {
    play();
    setModel(false);
  };

  return (
    <div className="flex-row-el flex-center bg-gray/40 rounded-full absolute top-32 left-2 overflow-hidden">
      {model && (
        <ConfirmMessageBox
          title={"モード変更"}
          message={"モードを変える"}
          confirm={handleChangeCourseModel}
          close={handleCloseChangeCourseModel}
        />
      )}
      <div
        id="creator-ranking-btn"
        onPointerDown={handleClickDown}
        onPointerUp={handleClickUp}
        style={{
          transform: `translateY(${click ? 2 : 0}px)`,
        }}
        className="flex-col-el flex-center relative cursor-pointer"
      >
        <div className="-z-[1] w-[191px] h-[199px]">
          {click ? <PickBtnClickedBg /> : <PickBtnBg />}
        </div>
        <div className="flex-col-el flex-center w-[136px] h-[168px] absolute">
          <p className="text-body text-[24px] text-textcolor/black">
            {"タンジブルモード:"}
            {config.course_model === CourseModel.COMMAND ? "OFF" : "ON"}
          </p>
        </div>
      </div>
    </div>
  );
};
