import I18n from "i18n-js";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CloseBtn } from "common/elements";
import {
  FlagIcon,
  GameTitle,
  OverviewMessageBalloonSvg,
} from "features/courses/assets";

export const Overview = ({
  stage,
  step,
  content,
  start,
  close,
}: {
  stage: number;
  step: number;
  content: {
    name: string;
    description: string;
    purpose: string[];
    image: string;
  };
  start: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  return (
    <div
      className="flex-col-center !justify-start w-[880px] p-[8px] rounded-[10px] bg-white"
      style={{
        transform: `scale(${scale})`,
      }}
    >
      <div className="flex-col-el absolute top-[10px] right-[10px] z-[100] min-h-0 min-w-0">
        <CloseBtn close={close} />
      </div>

      <div className="flex-col-view -z-[1] bg-beige/60 !absolute top-[-54px] w-[240px] h-[240px] border-solid border-white border-[8px] rounded-t-[250px]" />

      <div className="flex-col-center w-full h-[80px] rounded-t-[10px] box-content z-[1] bg-beige/60">
        <div className="flex-col-view !absolute bottom-[-45px]">
          <GameTitle
            level={step}
            title={content ? content.name.replace("\n", "") : ""}
          />
        </div>
      </div>

      <div className="flex-col-view items-center bg-white w-full h-full !flex-1 pt-[61px] px-[28px]">
        <div className="flex-col-view w-full">
          <div className="flex-row-view w-full">
            <div className="flex-col-view w-[380px] h-[243px] p-[8px] bg-white rounded-[5px] shadow-[2px_2px_5px_rgba(128,128,128,0.5)]">
              <img
                alt="*"
                src={`${Constants.assetHost}/assets/algorithm/shotcut/stage${stage}/step${step}.webp`}
                onError={(e) =>
                  // @ts-ignore
                  (e.target.src = `${Constants.assetHost}/assets/algorithm/shotcut/stage${stage}/step${step}.png`)
                }
                className="w-full h-full rounded-[8px] object-cover"
              />
            </div>
            <div className="flex-col-view !flex-1 w-full ml-[12px]">
              <div className="flex-col-center w-[394px] h-[202px]">
                <OverviewMessageBalloonSvg />
              </div>
              <div className="flex-row-center w-[323px] h-[120px] flex-wrap !absolute top-[30px] left-[36px]">
                <p className="text !text-left !text-[21px] !leading-[30px] text-textcolor/black w-full">
                  {content?.description}
                </p>
              </div>
            </div>
          </div>

          <div className="flex-col-view w-full bg-white border-solid border-[4px] border-beige/alt px-[4px] rounded-[10px] mt-[35px] py-[9px] flex-wrap">
            <div className="flex-row-center w-[217px] h-[33px] !absolute left-[4px] top-[-25px] bg-white">
              <div className="flex-col-center w-[19px] h-[26px] mr-[8px]">
                <FlagIcon />
              </div>
              <p className="title-text text-orange/100 !leading-[29px] !tracking-[.03em]">
                {I18n.t("MSG_COURSE_LEVEL_OVERVIEW_SUMMARY")}
              </p>
            </div>

            {content?.purpose?.map((item, index) => (
              <div
                key={index}
                className="flex-row-center !flex-1 w-full h-full my-[4px]"
              >
                <p className="text text-textcolor/black !text-[18px] !text-left !leading-[28px] self-start">
                  ・
                </p>
                <div className="flex-row-view !flex-1 w-full flex-wrap">
                  <p className="text text-textcolor/black !text-[18px] !text-left !leading-[28px] w-full">
                    {item}
                  </p>
                </div>
              </div>
            ))}

            <div className="flex-col-center w-full h-[153px] !absolute top-[-157px]">
              <img
                alt="*"
                src={`${Constants.assetHost}/assets/images/overview_character.png`}
                className="w-[150px] h-full absolute right-[30px]"
              />
            </div>
          </div>
        </div>

        <div className="flex-row-el flex-center alpha mt-2">
          <button className="btn btn-primary" onClick={start}>
            <p className="text-body text-white text-[36px] !mb-[3px]">
              {I18n.t("MSG_COURSE_LEVEL_OVERVIEW_START_BTN")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
