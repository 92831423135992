import produce from "immer";
import {
  ComponentType,
  TextStyleProps,
  BorderStyleType,
  ShadowStyleType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Label: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_LABEL";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_LABEL";

    const initialWidth = 50;
    const initialHeight = 20;

    draft.style.defaultText = TextStyleProps;
    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.borderStyle = BorderStyleType.NONE;
    draft.style.defaultShadow.shadowStyle = ShadowStyleType.NONE;

    draft.style.text = TextStyleProps;
    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.view.borderStyle = BorderStyleType.NONE;
    draft.style.shadow.shadowStyle = ShadowStyleType.NONE;

    draft.events = ["label_click_event"];
  }),
};

export default Label;
