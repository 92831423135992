import { EntityState } from "@reduxjs/toolkit";
import { Vector3D } from "common/types";
import { ProjectEntity } from "features/creator/types";

export enum MapType {
  ALGORITHM,
  FREE,
}

export enum AssetModelType {
  GEM = "gem",
  RIVER = "river",
  GROUND = "ground",
  CONDITION = "condition",
  CHARACTER = "character",
  DECORATION = "decoration",
}

export type AssetModel = {
  name: string;
  filename: string;
  type: AssetModelType;
  rotation?: boolean;
  offset?: Vector3D;
  scaling?: Vector3D;
  metadata?: { [key: string]: any };
};

export enum BlockType {
  EVENT_START = "character_event",
  CHARACTER_EVENT_DUCK = "character_event_duck",
  CHARACTER_EVENT_DOG = "character_event_dog",
  CHARACTER_EVENT_BEAR = "character_event_bear",
  MOTION_MOVE = "character_motion_move",
  MOTION_JUMP = "character_motion_jump",
  MOTION_TURN_LEFT = "character_motion_turn_left",
  MOTION_TURN_RIGHT = "character_motion_turn_right",
  CONTROLS_REPEAT_INTERNAL = "controls_repeat_internal",
  CONTROLS_WHILEUNTIL_COLOUR_INTERNAL = "controls_whileUntil_colour_internal",
  CONTROLS_IF_COLOUR_INTERNAL = "controls_if_colour_internal",
  CONTROLS_REPEAT_VAR_INTERNAL = "controls_repeat_var_internal",
  CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL = "controls_whileUntil_colour_var_internal",
  CONTROLS_IF_COLOUR_VAR_INTERNAL = "controls_if_colour_var_internal",
  MATH_NUMBER = "math_number",
  COLOUR_PICKER_INTERNAL = "colour_picker_internal",
  VARIABLES_SET = "variables_set",
  VARIABLES_GET = "variables_get",
  PROCEDURES_DEFNORETURN = "procedures_defnoreturn",
  PROCEDURES_CALLNORETURN = "procedures_callnoreturn",
}

export const MAP_MESH_SIZE = 2;
export const BUTTON_ASSET_SIZE = { x: 1.6, y: 0.3, z: 1.6 };

export type MapModel = AssetModel & {
  id: string;
  position: Vector3D;
  direction?: Vector3D;
};

export type Blocks = {
  [type: string]: {
    kind: string;
    type: BlockType;
    count: number;
    [key: string]: any;
  };
};

export type BuilderState = {
  loading: boolean;
  editingProjectId: string;
  project: ProjectEntity;
  size: Vector3D;
  type: MapType;
  map: EntityState<MapModel>;
  slot: AssetModel[];
  blocks: Blocks;
  undoStacks: AssetModel[];
  redoStacks: AssetModel[];
  actionMenu: {
    tap: boolean;
    asset: boolean;
    eraser: boolean;
    preview: boolean;
    assetList: boolean;
  };
  selectedSlotIndex: number | null;
  selectedAssetModel: AssetModel | null;
};

export enum ActionCommandType {
  ADD_MODE = "ADD_MODE",
  REMOVE_MODE = "REMOVE_MODE",
  CHANGE_MODE = "CHANGE_MODE",
  CHANGE_BLOCKLY = "CHANGE_BLOCKLY",
  UNDO = "UNDO",
  REDO = "REDO",
  CLEAR = "CLEAR",
}

export interface AddModeCommand {
  type: ActionCommandType.ADD_MODE;
  model: MapModel;
}

export interface RemoveModeCommand {
  type: ActionCommandType.REMOVE_MODE;
  meshId: string;
}

export interface ChangeModeCommand {
  type: ActionCommandType.CHANGE_MODE;
  id: string;
  changes: {
    [item: string]: string | Vector3D;
  };
}

export interface ChangeBlocklyCommand {
  type: ActionCommandType.CHANGE_BLOCKLY;
  block: {
    kind: string;
    type: BlockType;
    count: number;
    [key: string]: any;
  };
}

export interface UndoCommand {
  type: ActionCommandType.UNDO;
}

export interface RedoCommand {
  type: ActionCommandType.REDO;
}

export interface ClearCommand {
  type: ActionCommandType.CLEAR;
}

export type ActionCommand =
  | AddModeCommand
  | RemoveModeCommand
  | ChangeModeCommand
  | ChangeBlocklyCommand
  | UndoCommand
  | RedoCommand
  | ClearCommand;
