import {
  FlexStyle,
  ViewStyle,
  StarStyle,
  TextStyle,
  ImageType,
  ImageStyle,
  ShadowStyle,
  OverflowType,
  ResizeConfig,
  FontStyleType,
  TextAlignType,
  TextTransform,
  TransformStyle,
  FontWeightType,
  ShadowStyleType,
  BorderStyleType,
  ImageResizeMode,
  RegularPolygonStyle,
  TextDecorationLineType,
} from "./StyleTypes";
import Constants from "common/constant";

export const ImageStyleProps: ImageStyle = {
  name: "image",
  size: 7,
  width: 512,
  height: 512,
  type: ImageType.PHOTO,
  uri: `${Constants.assetHost}/assets/images/default_image.png`,
  resizeMode: ImageResizeMode.CONTAIN,
};

export const LayoutStyleProps: FlexStyle = {
  position: "absolute",
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  zIndex: 1,
  overflow: OverflowType.HIDDEN,
};

export const RegularPolygonStyleProps: RegularPolygonStyle = {
  sides: 3,
  radius: 50,
};

export const ResizeProps: ResizeConfig = {
  lockAspectRatioX: false,
  lockAspectRatioY: false,
};

export const StarStyleProps: StarStyle = {
  innerRadius: 30,
  outerRadius: 50,
  numPoints: 5,
};

export const ShadowStyleProps: ShadowStyle = {
  shadowWeight: 1,
  shadowStyle: ShadowStyleType.DROP,
  shadowColor: "rgba(28, 23, 23, 1)",
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 100,
  shadowRadius: 0,
};

export const TextStyleProps: TextStyle = {
  color: "rgba(28, 23, 23, 1)",
  opacity: 100,
  fontFamily: "MPLUSRounded1c-Bold",
  fontSize: 14,
  fontStyle: FontStyleType.NORMAL,
  fontWeight: FontWeightType.NORMAL,
  letterSpacing: 1,
  lineHeight: 1,
  textAlign: TextAlignType.CENTER,
  textDecorationLine: TextDecorationLineType.NONE,
  textShadowWeight: 4,
  textShadowColor: "rgba(28, 23, 23, 1)",
  textShadowOffset: { width: 0, height: 0 },
  textShadowRadius: 0,
  textTransform: TextTransform.NONE,
};

export const TransformsStyleProps: TransformStyle = {
  translateX: 0,
  translateY: 0,
  scaleX: 1,
  scaleY: 1,
  radius: 0,
  rotation: "0",
  transformOrigin: "center center",
};

export const ViewStyleProps: ViewStyle = {
  borderWidth: 0,
  borderStyle: BorderStyleType.SOLID,
  borderColor: "rgba(253,252,253,1)",
  opacity: 100,
};
