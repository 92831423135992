import { useState, PointerEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { actions } from "features/course/slice";
import { KeyDirection } from "features/creator/types";

export const CursorKey = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const [clicked, setClicked] = useState(false);
  const [clickedKey, setClickedKey] = useState(KeyDirection.NONE);

  const handleKeyDown = (
    direction: KeyDirection
  ): PointerEventHandler<HTMLDivElement> => (e) => {
    play();
    if (selectedCourse) {
      setClickedKey(direction);
    }
  };
  const handleKeyUp = () => {
    switch (clickedKey) {
      case KeyDirection.UP:
        dispatch(actions.updateCursor(KeyDirection.UP));
        break;
      case KeyDirection.RIGHT:
        dispatch(actions.updateCursor(KeyDirection.RIGHT));
        break;
      case KeyDirection.LEFT:
        dispatch(actions.updateCursor(KeyDirection.LEFT));
        break;
      case KeyDirection.DOWN:
        dispatch(actions.updateCursor(KeyDirection.DOWN));
        break;
      case KeyDirection.NONE:
        dispatch(actions.updateCursor(KeyDirection.NONE));
        break;
      default:
        break;
    }
    setClickedKey(KeyDirection.NONE);
  };

  return (
    <div
      onMouseDown={() => {
        setClicked(true);
      }}
      className="flex-col-el flex-center relative w-[200px] h-[184px] ml-6 mr-4"
    >
      {clicked && (
        <Dialog
          open
          fullScreen
          maxWidth={false}
          sx={{ zIndex: 999999 }}
          componentsProps={{
            backdrop: { style: { backgroundColor: "transparent" } },
          }}
          PaperProps={{
            style: {
              margin: 0,
              overflow: "visible",
              backgroundColor: "transparent",
            },
            elevation: 0,
          }}
        >
          <div
            onMouseUp={() => {
              handleKeyUp();
              setTimeout(() => setClicked(false), 500);
            }}
            className="flex-col-center w-full h-full"
          />
        </Dialog>
      )}

      <div
        onPointerUp={handleKeyUp}
        className={`flex-col-el flex-center absolute select-none ${
          clickedKey === KeyDirection.NONE
            ? "opacity-100 z-50"
            : "opacity-0 -z-10"
        }`}
      >
        <img
          alt="cursor arrow key"
          src={`${Constants.assetHost}/assets/images/cursor_arrow_key.png`}
          className="w-[200px] h-[184px]"
        />
        <div
          onPointerDown={handleKeyDown(KeyDirection.UP)}
          className="flex-col-el absolute top-3 w-[56px] h-[50px] z-50 cursor-pointer"
        />
        <div
          onPointerDown={handleKeyDown(KeyDirection.DOWN)}
          className="flex-col-el absolute bottom-6 w-[56px] h-[50px] z-50 cursor-pointer"
        />
        <div
          onPointerDown={handleKeyDown(KeyDirection.LEFT)}
          className="flex-col-el absolute left-4 mb-3 w-[56px] h-[54px] z-50 cursor-pointer"
        />
        <div
          onPointerDown={handleKeyDown(KeyDirection.RIGHT)}
          className="flex-col-el absolute right-5 mb-3 w-[56px] h-[54px] z-50 cursor-pointer"
        />
      </div>

      <div
        onPointerUp={handleKeyUp}
        className={`flex-col-el absolute select-none ${
          clickedKey === KeyDirection.UP
            ? "opacity-100 z-50"
            : "opacity-0 -z-10"
        }`}
      >
        <img
          alt="cursor arrow key"
          src={`${Constants.assetHost}/assets/images/cursor_arrow_key_up.png`}
          className="w-[200px] h-[184px] object-fill select-none"
        />
      </div>

      <div
        onPointerUp={handleKeyUp}
        className={`flex-col-el absolute select-none ${
          clickedKey === KeyDirection.RIGHT
            ? "opacity-100 z-50"
            : "opacity-0 -z-10"
        }`}
      >
        <img
          alt="cursor arrow key"
          src={`${Constants.assetHost}/assets/images/cursor_arrow_key_right.png`}
          className="w-[200px] h-[184px] object-fill select-none"
        />
      </div>

      <div
        onPointerUp={handleKeyUp}
        className={`flex-col-el absolute select-none ${
          clickedKey === KeyDirection.DOWN
            ? "opacity-100 z-50"
            : "opacity-0 -z-10"
        }`}
      >
        <img
          alt="cursor arrow key"
          src={`${Constants.assetHost}/assets/images/cursor_arrow_key_down.png`}
          className="w-[200px] h-[184px] object-fill select-none"
        />
      </div>

      <div
        onPointerUp={handleKeyUp}
        className={`flex-col-el absolute select-none ${
          clickedKey === KeyDirection.LEFT
            ? "opacity-100 z-50"
            : "opacity-0 -z-10"
        }`}
      >
        <img
          alt="cursor arrow key"
          src={`${Constants.assetHost}/assets/images/cursor_arrow_key_left.png`}
          className="w-[200px] h-[184px] object-fill select-none"
        />
      </div>
    </div>
  );
};
