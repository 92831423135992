import produce from "immer";
import {
  ComponentType,
  TextStyleProps,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Button: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_BUTTON";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_BUTTON";

    const initialBorderRadius = 100;
    const initialWidth = 90;
    const initialHeight = 40;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultText = {
      ...TextStyleProps,
      color: "rgba(253,252,253,1)",
    };
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = initialBorderRadius;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.text = { ...TextStyleProps, color: "rgba(253,252,253,1)" };
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = initialBorderRadius;

    draft.events = ["button_click_event"];
  }),
};

export default Button;
