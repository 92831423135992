import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { customAlphabet } from "nanoid";

export const InitControlsBlock = () => {
  Blockly.Blocks["controls_repeat_internal"] = {
    max_: 5,
    init: function () {
      const options = Array(this.max_)
        .fill(0)
        .map((value, index) => [`${index + 1}`, `${index + 1}`]);
      this.appendDummyInput("dummy_times")
        .appendField(new Blockly.FieldDropdown(options), "TIMES")
        .appendField("%{BKY_COURSE_ALGORITHM_REPEAT}", "label");
      this.appendStatementInput("DO");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
    saveExtraState: function () {
      return {
        max: this.max_,
      };
    },
    loadExtraState: function (state) {
      this.max_ = state["max"];
      const options = Array(this.max_)
        .fill(0)
        .map((value, index) => [`${index + 1}`, `${index + 1}`]);
      const dummyInput: Blockly.Input = this.getInput("dummy_times");
      dummyInput.removeField("TIMES", true);
      dummyInput.removeField("label", true);
      dummyInput
        .appendField(new Blockly.FieldDropdown(options), "TIMES")
        .appendField("%{BKY_COURSE_ALGORITHM_REPEAT}", "label");
    },
  };
  BlocklyJs["controls_repeat_internal"] = function (block: Blockly.Block) {
    const repeats = String(Number(block.getFieldValue("TIMES")));
    const logic = BlocklyJs.statementToCode(block, "DO");
    const varName = customAlphabet("abcdef", 6)();
    return `
    await util.sleep(100);
    for (var ${varName} = 0; ${varName} <  ${repeats}; ${varName}++){
      util.highlightBlock("${block.id}", true);
      await util.sleep(100);
      util.highlightBlock("${block.id}", false);
      ${logic}
    };`;
  };

  const controlsRepeatVarBlock = () => {
    return {
      type: "controls_repeat_var_internal",
      message0: "%1%{BKY_COURSE_ALGORITHM_REPEAT}",
      args0: [
        {
          type: "input_value",
          name: "TIMES",
          check: "Number",
        },
      ],
      message1: "%1",
      args1: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
  };
  Blockly.Blocks["controls_repeat_var_internal"] = {
    init: function () {
      this.jsonInit(controlsRepeatVarBlock());
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
  };
  BlocklyJs["controls_repeat_var_internal"] = function (block: Blockly.Block) {
    let repeats = BlocklyJs.valueToCode(
      block,
      "TIMES",
      (Blockly as any).JavaScript.ORDER_ATOMIC
    );

    if (!repeats) {
      repeats = "0";
    }

    const logic = BlocklyJs.statementToCode(block, "DO");
    return `
    await util.sleep(100);
    for (var count = 0; count <  ${repeats}; count++){
      util.highlightBlock("${block.id}", true);
      await util.sleep(100);
      util.highlightBlock("${block.id}", false);
      ${logic}
    };`;
  };

  const controlsWhileUnitlColourBlock = () => {
    return {
      type: "controls_whileUntil_colour_internal",
      message0: "%1%{BKY_COURSE_ALGORITHM_WHILE_UNTIL}",
      args0: [
        {
          type: "input_value",
          name: "COLOUR",
          check: "Colour",
        },
      ],
      message1: "%1",
      args1: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
  };
  Blockly.Blocks["controls_whileUntil_colour_internal"] = {
    init: function () {
      this.jsonInit(controlsWhileUnitlColourBlock());
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
  };
  BlocklyJs["controls_whileUntil_colour_internal"] = function (
    block: Blockly.Block
  ) {
    var colour = BlocklyJs.valueToCode(
      block,
      "COLOUR",
      (Blockly as any).JavaScript.ORDER_ADDITION
    );
    if (!colour) {
      colour = `""`;
    }

    const logic = BlocklyJs.statementToCode(block, "DO");
    if (logic === "") {
      // to stop infinite loop
      return `
        highlightBlock("${block.id}", true);
      `;
    } else {
      return `
        await util.sleep(100);
        while(!scene.isDisposed && (util.action.checkBtnColor(scene, character) !== ${colour})){
          if(--window.LoopTrap < 1) {
            if (scene && !scene.isDisposed) {
              scene.dispose();
            }
            return;
          };
          util.highlightBlock("${block.id}", true);
          await util.sleep(100);
          util.highlightBlock("${block.id}", false);
          ${logic}
          await util.sleep(100);
        };`;
    }
  };

  const controlsWhileUnitlColourVarBlock = () => {
    return {
      type: "controls_whileUntil_colour_var_internal",
      message0: "%1%{BKY_COURSE_ALGORITHM_WHILE_UNTIL}",
      args0: [
        {
          type: "input_value",
          name: "COLOUR",
          check: "Colour",
        },
      ],
      message1: "%1",
      args1: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
  };
  Blockly.Blocks["controls_whileUntil_colour_var_internal"] = {
    init: function () {
      this.jsonInit(controlsWhileUnitlColourVarBlock());
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
  };
  BlocklyJs["controls_whileUntil_colour_var_internal"] = function (
    block: Blockly.Block
  ) {
    var colour = BlocklyJs.valueToCode(
      block,
      "COLOUR",
      (Blockly as any).JavaScript.ORDER_ADDITION
    );
    if (!colour) {
      colour = `""`;
    }

    const logic = BlocklyJs.statementToCode(block, "DO");
    if (logic === "") {
      // to stop infinite loop
      return `util.highlightBlock("${block.id}", true);`;
    } else {
      return `
        await util.sleep(100);
        while(!scene.isDisposed && (util.action.checkBtnColor(scene, character) !== ${colour})){
          if(--window.LoopTrap < 1) {
            if (scene && !scene.isDisposed) {
              scene.dispose();
            }
            return;
          };
          util.highlightBlock("${block.id}", true);
          await util.sleep(100);
          util.highlightBlock("${block.id}", false);
          ${logic}
          await util.sleep(100);
        };`;
    }
  };

  const controlsIfColourBlock = () => {
    return {
      type: "controls_if_colour_internal",
      message0: "もし%1のボタンにいるなら",
      args0: [
        {
          type: "input_value",
          name: "COLOUR",
          check: "Colour",
        },
      ],
      message1: "%1",
      args1: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
  };
  Blockly.Blocks["controls_if_colour_internal"] = {
    init: function () {
      this.jsonInit(controlsIfColourBlock());
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
  };
  BlocklyJs["controls_if_colour_internal"] = function (block: Blockly.Block) {
    var colour = BlocklyJs.valueToCode(
      block,
      "COLOUR",
      (Blockly as any).JavaScript.ORDER_ADDITION
    );
    if (!colour) {
      colour = `""`;
    }

    const logic = BlocklyJs.statementToCode(block, "DO");
    if (logic === "") {
      return `util.highlightBlock("${block.id}", true);`;
    } else {
      return `
      await sleep(100);
      util.highlightBlock("${block.id}", true);
      await util.sleep(100);
      if(!scene.isDisposed && (util.action.checkBtnColor(scene, character) === ${colour})){
        util.highlightBlock("${block.id}", false);
        ${logic}
      } else {
        util.highlightBlock("${block.id}", false);
      };`;
    }
  };

  const controlsIfColourVarBlock = () => {
    return {
      type: "controls_if_colour_var_internal",
      message0: "もし%1のボタンにいるなら",
      args0: [
        {
          type: "input_value",
          name: "COLOUR",
          check: "Colour",
        },
      ],
      message1: "%1",
      args1: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
  };
  Blockly.Blocks["controls_if_colour_var_internal"] = {
    init: function () {
      this.jsonInit(controlsIfColourVarBlock());
      this.setColour("#FFAB19");
      this.contextMenu = false;
    },
  };
  BlocklyJs["controls_if_colour_var_internal"] = function (
    block: Blockly.Block
  ) {
    var colour = BlocklyJs.valueToCode(
      block,
      "COLOUR",
      (Blockly as any).JavaScript.ORDER_ADDITION
    );
    if (!colour) {
      colour = `""`;
    }

    const logic = BlocklyJs.statementToCode(block, "DO");
    if (logic === "") {
      return `util.highlightBlock("${block.id}", true);`;
    } else {
      return `
      await util.sleep(100);
      util.highlightBlock("${block.id}", true);
      await util.sleep(100);
      if(!scene.isDisposed && (util.action.checkBtnColor(scene, character) === ${colour})){
        util.highlightBlock("${block.id}", false);
        ${logic}
      } else {
        util.highlightBlock("${block.id}", false);
      };`;
    }
  };
};
