import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import i18n from "i18n-js";
import * as Blockly from "blockly";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { IconListDetails } from "common/assets";
import { TabIndex } from "features/creator/types";

export const Toolbox = ({
  scale,
  width,
  height,
  header,
  tabIndex,
  setTabIndex,
  workspace,
}: {
  scale: number;
  width: number;
  height: number;
  header: number;
  tabIndex: TabIndex;
  setTabIndex: (index: TabIndex) => void;
  workspace: Blockly.WorkspaceSvg;
}) => {
  const play = usePlaySound();
  const ref = useRef<HTMLDivElement>(null);
  const blocklyWorkspaceCollapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.blocklyWorkspace
  );

  useEffect(() => {
    if (blocklyWorkspaceCollapse && workspace) {
      const toolbox = workspace.getToolbox() as Blockly.Toolbox;
      const flyout = toolbox.getFlyout() as Blockly.Flyout;
      if (flyout.isVisible()) {
        const item = toolbox.getSelectedItem() as Blockly.ToolboxCategory;
        toolbox.setSelectedItem(item);
      }
      setTabIndex(TabIndex.NONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocklyWorkspaceCollapse]);

  const handleTabClick = (index: TabIndex) => () => {
    play();
    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const flyout = toolbox.getFlyout() as Blockly.Flyout;
    const selectedItem = toolbox.getSelectedItem() as Blockly.ToolboxItem;
    if (flyout.isVisible() && selectedItem.getId() === index) {
      setTabIndex(TabIndex.NONE);
    } else {
      setTabIndex(index);
    }
    const item = toolbox.getToolboxItemById(index.toString());
    toolbox.setSelectedItem(item);
  };

  return (
    <div
      className="flex-col-view bg-gray2/20 border-solid border-white !box-content overflow-hidden"
      style={{
        top: header * scale,
        width: width * scale,
        height: height * scale,
        borderTopLeftRadius: 16 * scale,
        borderBottomLeftRadius: 16 * scale,
        borderWidth: `${4 * scale}px 0 ${4 * scale}px ${4 * scale}px`,
      }}
    >
      <div
        className="flex-col-view origin-top-left"
        style={{
          height: height,
          width: width,
          transform: `scale(${scale})`,
        }}
      >
        <div ref={ref} className="flex-col-view !flex-1 invisible-scrollbar">
          <div className="flex-col-center w-full bg-gray2/60 h-[32px]">
            <p className="flex-row-center text text-white !text-[16px]">
              <span className="inline-block w-[20px] h-[20px] mr-[2px]">
                <IconListDetails color="#FEFDFE" />
              </span>
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_TITLE")}
            </p>
          </div>

          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.EVENT && "bg-pure/orange"
            }`}
            onClick={handleTabClick(TabIndex.EVENT)}
          >
            <div className="blockly__toolbox-tab__icon bg-pure/orange" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.EVENT && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_EVENT")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.BASIC && "bg-[#00abfb]"
            }`}
            onClick={handleTabClick(TabIndex.BASIC)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#00abfb]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.BASIC && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_BASIC")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.POSITION && "bg-[#7f5345]"
            }`}
            onClick={handleTabClick(TabIndex.POSITION)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#7f5345]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.POSITION && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_POSITION")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.SIZE && "bg-[#00b341]"
            }`}
            onClick={handleTabClick(TabIndex.SIZE)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#00b341]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.SIZE && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_SIZE")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.APPEARANCE && "bg-[#ff9300]"
            }`}
            onClick={handleTabClick(TabIndex.APPEARANCE)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#ff9300]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.APPEARANCE && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_APPEARANCE")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.COLOR && "bg-[#CF63CF]"
            }`}
            onClick={handleTabClick(TabIndex.COLOR)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#CF63CF]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.COLOR && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_COLOR")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab !w-[200px] ${
              tabIndex === TabIndex.ANIMATION && "bg-[#59c059]"
            }`}
            onClick={handleTabClick(TabIndex.ANIMATION)}
          >
            <div className="blockly__toolbox-tab__icon bg-[#59c059]" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.ANIMATION && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_ANIMATION")}
            </p>
          </div>

          {/* <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.SOUND && "blockly__toolbox-tab-selected"
            }`}
            onClick={handleTabClick(TabIndex.SOUND)}
          >
            <div className="blockly__toolbox-tab__icon bg-strong/cyan/lime/green" />
            <p className="text blockly__toolbox-tab__text">おんがく</p>
          </div> */}

          <div className="flex-row-view w-full h-[2px] bg-beige/alt" />
          <div className="flex-row-view w-full h-[2px] bg-white" />

          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.CONTROL && "bg-strong/cyan/lime/green"
            }`}
            onClick={handleTabClick(TabIndex.CONTROL)}
          >
            <div className="blockly__toolbox-tab__icon bg-strong/cyan/lime/green" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.CONTROL && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_CONTROL")}
            </p>
          </div>

          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.LOGIC && "bg-light/blue"
            }`}
            onClick={handleTabClick(TabIndex.LOGIC)}
          >
            <div className="blockly__toolbox-tab__icon bg-light/blue" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.LOGIC && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_LOGIC")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.MATH && "bg-moderate/lime/green"
            }`}
            onClick={handleTabClick(TabIndex.MATH)}
          >
            <div className="blockly__toolbox-tab__icon bg-moderate/lime/green" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.MATH && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_MATH")}
            </p>
          </div>

          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.VARIABLE && "bg-vivid/orange"
            }`}
            onClick={handleTabClick(TabIndex.VARIABLE)}
          >
            <div className="blockly__toolbox-tab__icon bg-vivid/orange" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.VARIABLE && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_VARIABLE")}
            </p>
          </div>
          <div
            className={`blockly__toolbox-tab ${
              tabIndex === TabIndex.PROCEDURE && "bg-light/red"
            }`}
            onClick={handleTabClick(TabIndex.PROCEDURE)}
          >
            <div className="blockly__toolbox-tab__icon bg-light/red" />
            <p
              className={`text blockly__toolbox-tab__text ${
                tabIndex === TabIndex.PROCEDURE && "!text-white"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_BLOCKLY_PROCEDURE")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
