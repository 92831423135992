import { Course } from "features/courses/types";

export enum CourseTab {
  COURSE = "course",
  BUILDER = "builder",
}

export const PAGE_LIMIT = 4;

export const courses = (debug: boolean, lab: boolean) => ({
  [CourseTab.COURSE]: [
    {
      course: Course.ALGORITHM,
      name: "アルゴリズムを\nまなぼう！",
      show: true,
    },
    {
      course: Course.PROGRAM,
      name: "はじめての\nプログラミング",
      show: true,
    },
    {
      course: Course.ALGORITHM_ADVANCED,
      name: "アルゴリズムを\nまなぼう！",
      show: true,
    },
    {
      course: Course.GROUP_WORK,
      name: "グループ\nワーク",
      show: true,
    },
    {
      course: Course.NISHISHINJUKU,
      name: "工学院大学",
      show: debug,
    },
    {
      course: Course.TAKUSHOKU_BASIC,
      name: "拓殖大学",
      show: debug,
    },
    {
      course: Course.TAKUSHOKU_ADVANCE,
      name: "拓殖大学",
      show: debug,
    },
  ],
  [CourseTab.BUILDER]: [
    {
      course: Course.BUILDER,
      name: "ビルダー",
      show: true,
    },
  ],
});
