import { useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Howl } from "howler";
import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { RootState } from "app/store";
import Constants from "common/constant";

export const useInitCharacterBlock = () => {
  const { sound } = useSelector((state: RootState) => state.config.userConfig);
  const soundRef = useRef(sound);

  useEffect(() => {
    soundRef.current = sound;
  }, [sound]);

  const playCharacterSound = useCallback(
    (src: string) => () => {
      new Howl({
        mute: !soundRef.current.enable,
        volume: soundRef.current.volume,
        autoplay: true,
        loop: false,
        src: [src],
      });
    },
    []
  );

  const InitCharacterBlock = () => {
    const characterDuckEventStartBlock = () => ({
      type: "character_event_duck",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    });
    Blockly.Blocks["character_event_duck"] = {
      init: function () {
        this.appendDummyInput()
          .appendField("%{BKY_COURSE_START_BLOCK}")
          .appendField(
            new Blockly.FieldImage(
              `${Constants.assetHost}/assets/images/character_duck_head.png`,
              56,
              42,
              "*",
              playCharacterSound(
                `${Constants.assetHost}/assets/sounds/sound_duck.mp3`
              ),
              null,
              null
            )
          );
        this.jsonInit(characterDuckEventStartBlock());
        this.setCollapsed(false);
        this.setColour("#FFBF00");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_event_duck"] = function (block: Blockly.Block) {
      const logic = BlocklyJs.statementToCode(block, "DO");
      const code = `
      return async () => {
        const character_type = "character_event_duck";
        window.LoopTrap = 20;
        if(index === 1) {
          await util.sleep(2000);
        } else if(index === 2){
          await util.sleep(4000);
        };
        util.highlightBlock("${block.id}", true);
        await util.sleep(1000);
        util.highlightBlock("${block.id}", false);
        ${logic}
        if(scene.isDisposed){
          console.log("scene has been disposed.");
        } else {
          util.handleCharacterGameOver(character_type);
        };
      }
      `;
      return code;
    };

    const characterDogEventStartBlock = () => ({
      type: "character_event_dog",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    });
    Blockly.Blocks["character_event_dog"] = {
      init: function () {
        this.appendDummyInput()
          .appendField("%{BKY_COURSE_START_BLOCK}")
          .appendField(
            new Blockly.FieldImage(
              `${Constants.assetHost}/assets/images/character_dog_head.png`,
              56,
              42,
              "*",
              playCharacterSound(
                `${Constants.assetHost}/assets/sounds/sound_dog.mp3`
              ),
              null,
              null
            )
          );
        this.jsonInit(characterDogEventStartBlock());
        this.setCollapsed(false);
        this.setColour("#FFBF00");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_event_dog"] = function (block: Blockly.Block) {
      const logic = BlocklyJs.statementToCode(block, "DO");
      const code = `
      return async () => {
        const character_type = "character_event_dog";
        window.LoopTrap = 20;
        if(index === 1) {
          await util.sleep(2000);
        } else if(index === 2){
          await util.sleep(4000);
        };
        util.highlightBlock("${block.id}", true);
        await util.sleep(1000);
        util.highlightBlock("${block.id}", false);
        
        ${logic}
  
        if(scene.isDisposed){
          console.log("scene has been disposed.");
        } else {
          util.handleCharacterGameOver(character_type);
        };
      }
      `;
      return code;
    };

    const characterBearEventStartBlock = () => ({
      type: "character_event_bear",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    });
    Blockly.Blocks["character_event_bear"] = {
      init: function () {
        this.appendDummyInput()
          .appendField("%{BKY_COURSE_START_BLOCK}")
          .appendField(
            new Blockly.FieldImage(
              `${Constants.assetHost}/assets/images/character_bear_head.png`,
              56,
              42,
              "*",
              playCharacterSound(
                `${Constants.assetHost}/assets/sounds/sound_bear.mp3`
              ),
              null,
              null
            )
          );
        this.jsonInit(characterBearEventStartBlock());
        this.setCollapsed(false);
        this.setColour("#FFBF00");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_event_bear"] = function (block: Blockly.Block) {
      const logic = BlocklyJs.statementToCode(block, "DO");
      const code = `
      return async () => {
        const character_type = "character_event_bear";
        window.LoopTrap = 20;
        if(index === 1) {
          await util.sleep(2000);
        } else if(index === 2){
          await util.sleep(4000);
        };
        util.highlightBlock("${block.id}", true);
        await util.sleep(1000);
        util.highlightBlock("${block.id}", false);
        
        ${logic}
  
        if(scene.isDisposed){
          console.log("scene has been disposed.");
        } else {
          util.handleCharacterGameOver(character_type);
        };
      }
      `;
      return code;
    };

    Blockly.Blocks["character_motion_move"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_MOVE}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_move"] = function (block: Blockly.BlockSvg) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.moveSound();await util.action.performMove(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_jump"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField("    ジャンプ    ");
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_jump"] = function (block: Blockly.BlockSvg) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.jumpSound();await util.action.performJump(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_turn_left"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_LEFT}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_turn_left"] = function (
      block: Blockly.BlockSvg
    ) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.turnSound();await util.action.performTurnToLeft(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_turn_right"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_RIGHT}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_turn_right"] = function (
      block: Blockly.BlockSvg
    ) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.turnSound();await util.action.performTurnToRight(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };
  };
  return InitCharacterBlock;
};
