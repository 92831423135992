export enum BlockType {
  EVENT_START = "character_event",
  CHARACTER_EVENT_DUCK = "character_event_duck",
  CHARACTER_EVENT_DOG = "character_event_dog",
  CHARACTER_EVENT_BEAR = "character_event_bear",
  MOTION_MOVE = "character_motion_move",
  MOTION_JUMP = "character_motion_jump",
  MOTION_TURN_LEFT = "character_motion_turn_left",
  MOTION_TURN_RIGHT = "character_motion_turn_right",
  CONTROLS_REPEAT_INTERNAL = "controls_repeat_internal",
  CONTROLS_WHILEUNTIL_COLOUR_INTERNAL = "controls_whileUntil_colour_internal",
  CONTROLS_IF_COLOUR_INTERNAL = "controls_if_colour_internal",
  CONTROLS_REPEAT_VAR_INTERNAL = "controls_repeat_var_internal",
  CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL = "controls_whileUntil_colour_var_internal",
  CONTROLS_IF_COLOUR_VAR_INTERNAL = "controls_if_colour_var_internal",
  MATH_NUMBER = "math_number",
  COLOUR_PICKER_INTERNAL = "colour_picker_internal",
  VARIABLES_SET = "variables_set",
  VARIABLES_GET = "variables_get",
  PROCEDURES_DEFNORETURN = "procedures_defnoreturn",
  PROCEDURES_CALLNORETURN = "procedures_callnoreturn",
}
