import produce from "immer";
import {
  ComponentType,
  BorderStyleType,
  ShadowStyleType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";

const Screen: ComponentType = {
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_SCREEN";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_SCREEN";

    const initialWidth = 1024;
    const initialHeight = 768;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.backgroundColor = "#ffffff";
    draft.style.defaultView.borderStyle = BorderStyleType.NONE;
    draft.style.defaultShadow.shadowStyle = ShadowStyleType.NONE;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.layout.position = "relative";
    draft.style.view.backgroundColor = "#ffffff";
    draft.style.view.borderStyle = BorderStyleType.NONE;
    draft.style.shadow.shadowStyle = ShadowStyleType.NONE;

    draft.events = [
      "screen_loading_event",
      "screen_click_event",
      "screen_navigate_event",
      "variables_procedures",
    ];
  }),
};

export default Screen;
