import { useState, useContext, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Howl } from "howler";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { Loading } from "common/elements";
import { ConfigContext } from "features/top";
import { getMapProjectContent } from "./api";
import { Canvas, MapPreview } from "./canvas";

export const Builder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isFirst, setIsFirst] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const configContext = useContext(ConfigContext);
  const [bgm, setBgm] = useState<Howl | null>(null);
  const { id: project_id } = useParams<{ id?: string }>();
  const preview = useSelector(
    (state: RootState) => state.builder.actionMenu.preview
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const loading = useSelector((state: RootState) => state.builder.loading);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      setTimeout(() => navigate(`/courses`), 300);
    }
  }, [user.active.uid]);

  useEffect(() => {
    dispatch(
      getMapProjectContent({
        project_id,
      })
    )
      .then(unwrapResult)
      .then(() => setLoadData(false));
  }, []);

  useEffect(() => {
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !config.bgm.enable,
        volume: config.bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      setBgm(bgmSound);
    }
  }, []);

  useEffect(() => {
    if (bgm) {
      bgm.mute(!config.bgm.enable);
      bgm.volume(config.bgm.volume);
    }
  }, [config]);

  useEffect(() => {
    if (configContext.bgmSound && bgm) {
      bgm.play();
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound && bgm) {
        bgm.stop();
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound, bgm]);

  if (loadData) {
    return <Loading />;
  }

  return (
    <div className="flex-col-center w-full h-full bg-transparent">
      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />
      {loading && <Loading />}
      {preview ? <MapPreview /> : <Canvas />}
    </div>
  );
};
