/* eslint-disable react-hooks/exhaustive-deps */
import {
  useRef,
  useMemo,
  useState,
  useEffect,
  PointerEventHandler,
} from "react";
import produce from "immer";
import { Mask } from "common/elements";
import { Vector2D } from "common/types";
import { ComponentManager, ComponentProperty } from "common/components";
import { PropertiesEntity } from "features/creator/types";
import { ContextMenu } from "features/creator/design/ContextMenu";

export const ResponderLinePanel = (props: {
  scale: number;
  zIndex: number;
  selected: boolean;
  groupDelta: Vector2D;
  grouping: boolean;
  groupMoving: boolean;
  propertyEntity: PropertiesEntity;
  onSelected: () => void;
  onActionEnd: (newProperty: ComponentProperty, record?: boolean) => void;
  handleDelete: (id: string) => void;
}) => {
  const {
    scale,
    zIndex,
    selected,
    groupDelta,
    grouping,
    groupMoving,
    propertyEntity,
    onSelected,
    onActionEnd,
    handleDelete,
  } = props;
  const { id, typeId } = propertyEntity;
  const [property, _setProperty] = useState<ComponentProperty>(
    propertyEntity.property
  );
  const propertyRef = useRef(property);
  const setProperty = (data: ComponentProperty) => {
    propertyRef.current = data;
    _setProperty(data);
  };

  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [start, setStart] = useState({
    x: propertyRef.current.style.transform.translateX,
    y: propertyRef.current.style.transform.translateY,
  });
  const [clicked, setClicked] = useState(false);
  const [context, setContext] = useState(false);
  const [contextPosition, setContextPosition] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const [anchor1Rotation, setAnchor1Rotation] = useState(false);
  const [anchor2Rotation, setAnchor2Rotation] = useState(false);
  const [anchor1Offset, setAnchor1Offset] = useState<Vector2D>({ x: 0, y: 0 });
  const [anchor2Offset, setAnchor2Offset] = useState<Vector2D>({ x: 0, y: 0 });
  const [anchor1ClientXY, setAnchor1ClientXY] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const [anchor2ClientXY, setAnchor2ClientXY] = useState<Vector2D>({
    x: 0,
    y: 0,
  });

  const [preClientXY, _setPreClientXY] = useState<Vector2D>({ x: 0, y: 0 });
  const preClientRef = useRef(preClientXY);
  const setPreClientXY = (data: Vector2D) => {
    preClientRef.current = data;
    _setPreClientXY(data);
  };
  const [timestamp, _setTimestamp] = useState<number>(0);
  const timestampRef = useRef(timestamp);
  const setTimestamp = (data: number) => {
    timestampRef.current = data;
    _setTimestamp(data);
  };
  const [startClientXY, setStartClientXY] = useState<Vector2D>({ x: 0, y: 0 });

  useEffect(() => {
    setProperty(propertyEntity.property);
  }, [propertyEntity.property]);

  useEffect(() => {
    if (grouping && !groupMoving) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX += groupDelta.x;
        draft.style.transform.translateY += groupDelta.y;
      });

      onActionEnd(newProperty, false);
    }
  }, [groupMoving]);

  const width =
    propertyRef.current.style.layout.width +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius;
  const LINE_STROKE_WIDTH =
    propertyRef.current.style.layout.height +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius;
  const ANCHOR_SIZE = 36;
  const ANCHOR_INNER_SIZE = 16;

  const calcVector = (distance: number, radius: number): Vector2D => {
    return {
      x: distance * Math.cos(radius),
      y: distance * Math.sin(radius),
    };
  };

  const onAnchor1Rotation = (clientX: number, clientY: number) => {
    const newAnchorOffset = {
      x: (clientX - anchor1ClientXY.x) / scale,
      y: (clientY - anchor1ClientXY.y) / scale,
    };
    setAnchor1ClientXY({
      x: clientX,
      y: clientY,
    });
    setAnchor1Offset(newAnchorOffset);

    const preWidth = propertyRef.current.style.layout.width;
    const preRadius = propertyRef.current.style.transform.radius;
    const preRefPoint = calcVector(preWidth / 2, preRadius);

    const refPointOffset = {
      x: preWidth * Math.cos(preRadius) - newAnchorOffset.x,
      y: preWidth * Math.sin(preRadius) - newAnchorOffset.y,
    };

    const newRadius = Math.atan2(refPointOffset.y, refPointOffset.x);
    const newWidth = Math.hypot(refPointOffset.y, refPointOffset.x);
    const newRefPoint = calcVector(newWidth / 2, newRadius);

    const offsetX = (newWidth - preWidth) / 2 - preRefPoint.x + newRefPoint.x;
    const offsetY = preRefPoint.y - newRefPoint.y;

    const newProperty = produce(propertyRef.current, (draft) => {
      draft.style.layout.width = newWidth;
      draft.style.transform.translateX =
        draft.style.transform.translateX - offsetX;
      draft.style.transform.translateY =
        draft.style.transform.translateY + offsetY;
      draft.style.transform.radius = newRadius;
      draft.style.transform.rotation = `${(newRadius * 180) / Math.PI}`;
    });
    setProperty(newProperty);
  };

  const onAnchor1RotationPointerStart: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setAnchor1Rotation(true);
    }
    setAnchor1ClientXY({ x: e.clientX, y: e.clientY });
  };
  const onAnchor1RotationPointerMove: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || anchor1Rotation) {
      onAnchor1Rotation(e.clientX, e.clientY);
    }
  };
  const onAnchor1RotationPointerEnd: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setAnchor1Rotation(false);
    }
    setAnchor1Offset({ x: 0, y: 0 });
    onActionEnd(propertyRef.current);
  };

  const onAnchor2Rotation = (clientX: number, clientY: number) => {
    const newAnchorOffset = {
      x: (clientX - anchor2ClientXY.x) / scale,
      y: (clientY - anchor2ClientXY.y) / scale,
    };
    setAnchor2ClientXY({
      x: clientX,
      y: clientY,
    });
    setAnchor2Offset(newAnchorOffset);

    const preWidth = propertyRef.current.style.layout.width;
    const preRadius = propertyRef.current.style.transform.radius;
    const preRefPoint = calcVector(preWidth / 2, preRadius);

    const refPointOffset = {
      x: preWidth * Math.cos(preRadius) + newAnchorOffset.x,
      y: preWidth * Math.sin(preRadius) + newAnchorOffset.y,
    };

    const newRadius = Math.atan2(refPointOffset.y, refPointOffset.x);
    const newWidth = Math.hypot(refPointOffset.y, refPointOffset.x);
    const newRefPoint = calcVector(newWidth / 2, newRadius);

    const offsetX = (newWidth - preWidth) / 2 + (preRefPoint.x - newRefPoint.x);
    const offsetY = newRefPoint.y - preRefPoint.y;

    const newProperty = produce(propertyRef.current, (draft) => {
      draft.style.layout.width = newWidth;
      draft.style.transform.translateX =
        draft.style.transform.translateX - offsetX;
      draft.style.transform.translateY =
        draft.style.transform.translateY + offsetY;
      draft.style.transform.radius = newRadius;
      draft.style.transform.rotation = `${(newRadius * 180) / Math.PI}`;
    });
    setProperty(newProperty);
  };

  const onAnchor2RotationPointerStart: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setAnchor2Rotation(true);
    }
    setAnchor2ClientXY({ x: e.clientX, y: e.clientY });
  };
  const onAnchor2RotationPointerMove: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || anchor2Rotation) {
      onAnchor2Rotation(e.clientX, e.clientY);
    }
  };
  const onAnchor2RotationPointerEnd: PointerEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setAnchor2Rotation(false);
    }
    setAnchor2Offset({ x: 0, y: 0 });
    onActionEnd(propertyRef.current);
  };

  const onPointerStart: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelected();
    setPreClientXY({ x: e.clientX, y: e.clientY });
    setStartClientXY({ x: e.clientX, y: e.clientY });
    if (e.pointerType === "mouse") {
      setClicked(true);
    } else {
      setTimestamp(e.timeStamp);
    }
  };
  const onPointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || clicked) {
      const dx = (e.clientX - preClientRef.current.x) / scale;
      const dy = (e.clientY - preClientRef.current.y) / scale;
      setPreClientXY({ x: e.clientX, y: e.clientY });
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX =
          draft.style.transform.translateX + dx;
        draft.style.transform.translateY =
          draft.style.transform.translateY + dy;
      });
      setProperty(newProperty);
    }
  };
  const onPointerEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setClicked(false);
      if (e.button === 2) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
    } else {
      const elapsed = (e.timeStamp - timestamp) / 1000;
      const dx = (e.clientX - startClientXY.x) / scale;
      const dy = (e.clientY - startClientXY.y) / scale;
      if (elapsed > 0.5 && Math.abs(dx) < 3 && Math.abs(dy) < 3) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
      setTimestamp(0);
    }

    if (
      propertyRef.current.style.transform.translateX +
        propertyRef.current.style.layout.width <
        0 ||
      propertyRef.current.style.transform.translateX > 1024 ||
      propertyRef.current.style.transform.translateY +
        propertyRef.current.style.layout.height <
        0 ||
      propertyRef.current.style.transform.translateY > 768
    ) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX = start.x;
        draft.style.transform.translateY = start.y;
      });
      setProperty(newProperty);
      onActionEnd(newProperty);
    } else {
      setStart({
        x: propertyRef.current.style.transform.translateX,
        y: propertyRef.current.style.transform.translateY,
      });
      onActionEnd(propertyRef.current);
    }
  };

  return (
    <>
      {selected && context && (
        <ContextMenu
          id={id}
          scale={scale}
          position={{
            x: contextPosition.x,
            y: contextPosition.y,
          }}
          close={() => setContext(false)}
          handleDelete={handleDelete}
        />
      )}
      {clicked && (
        <Mask onPointerMove={onPointerMove} onPointerUp={onPointerEnd} />
      )}
      {anchor1Rotation && (
        <div
          id="action-rotation-anchor1-mask"
          className="w-full h-full absolute top-0 left-0 cursor-grabbing z-[10003]"
          onPointerMove={onAnchor1RotationPointerMove}
          onPointerUp={onAnchor1RotationPointerEnd}
        />
      )}
      {anchor2Rotation && (
        <div
          id="action-rotation-anchor1-mask"
          className="w-full h-full absolute top-0 left-0 cursor-grabbing z-[10003]"
          onPointerMove={onAnchor2RotationPointerMove}
          onPointerUp={onAnchor2RotationPointerEnd}
        />
      )}
      {(selected || grouping) && (
        <div
          id={`component-resize-panel-${id}`}
          className="absolute top-0 left-0 cursor-grabbing z-[10001] pointer-events-none"
          style={{
            width: width,
            height: LINE_STROKE_WIDTH,
            transform: `translate(${
              propertyRef.current.style.transform.translateX +
              (grouping && groupMoving ? groupDelta.x : 0)
            }px, ${
              propertyRef.current.style.transform.translateY +
              (grouping && groupMoving ? groupDelta.y : 0)
            }px) rotate(${propertyRef.current.style.transform.rotation}deg)`,
            willChange: "transform",
          }}
        >
          <div
            className="flex-row-center !absolute z-[10002] bottom-[-34px] left-0"
            style={{
              backfaceVisibility: "hidden",
              width: width,
              height: 24,
            }}
          >
            <p className="text text-blue/80 !text-[14px]">
              {propertyRef.current.name}
            </p>
          </div>

          {grouping && (
            <div
              className="absolute bg-green/altcolor/10 box-border border-solid w-full h-full border-green/60"
              style={{
                height: LINE_STROKE_WIDTH * 5,
                top: -LINE_STROKE_WIDTH * 2,
              }}
            />
          )}

          {selected && (
            <>
              <div
                className="flex-col-center"
                style={{
                  zIndex: 10001,
                  width: ANCHOR_SIZE,
                  height: ANCHOR_SIZE,
                  boxSizing: "border-box",
                  position: "absolute",
                  pointerEvents: "auto",
                  cursor: "ew-resize",
                  left: -ANCHOR_SIZE / 2,
                  top: -ANCHOR_SIZE / 2 + LINE_STROKE_WIDTH / 2,
                  transform: `translate(${anchor1Offset.x}px, ${anchor1Offset.y}px))`,
                  willChange: "transform",
                }}
                onPointerDown={onAnchor1RotationPointerStart}
                onPointerMove={onAnchor1RotationPointerMove}
                onPointerUp={onAnchor1RotationPointerEnd}
              >
                <div
                  className="bg-white border-solid border-[1px] border-blue/80"
                  style={{
                    width: ANCHOR_INNER_SIZE,
                    height: ANCHOR_INNER_SIZE,
                    borderRadius: ANCHOR_INNER_SIZE / 2,
                    backgroundColor: "#FEFDFE",
                  }}
                />
              </div>
              <div
                className="flex-col-center"
                style={{
                  zIndex: 10001,
                  width: ANCHOR_SIZE,
                  height: ANCHOR_SIZE,
                  borderRadius: ANCHOR_SIZE / 2,
                  boxSizing: "border-box",
                  position: "absolute",
                  pointerEvents: "auto",
                  cursor: "ew-resize",
                  right: -ANCHOR_SIZE / 2,
                  top: -ANCHOR_SIZE / 2 + LINE_STROKE_WIDTH / 2,
                  transform: `translate(${anchor2Offset.x}px, ${anchor2Offset.y}px))`,
                  willChange: "transform",
                }}
                onPointerDown={onAnchor2RotationPointerStart}
                onPointerMove={onAnchor2RotationPointerMove}
                onPointerUp={onAnchor2RotationPointerEnd}
              >
                <div
                  className="bg-white border-solid border-[1px] border-blue/80"
                  style={{
                    width: ANCHOR_INNER_SIZE,
                    height: ANCHOR_INNER_SIZE,
                    borderRadius: ANCHOR_INNER_SIZE / 2,
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div
        id={`component-move-panel-${id}`}
        className="absolute top-0 left-0 mix-blend-normal"
        style={{
          zIndex: zIndex,
          width: width,
          height: LINE_STROKE_WIDTH,
          transform: `translate(${
            propertyRef.current.style.transform.translateX +
            (grouping && groupMoving ? groupDelta.x : 0)
          }px, ${
            propertyRef.current.style.transform.translateY +
            (grouping && groupMoving ? groupDelta.y : 0)
          }px) rotate(${propertyRef.current.style.transform.rotation}deg)`,
          willChange: "transform",
          opacity: propertyRef.current.style.view.opacity / 100,
          filter: `drop-shadow(${propertyRef.current.style.shadow.shadowOffset.width}px ${propertyRef.current.style.shadow.shadowOffset.height}px ${propertyRef.current.style.shadow.shadowRadius}px ${propertyRef.current.style.shadow.shadowColor}`,
        }}
      >
        <div
          className="absolute left-0 cursor-grab"
          style={{
            width: width,
            height:
              LINE_STROKE_WIDTH > ANCHOR_SIZE ? LINE_STROKE_WIDTH : ANCHOR_SIZE,
            top:
              LINE_STROKE_WIDTH > ANCHOR_SIZE
                ? 0
                : (LINE_STROKE_WIDTH - ANCHOR_SIZE) / 2,
          }}
          onPointerDown={onPointerStart}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerEnd}
        />

        <ChildComponent property={propertyRef.current} id={id} />
      </div>
    </>
  );
};
