import produce from "immer";
import {
  ComponentType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Rectangle: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_RECT";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_RECT";

    const initialWidth = 100;
    const initialHeight = 100;
    const initialBorderRadius = 0;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = initialBorderRadius;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = initialBorderRadius;
  }),
};

export default Rectangle;
