import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep } from "common/utils";
import { spin } from "./spin";
import { ySlide } from "./ySlide";

export const performAnimation = (scene: Scene, gem: AbstractMesh) => {
  const frameRate = 10;
  const ySlideAnimation = ySlide(gem.position.y, frameRate);
  const spinAnimation = spin(frameRate);

  scene.beginDirectAnimation(
    gem,
    [ySlideAnimation, spinAnimation],
    0,
    2 * frameRate,
    true
  );
};

export const performDisappear = async (scene: Scene, gem: AbstractMesh) => {
  await sleep(500);
  scene.stopAnimation(gem);
  const gemDisappear = new Animation(
    "gemDisappear",
    "position.y",
    2,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const currentPosition = new Vector3().copyFrom(gem.position);
  const keyFrames = [];
  keyFrames.push({
    frame: 0,
    value: currentPosition.y,
  });
  keyFrames.push({
    frame: 1,
    value: currentPosition.y + 4,
  });
  gemDisappear.setKeys(keyFrames);
  const spinAnimation = spin(10);
  const gemDisappearAnimation = scene.beginDirectAnimation(
    gem,
    [spinAnimation, gemDisappear],
    0,
    2,
    false
  );

  await gemDisappearAnimation.waitAsync();
  gem.setEnabled(false);
  await sleep(1300);
  gem.setEnabled(true);
  gem.position = currentPosition;
};
