import { IconArrowLeft, IconArrowRight } from "common/assets";

export const ArrowButton = ({
  handleMoveStage,
}: {
  handleMoveStage: (step: number) => () => void;
}) => (
  <>
    <div className="flex-row-el flex-center upsilon absolute left-1 pointer-events-auto cursor-pointer z-50">
      <button
        className="btn btn-primary w-[40px] h-[72px]"
        onClick={handleMoveStage(-1)}
      >
        <div className="flex-col-center mb-[2px]">
          <IconArrowLeft />
        </div>
      </button>
    </div>

    <div className="flex-row-el flex-center upsilon absolute right-1 pointer-events-auto cursor-pointer z-50">
      <button
        className="btn btn-primary w-[40px] h-[72px]"
        onClick={handleMoveStage(1)}
      >
        <div className="flex-col-center mb-[2px]">
          <IconArrowRight />
        </div>
      </button>
    </div>
  </>
);
