import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants from "common/constant";
import { IconPlayerPlay } from "common/assets";
import { btnDelay, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/course/slice";
import { WoodenBg } from "features/courses/assets";
import { GameHeader } from "features/courses/components";
import { StageList } from "features/courses/program/old_stage/StageList";

export const ProgramStageStartPage = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [start, setStart] = useState(true);
  const handleStart = () => {
    play();
    dispatch(actions.updateShowCourseList(true));
    btnDelay(() => setStart(false));
  };
  const handleAnimationEnd = () => {
    dispatch(actions.updateShowCourseTop(false));
    setStart(true);
  };
  return (
    <div
      className={`flex-col-el flex-center z-[100] w-full h-full bg-gray2/100 absolute will-change-transform ${
        start
          ? ""
          : "animate__animated animate__bounceOutRight pointer-events-none"
      }`}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="flex-col-el flex-center w-full h-full bg-white">
        <img
          alt="コース スタート画面"
          src={`${Constants.assetHost}/assets/images/course_program_start.png`}
          className="w-full h-full"
        />

        <div className="flex-col-el flex-center absolute bottom-14 phi">
          <button className="btn btn-vivid" onClick={handleStart}>
            <p className="text-body text-[48px]">
              {I18n.t("MSG_COURSE_START_BTN")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export const ProgramStagePage = () => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedStage = useSelector(
    (state: RootState) => state.course.selectedStage
  );
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const [selectedIndex, setSelectedIndex] = useState(
    selectedCourse === Course.PROGRAM ? selectedStage - 1 : 0
  );
  const display = useSelector(
    (state: RootState) => state.courses.message[Course.PROGRAM][selectedIndex]
  );
  const summary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  const handleGoToMap = () => {
    btnDelay(() => {
      play();
      dispatch(actions.updateSelectedStage({ stage: selectedIndex + 1 }));
      navigate(`${Course.PROGRAM}/stage/${selectedIndex + 1}`);
    });
  };

  return (
    <div className="flex-col-view w-full h-full bg-[#F5FCFF]">
      <GameHeader finished={summary.finished} />

      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_course_program_scene.png`}
        className="absolute top-0 left-0 object-cover opacity-70"
      />

      <div className="flex-row-view w-full h-[430px]">
        <div className="flex-row-view z-[1]">
          <StageList
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>

      <div className="flex-row-el flex-center w-full absolute bottom-3">
        <div className="flex-row-el flex-center relative w-[848px] h-[98px]">
          <div className="flex-col-el flex-center absolute">
            <WoodenBg />
          </div>

          <div className="flex-row-el flex-center absolute -top-8 left-10 pr-6 z-50 bg-white border-solid border-4 border-textcolor/black rounded-[8px] overflow-hidden">
            <div className="flex-col-center w-[48px] h-[48px] bg-red/100">
              <p className="title-text text-white !text-[39px]">
                {selectedIndex + 1}
              </p>
            </div>
            <div className="flex-col-center ml-3">
              <p className="text-body text-textcolor/black text-[32px]">
                {display[config.locale]?.name}
              </p>
            </div>
          </div>

          <div className="flex-row-el flex-center relative gap-6 mt-2">
            <div className="flex-row-el relative w-[520px] h-[56px]">
              <p className="text-body text-white text-[20px]">
                {display[config.locale]?.description}
              </p>
            </div>

            <div className="flex-row-el flex-center relative alpha">
              <button className="btn btn-primary" onClick={handleGoToMap}>
                <div className="flex-row-center gap-1 mb-[3px]">
                  <div className="flex-col-center w-[30px] h-[30px]">
                    <IconPlayerPlay color="white" width={2} />
                  </div>

                  <div className="flex-col-center">
                    <p className="text-body text-white text-[26px]">
                      {I18n.t("MSG_COURSE_GOTO_MAP_BTN")}
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
