export enum ConnectionStatus {
  OFFLINE = "offline",
  ONLINE = "online",
}

export enum PeeringStatus {
  DISCONNECTED = "disconnected",
  CONNECTED = "connected",
}

export enum PeerConnectionMessageType {
  GAME_START = "GAME_START",
  GAME_STOP = "GAME_STOP",
  TANGIBLE = "tangible",
}

export type PeerConnectionMessageContent = {
  content: {
    type: PeerConnectionMessageType;
    data: any;
  };
};

export type ConnectionState = {
  status: ConnectionStatus;
  peeringStatus: PeeringStatus;
  receive: PeerConnectionMessageContent;
  send: PeerConnectionMessageContent;
  peerId: string;
};
