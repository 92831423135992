/* eslint-disable react-hooks/exhaustive-deps */
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Howl } from "howler";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound, useScale } from "common/utils";
import { ConfigContext } from "features/top";

export const useInitGame = (
  stage: number,
  step: number,
  handleResize: () => void
) => {
  const { scale } = useScale();
  const navigate = useNavigate();
  const [isFirst, setIsFirst] = useState(true);
  const configContext = useContext(ConfigContext);
  const [gameBgm, setGameBgm] = useState<Howl | null>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      navigate("/courses");
    }
  }, [user]);

  useEffect(() => {
    if (!isFirst) {
      handleResize();
    }
  }, [scale]);

  useEffect(() => {
    console.log(`stage: ${stage}, step: ${step}`);
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !config.bgm.enable,
        volume: config.bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      setGameBgm(bgmSound);
    }
  }, []);

  useEffect(() => {
    if (gameBgm) {
      gameBgm.mute(!config.bgm.enable);
      gameBgm.volume(config.bgm.volume);
    }
  }, [config.bgm]);

  useEffect(() => {
    if (configContext.bgmSound && gameBgm) {
      gameBgm.play();
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound && gameBgm) {
        gameBgm.stop();
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound, gameBgm]);
  return { gameBgm };
};

export const useCanvas = (
  elementRef: MutableRefObject<HTMLDivElement>,
  onSceneReady: (
    canvas: HTMLCanvasElement,
    engine: Engine,
    scene: Scene
  ) => void,
  onError: (error: any) => void,
  reload: boolean
) => {
  useEffect(() => {
    let scene: Scene;
    let engine: Engine;

    var canvas = document.getElementById("app-canvas") as HTMLCanvasElement;

    if (elementRef.current) {
      try {
        if (canvas) {
          const target = elementRef.current.getBoundingClientRect();
          canvas.style.top = `${target.top}px`;
          canvas.style.left = `${target.left}px`;
          canvas.style.width = `${target.width}px`;
          canvas.style.height = `${target.height}px`;
        } else {
          const root = document.body;
          const target = elementRef.current.getBoundingClientRect();
          const newCanvasNode = document.createElement("canvas");
          newCanvasNode.id = "app-canvas";
          newCanvasNode.style.transition = "opacity 300ms";
          newCanvasNode.style.position = "absolute";
          newCanvasNode.style.pointerEvents = "auto";
          newCanvasNode.style.top = `${target.top}px`;
          newCanvasNode.style.left = `${target.left}px`;
          newCanvasNode.style.width = `${target.width}px`;
          newCanvasNode.style.height = `${target.height}px`;
          root.appendChild(newCanvasNode);
          canvas = newCanvasNode;
        }
        canvas.style.opacity = "0";
        engine = new Engine(
          canvas,
          true,
          { preserveDrawingBuffer: true, stencil: true },
          true
        );
        scene = new Scene(engine);
        onSceneReady(canvas, engine, scene);
        canvas.style.opacity = "1";
      } catch (error) {
        onError(error);
      }
    }
    return () => {
      canvas.style.width = "0";
      canvas.style.height = "0";
      canvas.width = 1;
      canvas.height = 1;
      if (engine) {
        scene.dispose();
        engine.dispose();
        scene = null;
        engine = null;
        canvas = null;
      }
    };
  }, [reload, elementRef]);
};

export const useGameSound = () => {
  const { sound } = useSelector((state: RootState) => state.config.userConfig);

  const jumpSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/jump.mp3`
  );
  const turnSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/turn.mp3`
  );
  const moveSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/footstep.mp3`
  );
  const appearanceSound = usePlaySound(
    `${Constants.assetHost}/assets/sounds/appearance.mp3`,
    { volume: 5 }
  );
  const fallingSound = usePlaySound(
    `${Constants.assetHost}/assets/sounds/falling.mp3`,
    { volume: 5 }
  );

  const startCountdownSound = usePlaySound(
    `${Constants.assetHost}/assets/sounds/game_start_countdown.mp3`,
    {
      volume: sound.volume * 5,
    }
  );
  const startSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/start.mp3`
  );
  const itemGetSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const failureSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/failure.mp3`
  );
  const levelclearSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/levelclear.mp3`
  );
  const levelclearCheersSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/levelclear_cheers.mp3`
  );

  return {
    jumpSound,
    turnSound,
    moveSound,
    startSound,
    appearanceSound,
    fallingSound,
    itemGetSound,
    failureSound,
    levelclearSound,
    startCountdownSound,
    levelclearCheersSound,
  };
};

export const useGameBgm = () => {
  const { bgm } = useSelector((state: RootState) => state.config.userConfig);

  const gameStandByBgm = useMemo(() => {
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !bgm.enable,
        volume: bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      return bgmSound;
    }
  }, []);

  const gameRunningBgm = useMemo(() => {
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !bgm.enable,
        volume: bgm.volume * 0.8,
        autoplay: false,
        loop: true,
        rate: 0.9,
        src: [`${Constants.assetHost}/assets/bgm/game_running_bgm.mp3`],
      });
      return bgmSound;
    }
  }, []);

  useEffect(() => {
    if (gameStandByBgm) {
      gameStandByBgm.mute(!bgm.enable);
      gameStandByBgm.volume(bgm.volume);
    }
    if (gameRunningBgm) {
      gameRunningBgm.mute(!bgm.enable);
      gameRunningBgm.volume(bgm.volume * 0.8);
    }
  }, [bgm.enable, bgm.volume]);

  return {
    gameStandByBgm,
    gameRunningBgm,
  };
};
