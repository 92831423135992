import { TileSetMapType, CHARACTER_DIRECTION } from "common/model";

const landscapeLayer1 = [
  [23, 31, 31, 31, 23],
  [22, 26, 22, 26, 22],
  [23, 31, 31, 31, 22],
  [23, 31, 31, 31, 22],
  [23, 23, 23, 31, 22],
];
const landscapeLayer2 = [
  [42, 0, 0, 0, 35],
  [1, 13, 0, 13, 12],
  [44, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [38, 0, 44, 0, 11],
];
const landscapeLayer3 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 8],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 5],
];

const landscape = [landscapeLayer1, landscapeLayer2, landscapeLayer3];

const map: TileSetMapType = {
  character: {
    direction: CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X,
  },
  landscape: landscape,
};

export default map;
