import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { Course } from "features/courses/types";
import { AppUnselected } from "features/creator/assets";

const Unselect = () => (
  <div className="flex-col-center gap-2">
    <AppUnselected />
  </div>
);

export const CourseProgress = ({ course }: { course: Course }) => {
  const algorithmSummary = useSelector(
    (state: RootState) => state.courses.progresses.algorithm.summary
  );
  const algorithmStageProgresses = Math.floor(
    (algorithmSummary.finished / algorithmSummary.total) * 100
  );
  const programSummary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const programStageProgresses = Math.floor(
    (programSummary.finished / programSummary.total) * 100
  );
  const courseSummary = useSelector(
    (state: RootState) => state.courses.courses[course]
  );
  const progress =
    course === Course.ALGORITHM
      ? algorithmStageProgresses
      : course === Course.PROGRAM
      ? programStageProgresses
      : Math.floor(
          courseSummary.summary.finished / courseSummary.summary.total
        ) * 100;

  return (
    <div className="flex-col-el flex-center w-full h-full relative">
      <div className="flex-row-el absolute top-0 bg-white rounded-b-full px-8 py-1 border-solid border-2 border-t-0 border-gray2/60">
        <p className="text-body text-font-size-caption1-narrow text-gray/80">
          これまでの取り込み
        </p>
      </div>
      <div className="flex-row-el flex-center gap-2">
        <div className="flex-col-el mt-1">
          <span className="material-symbols-outlined text-[56px] text-gray/80">
            select_check_box
          </span>
        </div>
        <div className="flex-col-el">
          <p className="text-body text-font-caption2-narrow text-gray/80">
            クリア率
          </p>
          <div className="flex-row-el flex-center gap-2">
            <div className="flex-row-el w-[160px] h-[32px] rounded-full bg-gray2/60 overflow-hidden">
              <div
                style={{ width: progress }}
                className="flex-row-el h-full bg-orange/80"
              />
            </div>
            <p className="text-body text-font-size-body2-narrow text-gray/80">
              {progress}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SubDisplay = () => {
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  return (
    <div className="flex-col-el flex-center relative w-[356px] h-[160px] bg-beige/60 border-solid border-gray/100 border-[6px] rounded-xl">
      {selectedCourse && selectedCourse !== Course.BUILDER ? (
        <CourseProgress course={selectedCourse} />
      ) : (
        <Unselect />
      )}
    </div>
  );
};
