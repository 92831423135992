import { useState, useEffect } from "react";
import { useDragLayer } from "react-dnd";
import { useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { Scene } from "@babylonjs/core/scene";
import { Mesh } from "@babylonjs/core/Meshes";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { usePlaySound } from "common/utils";
import {
  AssetModel,
  AssetModelType,
  ActionCommandType,
} from "features/builder/types";
import { actions } from "features/builder/slice";
import { useActionCommand } from "features/builder/hook";
import { createPreviewMesh } from "./loader";
import { createGround, createOuterMesh } from "./util";

export const useDndScene = (scene: Scene | null) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const handleAction = useActionCommand();
  const [creating, setCreating] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [previewedMesh, setPreviewedMesh] = useState<Mesh | null>(null);
  const [selectedModel, setSelectedModel] = useState<AssetModel | null>(null);

  const { item, clientOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  useEffect(() => {
    if (isDragging) {
      const camera = scene.getCameraByName("camera") as ArcRotateCamera;
      camera?.detachControl();
      setSelectedModel(item);
      scene.getMaterialByName("ground_layer").alpha = 0.3;
      (item.type === AssetModelType.RIVER ||
        item.type === AssetModelType.GROUND) &&
        (scene.getMaterialByName("ground").alpha = 0.3);
    } else {
      if (scene && selectedModel) {
        setSelectedModel(null);
        setOffset({ x: 0, y: 0 });
        scene.getMaterialByName("ground").alpha = 0;
        scene.getMaterialByName("ground_layer").alpha = 0;
        if (previewedMesh) {
          // 新しいメッシュを追加する
          play();
          scene.animationGroups.forEach((group) => {
            group.stop();
            const index = scene.animationGroups.indexOf(group);
            if (index !== -1) {
              scene.animationGroups.splice(index, 1);
            }
            group.dispose();
          });
          const hitGroundMesh = scene.pick(offset.x, offset.y, (mesh) =>
            mesh.name.includes("ground")
          );
          const outer = previewedMesh.parent as Mesh;
          if (
            hitGroundMesh.hit &&
            hitGroundMesh.pickedMesh.position.x === outer.position.x &&
            hitGroundMesh.pickedMesh.position.z === outer.position.z &&
            (selectedModel.type !== AssetModelType.RIVER &&
            selectedModel.type !== AssetModelType.GROUND
              ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
              : true)
          ) {
            const meshId = nanoid();
            const boundingInfo = outer.getBoundingInfo();
            const name = `asset_model_layer_${
              hitGroundMesh.pickedMesh.position.x
            }_${hitGroundMesh.pickedMesh.position.z}_${Math.round(
              boundingInfo.boundingBox.maximumWorld.y
            )}`;
            outer.getChildMeshes().forEach((subMesh) => {
              if (subMesh.material) {
                subMesh.material.alpha = 1;
              }
              if (subMesh.name !== "outer") {
                subMesh.id = meshId;
                subMesh.name = name;
              }
            });
            previewedMesh.metadata["position"] = {
              x: hitGroundMesh.pickedMesh.position.x,
              y: hitGroundMesh.pickedMesh.position.y,
              z: hitGroundMesh.pickedMesh.position.z,
            };
            previewedMesh.metadata[
              "bottomLayer"
            ] = hitGroundMesh.pickedMesh?.name.split("_").pop();
            createGround(
              scene,
              boundingInfo.boundingBox,
              selectedModel.type,
              hitGroundMesh.pickedMesh.position,
              hitGroundMesh.pickedMesh?.name.split("_").pop()
            );
            handleAction({
              type: ActionCommandType.ADD_MODE,
              model: {
                id: meshId,
                type: selectedModel.type,
                name,
                filename: selectedModel.filename,
                rotation: selectedModel.rotation,
                offset: selectedModel.offset,
                scaling: selectedModel.scaling,
                metadata: selectedModel.metadata,
                position: {
                  x: hitGroundMesh.pickedMesh.position.x,
                  y: hitGroundMesh.pickedMesh.position.y,
                  z: hitGroundMesh.pickedMesh.position.z,
                },
              },
            });
            dispatch(actions.selectSlot(null));
            dispatch(actions.selectedAssetMesh(null));
            dispatch(
              actions.updateActionMenu({
                key: "tap",
                value: true,
                exclusive: true,
              })
            );
            setPreviewedMesh(null);
          } else {
            (previewedMesh.parent as Mesh).dispose();
            setPreviewedMesh(null);
          }
        }
        const camera = scene.getCameraByName("camera") as ArcRotateCamera;
        camera?.attachControl(scene.getEngine().getRenderingCanvas(), true);
      }
    }
  }, [isDragging]);

  useEffect(() => {
    if (clientOffset && selectedModel) {
      setOffset({ x: clientOffset.x, y: clientOffset.y });
      const hitGroundMesh = scene.pick(clientOffset.x, clientOffset.y, (mesh) =>
        mesh.name.includes("ground")
      );
      if (hitGroundMesh.pickedPoint) {
        if (previewedMesh) {
          if (
            selectedModel.type !== AssetModelType.RIVER &&
            selectedModel.type !== AssetModelType.GROUND
              ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
              : true
          ) {
            (previewedMesh.parent as Mesh).position =
              hitGroundMesh.pickedMesh.position;
          }
        } else if (!creating) {
          const hitAssetMesh = scene.pick(
            clientOffset.x,
            clientOffset.y,
            (mesh) => mesh.name.includes("asset")
          );
          if (
            hitAssetMesh.hit &&
            hitGroundMesh.pickedMesh?.name.split("_").pop() ===
              hitAssetMesh.pickedMesh?.name.split("_").pop() &&
            (selectedModel.type !== AssetModelType.RIVER &&
            selectedModel.type !== AssetModelType.GROUND
              ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
              : true)
          ) {
            setCreating(true);
            scene.getMaterialByName("ground_layer").alpha = 0.3;
            (selectedModel.type === AssetModelType.RIVER ||
              selectedModel.type === AssetModelType.GROUND) &&
              (scene.getMaterialByName("ground").alpha = 0.3);
            createPreviewMesh(
              scene,
              selectedModel.filename,
              selectedModel.offset
            )
              .then((mesh) => {
                mesh.getChildMeshes().forEach((subMesh) => {
                  subMesh.material.alpha = 0.8;
                  if (subMesh.name.indexOf("Cube") !== -1) {
                    subMesh.isVisible = false;
                  }
                });
                const boundingInfo = mesh.getBoundingInfo();
                const outer = createOuterMesh(
                  scene,
                  boundingInfo.boundingBox,
                  hitGroundMesh.pickedMesh.position
                );
                if (selectedModel.type === AssetModelType.CHARACTER) {
                  outer.scaling = new Vector3(
                    selectedModel.scaling.x,
                    selectedModel.scaling.y,
                    selectedModel.scaling.z
                  );
                  scene.animationGroups.forEach((group) => {
                    group.stop();
                  });
                }
                mesh.metadata = {
                  type: selectedModel.type,
                  rotation: selectedModel.rotation,
                };
                mesh.parent = outer;
                setPreviewedMesh(mesh);
                setCreating(false);
              })
              .catch((error) => {
                console.error(error);
                setCreating(false);
              });
          }
        }
      }
    }
  }, [scene, clientOffset]);
};
