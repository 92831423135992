import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import Constants from "common/constant";
import { FadeTransition } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { BackToCourseTop } from "features/courses/components";
import { Levels } from "./Levels";
import { Overview } from "./Overview";

export const GroupWorkLevelMainPage = ({ stage }: { stage: number }) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [overview, setOverview] = useState(false);
  const [stepId, setStepId] = useState<number | null>(null);
  const progresses = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.GROUP_WORK].progress[stage - 1]
  );
  const display = useSelector(
    (state: RootState) => state.courses.message[Course.GROUP_WORK][stage - 1]
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const handleLevelPress = (stepId: number) => {
    play();
    setStepId(stepId);
    setOverview(true);
  };

  const handleStart = () => {
    play();
    btnDelay(() => {
      setOverview(false);
      navigate(`step/${stepId}`);
    });
  };

  const handleOverviewClose = () => {
    play();
    setOverview(false);
  };

  return (
    <div
      className={`w-full h-full flex-col-view ${
        loaded ? "pointer-events-auto" : "pointer-events-none"
      }`}
    >
      <img
        alt="ステージの背景画像"
        src={`${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.webp`}
        onError={(e) =>
          // @ts-ignore
          (e.target.src = `${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.jpg`)
        }
        className="w-full h-full absolute inset-0 z-[-2] object-cover"
      />

      <Levels
        scale={scale}
        stage={stage}
        display={display !== undefined ? display[config.locale] : display}
        progresses={progresses}
        handleLevelPress={handleLevelPress}
      />

      <Dialog
        fullScreen
        maxWidth={false}
        open={overview}
        sx={{ zIndex: 9999 }}
        TransitionComponent={FadeTransition}
        PaperProps={{ style: { backgroundColor: "transparent" } }}
        transitionDuration={{ enter: 1000, exit: 500 }}
        onClose={handleOverviewClose}
      >
        <div className="flex-row-center w-full h-full bg-textcolor/black/50">
          <Overview
            stage={stage}
            step={stepId}
            content={
              display !== undefined
                ? display[config.locale]?.level[stepId - 1]
                : ""
            }
            start={handleStart}
            close={handleOverviewClose}
          />
        </div>
      </Dialog>
    </div>
  );
};

export const GroupWorkLevelMapPage = () => {
  const { stageId } = useParams<{ stageId?: string }>();
  const stage = Number(stageId);

  return isNaN(stage) || stage > 1 ? (
    <BackToCourseTop />
  ) : (
    <GroupWorkLevelMainPage stage={stage} />
  );
};
