import Rect from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants, { BASE_HEIGHT, BASE_WIDTH } from "common/constant";
import { Course } from "features/courses/types";
import { StageClearSvg } from "features/courses/assets";
import { Level } from "./Level";
import { LevelStatus } from "./LevelStatus";
import { StageLevelRect } from "./StageLevelPaths";

export const StageCard = Rect.memo(
  ({
    stage,
    width,
    height,
    cardScale,
    selected,
  }: {
    stage: number;
    width: number;
    height: number;
    cardScale: number;
    selected: boolean;
  }) => {
    const scaleX = width / BASE_WIDTH;
    const scaleY = height / BASE_HEIGHT;
    const scale = scaleX < scaleY ? scaleX : scaleY;

    const display = useSelector(
      (state: RootState) =>
        state.courses.message[Course.TAKUSHOKU_ADVANCE][stage - 1]
    );
    const progresses = useSelector(
      (state: RootState) =>
        state.courses.courses[Course.TAKUSHOKU_ADVANCE].progress[stage - 1]
    );
    const config = useSelector((state: RootState) => state.config.userConfig);

    const LEVEL_RECT_CIRCLE_RADIUS = 96;
    const LEVEL_RECT_CIRCLE_STROKE = 20;

    const LEVEL_RECT_LEFTS = StageLevelRect.map((r) => r.left);
    const MIN_LEFT =
      Math.min(...LEVEL_RECT_LEFTS) -
      (282 / 2 - LEVEL_RECT_CIRCLE_RADIUS - LEVEL_RECT_CIRCLE_STROKE / 2);

    const MAX_LEFT =
      Math.max(...LEVEL_RECT_LEFTS) +
      282 / 2 +
      LEVEL_RECT_CIRCLE_RADIUS +
      LEVEL_RECT_CIRCLE_STROKE / 2;

    const offsetX =
      (width - (MAX_LEFT - MIN_LEFT) * scale) / 2 - MIN_LEFT * scale;

    return (
      <div
        className="flex-col-el shrink-0"
        style={{
          borderRadius: 12 * cardScale,
          width: width * cardScale,
          height: height * cardScale,
          boxShadow: selected && "0px 0px 6px 1px rgba(63, 74, 97, 0.3)",
        }}
      >
        <div
          className="flex-col-view origin-top-left border-solid border-textcolor/black overflow-hidden"
          style={{
            borderWidth: 4,
            borderRadius: 12,
            width: width,
            height: height,
            transform: `scale(${cardScale})`,
          }}
        >
          {progresses.stage.finished === progresses.level.length && (
            <div className="flex-col-el absolute right-8 top-5 z-50">
              <div className="flex-col-center w-[200px] h-[130px]">
                <StageClearSvg />
              </div>
            </div>
          )}

          <img
            alt="ステージの背景画像"
            src={`${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.webp`}
            onError={(e) =>
              // @ts-ignore
              (e.target.src = `${Constants.assetHost}/assets/images/bg_course_algorithm_stage_${stage}.jpg`)
            }
            className="w-full h-full absolute inset-0 z-[-2] object-cover"
          />

          <div className="flex-col-center w-[16px] h-full bg-red/60 !absolute top-0 left-0 z-50">
            <div className="flex-row-view items-center w-[300px] h-[48px] bg-white/80 border-solid border-[1px] border-l-0 border-white rounded-r-[8px] !absolute top-[20px] left-[16px]">
              <div className="flex-col-center w-[48px] h-[48px] bg-red/100 rounded-br-[10px]">
                <p className="title-text text-white !text-[39px]">{stage}</p>
              </div>
              <div className="flex-col-center ml-[12px]">
                <p className="text text-textcolor/black !text-[32px]">
                  {display[config.locale]?.name}
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex-col-view !flex-1"
            style={{
              width: width,
              transform: `translateY(${Math.floor(166 * scale)}px)`,
            }}
          >
            {progresses.level.map((_, index) => (
              <Level
                key={index}
                stage={stage}
                offset={offsetX}
                scale={scale}
                radius={LEVEL_RECT_CIRCLE_RADIUS}
                step={index}
                display={
                  display !== undefined ? display[config.locale] : display
                }
                progresses={progresses}
              />
            ))}
            <LevelStatus
              offset={offsetX}
              scale={scale}
              stroke={LEVEL_RECT_CIRCLE_STROKE}
              radius={LEVEL_RECT_CIRCLE_RADIUS}
              progresses={progresses}
            />
          </div>

          <div className="flex-col-center w-[148px] h-[90px] bg-textcolor/black/50 border-solid border-[1px] border-white/60 rounded-[8px] !absolute bottom-[10px] left-[40px]">
            <div className="flex-row-center border-solid border-0 !border-b-[1px] border-white px-[6px]">
              <p className="text text-white !text-[13px] !leading-[17px]">
                {I18n.t("MSG_COURSE_STAGE_CLEAR_TITLE")}
              </p>
            </div>

            <div className="flex-row-center w-full flex-wrap mt-2">
              {progresses.level.map((p, index) => {
                return (
                  <div className="flex-row-center" key={index}>
                    <div
                      className={`flex-col-center w-[27px] h-[27px] mx-[4px] my-[3px] border-solid border-brown/100 border-[2px] rounded-[20px] ${
                        progresses.level[index].is_finished
                          ? "bg-orange/100"
                          : "bg-white"
                      }`}
                    >
                      {index > 3 && (
                        <>
                          <div className="flex-col-center w-[12px] h-[2px] bg-brown/100 rotate-[60deg] !absolute top-[-6px] left-[-5px]" />
                          <div className="flex-col-center w-[12px] h-[2px] bg-brown/100 rotate-[-60deg] !absolute top-[-6px] left-[15px]" />
                        </>
                      )}

                      <div className="flex-col-center h-[30px]">
                        <p
                          className={`text ${
                            progresses.level[index].is_finished
                              ? "text-white"
                              : "text-gray/20"
                          }`}
                        >
                          {index + 1}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
