import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { MapList } from "./MapList";

export const BuilderPage = () => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const selectedStage = useSelector(
    (state: RootState) => state.course.selectedStage
  );
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const [selectedIndex, setSelectedIndex] = useState(
    selectedCourse === Course.BUILDER ? selectedStage - 1 : 0
  );

  const handleGoToMap = () => {
    btnDelay(() => {
      play();
      navigate(`${Course.BUILDER}/canvas`);
    });
  };

  return (
    <div className="flex-col-view w-full h-full bg-[#F5FCFF]">
      <img
        onClick={handleGoToMap}
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_course_program_scene.png`}
        className="absolute top-0 left-0 object-cover opacity-70"
      />

      <div className="flex-row-el w-full h-full">
        <div className="flex-row-el flex-center z-1 mb-10">
          <MapList
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>
    </div>
  );
};
