import { useMemo } from "react";
import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import {
  IconCircleZoomIn,
  IconCircleZoomOut,
  IconCircleZoomReset,
} from "common/assets";
import { useScale, usePlaySound } from "common/utils";

export const Controller = ({ scene }: { scene: Scene }) => {
  const play = usePlaySound();
  const { scale, ratio } = useScale();

  const radius = useMemo(
    () => (ratio > 1 ? (scale > 0.7 ? 20 / scale : 40 * scale) : 20 / scale),
    [scale]
  );

  const handleSceneZoom = (zoom: number) => () => {
    const camera = scene.getCameraByName("camera") as ArcRotateCamera;
    if (camera) {
      play();
      if (zoom > 0) {
        camera.radius -= 1;
      } else if (zoom < 0) {
        camera.radius += 1;
      } else {
        camera.radius = radius;
        camera.alpha = Math.PI / 4;
        camera.beta = Math.PI / 4;
      }
    }
  };

  return (
    <div
      style={{
        transform: `scale(${scale})`,
        right: 12 * scale,
      }}
      className="flex-col-el items-end absolute origin-right gap-10 z-10"
    >
      <div className="flex-col-el flex-center gap-8">
        <div
          onClick={handleSceneZoom(1)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomIn color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(0)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomReset color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(-1)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomOut color="#FEFDFE" />
        </div>
      </div>
    </div>
  );
};
