import { SVGProps } from "react";

export const IconMagnifierWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      stroke="#FEFDFE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M16.663 28.325c6.44 0 11.662-5.221 11.662-11.662C28.325 10.222 23.104 5 16.663 5 10.222 5 5 10.222 5 16.663c0 6.44 5.222 11.662 11.663 11.662ZM35 35 25 25"
    />
  </svg>
);
