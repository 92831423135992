import { useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { reorderPoints, calculateAngle, calculateDistance } from "./util";

export const useProcessing = () => {
  // const blockColorRange = useSelector(
  //   (state: RootState) => state.courses.config.tangible.colorRange
  // );
  // const blockColorRangeRef = useRef(blockColorRange);

  // useEffect(() => {
  //   blockColorRangeRef.current = blockColorRange;
  // }, [blockColorRange]);

  // const handleStartBlockDetection = useCallback(
  //   (frame: any, hsvFrame: any) => {
  //     let blocks: any[] = [];

  //     // @ts-ignore
  //     let mask = new cv.Mat();
  //     // @ts-ignore
  //     let lower = new cv.Mat(hsvFrame.rows, hsvFrame.cols, hsvFrame.type(), [
  //       blockColorRangeRef.current.yellow.h.lower,
  //       blockColorRangeRef.current.yellow.s.lower,
  //       blockColorRangeRef.current.yellow.v.lower,
  //       0,
  //     ]);
  //     // @ts-ignore
  //     let upper = new cv.Mat(hsvFrame.rows, hsvFrame.cols, hsvFrame.type(), [
  //       blockColorRangeRef.current.yellow.h.upper,
  //       blockColorRangeRef.current.yellow.s.upper,
  //       blockColorRangeRef.current.yellow.v.upper,
  //       0,
  //     ]);
  //     // @ts-ignore
  //     cv.inRange(hsvFrame, lower, upper, mask);
  //     lower.delete();
  //     upper.delete();

  //     // @ts-ignore
  //     let labels = new cv.Mat();
  //     // @ts-ignore
  //     let stats = new cv.Mat();
  //     // @ts-ignore
  //     let centroids = new cv.Mat();
  //     // @ts-ignore
  //     let numLabels = cv.connectedComponentsWithStats(
  //       mask,
  //       labels,
  //       stats,
  //       centroids,
  //       8
  //     );
  //     mask.delete();
  //     centroids.delete();

  //     // @ts-ignore
  //     let colorMask = cv.Mat.zeros(labels.rows, labels.cols, cv.CV_8U);

  //     for (let i = 1; i < numLabels; i++) {
  //       // @ts-ignore
  //       let area = stats.intPtr(i, cv.CC_STAT_AREA)[0];
  //       if (area >= 1000) {
  //         // @ts-ignore
  //         let compMask = new cv.Mat();
  //         try {
  //           // @ts-ignore
  //           compMask = cv.Mat.zeros(labels.rows, labels.cols, cv.CV_8U);

  //           // @ts-ignore
  //           let compareValue = new cv.Mat(
  //             labels.rows,
  //             labels.cols,
  //             // @ts-ignore
  //             cv.CV_32S,
  //             // @ts-ignore
  //             new cv.Scalar(i)
  //           );
  //           // @ts-ignore
  //           cv.compare(labels, compareValue, compMask, cv.CMP_EQ);
  //           // @ts-ignore
  //           colorMask.setTo(new cv.Scalar(255, 255, 255, 255), compMask);

  //           compareValue.delete();

  //           // @ts-ignore
  //           let contours = new cv.MatVector();
  //           // @ts-ignore
  //           let hierarchy = new cv.Mat();
  //           // @ts-ignore
  //           cv.findContours(
  //             compMask,
  //             contours,
  //             hierarchy,
  //             // @ts-ignore
  //             cv.RETR_EXTERNAL,
  //             // @ts-ignore
  //             cv.CHAIN_APPROX_SIMPLE
  //           );

  //           if (contours.size() > 0) {
  //             let points = contours.get(0);
  //             // @ts-ignore
  //             let rect = cv.minAreaRect(points);
  //             blocks.push(rect);
  //           }

  //           contours.delete();
  //           hierarchy.delete();
  //         } finally {
  //           compMask.delete();
  //         }
  //       }
  //     }

  //     stats.delete();
  //     labels.delete();

  //     // @ts-ignore
  //     let blockColorRoi = new cv.Mat();
  //     // @ts-ignore
  //     cv.bitwise_and(frame, frame, blockColorRoi, colorMask);

  //     // @ts-ignore
  //     let gray = new cv.Mat();
  //     // @ts-ignore
  //     cv.cvtColor(blockColorRoi, gray, cv.COLOR_RGBA2GRAY, 0);
  //     // @ts-ignore
  //     let colorBinaryInv = new cv.Mat();
  //     // @ts-ignore
  //     cv.threshold(gray, colorBinaryInv, 1, 255, cv.THRESH_BINARY_INV);
  //     gray.delete();
  //     blockColorRoi.delete();

  //     // @ts-ignore
  //     let contours = new cv.MatVector();
  //     // @ts-ignore
  //     let hierarchy = new cv.Mat();
  //     // @ts-ignore
  //     cv.findContours(
  //       colorBinaryInv,
  //       contours,
  //       hierarchy,
  //       // @ts-ignore
  //       cv.RETR_TREE,
  //       // @ts-ignore
  //       cv.CHAIN_APPROX_SIMPLE
  //     );
  //     hierarchy.delete();
  //     colorBinaryInv.delete();

  //     const triangles = [];
  //     for (let i = 0; i < contours.size(); i++) {
  //       // @ts-ignore
  //       let tmp = new cv.Mat();
  //       const contour = contours.get(i);
  //       // @ts-ignore
  //       const area = cv.contourArea(contour);
  //       // @ts-ignore
  //       let perimeter = 0.1 * cv.arcLength(contour, true);
  //       // @ts-ignore
  //       cv.approxPolyDP(contour, tmp, perimeter, true);

  //       if (tmp.rows === 3 && area > 500) {
  //         const vertices = [];
  //         for (let j = 0; j < tmp.rows; j++) {
  //           vertices.push({
  //             x: tmp.intPtr(j, 0)[0],
  //             y: tmp.intPtr(j, 0)[1],
  //           });
  //         }

  //         let centroid: { cx: number; cy: number };
  //         // @ts-ignore
  //         let moments = cv.moments(contour, false);
  //         if (moments.m00 !== 0) {
  //           let cx = moments.m10 / moments.m00;
  //           let cy = moments.m01 / moments.m00;
  //           centroid = { cx, cy };
  //         }

  //         triangles.push({ vertices, centroid, area });
  //       }

  //       tmp.delete();
  //       contour.delete();
  //     }
  //     contours.delete();

  //     const eventStartBlocks = [];
  //     for (let i = 0; i < blocks.length; i++) {
  //       const block = blocks[i];
  //       // @ts-ignore
  //       let vertices = cv.RotatedRect.points(block);
  //       let boxArray = vertices.map((point) => [
  //         Math.round(point.x),
  //         Math.round(point.y),
  //       ]);
  //       // @ts-ignore
  //       let contour = cv.matFromArray(4, 1, cv.CV_32SC2, boxArray.flat());

  //       // 三角形がこの矩形内にあるかチェック
  //       var blockTriangles = [];
  //       for (let j = 0; j < triangles.length; j++) {
  //         const triangle = triangles[j];
  //         if (
  //           triangle.vertices.every(
  //             (vertex) =>
  //               // @ts-ignore
  //               cv.pointPolygonTest(
  //                 contour,
  //                 // @ts-ignore
  //                 new cv.Point(vertex.x, vertex.y),
  //                 false
  //               ) >= 0
  //           )
  //         ) {
  //           blockTriangles.push(triangle);
  //         }
  //       }

  //       if (blockTriangles.length > 0) {
  //         const triangle = blockTriangles.reduce((max, t) =>
  //           t.area > max.area ? t : max
  //         );
  //         const a = { x: triangle.centroid.cx, y: triangle.centroid.cy };
  //         const b = {
  //           x: Math.round(block.center.x),
  //           y: Math.round(block.center.y),
  //         };

  //         const angle = calculateAngle(a, b);
  //         const distance = calculateDistance(a, b);

  //         console.log(`angle: ${angle}, distance: ${distance}`);

  //         // 対称点を計算
  //         const symmetricPoint = {
  //           x: Math.round(2 * b.x - a.x),
  //           y: Math.round(2 * b.y - a.y),
  //         };

  //         const rectSize = { width: 160, height: 50 };
  //         const messageRect = {
  //           center: symmetricPoint,
  //           size: rectSize,
  //           angle: angle,
  //         };

  //         // @ts-ignore
  //         const messageBox = cv.RotatedRect.points(messageRect);
  //         const resetMessageBox = reorderPoints(messageBox, angle);

  //         eventStartBlocks.push({
  //           block: block,
  //           messageBox: resetMessageBox,
  //           triangle,
  //         });
  //       }

  //       contour.delete();
  //     }

  //     return eventStartBlocks;
  //   },
  //   [blockColorRange]
  // );

  const gammaCorrection = (frame: any, gamma = 0.5) => {
    // @ts-ignore
    let dst = new cv.Mat();
    let invGamma = 1.0 / gamma;
    let table = new Uint8Array(256);

    for (let i = 0; i < 256; ++i) {
      table[i] = Math.min(255, Math.pow(i / 255, invGamma) * 255);
    }
    // @ts-ignore
    let lookUpTable = new cv.Mat(1, 256, cv.CV_8U);
    lookUpTable.data.set(table);
    // @ts-ignore
    cv.LUT(frame, lookUpTable, dst);

    lookUpTable.delete();

    return dst;
  };

  const handleEventBlockDetection = (frame: any) => {
    // // @ts-ignore
    // let hsvFrame = new cv.Mat();
    // // @ts-ignore
    // cv.cvtColor(frame, hsvFrame, cv.COLOR_RGB2HSV);
    // const eventStartBlocks = handleStartBlockDetection(frame, hsvFrame);
    // hsvFrame.delete();
    // return { eventStartBlocks: eventStartBlocks, proceduresStarts: null };
  };

  const handlePreprocessing = (frame: any, canvas: HTMLCanvasElement) => {
    // // @ts-ignore
    // let gray = new cv.Mat();
    // // @ts-ignore
    // cv.cvtColor(frame, gray, cv.COLOR_RGBA2GRAY, 0);

    // // @ts-ignore
    // let clahe = new cv.CLAHE(2.0, new cv.Size(8, 8));
    // // @ts-ignore
    // let claheResult = new cv.Mat();
    // clahe.apply(gray, claheResult);
    // // @ts-ignore
    // cv.imshow(canvas, claheResult);

    // claheResult.delete();
    // clahe.delete();
    // gray.delete();

    // let alpha = 0.3;
    // let beta = 40;

    // // @ts-ignore
    // let new_frame = new cv.Mat();
    // // @ts-ignore
    // cv.convertScaleAbs(frame, new_frame, alpha, beta);

    // let gamma = 0.5;
    // const result = gammaCorrection(frame, gamma);

    // @ts-ignore
    cv.imshow(canvas, frame);

    // new_frame.delete();
    // result.delete();
  };

  return { handlePreprocessing, handleEventBlockDetection };
};
