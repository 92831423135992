import React, { useRef, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Blockly from "blockly";
import utc from "dayjs/plugin/utc";
import throttle from "lodash-es/throttle";
import timezone from "dayjs/plugin/timezone";
import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { RootState } from "app/store";
import {
  IconCircleZoomIn,
  IconCircleZoomOut,
  IconCircleZoomReset,
} from "common/assets";
import { useScale } from "common/utils";
import { Dialog } from "common/blockly";
import { MainScene } from "./scene";
import { PreviewBlocklyWorkspace } from "./blockly";

export const MapPreview = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const TOP_OFFSET = 120 + 32;
  const COMMAND_PANEL_HEIGHT = 82;
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const { width, height, scale } = useScale();
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const [isFirst, setIsFirst] = useState(true);
  const [gameStart, setGameStart] = useState(false);
  const [scene, setScene] = useState<Scene | null>(null);
  const [blocklyFlyoutWidth, setBlocklyFlyoutWidth] = useState(0);
  const [blocklyFlyoutHeight, setBlocklyFlyoutHeight] = useState(0);
  const user = useSelector((state: RootState) => state.user.appUser);
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      navigate("/courses");
    }
  }, [user]);

  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload(!reloadRef.current);
        },
        1000,
        { leading: false, trailing: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!isFirst) {
      handleResize();
    }
  }, [scale]);

  useEffect(() => {
    if (workspace) {
      const flyout = workspace.getFlyout() as Blockly.Flyout;
      setBlocklyFlyoutWidth(flyout.getWidth() + 30 * scale);
      setBlocklyFlyoutHeight(flyout.getHeight());
    }
  }, [workspace, scale]);

  const handleSceneZoom = (zoom: number) => () => {
    const camera = scene.getCameraByName("camera") as ArcRotateCamera;
    if (camera) {
      if (zoom > 0) {
        camera.radius -= 1;
      } else if (zoom < 0) {
        camera.radius += 1;
      } else {
        camera.radius = width > height ? 22 : 25 / scale;
        camera.alpha = Math.PI / 4;
        camera.beta = Math.PI / 4;
      }
    }
  };

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;

      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
  };

  return (
    <div
      onClick={handleHideChaff}
      className="flex-col-view w-full h-full bg-transparent"
    >
      <Dialog workspace={workspace} />

      <div className="flex-col-view items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div
          className="flex-col-el w-full z-10"
          style={{ height: 80 * scale }}
        ></div>

        <div
          className="flex-col-view !flex-1 w-full h-full pointer-events-none"
          style={{
            marginTop: 32 * scale,
          }}
        >
          {blocklyFlyoutWidth && (
            <div
              className="flex-col-view !absolute h-full rounded-[10px] pointer-events-none"
              style={{
                left: blocklyFlyoutWidth + 100 * scale,
                width: width - blocklyFlyoutWidth - 100 * scale,
                padding: `0px ${10 * scale}px ${10 * scale}px`,
              }}
            >
              <MainScene
                reload={reload}
                setReload={setReload}
                scene={scene}
                gameStart={gameStart}
                setGameStart={setGameStart}
                setScene={setScene}
                workspace={workspace}
                flyoutWidth={blocklyFlyoutWidth}
                blocklyTopOffset={TOP_OFFSET + COMMAND_PANEL_HEIGHT}
                event={event}
              />
            </div>
          )}
        </div>

        <div
          className="flex-col-view !absolute top-0 left-0 !flex-1 h-full w-full rounded-[10px] z-20 pointer-events-none"
          style={{
            padding: `0px ${10 * scale}px ${17 * scale}px`,
          }}
        >
          <PreviewBlocklyWorkspace
            gameStart={gameStart}
            topOffset={TOP_OFFSET}
            commandPanelHeight={COMMAND_PANEL_HEIGHT}
            workspace={workspace}
            setWorkspace={setWorkspace}
            flyoutWidth={blocklyFlyoutWidth}
            flyoutHeight={blocklyFlyoutHeight}
            setEvent={setEvent}
          />
        </div>
      </div>

      <div className="flex-col-el absolute right-[20px] bottom-[25%] gap-8 z-[9997]">
        <div
          onClick={handleSceneZoom(1)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomIn color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(0)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomReset color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(-1)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomOut color="#FEFDFE" />
        </div>
      </div>
    </div>
  );
};
