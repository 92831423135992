import { CursorKey } from "./CursorKey";
import { SubDisplay } from "./SubDisplay";
import { ActionButton } from "./ActionButton";

export const Controller = () => {
  return (
    <div className="flex-row-el flex-center gap-4 w-[950px] h-[203px] absolute z-50 bottom-0 left-0">
      <CursorKey />
      <SubDisplay />
      <ActionButton />
    </div>
  );
};
