import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants from "common/constant";
import { IconPlayerPlay } from "common/assets";
import { btnDelay, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/course/slice";
import { WoodenBg } from "features/courses/assets";
import { StageList } from "./StageList";

export const TakushokuAdvanceStagePage = () => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedStage = useSelector(
    (state: RootState) => state.course.selectedStage
  );
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const [selectedIndex, setSelectedIndex] = useState(
    selectedCourse === Course.TAKUSHOKU_ADVANCE ? selectedStage - 1 : 0
  );
  const display = useSelector(
    (state: RootState) =>
      state.courses.message[Course.TAKUSHOKU_ADVANCE][selectedIndex]
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  const handleGoToMap = () => {
    btnDelay(() => {
      play();
      dispatch(actions.updateSelectedStage({ stage: selectedIndex + 1 }));
      navigate(`${Course.TAKUSHOKU_ADVANCE}/stage/${selectedIndex + 1}`);
    });
  };

  return (
    <div className="flex-col-view w-full h-full bg-[#F5FCFF]">
      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_course_program_scene.png`}
        className="absolute top-0 left-0 object-cover"
      />

      <div className="flex-row-el w-full h-full">
        <div className="flex-row-el flex-center z-1 mb-10">
          <StageList
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>

      <div
        style={{ scale: "80%" }}
        className="flex-row-el flex-center w-full absolute bottom-0"
      >
        <div className="flex-row-el flex-center relative w-[848px] h-[98px]">
          <div className="flex-col-el flex-center absolute">
            <WoodenBg />
          </div>

          <div className="flex-row-el flex-center absolute -top-8 left-10 pr-6 z-50 bg-white border-solid border-4 border-textcolor/black rounded-[8px] overflow-hidden">
            <div className="flex-col-center w-[48px] h-[48px] bg-red/100">
              <p className="title-text text-white !text-[39px]">
                {selectedIndex + 1}
              </p>
            </div>
            <div className="flex-col-center ml-3">
              <p className="text-body text-textcolor/black text-[32px]">
                {display[config.locale]?.name}
              </p>
            </div>
          </div>

          <div className="flex-row-el flex-center relative gap-6 mt-2">
            <div className="flex-row-el relative w-[520px] h-[56px]">
              <p className="text-body text-white text-[20px]">
                {display[config.locale]?.description}
              </p>
            </div>
          </div>

          <div className="flex-row-el flex-center relative alpha">
            <button className="btn btn-primary" onClick={handleGoToMap}>
              <div className="flex-row-center gap-1 mb-[3px]">
                <div className="flex-col-center w-[30px] h-[30px]">
                  <IconPlayerPlay color="white" width={2} />
                </div>

                <div className="flex-col-center">
                  <p className="text-body text-white text-[26px]">
                    {I18n.t("MSG_COURSE_GOTO_MAP_BTN")}
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
