import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import Constants from "common/constant";
import { CourseTopCaseManager } from "common/elements";

export const GameCase = React.memo(() => {
  const config = useSelector((state: RootState) => state.config.userConfig);
  const GameCaseComponent = CourseTopCaseManager[config.game_case];
  return (
    <div className="flex-col-center w-full h-full z-50">
      <img
        alt="game case"
        src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
        className="w-full h-full"
      />
    </div>
  );
});
