import { Scene } from "@babylonjs/core/scene";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AssetModelType, MAP_MESH_SIZE } from "features/builder/types";

export const createOuterMesh = (
  scene: Scene,
  boundingBox: BoundingBox,
  position: Vector3
) => {
  const outer = MeshBuilder.CreateBox(
    "outer",
    {
      width: boundingBox.maximum.x - boundingBox.minimum.x,
      height: boundingBox.maximum.y - boundingBox.minimum.y,
      depth: boundingBox.maximum.z - boundingBox.minimum.z,
    },
    scene
  );
  outer.isVisible = false;
  outer.isPickable = true;
  outer.checkCollisions = false;
  outer.showBoundingBox = false;
  outer.bakeTransformIntoVertices(
    Matrix.Translation(
      0,
      (boundingBox.maximum.y - boundingBox.minimum.y) / 2,
      0
    )
  );
  outer.position = new Vector3().copyFrom(position);
  return outer;
};

const calcMeshSize = (boundingBox: BoundingBox) => ({
  width: Math.round(boundingBox.maximum.x - boundingBox.minimum.x),
  height: Math.round(boundingBox.maximum.z - boundingBox.minimum.z),
});

export const createGround = (
  scene: Scene,
  boundingBox: BoundingBox,
  type: AssetModelType,
  position: Vector3,
  bottomLayer: string | number
) => {
  const { width, height } = calcMeshSize(boundingBox);
  const originX = Math.floor(position.x + MAP_MESH_SIZE / 2 - width / 2);
  const originY = Math.floor(position.z + MAP_MESH_SIZE / 2 - height / 2);
  for (
    let x = 0;
    x < Math.floor(width > 2 ? width / 2 : MAP_MESH_SIZE / 2);
    x++
  ) {
    for (
      let y = 0;
      y < Math.floor(height > 2 ? height / 2 : MAP_MESH_SIZE / 2);
      y++
    ) {
      if (type === AssetModelType.GROUND) {
        const ground = MeshBuilder.CreateGround(
          `asset_ground_layer_${originX + x * MAP_MESH_SIZE}_${
            originY + y * MAP_MESH_SIZE
          }_${Math.round(boundingBox.maximumWorld.y)}`,
          {
            width: MAP_MESH_SIZE,
            height: MAP_MESH_SIZE,
          },
          scene
        );
        ground.id = "ground";
        ground.material = scene.getMaterialByName("ground_layer");
        ground.position = new Vector3(
          originX + x * MAP_MESH_SIZE,
          Math.round(boundingBox.maximumWorld.y),
          originY + y * MAP_MESH_SIZE
        );
        ground.showBoundingBox = true;
      }

      const bottomLayerGround = scene.getMeshByName(
        `asset_ground_layer_${originX + x * MAP_MESH_SIZE}_${
          originY + y * MAP_MESH_SIZE
        }_${bottomLayer}`
      );
      if (bottomLayerGround) {
        bottomLayerGround.dispose();
      }
    }
  }
};

export const createBottomGround = (
  scene: Scene,
  boundingBox: BoundingBox,
  position: Vector3
) => {
  const { width, height } = calcMeshSize(boundingBox);
  const originX = Math.floor(position.x + MAP_MESH_SIZE / 2 - width / 2);
  const originY = Math.floor(position.z + MAP_MESH_SIZE / 2 - height / 2);
  for (
    let x = 0;
    x < Math.floor(width > 2 ? width / 2 : MAP_MESH_SIZE / 2);
    x++
  ) {
    for (
      let y = 0;
      y < Math.floor(height > 2 ? height / 2 : MAP_MESH_SIZE / 2);
      y++
    ) {
      const topGround = scene.getMeshByName(
        `asset_ground_layer_${originX + x * MAP_MESH_SIZE}_${
          originY + y * MAP_MESH_SIZE
        }_${Math.round(boundingBox.maximumWorld.y)}`
      );
      if (topGround) topGround.dispose();

      const bottomGround = MeshBuilder.CreateGround(
        `asset_ground_layer_${originX + x * MAP_MESH_SIZE}_${
          originY + y * MAP_MESH_SIZE
        }_${Math.round(position.y)}`,
        {
          width: MAP_MESH_SIZE,
          height: MAP_MESH_SIZE,
        },
        scene
      );
      bottomGround.id = "ground";
      bottomGround.showBoundingBox = true;
      bottomGround.material =
        Math.round(position.y) === 0
          ? scene.getMaterialByName("ground")
          : scene.getMaterialByName("ground_layer");
      bottomGround.position = new Vector3(
        originX + x * MAP_MESH_SIZE,
        Math.round(position.y),
        originY + y * MAP_MESH_SIZE
      );
    }
  }
};
