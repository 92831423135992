import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/course/slice";
import { Cassette } from "./Cassette";
import { GameCase } from "./GameCase";
import { Controller } from "./Controller";

export const DisplayContents = ({
  animation,
  setAnimation,
}: {
  animation: boolean;
  setAnimation: (animation: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const showWelcomePage = useSelector(
    (state: RootState) => state.course.showWelcomePage
  );
  const panelAction = useSelector((state: RootState) => state.course.action);
  const [localSelectedCourse, setLocalSelectedCourse] = useState<Course | null>(
    selectedCourse
  );

  useEffect(() => {
    if (panelAction.back && selectedCourse) {
      dispatch(
        actions.updateAction({
          ...panelAction,
          back: false,
        })
      );

      document
        .getElementById("course-card")
        ?.classList.add("course_card_fade_out_in");
      document.getElementById("course-cassette")?.classList.add("zoom_out_in");

      anime({
        targets: `#courses-soft-card-${selectedCourse}`,
        opacity: 1,
        duration: 300,
        easing: "easeInOutQuad",
      });

      setTimeout(() => {
        setLocalSelectedCourse(null);
        dispatch(actions.updateShowWelcomePage(true));
        dispatch(actions.updateSelectedCourse({ course: null }));
        setTimeout(() => {
          setAnimation(false);
          document
            .getElementById("course-card")
            ?.classList.remove("course_card_fade_out_in");
          document
            .getElementById("course-cassette")
            ?.classList.remove("zoom_out_in");
        }, 500);
      }, 500);
    }
  }, [panelAction.back]);

  useEffect(() => {
    if (animation && localSelectedCourse !== selectedCourse) {
      document
        .getElementById("course-card")
        ?.classList.add("course_card_fade_out_in");
      document.getElementById("course-cassette")?.classList.add("zoom_out_in");

      setTimeout(() => {
        dispatch(actions.updateShowWelcomePage(true));
        setLocalSelectedCourse(selectedCourse);
        setTimeout(() => {
          setAnimation(false);
          document
            .getElementById("course-card")
            ?.classList.remove("course_card_fade_out_in");
          document
            .getElementById("course-cassette")
            ?.classList.remove("zoom_out_in");
        }, 500);
      }, 500);
    } else {
      setAnimation(false);
    }
  }, [animation]);

  return (
    <div className="flex-col-el w-full h-full">
      <div
        id="course-card"
        className="flex-col-el absolute top-0 left-0 w-[165px] will-change-transform"
        style={{ transform: "translateX(124px) translateY(-40px)" }}
      >
        {localSelectedCourse && (
          <img
            className="w-full h-full"
            src={`${Constants.assetHost}/assets/images/course_soft_${localSelectedCourse}.png`}
          />
        )}
      </div>

      <div className="flex-col-el flex-center w-full h-[600px] px-[48px] absolute z-[50] top-[43px]">
        <div
          id="course-cassette"
          className="flex-col-el flex-center w-full h-full relative"
        >
          <Cassette
            course={localSelectedCourse}
            showWelcomePage={showWelcomePage}
          />
        </div>
      </div>
    </div>
  );
};

export const Display = ({
  animation,
  setAnimation,
}: {
  animation: boolean;
  setAnimation: (animation: boolean) => void;
}) => {
  const { scale } = useScale();
  return (
    <div
      className="flex-col-el flex-center z-30"
      style={{
        width: 950 * scale,
        height: 900 * scale,
      }}
    >
      {animation && (
        <div className="w-full h-full absolute inset-0 pointer-events-auto z-[20000]" />
      )}

      <div
        style={{ transform: `scale(${scale})` }}
        className="flex-col-el w-[950px] h-[900px] relative shrink-0 origin-center"
      >
        <div className="flex-col-el flex-center relative w-full h-full">
          <GameCase />
          <DisplayContents animation={animation} setAnimation={setAnimation} />
          <Controller />
        </div>
      </div>
    </div>
  );
};
