export const PageCover = () => (
  <>
    <div
      id="course-card-page-left"
      style={{ transformStyle: "preserve-3d" }}
      className="flex-col-el opacity-0 pointer-events-none w-[480px] h-[744px] absolute left-4 bg-beige/20 z-50 origin-right rounded-l-lg border-solid border-[2px] border-r-0 border-gray/40"
    />
    <div
      id="course-card-page-right"
      style={{ transformStyle: "preserve-3d" }}
      className="flex-col-el opacity-0 pointer-events-none w-[480px] h-[744px] absolute right-6 bg-beige/20 z-50 origin-left rounded-r-lg border-solid border-[2px] border-l-0 border-gray/40"
    />
  </>
);
