import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";

export const useInitCharacterBlock = () => {
  const InitCharacterBlock = () => {
    const characterEventStartBlock = () => ({
      type: "character_event",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    });
    Blockly.Blocks["character_event"] = {
      init: function () {
        const options = [
          [
            {
              src: `${Constants.assetHost}/assets/images/character_duck_head.png`,
              width: 56,
              height: 42,
              alt: "duck",
            },
            "character_duck",
          ],
          [
            {
              src: `${Constants.assetHost}/assets/images/character_dog_head.png`,
              width: 56,
              height: 42,
              alt: "dog",
            },
            "character_dog",
          ],
          [
            {
              src: `${Constants.assetHost}/assets/images/character_bear_head.png`,
              width: 56,
              height: 42,
              alt: "bear",
            },
            "character_bear",
          ],
        ];
        this.appendDummyInput()
          .appendField("%{BKY_COURSE_START_BLOCK}")
          .appendField(new Blockly.FieldDropdown(options), "CHARACTER");
        this.jsonInit(characterEventStartBlock());
        this.setCollapsed(false);
        this.setColour("#FFBF00");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_event"] = function (block: Blockly.Block) {
      const logic = BlocklyJs.statementToCode(block, "DO");
      const code = `
      return async () => {
        window.LoopTrap = 20;
        util.highlightBlock("${block.id}", true);
        await util.sleep(1000);
        util.highlightBlock("${block.id}", false);
        
        ${logic}

        if(scene.isDisposed){
          console.log("scene has been disposed.");
        } else {
          util.setGameOver(true);
        };
      }
      `;
      return code;
    };

    Blockly.Blocks["character_motion_move"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_MOVE}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_move"] = function (block: Blockly.BlockSvg) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.moveSound();await util.action.performMove(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_jump"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField("    ジャンプ    ");
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_jump"] = function (block: Blockly.BlockSvg) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.jumpSound();await util.action.performJump(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_turn_left"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_LEFT}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_turn_left"] = function (
      block: Blockly.BlockSvg
    ) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.turnSound();await util.action.performTurnToLeft(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };

    Blockly.Blocks["character_motion_turn_right"] = {
      init: function () {
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.appendDummyInput().appendField(
          "%{BKY_COURSE_TANGIBLE_DIRECTION_RIGHT}"
        );
        this.setColour("#4C97FF");
        this.contextMenu = false;
      },
    };
    BlocklyJs["character_motion_turn_right"] = function (
      block: Blockly.BlockSvg
    ) {
      return `
      util.highlightBlock("${block.id}", true);
      if(!scene.isDisposed){gameSound.turnSound();await util.action.performTurnToRight(scene, character);};
      util.highlightBlock("${block.id}", false);
      `;
    };
  };
  return InitCharacterBlock;
};
