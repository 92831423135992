import produce from "immer";
import {
  ComponentType,
  TextStyleProps,
  BorderStyleType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const CheckBox: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_CHECKBOX";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_CHECKBOX";
    draft.text = "MSG_CREATOR_COMPONENT_NAME_CHECKBOX";
    draft.defaultText = "MSG_CREATOR_COMPONENT_NAME_CHECKBOX";

    const initialWidth = 140;
    const initialHeight = 16;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultText = TextStyleProps;
    draft.style.defaultView.borderStyle = BorderStyleType.NONE;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.text = TextStyleProps;
    draft.style.view.borderStyle = BorderStyleType.NONE;

    draft.events = ["checkbox_value_change_event"];
  }),
};

export default CheckBox;
