import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Howl } from "howler";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import { BlockType } from "common/blockly";
import * as action from "common/model/animations";
import { sleep, useScale, btnDelay, usePlaySound } from "common/utils";
import { Course, RunType } from "features/courses/types";
import { useGameBgm, useGameSound } from "features/courses/hook";
import { Score } from "features/courses/algorithm/game/scene/components";
import { StartBtn, ResetBtn, ZoomPanel } from "features/courses/components";
import StageMaps from "./maps";
import { Control } from "./Control";
import { GameScene } from "./GameScene";
import { ReadyStart, ResultPanel } from "./component";
import { TOP_OFFSET } from "../constant";
import { updateFlyout } from "../blockly";

export const MainScenePage = ({
  stage,
  step,
  loading,
  reload,
  setReload,
  gameBgm,
  gameStart,
  setGameStart,
  animation,
  setAnimation,
  workspace,
  setLoading,
}: {
  stage: number;
  step: number;
  loading: boolean;
  reload: boolean;
  setReload: (reload: boolean) => void;
  gameBgm: Howl | null;
  gameStart: boolean;
  setGameStart: (start: boolean) => void;
  animation: { [character: string]: boolean };
  setAnimation: (animation: { [character: string]: boolean }) => void;
  workspace: Blockly.WorkspaceSvg;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const { gameRunningBgm } = useGameBgm();
  const [ready, setReady] = useState(false);
  const [gemScore, setGemScore] = useState(0);
  const [totalGem, setTotalGem] = useState(0);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [runType, setRunType] = useState(RunType.CLICK);
  const [scene, _setScene] = useState<Scene | null>(null);
  const sceneRef = useRef(scene);
  const setScene = (scene: Scene) => {
    sceneRef.current = scene;
    _setScene(scene);
  };
  const [goals, _setGoals] = useState<AbstractMesh[]>([]);
  const goalsRef = useRef(goals);
  const setGoals = (goals: AbstractMesh[]) => {
    goalsRef.current = goals;
    _setGoals(goals);
  };
  const [characterSuccess, setCharacterSuccess] = useState<{
    [character: string]: { gem: boolean; goal: boolean };
  }>({});
  const [characterGameOver, setCharacterGameOver] = useState({});
  const [characters, _setCharacters] = useState<AbstractMesh[]>([]);
  const charactersRef = useRef(characters);
  const setCharacters = (characters: AbstractMesh[]) => {
    charactersRef.current = characters;
    _setCharacters(characters);
  };
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (
      scene &&
      !loading &&
      workspace &&
      config.course_model === CourseModel.COMMAND
    ) {
      workspace
        .getTopBlocks(true)
        .filter((block) => block.isEnabled())
        .forEach((block) => {
          if (block.type.includes("character_event")) {
            const character = charactersRef.current
              .filter(
                (character) =>
                  character.name.split("_").pop() ===
                  block.type.split("_").pop()
              )
              .shift();
            if (character) {
              util.action.performAppearance(sceneRef.current, character);
            }
          }
        });
    }
  }, [workspace, scene, reload, loading]);

  useEffect(() => {
    let onWorkspaceChange: Function;
    if (workspace && config.course_model === CourseModel.COMMAND) {
      workspace.setScale(scale);

      setTimeout(() => {
        updateFlyout(workspace, TOP_OFFSET * scale);
      }, 300);

      onWorkspaceChange = (event: any) => {
        switch (event.type) {
          case Blockly.Events.VAR_CREATE:
          case Blockly.Events.VAR_RENAME:
          case Blockly.Events.VAR_DELETE:
            updateFlyout(workspace, TOP_OFFSET * scale);
            break;
          case Blockly.Events.BLOCK_CREATE:
            if (event.json.type.includes("character_event")) {
              const character = charactersRef.current
                .filter(
                  (character) =>
                    character.name.split("_").pop() ===
                    event.json.type.split("_").pop()
                )
                .shift();
              if (character) {
                util.action.performAppearance(sceneRef.current, character);
              }
            } else if (event.json.type === BlockType.PROCEDURES_DEFNORETURN) {
              updateFlyout(workspace, TOP_OFFSET * scale);
            } else if (event.json.type === BlockType.VARIABLES_SET) {
              if (
                workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                  .length > 1
              ) {
                const varName = `${I18n.t("MSG_BLOCKLY_VARIABLES")}${
                  workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                    .length
                }`;
                const variable = workspace.createVariable(varName);
                const block = workspace.getBlockById(event.blockId);
                block.setFieldValue(variable.getId(), "VAR");
              }
              updateFlyout(workspace, TOP_OFFSET * scale);
            }
            break;
          case Blockly.Events.BLOCK_CHANGE:
            const block = workspace.getBlockById(event.blockId);
            if (block.type === BlockType.PROCEDURES_DEFNORETURN) {
              const funcName = block.getFieldValue("NAME");
              workspace
                .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                .filter(
                  (block) => block.getFieldValue("NAME") === event.oldValue
                )
                .forEach((block) => block.setFieldValue(funcName, "NAME"));
              updateFlyout(workspace, TOP_OFFSET * scale);
            }
            break;
          case Blockly.Events.BLOCK_DELETE:
            if (event.oldJson.type.includes("character_event")) {
              charactersRef.current
                .filter(
                  (character) =>
                    character.name.split("_").pop() ===
                    event.oldJson.type.split("_").pop()
                )
                .shift()
                ?.setEnabled(false);
            } else if (
              event.oldJson.type === BlockType.PROCEDURES_DEFNORETURN
            ) {
              workspace
                .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                .filter(
                  (block) =>
                    block.getFieldValue("NAME") === event.oldJson.fields.NAME
                )
                .forEach((block) => block.dispose(false));
            } else if (event.oldJson.type === BlockType.VARIABLES_SET) {
              workspace
                .getBlocksByType(BlockType.VARIABLES_GET, false)
                .filter(
                  (block) =>
                    block.getFieldValue("VAR") === event.oldJson.fields.VAR.id
                )
                .forEach((block) => block.dispose(false));
            }

            workspace.trashcan.emptyContents();

            const toolbox = workspace.getToolbox() as Blockly.Toolbox;
            const flyout = toolbox.getFlyout() as Blockly.Flyout;
            if (flyout.isVisible()) {
              updateFlyout(workspace, TOP_OFFSET * scale);
            }
            break;
          default:
            break;
        }
      };
      workspace.addChangeListener(onWorkspaceChange);
    }
    return () => {
      if (onWorkspaceChange) {
        workspace.removeChangeListener(onWorkspaceChange);
      }
    };
  }, [scale, workspace]);

  useEffect(() => {
    if (gameOver) {
      setGameStart(false);

      const success =
        Object.values(characterSuccess).filter((v) => v.gem && v.goal).length >
        0;

      setShowResult(true);

      const xml = Blockly.Xml.workspaceToDom(workspace);
      const answer = Blockly.Xml.domToText(xml);

      const now = dayjs().tz("Asia/Tokyo").format("YYYY-MM-DDTHH:mm:ssZ");

      if (success) {
        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();

        // if (!isFinished) {
        //   dispatch(
        //     actions.updateLevelProgresses({
        //       course: Course.ALGORITHM_ADVANCED,
        //       stageId: stage,
        //       stepId: step,
        //       totalFinished: summary.finished + 1,
        //       answer,
        //       finishedAt: now,
        //     })
        //   );
        //   updateLevelProgresses({
        //     course_id,
        //     course: Course.ALGORITHM_ADVANCED,
        //     uid: user.active.uid,
        //     stageId: stage,
        //     stepId: step,
        //     totalFinished: summary.finished + 1,
        //     answer,
        //     finishedAt: now,
        //   });
        // } else {
        //   dispatch(
        //     actions.updateLevelAnswer({
        //       course: Course.ALGORITHM_ADVANCED,
        //       stageId: stage,
        //       stepId: step,
        //       answer,
        //     })
        //   );
        //   updateLevelAnswer({
        //     course_id,
        //     uid: user.active.uid,
        //     stageId: stage,
        //     stepId: step,
        //     answer,
        //     finishedAt: now,
        //   });
        // }
      } else {
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    const values = Object.values(characterGameOver);
    if (values.length > 0 && values.filter((v) => !v).length === 0) {
      charactersRef.current
        .filter((character) => character.isEnabled())
        .forEach((character) => {
          const character_type = `character_event_${character.name
            .split("_")
            .pop()}`;
          setCharacterSuccess((pre) => ({
            ...pre,
            [character_type]: {
              ...pre[character_type],
              goal: character.intersectsMesh(goalsRef.current[0]),
            },
          }));
        });

      setGameOver(true);
    }
  }, [characterGameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handleCreateCharacter = () => {
    const characters = workspace
      .getTopBlocks(true)
      .filter(
        (block) => block.isEnabled() && block.type.includes("character_event")
      );
    charactersRef.current.forEach((character) => {
      if (
        character.isEnabled() &&
        characters.filter(
          (block) =>
            character.name.split("_").pop() === block.type.split("_").pop()
        ).length === 0
      ) {
        character.setEnabled(false);
      } else if (
        !character.isEnabled() &&
        characters.filter(
          (block) =>
            character.name.split("_").pop() === block.type.split("_").pop()
        ).length > 0
      ) {
        util.action.performAppearance(sceneRef.current, character);
      }
    });
  };

  const handlePlusGemScore = (type: string, totalGem: number) => {
    setGemScore((prevScore) => {
      const newScore = prevScore + 1;
      if (newScore === totalGem) {
        setCharacterSuccess((pre) => ({
          ...pre,
          [type]: { ...pre[type], gem: true },
        }));
      }
      return newScore;
    });
  };

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (workspace.getBlockById(id)) {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const util = {
    sleep: sleep,
    handleCharacterGameOver: (type: string) =>
      setCharacterGameOver((pre) => ({ ...pre, [type]: true })),
    highlightBlock: highlightBlock,
    action: {
      checkBtnColor: action.checkBtnColor,
      performAppearance: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }

        if (!scene.isDisposed && !character.isEnabled()) {
          // @ts-ignore
          setAnimation((pre) => ({
            ...pre,
            [character.name]: true,
          }));
          await action.performAppearance(scene, character);
          gameSound.itemGetSound();
          // @ts-ignore
          setAnimation((pre) => ({
            ...pre,
            [character.name]: false,
          }));
        }
      },
      performMove: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.moveSound();
          await action.performMove(
            scene,
            character,
            (over: boolean) =>
              setCharacterGameOver((pre) => ({
                ...pre,
                [`character_event_${character.name.split("_").pop()}`]: over,
              })),
            gameSound.moveSound,
            gameSound.fallingSound
          );
        }
      },
      performJump: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.jumpSound();
          await action.performJump(scene, character);
        }
      },
      performTurnToLeft: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToLeft(scene, character);
        }
      },
      performTurnToRight: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToRight(scene, character);
        }
      },
    },
  };

  const handleRun = () => {
    setReady(false);
    gameRunningBgm.play();
    workspace?.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
      block.setMovable(false);
      block.setEditable(false);
    });
    try {
      let variablesCode: string;
      let proceduresCode: string;
      const characterEvents: { type: string; event: Function }[] = [];

      BlocklyJs.init(workspace);

      workspace
        .getTopBlocks(true)
        .filter((block) => block.isEnabled())
        .forEach((block) => {
          if (block.type.includes("character_event")) {
            const code = BlocklyJs.blockToCode(block);
            characterEvents.push({
              type: block.type,
              event: new Function(
                "scene",
                "character",
                "index",
                "util",
                "gameSound",
                `${code}`
              ),
            });
            setCharacterSuccess((pre) => ({
              ...pre,
              [block.type]: { gem: false, goal: false },
            }));
            setCharacterGameOver((pre) => ({ ...pre, [block.type]: false }));
          } else if (
            block.type === "procedures_defnoreturn" ||
            block.type === "procedures_defreturn"
          ) {
            BlocklyJs.blockToCode(block);
          }
        });

      const funCode = BlocklyJs.finish("");
      const code = funCode.replace(/function /g, "async $&"); // safari maybe has some problems.

      if (code.split("\n")[0].includes("var")) {
        const variables = [];
        // @ts-ignore
        BlocklyJs.nameDB_.setVariableMap(workspace.getVariableMap());
        workspace.getAllVariables().forEach((variable) => {
          // @ts-ignore
          const varName = BlocklyJs.nameDB_.getName(
            variable.name,
            Blockly.VARIABLE_CATEGORY_NAME
          );
          variables.push(`${varName} = "";`);
        });

        variablesCode = variables.join("");
        const splitStr = funCode.indexOf(";");
        proceduresCode = code.substring(splitStr + 1);
      } else {
        variablesCode = "";
        proceduresCode = code;
      }

      const handleEvents = new Function(
        "characterEvents",
        "scene",
        "characters",
        "util",
        "gameSound",
        characterEvents.length > 0
          ? `
          ${variablesCode};
          ${proceduresCode};
          return async () => {
            characterEvents.forEach((characterEvent, index) => {
              const event = characterEvent.event;
              const character = characters
                .filter((character) => character.name.split("_").pop() === characterEvent.type.split("_").pop())
                .shift();
              if (character) {
                event(scene, character, index, util, gameSound)();
              }
            });
          }`
          : `return () => {
              util.setGameOver(true);
            }
        `
      );

      handleEvents(
        characterEvents,
        sceneRef.current,
        charactersRef.current,
        util,
        gameSound
      )();
    } catch (error) {
      console.error(error);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGameOver(true);
    }
  };

  const handleClickStart = () => {
    const characters = charactersRef.current.filter((character) =>
      character.isEnabled()
    );
    if (
      characters.length > 0 &&
      Object.values(animation).filter((v) => v).length === 0
    ) {
      btnDelay(() => {
        setReady(true);
        gameBgm.stop();
        setGameStart(true);
        setRunType(RunType.CLICK);
        gameSound.startCountdownSound();
        setTimeout(() => handleRun(), 3000);
      });
    }
  };

  const handleStart = () => {
    const characters = charactersRef.current.filter((character) =>
      character.isEnabled()
    );
    if (
      characters.length > 0 &&
      Object.values(animation).filter((v) => v).length === 0
    ) {
      setReady(true);
      gameBgm.stop();
      setRunType(RunType.RECOGNITION);
      gameSound.startCountdownSound();
      setTimeout(() => {
        setGameStart(true);
        handleRun();
      }, 3000);
    }
  };

  const handleGameOver = () => {
    if (
      Object.values(characterSuccess).filter((v) => v.gem && v.goal).length > 0
    ) {
      gameRunningBgm.stop();
      if (step < 7) {
        navigate(
          `/courses/${Course.GROUP_WORK}/stage/${stage}/step/${step + 1}`
        );
      } else {
        navigate(`/courses`);
      }
    } else {
      handleReset();
    }
  };

  const handleReset = () => {
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setLoading(true);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setShowResult(false);
      setCharacterSuccess({});
      setCharacterGameOver({});
      gameRunningBgm.pause();
      gameBgm.play();
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setHighlighted(false);
        block.setEditable(true);
      });
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setShowResult(false);
      navigate(`/courses/${Course.GROUP_WORK}/stage/${stage}`);
    });
  };

  return (
    <>
      <ZoomPanel scene={sceneRef.current} />

      {config.course_model === CourseModel.TANGIBLE &&
        workspace &&
        scene &&
        characters.length > 0 && (
          <Control
            ready={ready}
            gameStart={gameStart}
            gameOver={gameOver}
            workspace={workspace}
            handleStart={handleStart}
            handleGameOver={handleGameOver}
            handleCreateCharacter={handleCreateCharacter}
          />
        )}

      <ReadyStart open={ready} />

      {gameOver && showResult && (
        <ResultPanel
          result={characterSuccess}
          auto={runType === RunType.RECOGNITION}
          back={handleBack}
          reset={handleReset}
        />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            map={stepMap}
            reload={reload}
            handler={{
              setLoading,
              setScene,
              setCharacters,
              setGoals,
              setTotalGem,
              handlePlusGemScore,
            }}
          />

          <div
            style={{ height: 56 * scale }}
            className="flex-row-el absolute top-0"
          >
            <div
              style={{ transform: `scale(${scale})` }}
              className="flex-row-view h-[56px] origin-top"
            >
              <Score totalGem={totalGem} gemScore={gemScore} />
            </div>
          </div>
        </div>

        {!gameStart ? (
          <StartBtn
            scale={scale}
            disabled={
              charactersRef.current.filter((character) => character.isEnabled())
                .length === 0 ||
              Object.values(animation).filter((v) => v).length > 0
            }
            start={handleClickStart}
          />
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}
      </div>
    </>
  );
};

export default MainScenePage;
