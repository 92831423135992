import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Course } from "features/courses/types";
import { KeyDirection } from "features/creator/types";

interface CourseInitialStateType {
  showCourseTop: boolean;
  showCourseList: boolean;
  showWelcomePage: boolean;
  selectedCourse?: Course;
  selectedStage: number;
  cursor: KeyDirection;
  action: { back: boolean; confirm: boolean };
}

const initialState: CourseInitialStateType = {
  showCourseTop: true,
  showCourseList: false,
  showWelcomePage: true,
  selectedCourse: Course.ALGORITHM,
  selectedStage: 1,
  cursor: KeyDirection.NONE,
  action: { back: false, confirm: false },
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    updateShowCourseTop(state, action: PayloadAction<boolean>) {
      state.showCourseTop = action.payload;
    },
    updateShowCourseList(
      state: CourseInitialStateType,
      action: PayloadAction<boolean>
    ) {
      state.showCourseList = action.payload;
    },
    updateShowWelcomePage(
      state: CourseInitialStateType,
      action: PayloadAction<boolean>
    ) {
      state.showWelcomePage = action.payload;
    },
    updateSelectedCourse(
      state: CourseInitialStateType,
      action: PayloadAction<{ course: Course }>
    ) {
      state.selectedStage = 1;
      state.selectedCourse = action.payload.course;
    },
    updateSelectedStage(
      state: CourseInitialStateType,
      action: PayloadAction<{ stage: number }>
    ) {
      state.selectedStage = action.payload.stage;
    },
    updateCursor(
      state: CourseInitialStateType,
      action: PayloadAction<KeyDirection>
    ) {
      state.cursor = action.payload;
    },
    updateAction(
      state: CourseInitialStateType,
      action: PayloadAction<{ back: boolean; confirm: boolean }>
    ) {
      state.action = action.payload;
    },
  },
});
export const actions = { ...courseSlice.actions };
export default courseSlice.reducer;
