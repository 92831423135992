import { Scene } from "@babylonjs/core/scene";
import { Ray } from "@babylonjs/core/Culling/ray";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep } from "common/utils";
import {
  MAP_MESH_SIZE,
  AssetModelType,
  BUTTON_ASSET_SIZE,
} from "features/builder/types";
import { performFalling } from "./falling";
import { performMarkJitter } from "./mark";

export const performJitter = async (
  scene: Scene,
  character: AbstractMesh,
  frames: number,
  fastForward?: boolean
) => {
  performMarkJitter(scene, character, frames);
  character.rotation.x -= 0.11;
  const characterMove = new Animation(
    "move",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const initialPosition = character.position;
  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initialPosition
        .multiply(new Vector3(10, 10, 10))
        .add(
          new Vector3(
            (i % 2 === 0 ? 0.1 : -0.1) * 10 * character.forward.x,
            0,
            (i % 2 === 0 ? 0.1 : -0.1) * 10 * character.forward.z
          )
        )
        .divide(new Vector3(10, 10, 10)),
    });
  }
  characterMove.setKeys(animationKeys);
  const animation = scene.beginDirectAnimation(
    character,
    [characterMove],
    0,
    frames,
    false,
    fastForward ? 2 : 1
  );
  await animation.waitAsync();
  await sleep(1000 / (fastForward ? 2 : 1));
};

export const performMove = async (
  scene: Scene,
  character: AbstractMesh,
  setGameOver: (over: boolean) => void,
  moveSound?: () => void,
  fallingSound?: () => void,
  fastForward?: boolean
) => {
  const jumpStep = 3;
  const jumpHeight = 0.3;
  const frames = jumpStep * 2;
  const rayCastAssetPos = new Vector3(
    character.position.x + character.forward.x * 2,
    character.position.y + 2,
    character.position.z + character.forward.z * 2
  );
  const groundAssetRay = new Ray(rayCastAssetPos, new Vector3(0, -1, 0), 2.5);
  const pickAsset = scene.pickWithRay(groundAssetRay, (mesh) =>
    mesh.name.includes("asset")
  );

  const pickedMesh = pickAsset.pickedMesh;
  if (!pickedMesh) {
    if (moveSound) {
      moveSound();
    }
    await performFalling(scene, character, fallingSound);
    if (scene && !scene.isDisposed) {
      scene.dispose();
    }
    setGameOver(true);
    return;
  } else {
    const boundingInfo = pickedMesh.getBoundingInfo();
    if (
      Math.round(
        (boundingInfo.boundingBox.maximumWorld.y - character.position.y) * 10
      ) /
        10 >
      0
    ) {
      await performJitter(scene, character, frames, fastForward);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGameOver(true);
      return;
    }
  }
  if (moveSound) {
    moveSound();
  }
  const rayCastCurrentConditionPos = new Vector3(
    character.position.x,
    character.position.y,
    character.position.z
  );
  const currentConditionRay = new Ray(
    rayCastCurrentConditionPos,
    new Vector3(0, -1, 0),
    1
  );
  const pickCurrentCondition = scene.pickWithRay(currentConditionRay, (mesh) =>
    mesh.name.includes(AssetModelType.CONDITION)
  );
  const pickNextCondition = scene.pickWithRay(groundAssetRay, (mesh) =>
    mesh.name.includes(AssetModelType.CONDITION)
  );
  const nextStepOffset = pickCurrentCondition.hit
    ? pickNextCondition.hit
      ? 0
      : -BUTTON_ASSET_SIZE.y
    : pickNextCondition.hit
    ? BUTTON_ASSET_SIZE.y
    : 0;

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  const characterMove = new Animation(
    "move",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: character.position
        .multiply(new Vector3(10, 10, 10))
        .add(
          new Vector3(
            ((i * (MAP_MESH_SIZE * character.forward.x)) / (jumpStep * 2)) * 10,
            (jumpHeight * (i % 2) + (i > frames / 2 ? nextStepOffset : 0)) * 10,
            ((i * (MAP_MESH_SIZE * character.forward.z)) / (jumpStep * 2)) * 10
          )
        )
        .divide(new Vector3(10, 10, 10)),
    });
  }
  characterMove.setKeys(animationKeys);
  const animation = scene.beginDirectAnimation(
    character,
    [characterMove],
    0,
    frames,
    false,
    fastForward ? 2 : 1
  );
  await animation.waitAsync();
  await sleep(1000 / (fastForward ? 2 : 1));
};
