import { EntityState } from "@reduxjs/toolkit";

export enum UserType {
  MAIN = "main",
  SUB = "sub",
  FROZEN = "frozen",
}

export enum AccountType {
  GUEST = "guest",
  KIDS = "kids",
  PARENTS = "parents",
}

export enum BillingType {
  FREE = "free",
  STANDARD = "standard",
  PROFESSIONAL = "professional",
  EDUCATION = "education",
}

export const BILLING_LIMIT_ACCOUNT = {
  FREE: 2,
  STANDARD: 4,
  PROFESSIONAL: 10,
  EDUCATION: 20,
};

export const BILLING_LIMIT_APP_PROJECT = {
  FREE: 5,
  STANDARD: 10,
};

export type AppUser = {
  uid: string;
  type: UserType;
  active: boolean;
  account_type: AccountType;
  billing_type: BillingType;
  parent_uid: string;
  email: string;
  provider: string;
  display_name: string;
  birthday: string;
  gender: string;
  icon?: string;
  created_at: string;
  last_sign_in_time: string;
  last_sign_in_env: string;
  login_count: number;
  billing_start_at?: string;
  billing_end_at?: string;
};

export enum MessageType {
  USER_INITIAL = "user_initial",
  NORMAL = "normal",
  CONTACT = "contact",
  CONTACT_MESSAGE = "contact_message",
  CONTACT_INITIAL = "contact_initial",
  CONTACT_REPLY = "contact_reply",
  CONTACT_INPUT_AUTO_REPLY = "contact_input_auto_reply",
  KIDS_REVIEW_RATING = "kids_review_rating",
  KIDS_REVIEW_RATING_AUTO_REPLY = "kids_review_rating_auto_reply",
  KIDS_REVIEW_FEEDBACK = "kids_review_feedback",
  KIDS_REVIEW_FEEDBACK_REPLY = "kids_review_feedback_reply",
  KIDS_REVIEW_FEEDBACK_AUTO_REPLY = "kids_review_feedback_auto_reply",
  KIDS_REVIEW_FEEDBACK_INPUT_AUTO_REPLY = "kids_review_feedback_input_auto_reply",
}

export enum MessageContentType {
  TEXT = "text",
  IMAGE = "image",
  PROJECT = "project",
  COURSE_CLEAR = "course_clear",
}

export type FormMessageContact = {
  id: string;
  uid: string;
  type: string;
  detail: string;
  created_at: string;
};

export type FormMessageContactReply = {
  id: string;
  contact_id: string;
  uid: string;
  type: string;
  detail: string;
  created_at: string;
  date: string;
  title: string;
  content: string;
  published: boolean;
  published_at: string;
};

export type FormMessageKidsReviewRating = {
  id: string;
  uid: string;
  rating: number;
  created_at: string;
};

export type FormMessageKidsReviewFeedback = {
  id: string;
  uid: string;
  rating?: number;
  type: string;
  detail: string;
  created_at: string;
};

export type FormMessageKidsReviewFeedbackReply = {
  id: string;
  kids_review_feedback_id: string;
  uid: string;
  rating?: number;
  type: string;
  detail: string;
  created_at: string;
  title: string;
  content: string;
  published: boolean;
  published_at: string;
};

export type ChatRoomMessageSync = {
  id: string;
  uid: string;
  lastSyncAt: string;
  lastReadAt?: string;
  latestReadAt?: string;
  latestMessage?: ChatRoomMessage;
  latestMessageCreatedAt?: string;
};

export enum ChatRoomType {
  TALK = "talk",
  CONTACT = "contact",
  NOTIFICATION = "notification",
}

export type ChatRoom = {
  id: string;
  uid: string;
  name: string;
  icon: string;
  type: ChatRoomType;
  description: string;
  last_read_at: string;
  created_at: string;
  updated_at: string;
  members: ChatRoomMember[];
};

export enum ChatRoomMemberType {
  KIDS = "kids",
  PARENTS = "parents",
  OFFICIAL = "official",
}
export type ChatRoomMember = {
  id: string;
  chat_room_id: string;
  uid: string;
  type: ChatRoomMemberType;
  last_read_at: string;
  created_at: string;
  updated_at: string;
};

export type ChatRoomMessage = {
  id: string;
  chat_room_id: string;
  user_uid: string;
  user_type: ChatRoomMemberType;
  message_type: MessageType;
  message_content_type: MessageContentType;
  message_content: any;
  created_at: string;
  updated_at: string;
};

export type UserState = {
  appUser: {
    main: AppUser;
    active: AppUser;
    users: AppUser[];
    idToken: string;
  };
  isRefreshed: boolean;
  status: {
    course: {
      finished: number;
      total: number;
    };
    project: {
      total: number;
      viewed: number;
      published: number;
      favorited: number;
    };
  };
  history: {
    period: string[];
    course: {
      total: {
        day: { date: string; finished: number }[];
        avg: { day: number };
      };
      new: {
        day: { date: string; finished: number }[];
      };
    };
    project: {
      favorited: {
        total: {
          day: { date: string; favorited: number }[];
          avg: { day: number };
        };
        new: {
          day: { date: string; favorited: number }[];
        };
      };
      viewed: {
        total: {
          day: { date: string; viewed: number }[];
        };
      };
    };
  };
  groupUsers: EntityState<AppUser>;
  selectedChatRoomId: string;
  chatRooms: EntityState<ChatRoom>;
  chatRoomMessages: EntityState<ChatRoomMessage>;
  chatRoomMessageSync: EntityState<ChatRoomMessageSync>;
};
