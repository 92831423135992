/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import { useCamera } from "common/hook";
import { SpinModal } from "common/elements";
import { useScale, isDebugMode, dataURLToBlob } from "common/utils";
import {
  handleExtractLabelFromImage,
  convertLabelToExecutableBlockList,
} from "./generator";
import { useProcessing } from "./useProcessing";
import { Label, ExecutableBlock } from "./type";

export const LocalStream = ({
  gameStart,
  success,
}: {
  gameStart: boolean;
  success: (result: string) => void;
}) => {
  const { scale } = useScale();
  const [show, _setShow] = useState(false);
  const showRef = useRef(show);
  const setShow = (show: boolean) => {
    showRef.current = show;
    _setShow(show);
  };
  const [debug, setDebug] = useState(false);
  const [start, _setStart] = useState(true);
  const startRef = useRef(start);
  const setStart = (start: boolean) => {
    startRef.current = start;
    _setStart(start);
  };
  const [paused, _setPaused] = useState(false);
  const pausedRef = useRef(paused);
  const setPaused = (paused: boolean) => {
    pausedRef.current = paused;
    _setPaused(paused);
  };
  const [loading, setLoading] = useState(true);
  const { handlePreprocessing } = useProcessing();
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamCanvas = useRef<HTMLCanvasElement>(null);
  const recognizeCanvas = useRef<HTMLCanvasElement>(null);
  const { initCamera, switchCamera, cancel } = useCamera(videoRef);

  const handleShowRectangle = (
    labels: Label[],
    labelCount: { [label: string]: number },
    eventBlocks: { eventStartBlocks: any[]; proceduresStarts: any[] }
  ) => {
    if (!recognizeCanvas.current || !videoRef.current) return;

    recognizeCanvas.current.width = videoRef.current.width;
    recognizeCanvas.current.height = videoRef.current.height;
    let context = recognizeCanvas.current.getContext("2d");
    context.clearRect(0, 0, videoRef.current.width, videoRef.current.height);

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      context.lineWidth = 5;
      context.strokeStyle = "#00FF00";
      context.strokeRect(
        label.boundingPolygon[0].x,
        label.boundingPolygon[0].y,
        label.boundingPolygon[1].x - label.boundingPolygon[0].x,
        label.boundingPolygon[2].y - label.boundingPolygon[0].y
      );
      if (isDebugMode) {
        context.font = "40px Arial";
        context.fillStyle = "red";
        context.fillText(
          `${debug ? label.originalText : label.text}(${
            labelCount[
              `${debug ? label.originalText : label.text}_${
                label.boundingPolygon[0].x
              }_${label.boundingPolygon[0].y}`
            ]
          }/3)`,
          label.boundingPolygon[0].x,
          label.boundingPolygon[0].y
        );
      }
    }

    for (let i = 0; i < eventBlocks?.eventStartBlocks.length; i++) {
      const eventStartBlock = eventBlocks.eventStartBlocks[i];
      context.lineWidth = 5;
      context.strokeStyle = "yellow";
      context.strokeRect(
        eventStartBlock.messageBox[0].x,
        eventStartBlock.messageBox[0].y,
        eventStartBlock.messageBox[1].x - eventStartBlock.messageBox[0].x,
        eventStartBlock.messageBox[2].y - eventStartBlock.messageBox[0].y
      );
    }
  };

  const handleCaptureStream = useCallback((capture: boolean) => {
    if (videoRef.current?.srcObject) {
      // @ts-ignore
      const cap = new cv.VideoCapture(videoRef.current);
      // @ts-ignore
      const cameraFrame = new cv.Mat(
        videoRef.current.height,
        videoRef.current.width,
        // @ts-ignore
        cv.CV_8UC4
      );
      cap.read(cameraFrame);

      if (capture) {
        // @ts-ignore
        cv.imshow(streamCanvas.current, cameraFrame);
      } else {
        handlePreprocessing(cameraFrame, streamCanvas.current);
      }

      cameraFrame.delete();
    }
  }, []);

  const handleDetectLabel = (delay: number) =>
    new Promise<{
      validLabels: Label[];
      eventBlocks: { eventStartBlocks: any[]; proceduresStarts: any[] };
      isStart: boolean;
      isAiRobotTeacher: boolean;
    }>((resolve, reject) =>
      setTimeout(() => {
        handleCaptureStream(false);
        let eventBlocks: {
          eventStartBlocks: any[];
          proceduresStarts: any[];
        };
        if (streamCanvas.current) {
          const dataUrl = streamCanvas.current.toDataURL("image/jpeg", 0.5);
          const fileBlob = dataURLToBlob(dataUrl);
          handleExtractLabelFromImage(
            fileBlob,
            debug,
            (validLabels, isStart, isAiRobotTeacher) => {
              resolve({
                validLabels,
                eventBlocks,
                isStart,
                isAiRobotTeacher,
              });
            },
            (error) => {
              console.error(error);
              resolve({
                validLabels: [],
                eventBlocks,
                isStart: false,
                isAiRobotTeacher: false,
              });
            },
            () => {
              resolve({
                validLabels: [],
                eventBlocks,
                isStart: false,
                isAiRobotTeacher: false,
              });
            }
          );
        } else {
          resolve({
            validLabels: [],
            eventBlocks,
            isStart: false,
            isAiRobotTeacher: false,
          });
        }
      }, delay)
    );

  const handleRecognize = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        if (pausedRef.current) {
          resolve(0);
        } else if (streamCanvas.current) {
          Promise.all([
            handleDetectLabel(0),
            handleDetectLabel(300),
            handleDetectLabel(600),
          ])
            .then((results) => {
              console.log(results);

              try {
                var isStart = false;
                var isAiRobotTeacher = false;
                let eventBlocks: any;
                var validLabels: Label[] = [];
                let blocks: ExecutableBlock[][] = [];
                var labelCount: { [label: string]: number } = {};

                for (let i = 0; i < results.length; i++) {
                  const result = results[i];

                  if (result.eventBlocks) {
                    eventBlocks = result.eventBlocks;
                  }

                  if (!isStart) {
                    isStart = result.isStart;
                  }
                  if (!isAiRobotTeacher) {
                    isAiRobotTeacher = result.isAiRobotTeacher;
                  }

                  for (let j = 0; j < result.validLabels.length; j++) {
                    const label = result.validLabels[j];
                    const existLabels = validLabels.filter(
                      (validBlockLabel) =>
                        Math.abs(
                          label.boundingPolygon[0].x -
                            validBlockLabel.boundingPolygon[0].x
                        ) < 50 &&
                        Math.abs(
                          label.boundingPolygon[0].y -
                            validBlockLabel.boundingPolygon[0].y
                        ) < 50
                    );

                    if (existLabels.length === 0) {
                      validLabels.push(label);
                      labelCount[
                        `${label.text}_${label.boundingPolygon[0].x}_${label.boundingPolygon[0].y}`
                      ] = 1;
                    } else {
                      const existLabel = existLabels.shift();
                      labelCount[
                        `${existLabel.text}_${existLabel.boundingPolygon[0].x}_${existLabel.boundingPolygon[0].y}`
                      ] += 1;
                    }
                  }
                }

                if (validLabels.length > 0) {
                  if (showRef.current) {
                    handleShowRectangle(validLabels, labelCount, eventBlocks);
                  }

                  if (
                    validLabels.filter(
                      (validLabel) =>
                        validLabel.text.includes("じっこう") ||
                        validLabel.text.includes("かんすう")
                    ).length > 0
                  ) {
                    blocks = convertLabelToExecutableBlockList(validLabels);
                  }
                  success(
                    JSON.stringify({ blocks, isStart, isAiRobotTeacher })
                  );
                }

                resolve(0);
              } catch (error) {
                console.log(error);
                resolve(0);
              }
            })
            .catch((error) => {
              console.log(error);
              resolve(0);
            })
            .finally(() => {
              resolve(0);
            });
        }
      }),
    []
  );

  useEffect(() => {
    let recognizeTimer: NodeJS.Timeout;
    if (start) {
      initCamera(
        () => {
          setLoading(false);

          const processRecognize = () => {
            handleRecognize().finally(() => {
              if (startRef.current) {
                recognizeTimer = setTimeout(processRecognize, 10);
              }
            });
          };

          processRecognize();
        },
        (error) => {
          console.log(error);
          setTimeout(() => {
            setLoading(false);
            setStart(false);
          }, 500);
        }
      );
    }
    return () => {
      if (recognizeTimer) {
        clearTimeout(recognizeTimer);
        setTimeout(() => {
          if (!recognizeCanvas.current || !videoRef.current) return;
          recognizeCanvas.current.width = videoRef.current.width;
          recognizeCanvas.current.height = videoRef.current.height;
          let context = recognizeCanvas.current.getContext("2d");
          context.clearRect(
            0,
            0,
            videoRef.current.width,
            videoRef.current.height
          );
        }, 500);
      }
    };
  }, [start]);

  useEffect(() => {
    setPaused(gameStart);
    if (gameStart) {
      handleCaptureStream(true);
    }
  }, [gameStart]);

  const handleOpenCameraWindow = () => {
    setShow(!show);
  };

  const handleStart = () => {
    if (!start) {
      setLoading(true);
      setStart(true);
    }
  };

  const handleCancel = () => {
    cancel();
    setStart(false);
  };

  const handleDownloadImage = () => {
    const link = document.createElement("a");
    link.download = "blocks.png";
    link.href = streamCanvas.current.toDataURL("image/png", 1);
    link.click();
    link.remove();
  };

  return (
    <>
      {loading && <SpinModal />}

      <div
        style={{ transform: `translateY(${show ? 0 : 640 / (16 / 9)}px)` }}
        className="flex-col-el items-start justify-center fixed left-0 bottom-0 z-[300000] transition-transform duration-700 will-change-transform"
      >
        <div
          onClick={handleOpenCameraWindow}
          className="flex-row-el items-center w-[300px] h-[50px] px-2 gap-2 bg-gray/30 rounded-t-xl cursor-pointer pointer-events-auto"
        >
          <div
            className={`flex-col-el rounded-full w-[25px] h-[25px] ${
              start ? "bg-green/100" : "bg-danger"
            }`}
          />
          <p className="text-body text-font-caption2-narrow text-textcolor/black">
            {start ? "カメラが稼働中" : "カメラが停止中"}
          </p>
        </div>

        <div className="flex-col-el flex-center relative z-[200000] w-[640px] aspect-video">
          <div className="flex-col-el flex-center bg-[#000000] pointer-events-auto w-full h-full">
            <div className="flex-col-el flex-center w-full h-full">
              <div className="flex-row-el flex-center w-full h-full z-50">
                <video
                  muted
                  playsInline
                  ref={videoRef}
                  className={`w-full h-full ${pausedRef.current && "hidden"}`}
                />
                <canvas
                  id="local-stream-canvas"
                  ref={streamCanvas}
                  className={`flex-co-el flex-center absolute top-0 left-0 w-full h-full z-10 ${
                    !pausedRef.current && "hidden"
                  }`}
                />
                <canvas
                  ref={recognizeCanvas}
                  className="flex-co-el flex-center absolute top-0 left-0 w-full h-full z-40"
                />

                {start ? (
                  loading ? (
                    <></>
                  ) : (
                    <div
                      style={{ bottom: 60 * scale }}
                      className="flex-row-el w-full items-center justify-around z-50 absolute pointer-events-auto"
                    >
                      <div
                        className="flex-row-center"
                        style={{ width: 150, height: 50 }}
                      >
                        <div
                          onClick={handleCancel}
                          className="flex-row-center cursor-pointer active:opacity-70"
                        >
                          <p className="text text-white">キャンセル</p>
                        </div>
                      </div>

                      <div
                        className="flex-row-center"
                        style={{ width: 150, height: 50 }}
                      >
                        <div
                          onClick={handleDownloadImage}
                          className="flex-row-center cursor-pointer active:opacity-70"
                        >
                          <p className="text text-white">キャプチャ</p>
                        </div>
                      </div>

                      <div
                        className="flex-row-center z-50"
                        style={{ width: 150, height: 50 }}
                      >
                        <div
                          onClick={switchCamera}
                          className="flex-row-center bg-textcolor/black/60 w-[50px] h-[50px] rounded-[40px] pointer-events-auto cursor-pointer active:opacity-70"
                        >
                          <span className="material-symbols-outlined text-[32px] text-white">
                            cameraswitch
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    onClick={handleStart}
                    className="flex-col-el flex-center w-full h-full absolute z-50 cursor-pointer"
                  >
                    <span className="material-symbols-outlined text-[72px] text-white">
                      play_circle
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
