import { CourseProgress } from "features/courses/types";
import { StageLevelRects } from "./StageLevelRects";
import { StageLevelPaths } from "./StageLevelPaths";

export const LevelStatus = ({
  offset,
  scale,
  stroke,
  radius,
  stage,
  progresses,
}: {
  offset: number;
  scale: number;
  stroke: number;
  radius: number;
  stage: number;
  progresses: CourseProgress;
}) => (
  <>
    <div
      className="absolute z-[-1] w-full h-full"
      style={{ filter: "drop-shadow(2px 2px 3px rgba(63, 74, 97, 0.5))" }}
    >
      <svg className="absolute z-[-1] w-full h-full">
        {progresses.level.map((level, index) => {
          return (
            <g key={index}>
              <path
                overflow="visible"
                d={StageLevelPaths[stage - 1](radius, scale, offset)[index]}
                fill="transparent"
                stroke="white"
                strokeWidth={stroke * 1.5 * scale}
              />
            </g>
          );
        })}
      </svg>
      {progresses.level.map((level, index) => {
        return (
          <div
            key={index}
            id={`level-animation-circle-${index}`}
            className="flex-col-view !absolute top-0 left-0 overflow-visible"
            style={{
              width: (radius + stroke) * 2 * scale,
              height: (radius + stroke) * 2 * scale,
              transform: `translate(${
                (StageLevelRects[stage - 1][index].left - stroke) * scale +
                offset
              }px, ${
                (StageLevelRects[stage - 1][index].top - stroke) * scale
              }px)`,
            }}
          >
            <svg
              overflow="visible"
              width={(radius + stroke) * 2 * scale}
              height={(radius + stroke) * 2 * scale}
              viewBox={`${-stroke * scale} ${-stroke * scale} ${
                (radius + stroke) * 2 * scale
              } ${(radius + stroke) * 2 * scale}`}
            >
              <g overflow="visible">
                <circle
                  cx={radius * scale}
                  cy={radius * scale}
                  r={(radius + stroke / 2) * scale}
                  fill="none"
                  stroke="white"
                  strokeWidth={stroke * scale}
                />
              </g>
            </svg>
          </div>
        );
      })}
    </div>
  </>
);
