import { useRef, useCallback } from "react";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { useScale } from "common/utils";
import * as action from "common/model/animations";
import { loadMap, loadMesh, TileSetMapType } from "common/model";
import { useCanvas } from "features/courses/hook";

export const offset = new Vector3(1, 0, 1);

export const GameScene = ({
  map,
  reload,
  handler,
}: {
  map: TileSetMapType;
  reload: boolean;
  handler: {
    setLoading: (loading: boolean) => void;
    setScene?: (scene: Scene) => void;
    setCharacters: (characters: AbstractMesh[]) => void;
    setGoals: (goals: AbstractMesh[]) => void;
    setTotalGem: (totalGem: number) => void;
    handlePlusGemScore: () => void;
  };
}) => {
  const { scale, width, height } = useScale();
  const elementRef = useRef<HTMLDivElement>(null);

  const onSceneReady = useCallback(
    async (canvas: HTMLCanvasElement, engine: Engine, scene: Scene) => {
      const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 4,
        Math.PI / 4,
        width > height ? 22 : 25 / scale,
        new Vector3(4.5 + offset.x, offset.y, 4 + offset.z),
        scene
      );
      camera.lowerBetaLimit = 0;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerRadiusLimit = 10;
      camera.upperRadiusLimit = 40 / scale;
      camera.attachControl(canvas, true);

      const light = new HemisphericLight(
        "light",
        new Vector3(-3, 5, -3),
        scene
      );
      light.intensity = 1.5;

      scene.clearColor = new Color4(0, 0, 0, 0);

      const { characters, gems, goals } = await loadMap(scene, map);
      handler.setCharacters(characters);
      handler.setGoals(goals);

      const mark = await loadMesh(scene, "lightning.gltf.glb");
      mark.name = "mark";
      mark.setEnabled(false);

      gems.forEach((gem) => {
        action.performAnimation(scene, gem);
      });
      handler.setTotalGem(gems.length);
      handler.setLoading(false);

      scene.onAfterRenderObservable.add(() => {
        if (!scene.isDisposed) {
          gems.forEach((gem) => {
            if (!gem.isDisposed()) {
              characters.forEach((character) => {
                if (character.intersectsMesh(gem)) {
                  gem.dispose();
                  handler.handlePlusGemScore();
                }
              });
            }
          });
        }
      });

      handler.setScene(scene);

      engine.runRenderLoop(() => {
        scene.render();
      });
    },
    []
  );

  useCanvas(
    elementRef,
    onSceneReady,
    (error) => {
      console.log(error);
    },
    reload
  );

  return (
    <div
      ref={elementRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
