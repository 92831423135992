import i18n from "i18n-js";
import platform from "platform";
import { set } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getUserConfig,
  getBannedWords,
  getNotifications,
  getAllNotifications,
  getParentsUserAppConfig,
} from "app/api";
import {
  GameCase,
  AppConfig,
  EventType,
  Character,
  DialogType,
  ConfigState,
  CourseModel,
  DeviceNotification,
} from "app/types";
import Constants from "common/constant";
import { insideInstalledApp } from "common/utils";
import stamps from "./stamps.json";
import userIcons from "./user_icons.json";

export const getEnv = () => {
  if (insideInstalledApp()) {
    return "pwa";
  } else if ("ReactNativeWebView" in window) {
    if ("kidsAppPlatform" in window) {
      return window.kidsAppPlatform;
    } else {
      return "webview";
    }
  } else {
    return "web";
  }
};

const initialState: ConfigState = {
  resign: false,
  appConfig: null,
  supported: false,
  userConfig: {
    is_first: true,
    locale: "ja",
    sound: { enable: true, volume: 0.5 },
    bgm: { enable: true, volume: 0.5 },
    tutorial: { top: true, creator_top: true, creator_panel: true },
    game_case: GameCase.GRAY,
    character: Character.DUCK,
    project_publish: true,
    project_copied: true,
    course_model: CourseModel.COMMAND,
  },
  tutorial: { top: true },
  device: {
    version: {
      hostAppVersion:
        "kidsAppVersion" in window
          ? window.kidsAppVersion
          : `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
      appVersion: `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
    },
    os: platform.os.toString(),
    notification: DeviceNotification.NOT_DETERMINED,
  },
  notification: {
    kids: [],
    parents: [],
    maintenances: [],
  },
  banned: { words: [] },
  assets: Object.fromEntries(
    stamps.concat(userIcons).map((assets) => [assets.prefix, assets.contents])
  ),
  event: { before_install_prompt: { value: false, args: {} } },
  dialog: {
    rating: { value: false, args: {} },
    contact: { value: false, args: {} },
    loading: { value: false, args: {} },
    feedback: { value: false, args: {} },
    assistant: { value: false, args: {} },
    universal: { value: false, args: {} },
    parent_gate: { value: false, args: {} },
    subscription: { value: false, args: {} },
    user_profile: { value: false, args: {} },
    notification: { value: false, args: {} },
    error_network: { value: false, args: {} },
    project_preview: { value: false, args: {} },
    pwa_install_prompt: { value: false, args: {} },
  },
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateResign(state: ConfigState, action: PayloadAction<boolean>) {
      state.resign = action.payload;
    },
    updateAnalyticsSupported(
      state: ConfigState,
      action: PayloadAction<boolean>
    ) {
      state.supported = action.payload;
    },
    updateUserConfig(
      state: ConfigState,
      action: PayloadAction<{ key: string; value: string | boolean | number }>
    ) {
      const { key, value } = action.payload;
      set(state.userConfig, key, value);
    },
    updateAppConfig(state: ConfigState, action: PayloadAction<AppConfig>) {
      state.appConfig = action.payload;
    },
    closeTutorial(state: ConfigState, action: PayloadAction<{ key: string }>) {
      state.tutorial[action.payload.key] = false;
    },
    updateDeviceNotification(
      state: ConfigState,
      action: PayloadAction<{ notification: DeviceNotification }>
    ) {
      state.device.notification = action.payload.notification;
    },
    updateDialog(
      state: ConfigState,
      action: PayloadAction<{
        type: DialogType;
        value: boolean;
        args?: { [key: string]: string };
      }>
    ) {
      state.dialog[action.payload.type].value = action.payload.value;
      state.dialog[action.payload.type].args = action.payload.args;
    },
    updateEvent(
      state: ConfigState,
      action: PayloadAction<{
        type: EventType;
        value: boolean;
        args?: { [key: string]: string };
      }>
    ) {
      state.event[action.payload.type].value = action.payload.value;
      state.event[action.payload.type].args = action.payload.args;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUserConfig.fulfilled,
        (state: ConfigState, action: PayloadAction<any>) => {
          state.userConfig = action.payload.user_config;
          i18n.locale = action.payload.user_config.locale;
        }
      )
      .addCase(
        getParentsUserAppConfig.fulfilled,
        (state: ConfigState, action: PayloadAction<any>) => {
          state.appConfig = action.payload.app_config;
        }
      )
      .addCase(
        getNotifications.fulfilled,
        (state: ConfigState, action: PayloadAction<any>) => {
          state.notification.kids = action.payload.kids;
          state.notification.parents = action.payload.parents;
          state.notification.maintenances = action.payload.maintenances;
        }
      )
      .addCase(
        getAllNotifications.fulfilled,
        (state: ConfigState, action: PayloadAction<any>) => {
          state.notification.kids = action.payload.kids;
          state.notification.parents = action.payload.parents;
          state.notification.maintenances = action.payload.maintenances;
        }
      )
      .addCase(
        getBannedWords.fulfilled,
        (
          state: ConfigState,
          action: PayloadAction<{ id: number; word: string }[]>
        ) => {
          state.banned.words = action.payload;
        }
      );
  },
});

export const actions = { ...configSlice.actions };

export default configSlice.reducer;
