import ReactDOM from "react-dom/client";
import "styles/index.scss";
import dayjs from "dayjs";
import i18n from "i18n-js";
import App from "app/App";
import { I18nTranslations } from "common/i18n";
import { insideInstalledApp } from "common/utils";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

i18n.locale = "ja";
i18n.fallbacks = true;
i18n.translations = I18nTranslations;

ReactDOM.createRoot(document.getElementById("root")).render(<App />);

declare global {
  interface Window {
    deferredPrompt?: any;
    ReactNativeWebView?: any;
    kidsAppHostRegionCode?: string;
    kidsAppHostLanguageCode?: string;
    kidsAppHostLanguage?: string;
    kidsAppPlatform?: string;
    kidsAppVersion?: string;
    kidsBuildVersion?: string;
  }
}

if (insideInstalledApp()) {
  window.resizeBy(1024 - window.innerWidth, 768 - window.innerHeight);
}
