import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { actions } from "features/course/slice";

export const ActionButton = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [cancel, setCancel] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const panelAction = useSelector((state: RootState) => state.course.action);

  const handleCancelDown = () => {
    play();
    setCancel(true);
  };
  const handleCancelUp = () => {
    setCancel(false);
    if (selectedCourse) {
      dispatch(
        actions.updateAction({
          ...panelAction,
          back: true,
        })
      );
    }
  };

  const handleConfirmDown = () => {
    setConfirm(true);
  };
  const handleConfirmUp = () => {
    setConfirm(false);
    if (selectedCourse) {
      dispatch(
        actions.updateAction({
          ...panelAction,
          confirm: true,
        })
      );
    }
  };

  return (
    <div className="flex-row-el flex-center">
      <div className="flex-col-center w-[120px] h-[120px] cursor-pointer -mr-2">
        {cancel ? (
          <div
            onPointerUp={handleCancelUp}
            className="flex-col-center w-[121px] h-[126px]"
          >
            <img
              draggable="false"
              alt="controller button"
              src={`${Constants.assetHost}/assets/images/controller_close_on.png`}
              className="w-[120px] h-[120px]"
            />
          </div>
        ) : (
          <div onPointerDown={handleCancelDown} className="flex-col-center">
            <img
              draggable="false"
              alt="controller button"
              src={`${Constants.assetHost}/assets/images/controller_close_default.png`}
              className="w-[121px] h-[126px]"
            />
          </div>
        )}
      </div>

      <div className="flex-col-center w-[120px] h-[120px] cursor-pointer">
        {confirm ? (
          <div
            onPointerUp={handleConfirmUp}
            className="flex-col-center w-[121px] h-[126px]"
          >
            <img
              draggable="false"
              alt="controller button"
              src={`${Constants.assetHost}/assets/images/controller_confirm_on.png`}
              className="w-[120px] h-[120px]"
            />
          </div>
        ) : (
          <div onPointerDown={handleConfirmDown} className="flex-col-center">
            <img
              draggable="false"
              alt="controller button"
              src={`${Constants.assetHost}/assets/images/controller_confirm_default.png`}
              className="w-[121px] h-[126px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};
