import Constants from "common/constant";
import { CourseProgress } from "features/courses/types";
import {
  StageLevelRect,
  StageLevelPath,
  StageLastLevelRect,
  StageLastLevelPath,
} from "./StageLevelPaths";

export const LevelStatus = ({
  stage,
  offset,
  scale,
  stroke,
  radius,
  progresses,
}: {
  stage: number;
  offset: number;
  scale: number;
  stroke: number;
  radius: number;
  progresses: CourseProgress;
}) => (
  <>
    <div
      className="absolute z-[-1] w-full h-full"
      style={{ filter: "drop-shadow(2px 2px 3px rgba(63, 74, 97, 0.5))" }}
    >
      <svg className="absolute z-[-1] w-full h-full">
        {progresses.level.map((level, index) => {
          return (
            <g key={index}>
              <path
                overflow="visible"
                d={
                  index < progresses.level.length - 1
                    ? StageLastLevelPath(radius, scale, offset)[index]
                    : StageLevelPath(radius, scale, offset)[index]
                }
                fill="transparent"
                stroke={
                  level.is_finished
                    ? index < progresses.stage.animation
                      ? "#F9E531"
                      : "white"
                    : "rgb(156,157,163)"
                }
                strokeWidth={stroke * 1.5 * scale}
              />
            </g>
          );
        })}
      </svg>
      {progresses.level.map((level, index) => {
        return (
          <div
            key={index}
            id={`level-animation-circle-${index}`}
            className="flex-col-view !absolute top-0 left-0 overflow-visible"
            style={{
              width: (radius + stroke) * 2 * scale,
              height: (radius + stroke) * 2 * scale,
              transform: `translate(${
                stage === 4
                  ? (StageLastLevelRect[index].left - stroke) * scale + offset
                  : (StageLevelRect[index].left - stroke) * scale + offset
              }px, ${
                stage === 4
                  ? (StageLastLevelRect[index].top - stroke) * scale
                  : (StageLevelRect[index].top - stroke) * scale
              }px)`,
            }}
          >
            <svg
              overflow="visible"
              width={(radius + stroke) * 2 * scale}
              height={(radius + stroke) * 2 * scale}
              viewBox={`${-stroke * scale} ${-stroke * scale} ${
                (radius + stroke) * 2 * scale
              } ${(radius + stroke) * 2 * scale}`}
            >
              <g overflow="visible">
                <circle
                  cx={radius * scale}
                  cy={radius * scale}
                  r={(radius + stroke / 2) * scale}
                  fill="none"
                  stroke={
                    level.is_finished
                      ? index < progresses.stage.animation
                        ? "#F9E531"
                        : "white"
                      : index === progresses.stage.finished
                      ? "white"
                      : "rgb(156,157,163)"
                  }
                  strokeWidth={stroke * scale}
                />
              </g>
            </svg>
          </div>
        );
      })}
    </div>

    {progresses.level.map((level, index) => {
      return (
        <div
          key={index}
          className="flex-col-center !absolute top-0 left-0 z-0 w-[140px] h-[52px] origin-top-left pointer-events-none"
          style={{
            transform: `translate(${
              stage === 4
                ? (StageLastLevelRect[index].left + radius) * scale + offset
                : (StageLevelRect[index].left + radius) * scale + offset
            }px, ${
              stage === 4
                ? (StageLastLevelRect[index].top + radius + 10) * scale
                : (StageLevelRect[index].top + radius + 10) * scale
            }px) scale(${scale})`,
            opacity: level.is_finished ? 1 : 0,
          }}
        >
          <img
            id={`level-animation-clear-image-${index}`}
            alt="*"
            src={`${Constants.assetHost}/assets/images/levelcrear_s@2x.png`}
            className="w-full h-full"
          />
        </div>
      );
    })}
  </>
);
