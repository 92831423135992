import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { animated, useSpring, useSpringRef } from "@react-spring/web";
import I18n from "i18n-js";
import { Howl } from "howler";
import { Dialog } from "@mui/material";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { FadeTransition } from "common/elements";
import { StageClearSvg } from "features/courses/assets";
import { actions } from "features/courses/coursesSlice";
import { levelClearAnimation } from "features/courses/api";
import { Course, CourseProgress } from "features/courses/types";
import { StageLevelRect, StageLevelPath } from "./StageLevelPaths";

export const LevelAnimation = ({
  offset,
  scale,
  stroke,
  radius,
  stage,
  progresses,
}: {
  offset: number;
  scale: number;
  stroke: number;
  radius: number;
  stage: number;
  progresses: CourseProgress;
}) => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [playClearSound, setPlayClearSound] = useState(false);
  const [clearImageShow, setClearImageShow] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const course_id = useSelector(
    (state: RootState) => state.courses.courses[Course.NISHISHINJUKU].course_id
  );

  const clearRef = useSpringRef();
  const { clear } = useSpring({
    clear: 0,
    ref: clearRef,
  });

  const circleRef = useSpringRef();
  const { circle } = useSpring({
    circle: 0.5,
    ref: circleRef,
  });

  const pathRef = useSpringRef();
  const { path } = useSpring({
    path: 0,
    ref: pathRef,
  });

  const handleBackToStage = () => {
    play();
    setClearImageShow(false);
    navigate("/courses");
  };

  useEffect(() => {
    if (playClearSound) {
      const sound = new Howl({
        mute: !config.sound.enable,
        volume: config.sound.volume,
        autoplay: true,
        loop: false,
        src: [`${Constants.assetHost}/assets/blockly/gotonextstage.mp3`],
      });
      sound.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playClearSound]);

  useEffect(() => {
    Promise.all([
      clear.start({ from: 0, to: 1, config: { duration: 2000 } }),
      circle.start({ from: 0, to: 1, config: { duration: 2000 } }),
    ]).then(() => {
      if (
        progresses.stage.total === progresses.stage.finished &&
        progresses.stage.animation !== progresses.stage.finished
      ) {
        setClearImageShow(true);
      }
      if (progresses.stage.animation < progresses.stage.finished) {
        path.start({ from: 0, to: 1, config: { duration: 2000 } }).then(() => {
          dispatch(
            actions.updateLevelAnimation({
              course: Course.NISHISHINJUKU,
              stageId: stage,
              stepId: progresses.stage.finished,
            })
          );
          levelClearAnimation({
            course_id,
            uid: user.active.uid,
            stageId: stage,
            stepId: progresses.stage.finished,
          });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearRef, circleRef, pathRef]);

  const handleTransition = () => {
    // usePlaySound doesn't work.
    if ("AudioContext" in window) {
      const sound = new Howl({
        mute: !config.sound.enable,
        volume: config.sound.volume,
        src: [`${Constants.assetHost}/assets/blockly/stageclear.mp3`],
      });
      sound.play();
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={clearImageShow}
        maxWidth={false}
        sx={{ zIndex: 9999 }}
        TransitionComponent={FadeTransition}
        componentsProps={{
          backdrop: { style: { backgroundColor: "transparent" } },
        }}
        PaperProps={{
          style: { backgroundColor: "transparent", overflow: "hidden" },
        }}
        transitionDuration={{ enter: 1000, exit: 500 }}
        TransitionProps={{
          onEnter: handleTransition,
        }}
      >
        <div
          className="flex-col-center h-full bg-white/70 animate-fade-in backdrop-blur-[8px]"
          onClick={handleBackToStage}
        >
          <div
            className="flex-col-center"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="flex-col-center w-[696px] h-[402px]">
              <StageClearSvg />
            </div>
            <div className="flex-col-view">
              <svg width={608} height={60} viewBox="0 0 608 60">
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fill="#3F4A61"
                  fontSize="32"
                  fontFamily="MPLUSRounded1c-Bold"
                  stroke="#FEFDFE"
                  strokeWidth={8}
                  paintOrder="stroke"
                >
                  {I18n.t("MSG_COURSE_GAME_STAGE_CLEAR")}
                </text>
              </svg>
            </div>

            <div className="flex-row-el flex-center mt-12 alpha">
              <button className="btn btn-primary" onClick={handleBackToStage}>
                <p className="text-body text-white text-[24px] !mb-[3px]">
                  {I18n.t("MSG_COURSE_GAME_SUCCESS_NEXT_STAGE_BTN")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <div
        className="absolute z-[-1] w-full h-full"
        style={{ filter: "drop-shadow(2px 2px 3px rgba(63, 74, 97, 0.5))" }}
      >
        <svg className="absolute z-[-1] w-full h-full">
          {progresses.level.map((level, index) => (
            <g key={index}>
              <path
                overflow="visible"
                d={StageLevelPath(radius, scale, offset)[index]}
                fill="transparent"
                stroke={level.is_finished ? "white" : "rgb(156,157,163)"}
                strokeWidth={stroke * 1.5 * scale}
              />

              {level.is_finished && (
                <animated.path
                  d={StageLevelPath(radius, scale, offset)[index]}
                  fill="transparent"
                  stroke="#F9E531"
                  strokeWidth={stroke * 1.5 * scale}
                  strokeDasharray={1000}
                  strokeDashoffset={
                    index < progresses.stage.animation
                      ? 1
                      : path.to((x) => (1 - x) * 1000)
                  }
                />
              )}
            </g>
          ))}
        </svg>
        {progresses.level.map((level, index) => (
          <div
            key={index}
            id={`level-animation-circle-${index}`}
            className="flex-col-view !absolute top-0 left-0 overflow-visible"
            style={{
              width: (radius + stroke) * 2 * scale,
              height: (radius + stroke) * 2 * scale,
              transform: `translate(${
                (StageLevelRect[index].left - stroke) * scale + offset
              }px, ${(StageLevelRect[index].top - stroke) * scale}px)`,
            }}
          >
            <svg
              overflow="visible"
              width={(radius + stroke) * 2 * scale}
              height={(radius + stroke) * 2 * scale}
              viewBox={`${-stroke * scale} ${-stroke * scale} ${
                (radius + stroke) * 2 * scale
              } ${(radius + stroke) * 2 * scale}`}
            >
              <g overflow="visible">
                <circle
                  cx={radius * scale}
                  cy={radius * scale}
                  r={(radius + stroke / 2) * scale}
                  fill="none"
                  stroke={
                    level.is_finished
                      ? "white"
                      : index === progresses.stage.finished
                      ? "white"
                      : "rgb(156,157,163)"
                  }
                  strokeWidth={stroke * scale}
                />
                {level.is_finished && (
                  <animated.circle
                    cx={radius * scale}
                    cy={radius * scale}
                    r={(radius + stroke / 2) * scale}
                    fill="transparent"
                    stroke="#F9E531"
                    strokeWidth={stroke * scale}
                    strokeDasharray={radius * scale * 2 * Math.PI}
                    transform={`rotate(100,${radius * scale}, ${
                      radius * scale
                    })`}
                    strokeDashoffset={
                      index < progresses.stage.animation
                        ? 1
                        : circle.to(
                            (x) =>
                              (1 - x) * Number(radius * scale * 2) * Math.PI
                          )
                    }
                  />
                )}
              </g>
            </svg>
          </div>
        ))}
      </div>

      {progresses.level.map((level, index) => (
        <animated.div
          key={index}
          className="flex-col-center !absolute top-0 left-0 z-0 w-[140px] h-[52px] origin-top-left pointer-events-none"
          style={{
            transform: `translate(${
              (StageLevelRect[index].left + radius) * scale + offset
            }px, ${
              (StageLevelRect[index].top + radius + 10) * scale
            }px) scale(${scale})`,
            opacity: level.is_finished
              ? index < progresses.stage.animation
                ? 1
                : clear.to((x) => {
                    if (x < 0.1 && !playClearSound) {
                      setPlayClearSound(true);
                    }
                    return x;
                  })
              : 0,
          }}
        >
          <img
            id={`level-animation-clear-image-${index}`}
            alt="*"
            src={`${Constants.assetHost}/assets/images/levelcrear_s@2x.png`}
            className="w-full h-full"
          />
        </animated.div>
      ))}
    </>
  );
};
