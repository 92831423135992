import Constants from "common/constant";
import { IconBgArrow } from "features/creator/assets";
import { IconBgTitle } from "./IconBgTitle";

const CourseTitleMsg = () => (
  <>
    <span className="inline-block rotate-6">コ</span>
    <span className="inline-block -rotate-6">ー</span>
    <span className="inline-block rotate-6">ス</span>
  </>
);

const LearTitleMsg = () => (
  <>
    <span className="inline-block -rotate-6">ま</span>
    <span className="inline-block rotate-6">な</span>
    <span className="inline-block rotate-6">ぶ</span>
  </>
);

const TopTitle = () => (
  <div className="flex-col-el flex-center w-[800px] h-[114px] absolute z-50">
    <div className="flex-col-el absolute z-0 mt-6">
      <img src="/course_title_bg.png" className="w-[493px] h-[206px]" />
    </div>

    <div className="flex-row-center gap-2">
      <p
        style={{
          WebkitTextStroke: "24px #FFA03B",
        }}
        className="flex-row-el flex-center title-text !text-[80px] -tracking-[0.1em] whitespace-pre text-center text-textcolor/black"
      >
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "12px #FEFDFE",
            position: "absolute",
          }}
        >
          <CourseTitleMsg />
        </span>
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          <CourseTitleMsg />
        </span>
        <CourseTitleMsg />
      </p>
      <p
        style={{
          WebkitTextStroke: "24px #FFA03B",
        }}
        className="flex-row-el flex-center title-text !text-[40px] -tracking-[0.1em] !mt-8 whitespace-pre text-center text-textcolor/black"
      >
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "12px #FEFDFE",
            position: "absolute",
          }}
        >
          <span className="inline-block rotate-6">で</span>
        </span>
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          <span className="inline-block rotate-6">で</span>
        </span>
        <span className="inline-block rotate-6">で</span>
      </p>
      <p
        style={{
          WebkitTextStroke: "24px #FFA03B",
        }}
        className="flex-row-el flex-center title-text !text-[80px] -tracking-[0.1em] whitespace-pre text-center text-green/100"
      >
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "12px #FEFDFE",
            position: "absolute",
          }}
        >
          <LearTitleMsg />
        </span>
        <span
          className="inline-block leading-0 z-10"
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          <LearTitleMsg />
        </span>
        <LearTitleMsg />
      </p>
    </div>
  </div>
);

export const DefaultDisplay = () => (
  <div className="flex-col-el flex-center w-full h-full bg-gray/20 absolute top-0 left-0 -z-0 overflow-hidden">
    <img
      alt="creator display default bg"
      src={`${Constants.assetHost}/assets/images/creator_display_bg.png`}
      className="w-full h-full object-fill"
    />

    <img
      alt="app logo"
      src={`${Constants.assetHost}/assets/images/title_and_logo.png`}
      className="w-[205px] h-[36px] absolute top-2 left-1 z-50"
    />

    <div className="flex-col-el flex-center absolute top-28 left-[200px] z-[100]">
      <IconBgTitle />
      <div className="flex-row-el flex-center absolute mb-2">
        <p className="title-text !text-[22px] text-textcolor/black -rotate-12">
          {"いろんなコースを\nまなんでみよう！"}
        </p>
      </div>
    </div>

    <TopTitle />

    <div className="flex-col-el item-end absolute top-8 right-[10px] z-50">
      <div className="flex-row-center mt-20">
        <p className="text-body text-white text-[32px] text-right whitespace-pre-line">
          {"ここからコースを\nまなぼう！"}
        </p>
      </div>
      <div className="flex-col-el item-end -scale-x-100">
        <IconBgArrow />
      </div>
    </div>

    <div
      id="display-left-bg-bottom"
      className="flex-col-el flex-center bg-green/40 w-[440px] h-[440px] rounded-full absolute top-[38px] -left-[280px] opacity-70 wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(-260px)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />
    <div
      id="display-left-bg-top"
      className="flex-col-el flex-center w-[440px] h-[440px] rounded-full absolute top-[4px] -left-[280px] wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(-260px)",
        background: "linear-gradient(76.85deg, #05C57B 58.03%, #54F3B6 95.04%)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />

    <div
      id="display-right-bottom-bg-bottom"
      className="flex-col-el flex-center bg-[#FED4D2] w-[190px] h-[190px] rounded-full absolute bottom-[50px] -right-[105px] opacity-70 wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(100px) translateY(70px)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />
    <div
      id="display-right-bottom-bg-top"
      className="flex-col-el flex-center w-[190px] h-[190px] rounded-full absolute bottom-[70px] -right-[95px] wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(100px) translateY(70px)",
        background: "linear-gradient(289.28deg, #FF5853 32.2%, #FEA9A6 89.75%)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />

    <div
      id="display-right-bg-bottom"
      className="flex-col-el flex-center bg-orange/40 w-[540px] h-[540px] rounded-full absolute -top-[190px] -right-[220px] opacity-70 wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(260px) translateY(-260px)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />
    <div
      id="display-right-bg-top"
      className="flex-col-el flex-center w-[540px] h-[540px] rounded-full absolute -top-[210px] -right-[220px] wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateX(260px) translateY(-260px)",
        background:
          "linear-gradient(221.35deg, #FF9524 45.24%, #FFB669 69.03%, #FFD7AD 89.64%)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />

    <div
      id="display-bottom-bg-bottom"
      className="flex-col-el flex-center bg-blue/40 w-[640px] h-[640px] ml-6 rounded-full absolute -bottom-[460px] opacity-70 wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateY(470px)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />
    <div
      id="display-bottom-bg-top"
      className="flex-col-el flex-center w-[640px] h-[640px] ml-14 rounded-full absolute -bottom-[460px] wave"
      style={{
        borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
        transform: "translateY(470px)",
        background:
          "linear-gradient(351.12deg, #3790ED 68.36%, #A9D3FF 100.27%)",
        boxShadow:
          "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
      }}
    />
  </div>
);
