import { Vector2D } from "common/types";
import {
  ResizeProps,
  ViewStyleProps,
  ImageStyleProps,
  LayoutStyleProps,
  ShadowStyleProps,
  TransformsStyleProps,
} from "./StyleProps";
import {
  FlexStyle,
  ViewStyle,
  TextStyle,
  StarStyle,
  ImageStyle,
  ShadowStyle,
  ResizeConfig,
  TransformStyle,
  RegularPolygonStyle,
} from "./StyleTypes";

export interface ComponentProperty {
  name: string;
  style: {
    image?: ImageStyle;
    text?: TextStyle;
    layout: FlexStyle;
    shadow?: ShadowStyle;
    view: ViewStyle;
    transform: TransformStyle;
    star?: StarStyle;
    polygon?: RegularPolygonStyle;

    defaultImage?: ImageStyle;
    defaultText?: TextStyle;
    defaultLayout: FlexStyle;
    defaultShadow?: ShadowStyle;
    defaultView: ViewStyle;
    defaultTransform: TransformStyle;
    defaultStar?: StarStyle;
    defaultPolygon?: RegularPolygonStyle;
  };
  resize: ResizeConfig;
  events: string[];
  [propName: string]: any;
}

export interface ComponentType {
  DragLayerComponent?: React.FC<any>;
  DesignComponent: React.FC<any>;
  property: ComponentProperty;
}

export interface DragLayerComponentProps {
  scale: number;
  property: ComponentProperty;
}

export interface DesignWrapComponentProps {
  id: string;
  scale: Vector2D;
  property: ComponentProperty;
  handleUpdateProperty?: (property?: ComponentProperty) => void;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}

export interface CanvasDesignWrapComponentProps {
  property: ComponentProperty;
}

type pointerEvents =
  | "auto"
  | "none"
  | "visiblePainted"
  | "visibleFill"
  | "visibleStroke"
  | "visible"
  | "painted"
  | "fill"
  | "stroke"
  | "all"
  | "inherit";
export interface RuntimeWrapComponentProps {
  id: string;
  typeId: string;
  zIndex: number;
  property: ComponentProperty;
  navigate: (id: string) => void;
  pointerEvents?: pointerEvents;
  onClick?: (event) => void | undefined;
  events?: { eventId: string; code: string }[];
  initVariables: string;
  proceduresCode: string;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}

export const DefaultComponentProperty: ComponentProperty = {
  name: "",
  style: {
    layout: {
      ...LayoutStyleProps,
    },
    shadow: {
      ...ShadowStyleProps,
    },
    view: {
      ...ViewStyleProps,
    },
    transform: {
      ...TransformsStyleProps,
    },
    defaultLayout: {
      ...LayoutStyleProps,
    },
    defaultShadow: {
      ...ShadowStyleProps,
    },
    defaultView: {
      ...ViewStyleProps,
    },
    defaultTransform: {
      ...TransformsStyleProps,
    },
  },
  resize: {
    ...ResizeProps,
  },
  events: [],
};

export const DefaultImageComponentProperty: ComponentProperty = {
  name: "",
  style: {
    image: {
      ...ImageStyleProps,
    },
    layout: {
      ...LayoutStyleProps,
    },
    shadow: {
      ...ShadowStyleProps,
    },
    view: {
      ...ViewStyleProps,
    },
    transform: {
      ...TransformsStyleProps,
    },
    defaultImage: {
      ...ImageStyleProps,
    },
    defaultLayout: {
      ...LayoutStyleProps,
    },
    defaultShadow: {
      ...ShadowStyleProps,
    },
    defaultView: {
      ...ViewStyleProps,
    },
    defaultTransform: {
      ...TransformsStyleProps,
    },
  },
  resize: {
    ...ResizeProps,
  },
  events: [],
};

export const DefaultDrawingComponentProperty: ComponentProperty = {
  name: "",
  drawing: { svg: "" },
  style: {
    layout: {
      ...LayoutStyleProps,
    },
    shadow: {
      ...ShadowStyleProps,
    },
    view: {
      ...ViewStyleProps,
    },
    transform: {
      ...TransformsStyleProps,
    },
    defaultLayout: {
      ...LayoutStyleProps,
    },
    defaultShadow: {
      ...ShadowStyleProps,
    },
    defaultView: {
      ...ViewStyleProps,
    },
    defaultTransform: {
      ...TransformsStyleProps,
    },
  },
  resize: {
    ...ResizeProps,
  },
  events: [],
};
