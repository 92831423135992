import produce from "immer";
import {
  ComponentType,
  DefaultDrawingComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";

const Drawing: ComponentType = {
  DesignComponent: DesignComponent,
  property: produce(DefaultDrawingComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_DRAWING";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_DRAWING";

    draft.style.defaultView.borderWidth = 0;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = 0;

    draft.style.view.borderWidth = 0;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = 0;

    draft.events = ["drawing_click_event"];
  }),
};

export default Drawing;
