import { SVGProps } from "react";

export const IconBgTitle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={210}
    height={136}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m170.829 2.412-138.5 17.863-29 34.735 38.324 77.57 148.464-35.306 12.055-56.834-31.343-38.028Z"
      fill="#F9E532"
      stroke="#3F4A61"
      strokeWidth={4}
    />
  </svg>
);
