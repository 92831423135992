/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-func */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Howl } from "howler";
import * as Blockly from "blockly";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { AppDispatch, RootState } from "app/store";
import { sleep, btnDelay, useScale, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { useGameSound } from "features/courses/hook";
import { ResetBtnIcon } from "features/courses/assets";
import { actions } from "features/courses/coursesSlice";
import {
  performMove,
  performJump,
  performTurnToLeft,
  performTurnToRight,
} from "features/courses/algorithm/game/scene/animations";
import {
  Score,
  Failed,
  offset,
  Success,
  StartBtn,
  ResetBtn,
} from "features/courses/algorithm/game/scene/components";
import { checkBtnColor } from "features/courses/algorithm/game/scene/maps";
import { updateLevelAnswer, updateLevelProgresses } from "features/courses/api";
import StageMaps from "./maps";
import { GameScene } from "./GameScene";

export const MainScenePage = ({
  stage,
  step,
  reload,
  setReload,
  isFinished,
  scene,
  gameStart,
  setGameStart,
  setScene,
  event,
  workspace,
  flyoutWidth,
  blocklyTopOffset,
  setLoading,
}: {
  stage: number;
  step: number;
  reload: boolean;
  setReload: (reload: boolean) => void;
  isFinished: boolean;
  scene: Scene;
  gameBgm: Howl | null;
  gameStart: boolean;
  setGameStart: (gameStart: boolean) => void;
  setScene: (scene: Scene) => void;
  workspace: Blockly.WorkspaceSvg;
  flyoutWidth: number;
  blocklyTopOffset: number;
  event: string;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const { scale, width, height } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [totalGem, setTotalGem] = useState(0);
  const [gemScore, setGemScore] = useState(0);
  const [success, setSuccess] = useState(false);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const course_id = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.TAKUSHOKU_BASIC].course_id
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const [character, setCharacter] = useState<AbstractMesh | null>(null);
  const summary = useSelector(
    (state: RootState) => state.courses.courses[Course.TAKUSHOKU_BASIC].summary
  );

  useEffect(() => {
    if (gameOver) {
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setEditable(true);
      });
      if (success) {
        setSuccessShow(true);

        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();

        const xml = Blockly.Xml.workspaceToDom(workspace);
        const answer = Blockly.Xml.domToText(xml);

        dayjs.extend(utc);
        dayjs.extend(timezone);
        const now = dayjs().tz("Asia/Tokyo").format("YYYY-MM-DDTHH:mm:ssZ"); // 現状は強制的にJSTにする、今後UTCにする

        if (!isFinished) {
          dispatch(
            actions.updateLevelProgresses({
              course: Course.TAKUSHOKU_BASIC,
              stageId: stage,
              stepId: step,
              totalFinished: summary.finished + 1,
              answer,
              finishedAt: now,
            })
          );

          updateLevelProgresses({
            course_id,
            course: Course.TAKUSHOKU_BASIC,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            totalFinished: summary.finished + 1,
            answer,
            finishedAt: now,
          });
        } else {
          dispatch(
            actions.updateLevelAnswer({
              course: Course.TAKUSHOKU_BASIC,
              stageId: stage,
              stepId: step,
              answer,
            })
          );
          updateLevelAnswer({
            course_id,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            answer,
            isFinished: true,
            answerAt: now,
          });
        }
      } else {
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handlePlusGemScore = (scene: Scene, totalGem: number) => {
    setGemScore((prevScore) => {
      const newScore = prevScore + 1;
      if (newScore === totalGem) {
        setSuccess(true);
        scene.metadata = { result: true };
      } else {
      }
      return newScore;
    });
  };

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (!workspace.getBlockById(id)) {
        const xml = Blockly.Xml.workspaceToDom(workspace);
        const xml_text = Blockly.Xml.domToText(xml);
        console.error(
          `failed to get block id: ${id} stage:${stage}, step:${step}, xml_text:${xml_text}`
        );
      } else {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const handleStart = () => {
    btnDelay(() => {
      gameSound.startSound();
      setGameStart(true);
      scene.metadata = { result: false };

      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(false);
        block.setEditable(false);
      });
      try {
        const handleEvent = new Function(
          "highlightBlock",
          "sleep",
          "scene",
          "character",
          "stepMap",
          "offset",
          "performMove",
          "performJump",
          "performTurnToLeft",
          "performTurnToRight",
          "moveSound",
          "jumpSound",
          "turnSound",
          "checkBtnColor",
          "setGameOver",
          "setSuccess",
          event
            ? event
            : `return () => {
            setGameOver(true);
          }
          `
        );
        handleEvent(
          highlightBlock,
          sleep,
          scene,
          character,
          stepMap,
          offset,
          performMove,
          performJump,
          performTurnToLeft,
          performTurnToRight,
          gameSound.moveSound,
          gameSound.jumpSound,
          gameSound.turnSound,
          checkBtnColor,
          setGameOver,
          setSuccess
        )();
      } catch (error) {
        const xml = Blockly.Xml.workspaceToDom(workspace);
        const xml_text = Blockly.Xml.domToText(xml);
        console.error(
          `failed to run event: stage:${stage}, step:${step}, error: ${error}, xml_text:${xml_text}`
        );
      }
    });
  };

  const handleReset = () => {
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setLoading(true);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setHighlighted(false);
        block.setMovable(true);
        block.setEditable(true);
      });
    });
  };

  const handleClearBlock = () => {
    play();
    if (gameStart) {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setLoading(true);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
    }
    workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
      if (block.type === "character_event") {
        block.setHighlighted(false);
        block.moveTo(
          new Blockly.utils.Coordinate(
            flyoutWidth / scale + 20,
            blocklyTopOffset
          )
        );
      } else {
        block.dispose();
      }
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setSuccessShow(false);
      navigate(`/courses/${Course.TAKUSHOKU_BASIC}/stage/${stage}`);
    });
  };

  return (
    <>
      {gameOver && !success && <Failed scale={scale} reset={handleReset} />}

      {successShow && (
        <Success scale={scale} back={handleBack} reset={handleReset} />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            width={width}
            height={height}
            scale={scale}
            stage={stage}
            step={step}
            map={stepMap}
            reload={reload}
            config={{
              setLoading,
              setScene,
              setCharacter,
              setTotalGem,
              handlePlusGemScore,
            }}
          />

          {/* てにいれたアイテム */}
          <div
            className="flex-row-center !items-start w-full !absolute top-0"
            style={{
              height: 56 * scale,
              left: -(flyoutWidth + 10 * scale) / 2,
            }}
          >
            <div
              className="flex-row-view h-[56px] origin-top"
              style={{ transform: `scale(${scale})` }}
            >
              <Score totalGem={totalGem} gemScore={gemScore} />
            </div>
          </div>
        </div>

        {!gameStart && (
          <div
            className="flex-col-center !absolute z-50 origin-bottom-left pointer-events-auto"
            style={{
              bottom: 24 * scale,
              left: -flyoutWidth - 88 * scale,
              transform: `scale(${scale})`,
            }}
          >
            <button
              className="opacity-button pointer-events-auto bg-gray/80"
              onClick={handleClearBlock}
            >
              <div className="flex-col-center w-[48px] h-[48px]">
                <ResetBtnIcon />
              </div>
            </button>
          </div>
        )}

        {!gameStart ? (
          <StartBtn
            scale={scale}
            disabled={
              workspace.getBlocksByType("character_event", false).length < 1 ||
              workspace
                .getBlocksByType("character_event", false)
                .shift()
                .getChildren(false).length < 1
            }
            start={handleStart}
          />
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}
      </div>
    </>
  );
};

export default MainScenePage;
