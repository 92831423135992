/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CreatorTopCaseManager } from "common/elements";
import { DisplayMode } from "features/creator/types";
import { actions, selectProjectById } from "features/creator/slice";
import { PickUpPage, RankingPage } from "features/creator/recommend";
import { Controller } from "./Controller";
import { DefaultDisplay } from "./DefaultDisplay";
import { ProjectActionPanel } from "./ProjectActionPanel";
import { ProjectDetailPanel } from "./ProjectDetailPanel";

export const Panel = () => {
  const { scale } = useScale();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [isFirst, setIsFirst] = useState(true);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const detailPanelRef = useRef<HTMLDivElement>(null);
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const selectedProject = useSelector((state: RootState) =>
    selectProjectById(state, selectedProjectId)
  );
  const [display, setDisplay] = useState<DisplayMode>(displayMode);
  const user = useSelector((state: RootState) => state.user.appUser);
  const [localSelectedProject, setLocalSelectedProject] = useState(
    selectedProject
  );
  const config = useSelector((state: RootState) => state.config.userConfig);
  const GameCaseComponent = CreatorTopCaseManager[config.game_case];

  useEffect(() => {
    if (
      !isFirst &&
      selectedProject &&
      localSelectedProject &&
      selectedProjectId === localSelectedProject.project_id
    ) {
      setLocalSelectedProject(selectedProject);
    }
  }, [selectedProject]);

  useEffect(() => {
    dispatch(actions.updateRecommendProject(null));

    if (isFirst) {
      anime({
        targets: document.getElementsByClassName(
          `app-project-card-${selectedProjectId}`
        ),
        opacity: [1, 0],
        duration: 1,
        easing: "easeInOutQuad",
      });
      setIsFirst(false);
    }

    if (!selectedProjectId) {
      setLocalSelectedProject(null);
    }

    const displayAnimation = async () => {
      if (ref.current && !isFirst) {
        await anime({
          targets: ref.current,
          scaleX: [1, 1, 1, 0.8, 0.5, 0.2, 0.1, 0.001],
          scaleY: [1, 0.5, 0.2, 0.001, 0.001, 0.001, 0.001, 0.001],
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        await anime({
          targets: ref.current,
          opacity: 0,
          duration: 1,
          autoplay: true,
        }).finished;
        if (detailPanelRef.current) {
          await anime({
            targets: detailPanelRef.current,
            translateY: "-100%",
            duration: 1,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        }
        if (displayMode === DisplayMode.CAPTURE && selectedProject) {
          setLocalSelectedProject(selectedProject);
        }
        if (
          displayMode === DisplayMode.NONE &&
          display === DisplayMode.CAPTURE
        ) {
          dispatch(actions.updateSelectedProjectId(null));
        }
        if (
          displayMode === DisplayMode.RANKING ||
          displayMode === DisplayMode.PICKUP
        ) {
          dispatch(actions.updateSelectedProjectId(null));
        }
        setDisplay(displayMode);

        await anime({
          targets: ref.current,
          opacity: 1,
          duration: 1,
          autoplay: true,
        }).finished;
        await anime({
          targets: ref.current,
          scaleX: [0.001, 0.2, 0.5, 1, 1, 1, 1, 1],
          scaleY: [0.001, 0.001, 0.001, 0.001, 0.1, 0.2, 0.5, 1],
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
      }
    };
    const displayDetailInAnimation = async () => {
      if (detailPanelRef.current) {
        setDisplay(displayMode);
        await anime({
          targets: detailPanelRef.current,
          translateY: ["-100%", 0],
          duration: 700,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
      }
    };
    const displayDetailOutAnimation = async () => {
      if (detailPanelRef.current) {
        setLocalSelectedProject(selectedProject);
        await anime({
          targets: detailPanelRef.current,
          translateY: [0, "-100%"],
          duration: 700,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        setDisplay(displayMode);
      }
    };
    if (displayMode === DisplayMode.DETAIL) {
      displayDetailInAnimation();
    } else if (
      display === DisplayMode.DETAIL &&
      displayMode === DisplayMode.CAPTURE
    ) {
      displayDetailOutAnimation();
    } else if (
      display === DisplayMode.CAPTURE ||
      display === DisplayMode.DETAIL ||
      displayMode !== display
    ) {
      displayAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, displayMode]);

  return (
    <div className="flex-col-el">
      <div
        id="project-preview-panel"
        className="flex-col-el z-30"
        style={{
          width: 788 * scale,
          height: 798 * scale,
        }}
      >
        <div
          className="flex-col-view w-[788px] h-[798px] origin-top-left"
          style={{ transform: `scale(${scale})` }}
        >
          <div className="flex-col-center w-full h-full">
            {/* ケース */}
            <div className="flex-col-center w-full h-full z-50">
              {selectedProject && (
                <div
                  id="project-display-card"
                  className="flex-col-el flex-center absolute -top-4 left-[58px] z-[-1]"
                >
                  <img
                    alt="officail app card"
                    src={`${Constants.assetHost}/assets/images/${
                      selectedProject.author_name === "まなパズ公式"
                        ? "app_card_official"
                        : selectedProject.author_uid === user.active.uid
                        ? "app_card_user"
                        : "app_card_shared"
                    }.png`}
                    className="w-[112px] h-[138px]"
                  />
                </div>
              )}
              <div
                className="flex-col-center w-full h-full"
                style={{
                  filter:
                    "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.20))",
                }}
              >
                <img
                  alt="game case"
                  src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
                  className="w-full h-full"
                />
              </div>
            </div>

            <div className="flex-col-el w-full h-[528px] px-[44px] absolute z-[50] top-[24px]">
              <div className="flex-col-el w-[702px] h-[530px] absolute -mt-[1px] -ml-[1px] z-50 pointer-events-none">
                <div className="flex-col-el w-full h-full rounded-[17px] border-solid border-[1px] border-[#000000]/40 p-1">
                  <div className="flex-col-el w-full h-full rounded-[12px] border-solid border-[2px] border-[#000000]/30 overflow-hidden">
                    <div className="flex-col-el w-full h-full rounded-[10px] border-solid border-[4px] border-[#FFFFFF]/40" />
                  </div>
                </div>
              </div>

              <div className="flex-col-el w-full h-full border-solid border-gray/100 border-[6px] rounded-2xl overflow-hidden">
                <div className="flex-col-view w-full h-full">
                  <div className="flex-col-el flex-center w-full h-full bg-gray2/100 absolute top-0 left-0" />

                  <div
                    ref={ref}
                    style={{ transform: `translateZ(0)` }}
                    className="flex-col-view w-full h-full z-10 will-change-transform"
                  >
                    {display === DisplayMode.NONE && <DefaultDisplay />}

                    {display === DisplayMode.RANKING && (
                      <div
                        className="flex-col-el flex-center w-full h-full z-10"
                        style={{
                          background:
                            "linear-gradient(0deg, #FFEFBA 0%, #FFFFFF 100%)",
                        }}
                      >
                        <RankingPage />
                      </div>
                    )}

                    {display === DisplayMode.PICKUP && (
                      <div
                        className="flex-col-el flex-center w-full h-full z-10"
                        style={{
                          background:
                            "linear-gradient(0deg, #FFEFBA 0%, #FFFFFF 100%)",
                        }}
                      >
                        <PickUpPage />
                      </div>
                    )}

                    {(display === DisplayMode.CAPTURE ||
                      display === DisplayMode.DETAIL) &&
                      localSelectedProject && (
                        <ProjectActionPanel
                          isUserProject={
                            localSelectedProject.author_uid === user.active.uid
                          }
                          project={localSelectedProject}
                        />
                      )}

                    <div
                      ref={detailPanelRef}
                      className="flex-col-el flex-center w-full h-full z-50 absolute"
                      style={{ transform: "translateY(-100%)" }}
                    >
                      {display === DisplayMode.DETAIL &&
                        localSelectedProject && (
                          <ProjectDetailPanel
                            readonly={
                              localSelectedProject?.author_uid !==
                                user.active.uid ||
                              !!localSelectedProject.deleted_at
                            }
                            project={localSelectedProject}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Controller
              isUserProject={
                localSelectedProject?.author_uid === user.active.uid
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
