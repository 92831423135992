import { useState, TransitionEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Display } from "./display";
import { Collection } from "./collection";
import { CourseModelPanel } from "./tangible";

export const CourseTopPage = () => {
  const { ratio, scale } = useScale();
  const [animation, setAnimation] = useState(false);
  const showCourseList = useSelector(
    (state: RootState) => state.course.showCourseList
  );

  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (
      e.propertyName === "transform" &&
      (e.target as HTMLDivElement).id === "course-top-page" &&
      !showCourseList
    ) {
      setAnimation(true);
    }
  };

  return (
    <div className="flex-col-el flex-center w-full h-full relative">
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/course_top_bg.png`}
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      />

      <div
        id="course-top-page"
        onTransitionEnd={handleTransitionEnd}
        style={{
          transform: `translateX(${showCourseList ? -1020 * scale : 0}px)`,
        }}
        className={`flex-row-el w-full h-full ${
          ratio > 1 ? "items-end" : "items-center"
        } justify-center transition-transform duration-700 will-change-transform mb-3`}
      >
        <CourseModelPanel />
        <Display animation={animation} setAnimation={setAnimation} />
        <Collection />
      </div>
    </div>
  );
};
