import Constants from "common/constant";
import { handleImageAnalysis } from "features/courses/api";
import {
  validText,
  calculateAngle,
  extractValidText,
  calculateDistance,
  convertObjectToBlockByLabel,
} from "./util";
import { Block, Label, BlockType, ExecutableBlock } from "./type";

export const handleExtractLabelFromImage = (
  fileBlob: string | Blob | ArrayBuffer,
  debug: boolean,
  success: (
    labels: Label[],
    isStart: boolean,
    isAiRobotTeacher: boolean
  ) => void,
  error: (err: any) => void,
  completely: () => void
) =>
  handleImageAnalysis({ data: fileBlob })
    .then((response) => {
      var isStart = false;
      var isAiRobotTeacher = false;

      const validLabels =
        response.data.readResult.blocks.length > 0
          ? (response.data.readResult.blocks[0].lines as Label[])
              .filter((label) => {
                if (label.text.includes("スタート")) {
                  isStart = true;
                  return true;
                }

                if (label.text.includes("AIロボットせんせい")) {
                  isAiRobotTeacher = true;
                  return true;
                }

                return debug ? label.text : validText(label.text);
              })
              .map((validLabel) => ({
                originalText: validLabel.text,
                text: extractValidText(validLabel.text),
                boundingPolygon: validLabel.boundingPolygon,
              }))
          : [];

      success(validLabels, isStart, isAiRobotTeacher);
    })
    .catch(error)
    .finally(completely);

export const generateExecutableBlockList = (labels: Label[]) => {
  const blocks = (labels.map((label) =>
    convertObjectToBlockByLabel(label, labels)
  ) as Block[])
    .filter((block) => block.type !== BlockType.UNKNOWN)
    .sort((a, b) => {
      const blockAngle = calculateAngle(
        {
          x: a.label.boundingPolygon[0].x,
          y: a.label.boundingPolygon[0].y,
        },
        {
          x: a.label.boundingPolygon[2].x,
          y: a.label.boundingPolygon[2].y,
        }
      );
      let diffAngle =
        calculateAngle(
          {
            x: a.label.boundingPolygon[0].x,
            y: a.label.boundingPolygon[0].y,
          },
          {
            x: b.label.boundingPolygon[0].x,
            y: b.label.boundingPolygon[0].y,
          }
        ) - blockAngle;
      const angle = diffAngle < 0 ? 360 + diffAngle : diffAngle;
      return angle > 180 ? 1 : -1;
    });

  if (Constants.debugLog) {
    console.log("All blocks sorted: ", blocks);
  }

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (
      block.type === BlockType.EVENT_START ||
      block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
    ) {
      blocks[i].rootId = block.id;
    } else {
      const blockAngle = calculateAngle(
        {
          x: block.label.boundingPolygon[0].x,
          y: block.label.boundingPolygon[0].y,
        },
        {
          x: block.label.boundingPolygon[2].x,
          y: block.label.boundingPolygon[2].y,
        }
      );
      const parentsBlocks = blocks.filter(
        (b, index) =>
          index < i &&
          Math.abs(
            calculateAngle(
              {
                x: b.label.boundingPolygon[0].x,
                y: b.label.boundingPolygon[0].y,
              },
              {
                x: b.label.boundingPolygon[2].x,
                y: b.label.boundingPolygon[2].y,
              }
            ) - blockAngle
          ) < 20
      );
      let minDistanceParents: number;
      let minDistanceParentsBlock: Block;
      parentsBlocks.forEach((b) => {
        let distance = calculateDistance(
          {
            x: block.label.boundingPolygon[0].x,
            y: block.label.boundingPolygon[0].y,
          },
          {
            x: b.label.boundingPolygon[0].x,
            y: b.label.boundingPolygon[0].y,
          }
        );
        if (minDistanceParents) {
          if (distance < minDistanceParents) {
            minDistanceParents = distance;
            minDistanceParentsBlock = b;
          }
        } else {
          minDistanceParents = distance;
          minDistanceParentsBlock = b;
        }
      });

      const childrenBlocks = blocks.filter(
        (b, index) =>
          index > i &&
          Math.abs(
            calculateAngle(
              {
                x: b.label.boundingPolygon[0].x,
                y: b.label.boundingPolygon[0].y,
              },
              {
                x: b.label.boundingPolygon[2].x,
                y: b.label.boundingPolygon[2].y,
              }
            ) - blockAngle
          ) < 20
      );
      let minDistanceChildren: number;
      childrenBlocks.forEach((b) => {
        let distance = calculateDistance(
          {
            x: block.label.boundingPolygon[0].x,
            y: block.label.boundingPolygon[0].y,
          },
          {
            x: b.label.boundingPolygon[0].x,
            y: b.label.boundingPolygon[0].y,
          }
        );
        if (minDistanceChildren) {
          if (distance < minDistanceChildren) {
            minDistanceChildren = distance;
          }
        } else {
          minDistanceChildren = distance;
        }
      });

      if (Constants.debugLog) {
        console.log({ block, minDistanceParentsBlock, minDistanceParents });
      }

      if (
        minDistanceParents &&
        minDistanceParentsBlock &&
        minDistanceParents < 200 &&
        minDistanceParentsBlock.type !== BlockType.FUNCTION_END &&
        ((minDistanceChildren && minDistanceChildren < 200) ||
          block.type === BlockType.FUNCTION_END)
      ) {
        blocks[i].rootId = minDistanceParentsBlock.rootId;
      }
    }
  }

  if (Constants.debugLog) {
    console.log("All valid blocks: ", blocks);
  }

  const executableBlockList: ExecutableBlock[][] = blocks
    .filter(
      (block) =>
        block.type === BlockType.EVENT_START ||
        block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
    )
    .map((startBlock) =>
      blocks.filter((block) => block.rootId === startBlock.id)
    )
    .map((eventBlocks) => {
      const blockList: ExecutableBlock[] = [];
      var controlBlockStack = eventBlocks
        .filter((block) => block.type.includes("start"))
        .reverse();

      for (let i = 0; i < eventBlocks.length; i++) {
        let block = eventBlocks[i];
        if (block.type.includes("end")) {
          let parentBlock: Block;
          switch (block.type) {
            case BlockType.FUNCTION_END:
              parentBlock = controlBlockStack
                .filter(
                  (controlBlock) => controlBlock.type === eventBlocks[0].type
                )
                .shift();
              break;
            case BlockType.CONTROLS_END:
              parentBlock = controlBlockStack
                .filter((controlBlock) =>
                  controlBlock.type.includes("controls")
                )
                .shift();
              break;
            default:
              break;
          }

          if (Constants.debugLog) {
            console.log("parent block: ", parentBlock);
          }

          if (parentBlock) {
            const parentBlockIndex = eventBlocks.findIndex(
              (block) => block.id === parentBlock.id
            );
            if (parentBlockIndex < i) {
              blockList.push({
                id: block.id,
                parentId: parentBlock.id,
                type: block.type,
                args: block.args,
              });
              controlBlockStack = controlBlockStack.filter(
                (block) => block.id !== parentBlock.id
              );
            }
          }

          if (block.type === BlockType.FUNCTION_END) break;
        } else {
          blockList.push({
            id: block.id,
            type: block.type,
            args: block.args,
          });
        }
      }

      return blockList;
    });

  if (Constants.debugLog) {
    console.log("executable block list: ", executableBlockList);
  }

  return executableBlockList;
};

export const convertLabelToExecutableBlockList = (labels: Label[]) => {
  const contactedBlocks = (labels.map((label) =>
    convertObjectToBlockByLabel(label, labels)
  ) as Block[])
    .filter((block, _, blocks) => {
      if (block.type === BlockType.UNKNOWN) {
        return true;
      } else if (
        block.type === BlockType.EVENT_START ||
        block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
      ) {
        return true;
      } else {
        return (
          blocks.filter(
            (b) =>
              block.id !== b.id &&
              Math.abs(block.angle - b.angle) < 20 &&
              calculateDistance(
                {
                  x: block.label.boundingPolygon[0].x,
                  y: block.label.boundingPolygon[0].y,
                },
                {
                  x: b.label.boundingPolygon[0].x,
                  y: b.label.boundingPolygon[0].y,
                }
              ) < 200
          ).length > 0
        );
      }
    })
    .sort((a, b) =>
      a.label.boundingPolygon[0].y < b.label.boundingPolygon[0].y ? -1 : 1
    );

  if (Constants.debugLog) {
    console.log("Contacted blocks: ", contactedBlocks);
  }

  const eventBlocks = contactedBlocks
    .filter(
      (block) =>
        block.type === BlockType.EVENT_START ||
        block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
    )
    .sort((a, b) =>
      a.label.boundingPolygon[0].x < b.label.boundingPolygon[0].x ? -1 : 1
    )
    .map((eventStartBlock) => {
      const blockList = [];
      eventStartBlock.rootId = eventStartBlock.id;
      blockList.push(eventStartBlock);

      const candidates = contactedBlocks
        .filter((block) =>
          block.id === eventStartBlock.id
            ? false
            : Math.abs(eventStartBlock.angle - block.angle) < 20 &&
              Math.abs(
                calculateAngle(
                  {
                    x: eventStartBlock.label.boundingPolygon[0].x,
                    y: eventStartBlock.label.boundingPolygon[0].y,
                  },
                  {
                    x: block.label.boundingPolygon[0].x,
                    y: block.label.boundingPolygon[0].y,
                  }
                ) - eventStartBlock.angle
              ) < 90
        )
        .sort((a, b) =>
          calculateDistance(
            {
              x: eventStartBlock.label.boundingPolygon[0].x,
              y: eventStartBlock.label.boundingPolygon[0].y,
            },
            {
              x: a.label.boundingPolygon[0].x,
              y: a.label.boundingPolygon[0].y,
            }
          ) <
          calculateDistance(
            {
              x: eventStartBlock.label.boundingPolygon[0].x,
              y: eventStartBlock.label.boundingPolygon[0].y,
            },
            {
              x: b.label.boundingPolygon[0].x,
              y: b.label.boundingPolygon[0].y,
            }
          )
            ? -1
            : 1
        );

      console.log({ eventStartBlock, candidates });

      for (let i = 0; i < candidates.length; i++) {
        const block = candidates[i];
        if (
          calculateDistance(
            {
              x: eventStartBlock.label.boundingPolygon[0].x,
              y: eventStartBlock.label.boundingPolygon[0].y,
            },
            {
              x: block.label.boundingPolygon[0].x,
              y: block.label.boundingPolygon[0].y,
            }
          ) < 200
        ) {
          if (
            block.type === BlockType.EVENT_START ||
            block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
          ) {
            break;
          } else {
            block.rootId = eventStartBlock.id;
            blockList.push(block);
            if (block.type === BlockType.EVENT_END) break;
          }
        } else if (
          blockList.filter(
            (b) =>
              calculateDistance(
                {
                  x: b.label.boundingPolygon[0].x,
                  y: b.label.boundingPolygon[0].y,
                },
                {
                  x: block.label.boundingPolygon[0].x,
                  y: block.label.boundingPolygon[0].y,
                }
              ) < 200
          ).length > 0
        ) {
          if (
            block.type === BlockType.EVENT_START ||
            block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
          ) {
            break;
          } else {
            block.rootId = eventStartBlock.id;
            blockList.push(block);
            if (block.type === BlockType.EVENT_END) break;
          }
        }
      }

      return blockList.filter((b) => b.type !== BlockType.UNKNOWN);
    });

  const executableBlockList: ExecutableBlock[][] = eventBlocks.map(
    (eventBlockList) => {
      const blockList: ExecutableBlock[] = [];
      var controlBlockStack = eventBlockList
        .filter((block) => block.type.includes("start"))
        .reverse();

      for (let i = 0; i < eventBlockList.length; i++) {
        let block = eventBlockList[i];
        if (block.type.includes("end")) {
          let parentBlock: Block;
          switch (block.type) {
            case BlockType.FUNCTION_END:
              parentBlock = controlBlockStack
                .filter(
                  (controlBlock) => controlBlock.type === eventBlockList[0].type
                )
                .shift();
              break;
            case BlockType.CONTROLS_END:
              parentBlock = controlBlockStack
                .filter((controlBlock) =>
                  controlBlock.type.includes("controls")
                )
                .shift();
              break;
            default:
              break;
          }

          if (parentBlock) {
            const parentBlockIndex = eventBlockList.findIndex(
              (block) => block.id === parentBlock.id
            );
            if (parentBlockIndex < i) {
              blockList.push({
                id: block.id,
                parentId: parentBlock.id,
                type: block.type,
                args: block.args,
              });
              controlBlockStack = controlBlockStack.filter(
                (block) => block.id !== parentBlock.id
              );
            }
          }

          if (block.type === BlockType.FUNCTION_END) break;
        } else {
          blockList.push({
            id: block.id,
            type: block.type,
            args: block.args,
          });
        }
      }

      return blockList;
    }
  );

  if (Constants.debugLog) {
    console.log("Events block list: ", eventBlocks);
    console.log("Executable block list: ", executableBlockList);
  }

  return executableBlockList;
};
