import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { RootState } from "app/store";
import { isDebugMode, usePlaySound } from "common/utils";
import { courses, CourseTab, PAGE_LIMIT } from "./constant";

export const Pagination = ({
  tab,
  page,
  setPage,
}: {
  tab: CourseTab;
  page: number;
  setPage: (page: number) => void;
}) => {
  const play = usePlaySound();
  const [animation, setAnimation] = useState(false);
  const config = useSelector((state: RootState) => state.config.appConfig);
  const pages = useMemo(
    () =>
      Math.round(
        courses(isDebugMode, config?.enable_lab ?? false)[tab].filter(
          (course) => course.show
        ).length / PAGE_LIMIT
      ) - 1,
    [isDebugMode, config?.enable_lab]
  );

  const handleMove = (direction: number) => () => {
    let newPage = page + direction;
    if (newPage < 0 || newPage > pages || animation) return;
    play();
    setAnimation(true);
    if (direction > 0) {
      anime({
        targets: "#course-card-page-right",
        opacity: [0, 1, 1, 1, 1],
        rotateY: [0, "-180deg"],
        translateX: 25,
        duration: 1500,
        autoplay: true,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        anime({
          targets: "#course-card-page-right",
          opacity: [1, 0],
          duration: 100,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        setPage(page + direction);
        setAnimation(false);
      });
    } else {
      setTimeout(() => setPage(page + direction), 700);
      anime({
        targets: "#course-card-page-left",
        opacity: [0, 1, 1, 1, 1],
        rotateY: [0, "180deg"],
        translateX: -25,
        duration: 1500,
        autoplay: true,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        anime({
          targets: "#course-card-page-left",
          opacity: [1, 0],
          duration: 100,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        setAnimation(false);
      });
    }
  };

  return (
    <div className="flex-col-el self-end mb-20 -ml-6 z-50">
      <button
        disabled={page === 0}
        onClick={handleMove(-1)}
        className={`flex-col-el flex-center border-none w-[56px] h-[84px] rounded-r-[24px] gap-2 cursor-pointer active:opacity-70 disabled:opacity-100 ${
          page === 0 ? "bg-gray2/60" : "bg-green/100"
        }`}
      >
        <span className="material-symbols-outlined text-[24px] text-white ml-2">
          arrow_back_ios
        </span>
        <p className="text-body text-white text-font-size-caption1-narrow">
          {I18n.t("MSG_COURSE_PAGINATION_PREV")}
        </p>
      </button>
      <button
        disabled={page === pages}
        onClick={handleMove(1)}
        className={`flex-col-el flex-center border-none w-[56px] h-[84px] rounded-r-[24px] gap-2 cursor-pointer active:opacity-70 disabled:opacity-100 ${
          page === pages ? "bg-gray2/60" : "bg-green/100"
        }`}
      >
        <span className="material-symbols-outlined text-[24px] text-white">
          arrow_forward_ios
        </span>
        <p className="text-body text-white text-font-size-caption1-narrow">
          {I18n.t("MSG_COURSE_PAGINATION_NEXT")}
        </p>
      </button>
    </div>
  );
};
