/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import { useScale } from "common/utils";
import { InitMsg, BlockType } from "common/blockly";
import {
  InitColourBlock,
  InitControlsBlock,
  InitVariableBlock,
  useInitCharacterBlock,
  InitProceduresCallBlock,
} from "./blocks";
import { updateFlyout, toolboxDefinition, createBlocklyOptions } from "./utils";
import { TOP_OFFSET } from "../constant";

export const useInitWorkspace = (
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void
) => {
  const { scale } = useScale();
  const InitCharacterBlock = useInitCharacterBlock();
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    InitMsg(config.locale);
  }, [config.locale]);

  useEffect(() => {
    InitCharacterBlock();
    InitControlsBlock();
    InitColourBlock();
    InitVariableBlock();
    InitProceduresCallBlock();

    let workspace: Blockly.WorkspaceSvg;
    if (config.course_model === CourseModel.COMMAND) {
      // @ts-ignore
      Blockly.FieldTextInput.prototype.showEditor_ = function () {
        // @ts-ignore
        Blockly.dialog.customPrompt(
          this,
          // @ts-ignore
          Blockly.Msg["CHANGE_VALUE_TITLE"],
          this.getText(),
          function (text) {
            if (text !== null) {
              this.setValue(this.getValueFromEditorText_(text));
            }
          }.bind(this)
        );
      };

      // @ts-ignore
      Blockly.FieldTextInput.prototype.showPromptEditor_ = function () {
        // @ts-ignore
        Blockly.dialog.customPrompt(
          this,
          // @ts-ignore
          Blockly.Msg["CHANGE_VALUE_TITLE"],
          this.getText(),
          function (text) {
            if (text !== null) {
              this.setValue(this.getValueFromEditorText_(text));
            }
          }.bind(this)
        );
      };

      // @ts-ignore
      Blockly.FieldNumber.prototype.showEditor_ = function () {
        // @ts-ignore
        Blockly.dialog.customPrompt(
          this,
          // @ts-ignore
          Blockly.Msg["CHANGE_VALUE_TITLE"],
          this.getText(),
          function (text) {
            if (text !== null) {
              this.setValue(this.getValueFromEditorText_(text));
            }
          }.bind(this)
        );
      };

      Blockly.WorkspaceSvg.prototype.refreshToolboxSelection = function () {
        // disable refresh toolbox
      };

      workspace = Blockly.inject(
        "blocklyDiv",
        createBlocklyOptions(scale, config.course_model, toolboxDefinition)
      );

      BlocklyJs.init(workspace);

      const toolbox = workspace.getToolbox() as Blockly.Toolbox;
      const div = toolbox.HtmlDiv as HTMLDivElement;
      div.style.width = "0px";
      div.style.top = `${TOP_OFFSET * scale}px`;
      div.style.display = "none";

      workspace.updateToolbox(toolboxDefinition);
      updateFlyout(workspace, TOP_OFFSET * scale);

      const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
      blocklyFlyout.autoClose = false;

      const blocklyFlyouts = document.getElementsByClassName("blocklyFlyout");
      const blocklyFlyoutEle = blocklyFlyouts[1];
      blocklyFlyoutEle.setAttribute(
        "style",
        "backdrop-filter: blur(10px);border-radius: 16px;z-index: 100;"
      );

      const blocklyFlyoutBackground = document.getElementsByClassName(
        "blocklyFlyoutBackground"
      );
      for (let i: number = 0; i < blocklyFlyouts.length; i++) {
        let e = blocklyFlyoutBackground[i];
        e.setAttribute("style", "fill: rgba(242, 239, 241, 0.5) !important");
      }

      const blocklyScrollbarVerticals = document.getElementsByClassName(
        "blocklyScrollbarVertical"
      );
      for (let i: number = 0; i < blocklyScrollbarVerticals.length; i++) {
        let e = blocklyScrollbarVerticals[i] as SVGElement;
        const display = e.getAttribute("display");
        if (display !== "block") {
          e.setAttribute("style", "pointer-events: none;z-index: 101;");
        }
      }

      const variable1 = workspace.createVariable(
        `${I18n.t("MSG_BLOCKLY_VARIABLES")}1`
      );
      const variable2 = workspace.createVariable(
        `${I18n.t("MSG_BLOCKLY_VARIABLES")}2`
      );

      const variableFlyoutCallback = function (
        workspace: Blockly.WorkspaceSvg
      ) {
        const blockList = [
          {
            kind: "label",
            text: "Placeholder",
            "web-class": "course-toolbox-label",
          },
          {
            kind: "block",
            type: BlockType.VARIABLES_SET,
            fields: {
              VAR: variable1,
            },
            inputs: {
              VALUE: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 0,
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: BlockType.VARIABLES_SET,
            fields: {
              VAR: variable2,
            },
            inputs: {
              VALUE: {
                shadow: {
                  type: "colour_picker_internal",
                  fields: {
                    COLOUR: "#71cdfc",
                  },
                },
              },
            },
          },
        ];

        const varBlocks = workspace.getBlocksByType(
          BlockType.VARIABLES_SET,
          false
        );
        if (varBlocks.length > 0) {
          const variable_id = varBlocks[varBlocks.length - 1].getFieldValue(
            "VAR"
          );
          const variable = workspace.getVariableById(variable_id);
          // @ts-ignore
          blockList.push({
            kind: "block",
            type: BlockType.VARIABLES_GET,
            fields: {
              VAR: variable,
            },
          });
        }

        return blockList;
      };
      workspace.registerToolboxCategoryCallback(
        "CUSTOM_VARIABLE_FLYOUT",
        variableFlyoutCallback
      );

      const proceduresFlyoutCallback = function (
        workspace: Blockly.WorkspaceSvg
      ) {
        const blockList = [
          {
            kind: "label",
            text: "Placeholder",
            "web-class": "course-toolbox-label",
          },
          {
            kind: "block",
            type: BlockType.PROCEDURES_DEFNORETURN,
            fields: {
              NAME: "なにかする",
            },
          },
        ];

        for (const block of workspace.getBlocksByType(
          BlockType.PROCEDURES_DEFNORETURN,
          false
        )) {
          const funcName = block.getFieldValue("NAME");
          block.setMutator(null);
          blockList.push({
            kind: "block",
            type: BlockType.PROCEDURES_CALLNORETURN,
            fields: {
              NAME: funcName,
            },
          });
        }

        return blockList;
      };
      workspace.registerToolboxCategoryCallback(
        "CUSTOM_PROCEDURE_FLYOUT",
        proceduresFlyoutCallback
      );

      const eventBlock = new Blockly.BlockSvg(
        workspace,
        "character_event_duck"
      );
      eventBlock.initSvg();
      eventBlock.render();
      eventBlock.moveBy(
        blocklyFlyout.getWidth() / scale + 20 * scale,
        TOP_OFFSET + 20 * scale
      );
    } else {
      workspace = Blockly.inject(
        "blocklyDiv",
        createBlocklyOptions(scale, config.course_model)
      );
      BlocklyJs.init(workspace);
    }

    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};
