import { useEffect, PointerEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import { IconBack } from "features/user/assets";
import { getUserStatus } from "features/user/api";
import { actions as creatorActions } from "features/creator/slice";
import { selectAllChatRoomMessageSync } from "features/user/slice";

export const User = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.appUser);
  const chatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);
  const hasUnreadMessage =
    chatRoomMessageSync.filter(
      (sync) =>
        dayjs(sync.latestMessageCreatedAt, "YYYY-MM-DDTHH:mm:ssZ").diff(
          dayjs(sync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")
        ) > 0 ||
        (sync.latestReadAt === null && sync.latestMessageCreatedAt !== null)
    ).length > 0;

  useEffect(() => {
    dispatch(getUserStatus({ uid: user.active.uid }));
  }, [user]);

  const handleClick = (e: PointerEvent) => {
    e.stopPropagation();
    play();
    btnDelay(() => {
      dispatch(
        actions.updateDialog({
          type: DialogType.USER_PROFILE,
          value: true,
        })
      );
    });
  };

  const handleBack = () => {
    play();
    if (location.pathname !== "/") {
      const pathArray = location.pathname.split("/");
      switch (true) {
        case /\/creator$/.test(location.pathname):
          navigate("/");
          break;
        case /\/creator\//.test(location.pathname):
          btnDelay(() => dispatch(creatorActions.updateCapture(true)));
          break;
        case /\/courses$/.test(location.pathname):
          navigate("/");
          break;
        case /\/courses\/[a-zA-Z0-9]+\/stage\/\d$/.test(location.pathname):
          navigate("/courses");
          break;
        case /\/courses\/[a-zA-Z0-9_]+\/stage\/\d$/.test(location.pathname):
          navigate(`/courses`);
          break;
        case /\/courses\/[a-zA-Z0-9_]+\/stage\/\d\/step\/\d$/.test(
          location.pathname
        ):
          navigate(`/courses/${pathArray[2]}/stage/${pathArray[4]}`);
          break;
        case /\/courses\/[a-zA-Z0-9_]+/.test(location.pathname):
          navigate(`/courses`);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div
      className="flex-row-view !absolute left-0 z-[9998]"
      style={{ top: 4 * scale }}
    >
      <div
        className="flex-row-view items-center justify-start"
        style={{ width: 170 * scale, height: 102 * scale }}
      >
        <div
          className="flex-row-view items-center justify-start w-[170px] h-[102px] origin-left"
          style={{ transform: `scale(${scale})` }}
        >
          <div
            id="app-back-btn"
            className={`flex-col-view items-start justify-center w-[96px] h-[72px] pl-[8px] cursor-pointer active:opacity-70 ${
              location.pathname === "/" ? "bg-gray2/60" : "bg-brown/100"
            }`}
            onClick={handleBack}
          >
            <div className="flex-col-center">
              <div className="flex-col-center w-[32px] h-[32px] mt-1 mb-1">
                <IconBack />
              </div>

              <p className="text text-white !text-[16px]">
                {I18n.t("MSG_BACK_BTN")}
              </p>
            </div>
          </div>

          <div
            className="flex-col-el flex-center absolute left-[64px] cursor-pointer bg-white rounded-full z-10 active:shadow-active_show"
            style={{
              width: 104,
              height: 104,
              filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
            }}
          >
            {hasUnreadMessage && (
              <div className="flex-col-el flex-center absolute right-0 top-0 bg-danger rounded-full w-[30px] h-[30px] z-50" />
            )}

            <div
              className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden"
              onPointerDown={handleClick}
            >
              <img
                key={user?.active.icon}
                alt="ユーザプロフィール画像"
                src={user?.active.icon}
                className="w-full h-full object-contain"
              />
            </div>

            <div
              className="flex-col-el flex-center absolute -bottom-3 w-[90px] h-[30px] px-2 bg-beige/10 rounded-[32px] text-textcolor/black"
              style={{
                boxShadow:
                  "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              <p className="text-body text-[16px] text-body-ellipsis w-full">
                {user?.active.display_name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
