import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitProceduresCallBlock = () => {
  Blockly.Blocks["procedures_callnoreturn"] = {
    _procedure_id: null,
    init: function () {
      this.appendDummyInput().appendField("", "NAME");
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setStyle("procedure_blocks");
      this.contextMenu = false;
    },
    mutationToDom: function () {
      const container = Blockly.utils.xml.createElement("mutation");
      container.setAttribute("name", this.getFieldValue("NAME"));
      return container;
    },
    domToMutation: function (xmlElement) {
      const name = xmlElement.getAttribute("name");
      this.setFieldValue(name, "NAME");
    },
  };
  BlocklyJs["procedures_callnoreturn"] = function (block: Blockly.Block) {
    const funcName = BlocklyJs.nameDB_.getName(
      block.getFieldValue("NAME"),
      Blockly.PROCEDURE_CATEGORY_NAME
    );
    return `
    util.highlightBlock("${block.id}", true);
    if(--window.LoopTrap < 1) {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      util.setGameOver(true);
      return;
    };
    await ${funcName}();
    util.highlightBlock("${block.id}", false);
    \n`;
  };

  BlocklyJs["procedures_callreturn"] = function (block: Blockly.Block) {
    const funcName = BlocklyJs.nameDB_.getName(
      block.getFieldValue("NAME"),
      Blockly.PROCEDURE_CATEGORY_NAME
    );
    const args = [];
    const variables = block.getVars();
    for (var i = 0; i < variables.length; i++) {
      args[i] =
        BlocklyJs.valueToCode(
          block,
          "ARG" + i,
          (Blockly as any).JavaScript.ORDER_NONE
        ) || "null";
    }
    const code = `util.highlightBlock("${
      block.id
    }", undefined);unhighlight = true;
    if(--window.LoopTrap < 1) {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      util.setGameOver(true);
      return;
    };
    await ${funcName}(${args.join(", ")}) 
    unhighlight = undefined;`;
    return [code, (Blockly as any).JavaScript.ORDER_FUNCTION_CALL];
  };
};
