export enum AppPlatform {
  iOS = "ios",
  Android = "android",
  Amazon = "amazon",
  Web = "web",
}

export enum AnalyticsEventType {
  ACTIVE = "ACTIVE",
  BACKGROUND = "BACKGROUND",
  PAGE_VIEW = "PAGE_VIEW",
  PARENT_GATE_BTN_CLICK = "PARENT_GATE_CLICK",
  PARENT_GATE_SUCCESS_BTN_CLICK = "PARENT_GATE_SUCCESS_BTN_CLICK",
  PARENT_GATE_MENU_NOTIFICATION_CLICK = "PARENT_GATE_MENU_NOTIFICATION_CLICK",
  PARENT_GATE_MENU_PARENT_USER_NOT_SIGN = "PARENT_GATE_MENU_PARENT_USER_NOT_SIGN",
  PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_CLICK = "PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_CLICK",
  PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_LINKED_ACCOUNT_CLICK = "PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_LINKED_ACCOUNT_CLICK",
  PARENT_GATE_MENU_PARENT_USER_ACCOUNT_CLICK = "PARENT_GATE_MENU_PARENT_USER_ACCOUNT_CLICK",
  PARENT_GATE_MENU_PARENT_USER_CLICK = "PARENT_GATE_MENU_PARENT_USER_CLICK",
  PARENT_GATE_MENU_PARENT_APP_CLICK = "PARENT_GATE_MENU_PARENT_APP_CLICK",
  PARENT_GATE_MENU_ENABLE_LAB_CLICK = "PARENT_GATE_MENU_ENABLE_LAB_CLICK",
  UNIVERSAL_BTN_CLICK = "UNIVERSAL_BTN_CLICK",
}

export enum GameCase {
  GRAY = "gray",
  BLUE = "blue",
  CYAN = "cyan",
  MILK = "milk",
  PINK = "pink",
  GREEN = "green",
  ORANGE = "orange",
  SOFT_PINK = "soft_pink",
  SOFT_YELLOW = "soft_yellow",
}

export enum Character {
  DUCK = "duck",
  BEAR = "bear",
  DOG = "dog",
}

export enum CourseModel {
  COMMAND = "command",
  TANGIBLE = "tangible",
}

export enum DeviceNotification {
  NOT_DETERMINED = "NOT_DETERMINED",
  AUTHORIZED = "AUTHORIZED",
  DENIED = "DENIED",
}

export enum LocalStorageKey {
  LAST_SIGN_AT = "last_sign_at",
  DISMISS_BANNER = "dismiss_banner",
  KEYBOARD_HEIGHT = "keyboard_height",
  DEVICE_KIDS_MAIN_USER = "device_kids_main_user",
}

export type AppConfig = {
  id: number;
  main_uid: string;
  notification: boolean;
  total_login_count: number;
  last_store_review_time?: string;
  enable_lab: boolean;
};

export interface UserConfig {
  is_first: boolean;
  locale: string | null;
  sound: { enable: boolean; volume: number };
  bgm: { enable: boolean; volume: number };
  tutorial: { top: boolean; creator_top: boolean; creator_panel: boolean };
  game_case: GameCase;
  character: Character;
  project_publish: boolean;
  project_copied: boolean;
  course_model: CourseModel;
}

export enum EventType {
  BEFORE_INSTALL_PROMPT = "before_install_prompt",
}

export type Asset = {
  id: number;
  uid: string;
  type: string;
  name: string;
  tags: string;
  content_type: string;
  file_size: number;
  metadata: { width: string; height: string; [key: string]: string };
  url: string;
  created_at: string;
};

export enum DialogType {
  RATING = "rating",
  CONTACT = "contact",
  LOADING = "loading",
  FEEDBACK = "feedback",
  UNIVERSAL = "universal",
  ASSISTANT = "assistant",
  PARENT_GATE = "parent_gate",
  SUBSCRIPTION = "subscription",
  USER_PROFILE = "user_profile",
  NOTIFICATION = "notification",
  ERROR_NETWORK = "error_network",
  PROJECT_PREVIEW = "project_preview",
  PWA_INSTALL_PROMPT = "pwa_install_prompt",
}

export interface ConfigState {
  resign: boolean;
  supported: boolean;
  appConfig?: AppConfig;
  userConfig: UserConfig;
  tutorial: { top: boolean }; // チュートリアルが閉じているかどうか状態を保存する。
  device: {
    version: {
      hostAppVersion: string;
      appVersion: string;
    };
    os: string;
    notification: DeviceNotification;
  };
  notification: {
    kids: {
      id: number;
      ja: { date: string; title: string; content: string };
      ja_kanji: { date: string; title: string; content: string };
      published_at: string;
    }[];
    parents: {
      id: number;
      ja_kanji: { date: string; title: string; content: string };
    }[];
    maintenances: {
      id: number;
      ja: { date: string; title: string; content: string };
      created_at: string;
      published_at: string;
    }[];
  };
  banned: { words: { id: number; word: string }[] };
  assets: {
    [prefix: string]: {
      fileKey: string;
      fileSize: number;
      category?: string;
    }[];
  };
  event: {
    before_install_prompt: { value: boolean; args?: { [key: string]: string } };
  };
  dialog: {
    rating: { value: boolean; args?: { [key: string]: string } };
    contact: { value: boolean; args?: { [key: string]: string } };
    loading: { value: boolean; args?: { [key: string]: string } };
    feedback: { value: boolean; args?: { [key: string]: string } };
    assistant: { value: boolean; args?: { [key: string]: string } };
    universal: { value: boolean; args?: { [key: string]: string } };
    parent_gate: { value: boolean; args?: { [key: string]: string } };
    subscription: { value: boolean; args?: { [key: string]: string } };
    user_profile: { value: boolean; args?: { [key: string]: string } };
    notification: { value: boolean; args?: { [key: string]: string } };
    error_network: { value: boolean; args?: { [key: string]: string } };
    project_preview: { value: boolean; args?: { [key: string]: string } };
    pwa_install_prompt: { value: boolean; args?: { [key: string]: string } };
  };
}
