import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import produce from "immer";
import { RootState } from "app/store";
import {
  getUsers,
  deleteUser,
  getUserStatus,
  getGroupUsers,
  getUserHistory,
  getUserChatRooms,
  getChatRoomMessages,
  markChatRoomMessage,
} from "features/user/api";
import {
  AppUser,
  ChatRoom,
  UserState,
  ChatRoomMessage,
  ChatRoomMessageSync,
} from "features/user/types";

export const groupUserAdapter = createEntityAdapter<AppUser>({
  selectId: (user) => user.uid,
  sortComparer: (a, b) => (dayjs(a.created_at) < dayjs(b.created_at) ? -1 : 1),
});
export const {
  selectAll: selectAllGroupUsers,
  selectById: selectGroupUserById,
} = groupUserAdapter.getSelectors((state: RootState) => state.user.groupUsers);

export const chatRoomAdapter = createEntityAdapter<ChatRoom>({
  sortComparer: (a, b) => (dayjs(a.created_at) < dayjs(b.created_at) ? -1 : 1),
});
export const { selectAll: selectAllChatRooms } = chatRoomAdapter.getSelectors(
  (state: RootState) => state.user.chatRooms
);

export const chatRoomMessageAdapter = createEntityAdapter<ChatRoomMessage>({
  sortComparer: (a, b) => (dayjs(a.created_at) < dayjs(b.created_at) ? -1 : 1),
});
export const {
  selectAll: selectAllChatRoomMessages,
} = chatRoomMessageAdapter.getSelectors(
  (state: RootState) => state.user.chatRoomMessages
);

export const chatRoomMessageSyncAdapter = createEntityAdapter<ChatRoomMessageSync>();
export const {
  selectAll: selectAllChatRoomMessageSync,
  selectById: selectChatRoomMessageSyncById,
} = chatRoomMessageSyncAdapter.getSelectors(
  (state: RootState) => state.user.chatRoomMessageSync
);

const initialState: UserState = {
  appUser: null,
  groupUsers: groupUserAdapter.getInitialState(),
  isRefreshed: false,
  status: null,
  history: null,
  selectedChatRoomId: null,
  chatRooms: chatRoomAdapter.getInitialState(),
  chatRoomMessages: chatRoomMessageAdapter.getInitialState(),
  chatRoomMessageSync: chatRoomMessageSyncAdapter.getInitialState(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateActiveUser(state: UserState, action: PayloadAction<AppUser>) {
      state.appUser.active = action.payload;
    },
    updateUserProfile(state: UserState, action: PayloadAction<AppUser>) {
      state.appUser = produce(state.appUser, (draft) => {
        draft.active = action.payload;
        const index = draft.users.findIndex(
          (v) => v.uid === action.payload.uid
        );
        if (index != -1) {
          draft.users[index] = action.payload;
        }
      });
    },
    updateParentsUserProfile(state: UserState, action: PayloadAction<AppUser>) {
      state.appUser = produce(state.appUser, (draft) => {
        draft.main = action.payload;
        const index = draft.users.findIndex(
          (v) => v.uid === action.payload.uid
        );
        if (index != -1) {
          draft.users[index] = action.payload;
        }
      });
    },
    updateIsRefreshed(
      state: UserState,
      action: PayloadAction<{ refreshed: boolean }>
    ) {
      state.isRefreshed = action.payload.refreshed;
    },
    updateSelectedChatRoomId(
      state: UserState,
      action: PayloadAction<{ id: string }>
    ) {
      state.selectedChatRoomId = action.payload.id;
      if (action.payload.id) {
        const sync = chatRoomMessageSyncAdapter
          .getSelectors()
          .selectById(state.chatRoomMessageSync, action.payload.id);
        if (sync.latestMessageCreatedAt) {
          chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
            id: action.payload.id,
            changes: { latestReadAt: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") },
          });
        }
      }
    },
    updateChatRoomMessageLastSyncAt(
      state: UserState,
      action: PayloadAction<{
        chatRoomId: string;
        uid: string;
        lastMessageSyncAt: string;
      }>
    ) {
      chatRoomMessageSyncAdapter.upsertOne(state.chatRoomMessageSync, {
        id: action.payload.chatRoomId,
        uid: action.payload.uid,
        lastReadAt: null,
        lastSyncAt: action.payload.lastMessageSyncAt,
        latestReadAt: null,
        latestMessage: null,
        latestMessageCreatedAt: null,
      });
    },
    insertChatRoomMessage(
      state: UserState,
      action: PayloadAction<{ uid: string; message: ChatRoomMessage }>
    ) {
      const { uid, message } = action.payload;
      chatRoomMessageAdapter.addOne(state.chatRoomMessages, message);
      const latestMessageCreatedAt =
        message.user_uid === uid ? null : message.created_at;

      if (state.selectedChatRoomId === message.chat_room_id) {
        chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
          id: message.chat_room_id,
          changes: {
            latestReadAt: message.created_at,
            latestMessage: message,
            latestMessageCreatedAt: latestMessageCreatedAt,
          },
        });
      } else {
        if (latestMessageCreatedAt === null) {
          chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
            id: message.chat_room_id,
            changes: {
              latestMessage: message,
            },
          });
        } else {
          chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
            id: message.chat_room_id,
            changes: {
              latestMessage: message,
              latestMessageCreatedAt: latestMessageCreatedAt,
            },
          });
        }
      }
    },
    bulkInsertChatRoomMessage(
      state: UserState,
      action: PayloadAction<{ uid: string; messages: ChatRoomMessage[] }>
    ) {
      const { uid, messages } = action.payload;

      if (messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        const chatRoom = chatRoomAdapter
          .getSelectors()
          .selectById(state.chatRooms, lastMessage.chat_room_id);
        chatRoomMessageSyncAdapter.upsertOne(state.chatRoomMessageSync, {
          id: lastMessage.chat_room_id,
          uid,
          lastSyncAt: lastMessage.created_at,
          latestReadAt: chatRoom.last_read_at,
          lastReadAt: chatRoom.last_read_at,
          latestMessage: messages[0],
          latestMessageCreatedAt:
            messages[0].user_uid === uid ? null : messages[0].created_at,
        });
      }

      chatRoomMessageAdapter.upsertMany(state.chatRoomMessages, messages);
    },
    removeUserChatRoomMessageLastSyncAt(
      state: UserState,
      action: PayloadAction<{ uid: string }>
    ) {
      const allChatRoomMessageSync = chatRoomMessageSyncAdapter
        .getSelectors()
        .selectAll(state.chatRoomMessageSync);
      const ids = allChatRoomMessageSync
        .filter((sync) => sync.uid === action.payload.uid)
        .map((sync) => sync.id);
      chatRoomMessageSyncAdapter.removeMany(state.chatRoomMessageSync, ids);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUsers.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          state.appUser = action.payload;
        }
      )
      .addCase(
        getGroupUsers.fulfilled,
        (state: UserState, action: PayloadAction<{ users: AppUser[] }>) => {
          const { users } = action.payload;
          groupUserAdapter.removeAll(state.groupUsers);
          if (users.length > 0) {
            groupUserAdapter.addMany(state.groupUsers, users);
          }
        }
      )
      .addCase(
        deleteUser.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          state.appUser = action.payload;
        }
      )
      .addCase(
        getUserChatRooms.fulfilled,
        (
          state: UserState,
          action: PayloadAction<{ chat_rooms: ChatRoom[] }>
        ) => {
          const { chat_rooms } = action.payload;
          chatRoomAdapter.removeAll(state.chatRooms);
          chatRoomMessageAdapter.removeAll(state.chatRoomMessages);
          chatRoomMessageSyncAdapter.removeAll(state.chatRoomMessageSync);
          if (chat_rooms.length > 0) {
            chatRoomAdapter.addMany(state.chatRooms, chat_rooms);
          }
        }
      )
      .addCase(
        getChatRoomMessages.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          const { messages } = action.payload;
          if (messages.length > 0) {
            chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
              id: state.selectedChatRoomId,
              changes: {
                lastSyncAt: messages[messages.length - 1].created_at,
              },
            });

            chatRoomMessageAdapter.addMany(state.chatRoomMessages, messages);
          }
        }
      )
      .addCase(
        markChatRoomMessage.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          const { chat_rooms_read_at } = action.payload;
          (chat_rooms_read_at as {
            chat_room_id: string;
            uid: string;
            last_read_at: string;
          }[]).forEach((read_at) => {
            chatRoomMessageSyncAdapter.updateOne(state.chatRoomMessageSync, {
              id: read_at.chat_room_id,
              changes: {
                lastReadAt: read_at.last_read_at,
                latestReadAt: read_at.last_read_at, // sync time with server
              },
            });
          });
        }
      )
      .addCase(
        getUserStatus.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          state.status = action.payload;
        }
      )
      .addCase(
        getUserHistory.fulfilled,
        (state: UserState, action: PayloadAction<any>) => {
          state.history = action.payload;
        }
      );
  },
});

export const actions = { ...userSlice.actions };

export default userSlice.reducer;
