import { useState } from "react";
import { useScale } from "common/utils";

export const LevelClearHistory = () => {
  const { scale } = useScale();
  const [collapse, setCollapse] = useState(true);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <div
      style={{ transform: `translateY(${collapse ? 640 : 0}px)` }}
      className="flex-col-el items-start justify-center fixed right-0 bottom-0 z-[9998] transition-transform duration-700 will-change-transform"
    >
      <div
        onClick={handleCollapse}
        className="flex-row-el items-center w-[300px] h-[50px] px-2 gap-2 bg-gray/30 rounded-t-xl cursor-pointer pointer-events-auto"
      >
        <p className="text-body text-font-caption2-narrow text-textcolor/black">
          履歴
        </p>
      </div>

      <div className="flex-col-el flex-center relative w-[400px] h-[640px] bg-gray2/10"></div>
    </div>
  );
};
