import { TileSet, AssetModelType } from "./types";

export const tiles: TileSet[] = [
  {
    id: 0,
    type: AssetModelType.NONE,
    name: "",
    filename: "",
  },
  {
    id: 1,
    type: AssetModelType.CHARACTER,
    name: "character_duck",
    filename: "character_duck.glb",
    offset: { x: 0, y: -0.4, z: 0 },
    scaling: { x: 1.2, y: 1.2, z: 1.2 },
  },
  {
    id: 2,
    type: AssetModelType.CHARACTER,
    name: "character_dog",
    filename: "character_dog.gltf",
    offset: { x: 0, y: 0, z: 0 },
    scaling: { x: 1.3, y: 1.3, z: 1.3 },
  },
  {
    id: 3,
    type: AssetModelType.CHARACTER,
    name: "character_bear",
    filename: "character_bear.gltf",
    offset: { x: 0, y: 0, z: 0 },
    scaling: { x: 1.3, y: 1.3, z: 1.3 },
  },
  {
    id: 4,
    type: AssetModelType.GOAL,
    name: "flag_teamBlue",
    filename: "flag_teamBlue.gltf.glb",
  },
  {
    id: 5,
    type: AssetModelType.GOAL,
    name: "flag_teamRed",
    filename: "flag_teamRed.gltf.glb",
  },
  {
    id: 6,
    type: AssetModelType.GOAL,
    name: "flag_teamYellow",
    filename: "flag_teamYellow.gltf.glb",
  },
  {
    id: 7,
    type: AssetModelType.GEM,
    name: "diamond_teamBlue",
    filename: "diamond_teamBlue.gltf.glb",
  },
  {
    id: 8,
    type: AssetModelType.GEM,
    name: "diamond_teamRed",
    filename: "diamond_teamRed.gltf.glb",
    size: { x: 1, y: 1, z: 1 },
  },
  {
    id: 9,
    type: AssetModelType.GEM,
    name: "diamond_teamYellow",
    filename: "diamond_teamYellow.gltf.glb",
  },
  {
    id: 10,
    type: AssetModelType.CONDITION,
    name: "button_teamBlue",
    filename: "button_teamBlue.gltf.glb",
    size: { x: 0, y: 0.5, z: 0 },
    metadata: {
      color: "#71cdfc",
    },
  },
  {
    id: 11,
    type: AssetModelType.CONDITION,
    name: "button_teamRed",
    filename: "button_teamRed.gltf.glb",
    size: { x: 0, y: 0.5, z: 0 },
    metadata: {
      color: "#fb3a69",
    },
  },
  {
    id: 12,
    type: AssetModelType.CONDITION,
    name: "button_teamYellow",
    filename: "button_teamYellow.gltf.glb",
    size: { x: 0, y: 0.5, z: 0 },
    metadata: {
      color: "#fdd73e",
    },
  },
  {
    id: 13,
    type: AssetModelType.GROUND,
    name: "tileLow_desert",
    filename: "tileLow_desert.gltf.glb",
    size: { x: 2, y: 1, z: 2 },
  },
  {
    id: 14,
    type: AssetModelType.GROUND,
    name: "tileLow_forest",
    filename: "tileLow_forest.gltf.glb",
    size: { x: 2, y: 1, z: 2 },
  },
  {
    id: 15,
    type: AssetModelType.GROUND,
    name: "tileLow_teamRed",
    filename: "tileLow_teamRed.gltf.glb",
    size: { x: 2, y: 1, z: 2 },
  },
  {
    id: 16,
    type: AssetModelType.GROUND,
    name: "tileLow_teamYellow",
    filename: "tileLow_teamYellow.gltf.glb",
    size: { x: 2, y: 1, z: 2 },
  },
  {
    id: 17,
    type: AssetModelType.GROUND,
    name: "tileLow_forest_ground",
    filename: "tileLow_forest_ground.glb",
    size: { x: 2, y: 1, z: 2 },
  },
  {
    id: 18,
    type: AssetModelType.GROUND,
    name: "tileMedium_desert",
    filename: "tileMedium_desert.gltf.glb",
    size: { x: 2, y: 1.5, z: 2 },
  },
  {
    id: 19,
    type: AssetModelType.GROUND,
    name: "tileMedium_forest",
    filename: "tileMedium_forest.gltf.glb",
    size: { x: 2, y: 1.5, z: 2 },
  },
  {
    id: 20,
    type: AssetModelType.GROUND,
    name: "tileMedium_teamRed",
    filename: "tileMedium_teamRed.gltf.glb",
    size: { x: 2, y: 1.5, z: 2 },
  },
  {
    id: 21,
    type: AssetModelType.GROUND,
    name: "tileMedium_teamYellow",
    filename: "tileMedium_teamYellow.gltf.glb",
    size: { x: 2, y: 1.5, z: 2 },
  },
  {
    id: 22,
    type: AssetModelType.GROUND,
    name: "tileHigh_desert",
    filename: "tileHigh_desert.gltf.glb",
  },
  {
    id: 23,
    type: AssetModelType.GROUND,
    name: "tileHigh_forest",
    filename: "tileHigh_forest.gltf.glb",
  },
  {
    id: 24,
    type: AssetModelType.GROUND,
    name: "tileHigh_teamRed",
    filename: "tileHigh_teamRed.gltf.glb",
  },
  {
    id: 25,
    type: AssetModelType.GROUND,
    name: "tileHigh_teamYellow",
    filename: "tileHigh_teamYellow.gltf.glb",
  },
  {
    id: 26,
    type: AssetModelType.GROUND,
    name: "tileHigh_desert_ground",
    filename: "tileHigh_desert_ground.glb",
  },
  {
    id: 27,
    type: AssetModelType.GROUND,
    name: "tileLarge_desert",
    filename: "tileLarge_desert.gltf.glb",
  },
  {
    id: 28,
    type: AssetModelType.GROUND,
    name: "tileLarge_forest",
    filename: "tileLarge_forest.gltf.glb",
    offset: { x: 0, y: 1, z: 0 },
  },
  {
    id: 29,
    type: AssetModelType.GROUND,
    name: "tileLarge_teamRed",
    filename: "tileLarge_teamRed.gltf.glb",
  },
  {
    id: 30,
    type: AssetModelType.GROUND,
    name: "tileLarge_teamYellow",
    filename: "tileLarge_teamYellow.gltf.glb",
  },
  {
    id: 31,
    type: AssetModelType.RIVER,
    name: "tileLow_teamBlue",
    filename: "tileLow_teamBlue.gltf.glb",
  },
  {
    id: 32,
    type: AssetModelType.RIVER,
    name: "tileMedium_teamBlue",
    filename: "tileMedium_teamBlue.gltf.glb",
  },
  {
    id: 33,
    type: AssetModelType.RIVER,
    name: "tileHigh_teamBlue",
    filename: "tileHigh_teamBlue.gltf.glb",
  },
  {
    id: 34,
    type: AssetModelType.RIVER,
    name: "tileLarge_teamBlue",
    filename: "tileLarge_teamBlue.gltf.glb",
  },
  {
    id: 35,
    type: AssetModelType.DECORATION,
    name: "tree_desert",
    filename: "tree_desert.gltf.glb",
  },
  {
    id: 36,
    type: AssetModelType.DECORATION,
    name: "tree_forest",
    filename: "tree_forest.gltf.glb",
  },
  {
    id: 37,
    type: AssetModelType.DECORATION,
    name: "detail_desert",
    filename: "detail_desert.gltf.glb",
  },
  {
    id: 38,
    type: AssetModelType.DECORATION,
    name: "detail_forest",
    filename: "detail_forest.gltf.glb",
    size: { x: 1.7, y: 0.3, z: 1.7 },
  },
  {
    id: 39,
    type: AssetModelType.DECORATION,
    name: "rocksA_desert",
    filename: "rocksA_desert.gltf.glb",
  },
  {
    id: 40,
    type: AssetModelType.DECORATION,
    name: "rocksA_forest",
    filename: "rocksA_forest.gltf.glb",
  },
  {
    id: 41,
    type: AssetModelType.DECORATION,
    name: "rocksB_desert",
    filename: "rocksB_desert.gltf.glb",
  },
  {
    id: 42,
    type: AssetModelType.DECORATION,
    name: "rocksB_forest",
    filename: "rocksB_forest.gltf.glb",
  },
  {
    id: 43,
    type: AssetModelType.DECORATION,
    name: "barrierSmall_x",
    filename: "barrierSmall.gltf.glb",
  },
  {
    id: 44,
    type: AssetModelType.DECORATION,
    name: "barrierSmall_z",
    filename: "barrierSmall.gltf.glb",
    direction: { x: Math.PI, y: 0, z: 0 },
  },
  {
    id: 45,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowHigh_desert",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
  },
  {
    id: 46,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowHigh_forest",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
  },
  {
    id: 47,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowMedium_forest",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    size: { x: 2, y: 1.5, z: 2 },
  },
  {
    id: 48,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowMedium_forest_x",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    direction: { x: 0, y: 0, z: Math.PI },
  },
  {
    id: 49,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowMedium_forest_minus_x",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    direction: { x: 0, y: 0, z: -Math.PI },
  },
  {
    id: 50,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowMedium_forest_z",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    direction: { x: Math.PI, y: 0, z: 0 },
  },
  {
    id: 51,
    type: AssetModelType.DECORATION,
    name: "tileSlopeLowMedium_forest_minus_z",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    direction: { x: -Math.PI, y: 0, z: 0 },
  },
  // {
  //   id: 7,
  //   type: AssetModelType.DECORATION,
  //   name: "arrow_teamBlue",
  //   filename: "arrow_teamBlue.gltf.glb",
  // },
  // {
  //   id: 8,
  //   type: AssetModelType.DECORATION,
  //   name: "arrow_teamRed",
  //   filename: "arrow_teamRed.gltf.glb",
  // },
  // {
  //   id: 9,
  //   type: AssetModelType.DECORATION,
  //   name: "arrow_teamYellow",
  //   filename: "arrow_teamYellow.gltf.glb",
  // },
  // {
  //   id: 10,
  //   type: AssetModelType.DECORATION,
  //   name: "ball",
  //   filename: "ball.gltf.glb",
  // },
  // {
  //   id: 11,
  //   type: AssetModelType.DECORATION,
  //   name: "ball_teamBlue",
  //   filename: "ball_teamBlue.gltf.glb",
  // },
  // {
  //   id: 12,
  //   type: AssetModelType.DECORATION,
  //   name: "ball_teamRed",
  //   filename: "ball_teamRed.gltf.glb",
  // },
  // {
  //   id: 13,
  //   type: AssetModelType.DECORATION,
  //   name: "ball_teamYellow",
  //   filename: "ball_teamYellow.gltf.glb",
  // },
  // {
  //   id: 14,
  //   type: AssetModelType.DECORATION,
  //   name: "barrierFloor",
  //   filename: "barrierFloor.gltf.glb",
  // },
  // {
  //   id: 15,
  //   type: AssetModelType.DECORATION,
  //   name: "barrierLadder",
  //   filename: "barrierLadder.gltf.glb",
  // },
  // {
  //   id: 17,
  //   type: AssetModelType.DECORATION,
  //   name: "barrierStrut",
  //   filename: "barrierStrut.gltf.glb",
  // },
  // {
  //   id: 18,
  //   type: AssetModelType.DECORATION,
  //   name: "blaster_teamBlue",
  //   filename: "blaster_teamBlue.gltf.glb",
  // },
  // {
  //   id: 19,
  //   type: AssetModelType.DECORATION,
  //   name: "blaster_teamRed",
  //   filename: "blaster_teamRed.gltf.glb",
  // },
  // {
  //   id: 20,
  //   type: AssetModelType.DECORATION,
  //   name: "blaster_teamYellow",
  //   filename: "blaster_teamYellow.gltf.glb",
  // },
  // {
  //   id: 21,
  //   type: AssetModelType.DECORATION,
  //   name: "bomb_teamBlue",
  //   filename: "bomb_teamBlue.gltf.glb",
  // },
  // {
  //   id: 22,
  //   type: AssetModelType.DECORATION,
  //   name: "bomb_teamRed",
  //   filename: "bomb_teamRed.gltf.glb",
  // },
  // {
  //   id: 23,
  //   type: AssetModelType.DECORATION,
  //   name: "bomb_teamYellow",
  //   filename: "bomb_teamYellow.gltf.glb",
  // },
  // {
  //   id: 24,
  //   type: AssetModelType.DECORATION,
  //   name: "bow_teamBlue",
  //   filename: "bow_teamBlue.gltf.glb",
  // },
  // {
  //   id: 25,
  //   type: AssetModelType.DECORATION,
  //   name: "bow_teamRed",
  //   filename: "bow_teamRed.gltf.glb",
  // },
  // {
  //   id: 26,
  //   type: AssetModelType.DECORATION,
  //   name: "bow_teamYellow",
  //   filename: "bow_teamYellow.gltf.glb",
  // },
  // {
  //   id: 29,
  //   type: AssetModelType.DECORATION,
  //   name: "gateLarge_teamBlue",
  //   filename: "gateLarge_teamBlue.gltf.glb",
  // },
  // {
  //   id: 30,
  //   type: AssetModelType.DECORATION,
  //   name: "gateLarge_teamRed",
  //   filename: "gateLarge_teamRed.gltf.glb",
  // },
  // {
  //   id: 31,
  //   type: AssetModelType.DECORATION,
  //   name: "gateLarge_teamYellow",
  //   filename: "gateLarge_teamYellow.gltf.glb",
  // },
  // {
  //   id: 32,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmallWide_teamBlue",
  //   filename: "gateSmallWide_teamBlue.gltf.glb",
  // },
  // {
  //   id: 33,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmallWide_teamRed",
  //   filename: "gateSmallWide_teamRed.gltf.glb",
  // },
  // {
  //   id: 34,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmallWide_teamYellow",
  //   filename: "gateSmallWide_teamYellow.gltf.glb",
  // },
  // {
  //   id: 35,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmall_teamBlue",
  //   filename: "gateSmall_teamBlue.gltf.glb",
  // },
  // {
  //   id: 36,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmall_teamRed",
  //   filename: "gateSmall_teamRed.gltf.glb",
  // },
  // {
  //   id: 37,
  //   type: AssetModelType.DECORATION,
  //   name: "gateSmall_teamYellow",
  //   filename: "gateSmall_teamYellow.gltf.glb",
  // },
  // {
  //   id: 38,
  //   type: AssetModelType.DECORATION,
  //   name: "heart_teamBlue",
  //   filename: "heart_teamBlue.gltf.glb",
  // },
  // {
  //   id: 39,
  //   type: AssetModelType.DECORATION,
  //   name: "heart_teamRed",
  //   filename: "heart_teamRed.gltf.glb",
  // },
  // {
  //   id: 40,
  //   type: AssetModelType.DECORATION,
  //   name: "heart_teamYellow",
  //   filename: "heart_teamYellow.gltf.glb",
  // },
  // {
  //   id: 41,
  //   type: AssetModelType.DECORATION,
  //   name: "hoop_teamBlue",
  //   filename: "hoop_teamBlue.gltf.glb",
  // },
  // {
  //   id: 42,
  //   type: AssetModelType.DECORATION,
  //   name: "hoop_teamRed",
  //   filename: "hoop_teamRed.gltf.glb",
  // },
  // {
  //   id: 43,
  //   type: AssetModelType.DECORATION,
  //   name: "hoop_teamYellow",
  //   filename: "hoop_teamYellow.gltf.glb",
  // },
  // {
  //   id: 44,
  //   type: AssetModelType.DECORATION,
  //   name: "lightning",
  //   filename: "lightning.gltf.glb",
  // },
  // {
  //   id: 45,
  //   type: AssetModelType.DECORATION,
  //   name: "plantA_desert",
  //   filename: "plantA_desert.gltf.glb",
  // },
  // {
  //   id: 46,
  //   type: AssetModelType.DECORATION,
  //   name: "plantA_forest",
  //   filename: "plantA_forest.gltf.glb",
  // },
  // {
  //   id: 47,
  //   type: AssetModelType.DECORATION,
  //   name: "plantB_desert",
  //   filename: "plantB_desert.gltf.glb",
  // },
  // {
  //   id: 48,
  //   type: AssetModelType.DECORATION,
  //   name: "plantB_forest",
  //   filename: "plantB_forest.gltf.glb",
  // },
  // {
  //   id: 49,
  //   type: AssetModelType.DECORATION,
  //   name: "powerupBlock_teamBlue",
  //   filename: "powerupBlock_teamBlue.gltf.glb",
  // },
  // {
  //   id: 50,
  //   type: AssetModelType.DECORATION,
  //   name: "powerupBlock_teamRed",
  //   filename: "powerupBlock_teamRed.gltf.glb",
  // },
  // {
  //   id: 51,
  //   type: AssetModelType.DECORATION,
  //   name: "powerupBlock_teamYellow",
  //   filename: "powerupBlock_teamYellow.gltf.glb",
  // },
  // {
  //   id: 52,
  //   type: AssetModelType.DECORATION,
  //   name: "powerupBomb",
  //   filename: "powerupBomb.gltf.glb",
  // },
  // {
  //   id: 53,
  //   type: AssetModelType.DECORATION,
  //   name: "ring_teamBlue",
  //   filename: "ring_teamBlue.gltf.glb",
  // },
  // {
  //   id: 54,
  //   type: AssetModelType.DECORATION,
  //   name: "ring_teamRed",
  //   filename: "ring_teamRed.gltf.glb",
  // },
  // {
  //   id: 55,
  //   type: AssetModelType.DECORATION,
  //   name: "ring_teamYellow",
  //   filename: "ring_teamYellow.gltf.glb",
  // },
  // {
  //   id: 60,
  //   type: AssetModelType.DECORATION,
  //   name: "slingshot_teamBlue",
  //   filename: "slingshot_teamBlue.gltf.glb",
  // },
  // {
  //   id: 61,
  //   type: AssetModelType.DECORATION,
  //   name: "slingshot_teamRed",
  //   filename: "slingshot_teamRed.gltf.glb",
  // },
  // {
  //   id: 62,
  //   type: AssetModelType.DECORATION,
  //   name: "slingshot_teamYellow",
  //   filename: "slingshot_teamYellow.gltf.glb",
  // },
  // {
  //   id: 63,
  //   type: AssetModelType.DECORATION,
  //   name: "spikeRoller",
  //   filename: "spikeRoller.gltf.glb",
  // },
  // {
  //   id: 64,
  //   type: AssetModelType.DECORATION,
  //   name: "star",
  //   filename: "star.gltf.glb",
  // },
  // {
  //   id: 65,
  //   type: AssetModelType.DECORATION,
  //   name: "swiper_teamBlue",
  //   filename: "swiper_teamBlue.gltf.glb",
  // },
  // {
  //   id: 66,
  //   type: AssetModelType.DECORATION,
  //   name: "swiper_teamRed",
  //   filename: "swiper_teamRed.gltf.glb",
  // },
  // {
  //   id: 67,
  //   type: AssetModelType.DECORATION,
  //   name: "swiper_teamYellow",
  //   filename: "swiper_teamYellow.gltf.glb",
  // },
  // {
  //   id: 68,
  //   type: AssetModelType.DECORATION,
  //   name: "sword_teamBlue",
  //   filename: "sword_teamBlue.gltf.glb",
  // },
  // {
  //   id: 69,
  //   type: AssetModelType.DECORATION,
  //   name: "sword_teamRed",
  //   filename: "sword_teamRed.gltf.glb",
  // },
  // {
  //   id: 70,
  //   type: AssetModelType.DECORATION,
  //   name: "sword_teamYellow",
  //   filename: "sword_teamYellow.gltf.glb",
  // },
  // {
  //   id: 71,
  //   type: AssetModelType.DECORATION,
  //   name: "target",
  //   filename: "target.gltf.glb",
  // },
  // {
  //   id: 72,
  //   type: AssetModelType.DECORATION,
  //   name: "targetStand",
  //   filename: "targetStand.gltf.glb",
  // },
  // {
  //   id: 77,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowHigh_teamBlue",
  //   filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
  // },
  // {
  //   id: 78,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowHigh_teamRed",
  //   filename: "tileSlopeLowHigh_teamRed.gltf.glb",
  // },
  // {
  //   id: 79,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowHigh_teamYellow",
  //   filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
  // },
  // {
  //   id: 80,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowMedium_teamRed",
  //   filename: "tileSlopeLowMedium_teamRed.gltf.glb",
  // },
  // {
  //   id: 81,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowMedium_desert",
  //   filename: "tileSlopeLowMedium_desert.gltf.glb",
  // },
  // {
  //   id: 83,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowMedium_teamBlue",
  //   filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
  // },
  // {
  //   id: 84,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeLowMedium_teamYellow",
  //   filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
  // },
  // {
  //   id: 85,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeMediumHigh_desert",
  //   filename: "tileSlopeMediumHigh_desert.gltf.glb",
  // },
  // {
  //   id: 86,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeMediumHigh_forest",
  //   filename: "tileSlopeMediumHigh_forest.gltf.glb",
  // },
  // {
  //   id: 87,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeMediumHigh_teamBlue",
  //   filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
  // },
  // {
  //   id: 88,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeMediumHigh_teamRed",
  //   filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
  // },
  // {
  //   id: 89,
  //   type: AssetModelType.DECORATION,
  //   name: "tileSlopeMediumHigh_teamYellow",
  //   filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
  // },
];

export default tiles;
