import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { FadeTransition } from "common/elements";
import { Decoration, Background } from "features/install/assets";

export const ReadyStart = ({ open }: { open: boolean }) => {
  const { scale } = useScale();
  const [count, setCount] = useState(4);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (open) {
      setCount(4);
      timer = setInterval(() => {
        setCount((pre) => pre - 1);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={FadeTransition}
      transitionDuration={{ enter: 1000, exit: 500 }}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      sx={{ zIndex: 10001, backgroundColor: "transparent" }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-el flex-center absolute w-full h-full bg-textcolor/black/20">
        <div
          style={{
            width: 580 * scale,
            height: 140 * scale,
            filter: "drop-shadow(0px 0px 30px rgba(63, 74, 97, 0.35))",
          }}
          className="flex-col-center"
        >
          <div
            className="flex-col-center w-[580px] h-[140px] cursor-pointer"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="absolute block z-[-1]">
              <div>
                <Background />
              </div>

              <div className="absolute block top-[32px] left-[-40px]">
                <Decoration />
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div
                className="flex-col-center bg-white rounded-full w-[96px] h-[96px] mr-4"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                  <img
                    alt="アプリケーションのアイコン"
                    src={`${Constants.assetHost}/assets/images/app-icon.png`}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>

              <div className="flex flex-col flex-1">
                <div className="flex flex-col">
                  <p className="text-body text-textcolor/black text-[36px]">
                    {"コマンド スタート！"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: 600 * scale,
            height: 600 * scale,
          }}
          className="flex-col-el flex-center absolute"
        >
          <div
            className="flex-col-center w-[600px] h-[600px] cursor-pointer"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="flex flex-row items-center">
              {count > 0 && open && (
                <p
                  key={count}
                  className="text-body text-yellow/alt2 text-[300px] fuchidori tsumiki_countdown_number"
                >
                  {count - 1}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
