import { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { actions } from "features/courses/coursesSlice";
import { updateCourseConfig } from "features/courses/api";

export const useOpenCv = ({
  setInitialized,
}: {
  setInitialized?: () => void;
}) => {
  useEffect(() => {
    if ("cv" in window) {
      if (setInitialized) {
        setInitialized();
      }
      console.log("already import opencv!");
    } else {
      const script = document.createElement("script");
      script.src = "https://assets.kids.inexus-co.com/lib/opencv_4.10.0.js";
      script.async = true;
      script.onload = () => {
        // @ts-ignore
        cv["onRuntimeInitialized"] = () => {
          console.log("OpenCV is ready to use.");
          if (setInitialized) {
            setInitialized();
          }
        };
      };
      script.onerror = () => {
        console.error("Failed to load OpenCV.js");
      };
      document.head.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useCamera = (
  videoRef: React.MutableRefObject<HTMLVideoElement>
) => {
  const { scale } = useScale();
  const dispatch = useDispatch();
  const streamRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  const [cameraIds, setCameraIds] = useState([]);
  const [cameraIndex, setCameraIndex] = useState(0);
  const cameraDeviceId = useSelector(
    (state: RootState) => state.courses.config.tangible.deviceId
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleOpenCamera = useCallback(
    async (
      deviceId: string,
      success?: () => void,
      error?: (err: any) => void
    ) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            deviceId,
            aspectRatio: 16 / 9,
            width: { ideal: 1920 },
            frameRate: 30,
          },
        })
        .then((stream) => {
          streamRef.current = stream;
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.onloadedmetadata = function () {
              videoRef.current.play();
              videoRef.current.width = videoRef.current.videoWidth; // width, heightを設定しないとcap.read(src)で失敗する。
              videoRef.current.height = videoRef.current.videoHeight;
              if (success) {
                success();
              }
            };
          } else {
            error("Can not found video!");
          }
        })
        .catch(error);
    },
    []
  );

  const initCamera = useCallback(
    async (success?: () => void, error?: (err: any) => void) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        stream.getVideoTracks().forEach((track) => track.stop());
        const devices = await navigator.mediaDevices.enumerateDevices();
        const deviceIds = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => device.deviceId);
        setCameraIds(deviceIds);
        let deviceId: string;
        if (cameraDeviceId) {
          const deviceIdIndex = deviceIds.indexOf(cameraDeviceId);
          if (deviceIdIndex !== -1) {
            setCameraIndex(deviceIdIndex);
            deviceId = cameraDeviceId;
          } else {
            setCameraIndex(deviceIds.length - 1);
            deviceId = deviceIds[deviceIds.length - 1];
            dispatch(actions.updateCameraDeviceId(deviceId));
            dispatch(
              updateCourseConfig({
                mainUid: user.main.uid,
                type: "tangible",
              })
            );
          }
        } else {
          setCameraIndex(deviceIds.length - 1);
          deviceId = deviceIds[deviceIds.length - 1];
          dispatch(actions.updateCameraDeviceId(deviceId));
          dispatch(
            updateCourseConfig({
              mainUid: user.main.uid,
              type: "tangible",
            })
          );
        }
        handleOpenCamera(deviceId, success, error);
      } catch (err) {
        error(err);
      }
    },
    [cameraDeviceId]
  );

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current?.srcObject) {
      const tracks = (videoRef.current
        .srcObject as MediaStream).getVideoTracks();
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
      }
      videoRef.current.srcObject = null;
      setTimeout(() => handleOpenCamera(cameraIds[cameraIndex]), 200);
    }
  }, [scale]);

  const switchCamera = () => {
    if (clicked) return;
    setClicked(true);
    if (cameraIndex === cameraIds.length - 1) {
      if (videoRef.current?.srcObject) {
        (videoRef.current.srcObject as MediaStream)
          .getVideoTracks()
          .forEach((track) => track.stop());
        videoRef.current.srcObject = null;
        setTimeout(
          () =>
            handleOpenCamera(
              cameraIds[0],
              () => {
                setClicked(false);
              },
              () => {
                setClicked(false);
              }
            ),
          200
        );
        setCameraIndex(0);
        dispatch(actions.updateCameraDeviceId(cameraIds[0]));
        dispatch(
          updateCourseConfig({
            mainUid: user.main.uid,
            type: "tangible",
          })
        );
      }
    } else {
      if (videoRef.current?.srcObject) {
        (videoRef.current.srcObject as MediaStream)
          .getVideoTracks()
          .forEach((track) => track.stop());
        videoRef.current.srcObject = null;
        setTimeout(
          () =>
            handleOpenCamera(
              cameraIds[cameraIndex + 1],
              () => {
                setClicked(false);
              },
              () => {
                setClicked(false);
              }
            ),
          200
        );
        setCameraIndex(cameraIndex + 1);
        dispatch(actions.updateCameraDeviceId(cameraIds[cameraIndex + 1]));
        dispatch(
          updateCourseConfig({
            mainUid: user.main.uid,
            type: "tangible",
          })
        );
      }
    }
  };

  const resetCamera = (success: () => void, error?: () => void) => {
    if (clicked) return;
    setClicked(true);
    handleOpenCamera(
      cameraIds[cameraIndex],
      () => {
        setClicked(false);
        success();
      },
      () => {
        setClicked(false);
        if (error) {
          error();
        }
      }
    );
  };

  const cancel = () => {
    try {
      const tracks = (videoRef.current
        .srcObject as MediaStream).getVideoTracks();
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
      }
      videoRef.current.srcObject = null;
    } catch (error) {
      console.log(error);
    }
  };

  return { initCamera, switchCamera, resetCamera, cancel };
};
