import Blockly from "blockly";
import { CourseModel } from "app/types";
import {
  ExecutableBlock,
  WorkspaceBlockList,
  BlockType as TangibleBlockType,
} from "common/tangible";
import Constants from "common/constant";
import { theme, BlockType } from "common/blockly";

export const createBlocklyOptions = (
  scale: number,
  model: CourseModel,
  toolbox?: Blockly.utils.toolbox.ToolboxDefinition
) => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: false,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/game/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  maxInstances: {
    character_event_duck: 1,
    character_event_dog: 1,
    character_event_bear: 1,
  },
  trashcan: model === CourseModel.COMMAND,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});

export const toolboxDefinition = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "event",
      contents: [
        {
          kind: "label",
          text: "Placeholder",
          "web-class": "course-toolbox-label",
        },
        {
          kind: "label",
          text: "",
          webClass: "toolbox-label",
        },
        {
          kind: "block",
          type: BlockType.CHARACTER_EVENT_DUCK,
        },
        {
          kind: "block",
          type: BlockType.CHARACTER_EVENT_DOG,
        },
        {
          kind: "block",
          type: BlockType.CHARACTER_EVENT_BEAR,
        },
      ],
    },
    {
      kind: "category",
      toolboxitemid: "action",
      contents: [
        {
          kind: "label",
          text: "Placeholder",
          "web-class": "course-toolbox-label",
        },
        {
          kind: "label",
          text: "",
          webClass: "toolbox-label",
        },
        {
          kind: "block",
          type: BlockType.MOTION_MOVE,
        },
        {
          kind: "block",
          type: BlockType.MOTION_JUMP,
        },
        {
          kind: "block",
          type: BlockType.MOTION_TURN_LEFT,
        },
        {
          kind: "block",
          type: BlockType.MOTION_TURN_RIGHT,
        },
      ],
    },
    {
      kind: "category",
      toolboxitemid: "control",
      contents: [
        {
          kind: "label",
          text: "Placeholder",
          "web-class": "course-toolbox-label",
        },
        {
          kind: "block",
          type: BlockType.CONTROLS_REPEAT_VAR_INTERNAL,
          inputs: {
            TIMES: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL,
          inputs: {
            COLOUR: {
              shadow: {
                type: "colour_picker_internal",
                fields: {
                  COLOUR: "#71cdfc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL,
          inputs: {
            COLOUR: {
              shadow: {
                type: "colour_picker_internal",
                fields: {
                  COLOUR: "#71cdfc",
                },
              },
            },
          },
        },
      ],
    },
    {
      kind: "category",
      custom: "CUSTOM_VARIABLE_FLYOUT",
      toolboxitemid: "variable",
    },
    {
      kind: "category",
      custom: "CUSTOM_PROCEDURE_FLYOUT",
      toolboxitemid: "procedure",
    },
  ],
};

export const updateFlyout = (workspace, offset: number) => {
  const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
  const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
  const selectedItem = blockToolbox.getSelectedItem() as Blockly.ToolboxItem;
  if (!selectedItem) {
    const item = blockToolbox.getToolboxItemById(
      "event"
    ) as Blockly.ToolboxCategory;
    blockToolbox.setSelectedItem(item);
    if (!blocklyFlyout.isVisible()) {
      blockToolbox.setSelectedItem(item);
    }
  } else {
    blockToolbox.setSelectedItem(selectedItem);
    if (!blocklyFlyout.isVisible()) {
      blockToolbox.setSelectedItem(selectedItem);
    }
  }
  // @ts-ignore
  blocklyFlyout.positionAt_(
    blocklyFlyout.getWidth(),
    blocklyFlyout.getHeight() - offset,
    0,
    offset
  );
};

export const generateWorkspaceBlock = (
  scale: number,
  blocks: ExecutableBlock[][],
  workspace: Blockly.WorkspaceSvg
) => {
  workspace.clear();
  try {
    for (let i = 0; i < blocks.length; i++) {
      const workspaceBlockList: WorkspaceBlockList[] = [];
      for (let j = 0; j < blocks[i].length; j++) {
        const block = blocks[i][j];
        if (
          block.type === TangibleBlockType.EVENT_START ||
          block.type ===
            TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
        ) {
          let workspaceBlock: Blockly.BlockSvg;
          if (block.type === TangibleBlockType.EVENT_START) {
            let character_type: string;
            if (i === 0) {
              character_type = "character_event_duck";
            } else if (i === 1) {
              character_type = "character_event_dog";
            } else if (i === 2) {
              character_type = "character_event_bear";
            } else {
              character_type = "character_event_duck";
            }
            workspaceBlock = new Blockly.BlockSvg(workspace, character_type);
          } else {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type.replace("_start", "")
            );
          }

          workspaceBlock.initSvg();
          workspaceBlock.render();
          workspaceBlock.moveTo(
            new Blockly.utils.Coordinate(50 / scale + i * 400, 170 / scale)
          );

          if (
            block.type ===
            TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
          ) {
            workspaceBlock.getField("NAME")?.setValue(block.args[0]);
          }

          workspaceBlockList.push({ block, workspaceBlock });
        } else if (block.type.includes("end")) {
          workspaceBlockList.push({ block, workspaceBlock: null });
        } else {
          let workspaceBlock: Blockly.BlockSvg;
          if (block.type === TangibleBlockType.PROCEDURES_CALLNORETURN_CALL) {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type + `_${block.args[0]}`
            );
            workspaceBlock.getField("NAME")?.setValue(block.args[1]);
          } else if (block.type === TangibleBlockType.VARIABLES_SET) {
            workspaceBlock = new Blockly.BlockSvg(workspace, block.type);
            const variableSetBlock = workspace.getVariableById(
              workspaceBlock.getFieldValue("VAR")
            );
            variableSetBlock.name = block.args[0];
            if (isNaN(Number(block.args[1]))) {
              variableSetBlock.type = "Colour";
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(colourPickerBlock.outputConnection);
            } else {
              variableSetBlock.type = "Number";
              const mathNumberBlock = new Blockly.BlockSvg(
                workspace,
                "math_number"
              );
              mathNumberBlock.initSvg();
              mathNumberBlock.render();
              mathNumberBlock.getField("NUM")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(mathNumberBlock.outputConnection);
            }
            console.log(variableSetBlock);
          } else if (block.type.includes("var_internal")) {
            const variableSetBlock = workspace.getVariable(block.args[0]);

            const variableGetBlock = new Blockly.BlockSvg(
              workspace,
              TangibleBlockType.VARIABLES_GET
            );
            variableGetBlock.initSvg();
            variableGetBlock.render();
            (variableGetBlock.getField(
              "VAR"
            ) as Blockly.FieldVariable).variableTypes = [variableSetBlock.type];
            variableGetBlock.setFieldValue(variableSetBlock.getId(), "VAR");

            if (
              block.type === TangibleBlockType.CONTROLS_LOOP_VAR_INTERNAL_START
            ) {
              if (variableSetBlock.type === "Colour") {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  TangibleBlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("COLOUR")
                  .connection.connect(variableGetBlock.outputConnection);
              } else {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  TangibleBlockType.CONTROLS_REPEAT_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("TIMES")
                  .connection.connect(variableGetBlock.outputConnection);
              }
            } else if (
              block.type ===
              TangibleBlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL_START
            ) {
              workspaceBlock = new Blockly.BlockSvg(
                workspace,
                block.type.replace("_start", "")
              );
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(variableGetBlock.outputConnection);
            }
          } else {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type.includes("start")
                ? block.type.replace("_start", "")
                : block.type
            );
            if (workspaceBlock.loadExtraState) {
              workspaceBlock.loadExtraState({ max: Number(block.args[0]) });
            }
            workspaceBlock.getField("TIMES")?.setValue(block.args[0]);
            if (workspaceBlock.getInput("COLOUR")) {
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[0]);
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(colourPickerBlock.outputConnection);
            }
          }

          workspaceBlock.initSvg();
          workspaceBlock.render();

          const previousBlock =
            workspaceBlockList[workspaceBlockList.length - 1];
          if (previousBlock.block.type.includes("start")) {
            previousBlock.workspaceBlock
              .getInput(
                previousBlock.block.type ===
                  TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
                  ? "STACK"
                  : "DO"
              )
              .connection.connect(workspaceBlock.previousConnection);
          } else if (previousBlock.block.type.includes("end")) {
            const parentBlock = workspaceBlockList
              .filter((item) => item.block.id === previousBlock.block.parentId)
              .shift();
            if (parentBlock && parentBlock.workspaceBlock.nextConnection) {
              parentBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          } else {
            if (previousBlock.workspaceBlock.nextConnection) {
              previousBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          }

          workspaceBlock.setMovable(false);
          workspaceBlock.setEditable(false);
          workspaceBlockList.push({ block, workspaceBlock });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
};
