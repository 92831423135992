import { useState, useEffect, PointerEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/course/slice";
import { KeyDirection } from "features/creator/types";
import { ArrowButton } from "features/courses/components";
import { StageCard } from "./StageCard";

export const StageList = ({
  selectedIndex,
  setSelectedIndex,
}: {
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
}) => {
  const ITEM_WIDTH = 552;
  const ITEM_HEIGHT = 360;
  const ITEM_MARGIN = -180;
  const ITEM_SCALE = 0.5;
  const CENTER_ITEM_MARGIN_LEFT = -ITEM_MARGIN * ITEM_SCALE;
  const CENTER_ITEM_MARGIN_RIGHT = -ITEM_MARGIN * 1.3 * ITEM_SCALE;

  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showWelcomePage = useSelector(
    (state: RootState) => state.course.showWelcomePage
  );
  const [offsetX, setOffsetX] = useState(
    (ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * selectedIndex
  );
  const stages = useSelector(
    (state: RootState) => state.courses.courses[Course.TAKUSHOKU_BASIC].progress
  ).length;

  const cursor = useSelector((state: RootState) => state.course.cursor);
  const panelAction = useSelector((state: RootState) => state.course.action);

  useEffect(() => {
    if (!showWelcomePage) {
      dispatch(actions.updateCursor(KeyDirection.NONE));
      switch (cursor) {
        case KeyDirection.UP:
        case KeyDirection.LEFT:
          if (selectedIndex !== 0) {
            setOffsetX(
              (ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * (selectedIndex - 1)
            );
            setSelectedIndex(selectedIndex - 1);
          }
          break;
        case KeyDirection.DOWN:
        case KeyDirection.RIGHT:
          if (selectedIndex < stages - 1) {
            setOffsetX(
              (ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * (selectedIndex + 1)
            );
            setSelectedIndex(selectedIndex + 1);
          }
          break;
        default:
          break;
      }
    }
  }, [cursor]);

  useEffect(() => {
    if (panelAction.confirm && !showWelcomePage) {
      dispatch(
        actions.updateAction({
          ...panelAction,
          confirm: false,
        })
      );
      dispatch(actions.updateSelectedStage({ stage: selectedIndex + 1 }));
      navigate(`${Course.TAKUSHOKU_BASIC}/stage/${selectedIndex + 1}`);
    }
  }, [panelAction.confirm]);

  const handleMoveStage = (step: number) => () => {
    play();
    if (step < 0 && selectedIndex !== 0) {
      setOffsetX((ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * (selectedIndex - 1));
      setSelectedIndex(selectedIndex - 1);
    }
    if (step > 0 && selectedIndex < stages - 1) {
      setOffsetX((ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * (selectedIndex + 1));
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handleTapMap = (
    index: number
  ): PointerEventHandler<HTMLDivElement> => () => {
    play();
    if (selectedIndex === index) {
      dispatch(actions.updateSelectedStage({ stage: selectedIndex + 1 }));
      navigate(`${Course.TAKUSHOKU_BASIC}/stage/${selectedIndex + 1}`);
    } else {
      setSelectedIndex(index);
      setOffsetX((ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * index);
    }
  };

  return (
    <>
      <ArrowButton handleMoveStage={handleMoveStage} />

      <div className="flex-row-el w-full h-full overflow-hidden cursor-grab pointer-events-auto">
        <div
          className="flex-row-el items-center w-full h-full pt-[12px] pointer-events-auto"
          style={{
            transform: `translateX(${-offsetX}px)`,
            transition: "transform 300ms ease-in-out",
          }}
        >
          <div className="flex-row-el w-[230px] h-full" />
          {Array(stages)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className={`flex-col-el cursor-pointer ${
                  selectedIndex === index && "scale-[2]"
                }`}
                style={{
                  transition: "transform 300ms ease-in-out",
                  zIndex: selectedIndex === index ? 10 : 1,
                  pointerEvents: selectedIndex === index ? "none" : "auto",
                }}
              >
                <div
                  onPointerDown={handleTapMap(index)}
                  className="flex-col-center pointer-events-auto cursor-pointer"
                  style={{
                    transform: `translateY(0px)`,
                    marginLeft:
                      selectedIndex === index ? CENTER_ITEM_MARGIN_LEFT : 0,
                    marginRight:
                      selectedIndex === index
                        ? CENTER_ITEM_MARGIN_RIGHT
                        : ITEM_MARGIN * ITEM_SCALE,
                  }}
                >
                  {selectedIndex !== index && (
                    <div className="flex-col-el absolute top-0 left-0 w-full h-full bg-textcolor/black/20 z-10" />
                  )}
                  <StageCard
                    stage={index + 1}
                    width={ITEM_WIDTH}
                    height={ITEM_HEIGHT}
                    cardScale={ITEM_SCALE}
                    selected={selectedIndex === index}
                  />
                </div>
              </div>
            ))}
          <div className="flex-row-el w-[450px] h-full" />
        </div>
      </div>
    </>
  );
};
