import {
  ProgramStagePage,
  ProgramWelcomePage,
} from "features/courses/program/stage";
import {
  AlgorithmStagePage,
  AlgorithmWelcomePage,
} from "features/courses/algorithm/stage";
import {
  NishishinjukuStagePage,
  NishishinjukuWelcomePage,
} from "features/courses/nishishinjuku/stage";
import { Course } from "features/courses/types";
import {
  TakushokuBasicStagePage,
  TakushokuBasicWelcomePage,
} from "features/courses/takushoku_basic/stage";
import {
  TakushokuAdvanceStagePage,
  TakushokuAdvanceWelcomePage,
} from "features/courses/takushoku_advance/stage";
import {
  AlgorithmAdvancedStagePage,
  AlgorithmAdvancedWelcomePage,
} from "features/courses/algorithm_advanced/stage";
import { BuilderPage, BuilderWelcomePage } from "features/courses/builder";
import {
  GroupWorkStagePage,
  GroupWorkWelcomePage,
} from "features/courses/group_work/stage";
import { DefaultDisplay } from "./DefaultDisplay";

export const Cassette = ({
  course,
  showWelcomePage,
}: {
  course: Course;
  showWelcomePage: boolean;
}) => (
  <div className="flex-col-el flex-center relative w-full h-full overflow-hidden rounded-xl">
    {!course && (
      <div className="flex-col-el flex-center w-full h-full bg-white">
        <DefaultDisplay />
      </div>
    )}
    {course === Course.ALGORITHM && (
      <>
        {showWelcomePage && <AlgorithmWelcomePage />}
        <AlgorithmStagePage />
      </>
    )}
    {course === Course.PROGRAM && (
      <>
        {showWelcomePage && <ProgramWelcomePage />}
        <ProgramStagePage />
      </>
    )}
    {course === Course.ALGORITHM_ADVANCED && (
      <>
        {showWelcomePage && <AlgorithmAdvancedWelcomePage />}
        <AlgorithmAdvancedStagePage />
      </>
    )}
    {course === Course.GROUP_WORK && (
      <>
        {showWelcomePage && <GroupWorkWelcomePage />}
        <GroupWorkStagePage />
      </>
    )}
    {course === Course.NISHISHINJUKU && (
      <>
        {showWelcomePage && <NishishinjukuWelcomePage />}
        <NishishinjukuStagePage />
      </>
    )}
    {course === Course.TAKUSHOKU_BASIC && (
      <>
        {showWelcomePage && <TakushokuBasicWelcomePage />}
        <TakushokuBasicStagePage />
      </>
    )}
    {course === Course.TAKUSHOKU_ADVANCE && (
      <>
        {showWelcomePage && <TakushokuAdvanceWelcomePage />}
        <TakushokuAdvanceStagePage />
      </>
    )}
    {course === Course.BUILDER && (
      <>
        {showWelcomePage && <BuilderWelcomePage />}
        <BuilderPage />
      </>
    )}
  </div>
);
