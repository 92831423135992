import {
  Stage1LevelRect,
  Stage2LevelRect,
  Stage3LevelRect,
  Stage4LevelRect,
  Stage5LevelRect,
  Stage6LevelRect,
} from "./StageLevelRects";

const Stage1LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage1LevelRect[0].left + radius) * scale + offset} ${
    (Stage1LevelRect[0].top + radius * 2) * scale
  } Q ${(Stage1LevelRect[0].left + radius) * scale + offset} ${
    (Stage1LevelRect[0].top +
      radius * 2 +
      (Stage1LevelRect[1].top - Stage1LevelRect[0].top - radius * 2) / 2) *
    scale
  } ${Stage1LevelRect[1].left * scale + offset} ${
    (Stage1LevelRect[1].top + radius) * scale
  }`,
  `M ${(Stage1LevelRect[1].left + radius * 1.5) * scale + offset} ${
    Stage1LevelRect[1].top * scale
  } Q ${(Stage1LevelRect[2].left + radius) * scale + offset} ${
    (Stage1LevelRect[2].top +
      radius * 2 +
      (Stage1LevelRect[1].top - Stage1LevelRect[2].top - radius * 2) / 2) *
    scale
  } ${(Stage1LevelRect[2].left + radius) * scale + offset} ${
    (Stage1LevelRect[2].top + radius * 2) * scale
  }`,
  `M ${(Stage1LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[2].top + radius) * scale
  } Q ${
    (Stage1LevelRect[2].left +
      radius * 2 +
      (Stage1LevelRect[3].left - Stage1LevelRect[2].left - radius * 2) / 2) *
      scale +
    offset
  } ${(Stage1LevelRect[3].top + radius) * scale} ${
    Stage1LevelRect[3].left * scale + offset
  } ${(Stage1LevelRect[3].top + radius) * scale}`,
  `M ${(Stage1LevelRect[3].left + radius) * scale + offset} ${
    (Stage1LevelRect[3].top + radius * 2) * scale
  } Q ${(Stage1LevelRect[3].left + radius) * scale + offset} ${
    (Stage1LevelRect[3].top +
      radius * 2 +
      (Stage1LevelRect[4].top - Stage1LevelRect[3].top - radius * 2) / 2) *
    scale
  }  ${(Stage1LevelRect[4].left + radius) * scale + offset} ${
    Stage1LevelRect[4].top * scale
  }`,
  `M ${(Stage1LevelRect[4].left + radius) * scale + offset} ${
    (Stage1LevelRect[4].top + radius * 2) * scale
  } Q ${(Stage1LevelRect[4].left + radius) * scale + offset} ${
    (Stage1LevelRect[4].top +
      radius * 2 +
      (Stage1LevelRect[5].top - Stage1LevelRect[4].top - radius * 2) / 2) *
    scale
  } ${Stage1LevelRect[5].left * scale + offset} ${
    (Stage1LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage1LevelRect[5].left + radius * 1.5) * scale + offset} ${
    Stage1LevelRect[5].top * scale
  } Q ${(Stage1LevelRect[6].left + radius) * scale + offset} ${
    (Stage1LevelRect[6].top +
      radius * 2 +
      (Stage1LevelRect[5].top - Stage1LevelRect[6].top - radius * 2) / 2) *
    scale
  } ${(Stage1LevelRect[6].left + radius) * scale + offset} ${
    (Stage1LevelRect[6].top + radius * 2) * scale
  }`,
];

const Stage2LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage2LevelRect[0].left + radius) * scale + offset} ${
    (Stage2LevelRect[0].top + radius * 2) * scale
  } Q ${(Stage2LevelRect[0].left + radius) * scale + offset} ${
    (Stage2LevelRect[0].top +
      radius * 2 +
      (Stage2LevelRect[1].top - Stage2LevelRect[0].top - radius * 2) / 2) *
    scale
  } ${(Stage2LevelRect[1].left + radius) * scale + offset} ${
    Stage2LevelRect[1].top * scale
  }`,
  `M ${(Stage2LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[1].top + radius) * scale
  } Q ${Stage2LevelRect[2].left * scale + offset} ${
    (Stage2LevelRect[1].top + radius) * scale
  } ${(Stage2LevelRect[2].left + radius) * scale + offset} ${
    (Stage2LevelRect[2].top + radius * 2) * scale
  }`,
  `M ${(Stage2LevelRect[2].left + radius * 1.8) * scale + offset} ${
    (Stage2LevelRect[2].top + radius) * scale
  } Q ${(Stage2LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[3].top +
      radius * 2 +
      (Stage2LevelRect[2].top - Stage2LevelRect[3].top - radius * 2) / 2) *
    scale
  }  ${(Stage2LevelRect[3].left + radius) * scale + offset} ${
    (Stage2LevelRect[3].top + radius) * scale
  }`,
  `M ${(Stage2LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[3].top + radius) * scale
  } Q ${(Stage2LevelRect[4].left + radius) * scale + offset} ${
    Stage2LevelRect[3].top + radius * scale
  }  ${(Stage2LevelRect[4].left + radius) * scale + offset} ${
    Stage2LevelRect[4].top * scale
  }`,
  `M ${(Stage2LevelRect[4].left + radius) * scale + offset} ${
    (Stage2LevelRect[4].top + radius * 2) * scale
  } Q ${(Stage2LevelRect[4].left + radius * 0.8) * scale + offset} ${
    Stage2LevelRect[5].top * scale
  } ${Stage2LevelRect[5].left * scale + offset} ${
    (Stage2LevelRect[5].top + radius * 0.8) * scale
  }`,
  `M ${(Stage2LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[5].top + radius) * scale
  } Q ${(Stage2LevelRect[6].left + radius) * scale + offset} ${
    (Stage2LevelRect[6].top +
      radius * 2 +
      (Stage2LevelRect[5].top - Stage2LevelRect[6].top - radius * 2) / 2) *
    scale
  } ${(Stage2LevelRect[6].left + radius) * scale + offset} ${
    (Stage2LevelRect[6].top + radius * 2) * scale
  }`,
];
const Stage3LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage3LevelRect[0].left + radius * 0.3) * scale + offset} ${
    (Stage3LevelRect[0].top + radius * 2) * scale
  } Q ${Stage3LevelRect[0].left * scale + offset} ${
    (Stage3LevelRect[0].top +
      radius * 2 +
      (Stage3LevelRect[1].top - Stage3LevelRect[0].top - radius * 2) / 2) *
    scale
  } ${(Stage3LevelRect[1].left + radius * 0.3) * scale + offset} ${
    (Stage3LevelRect[1].top + radius * 0.3) * scale
  }`,
  `M ${(Stage3LevelRect[1].left + radius) * scale + offset} ${
    (Stage3LevelRect[1].top + radius * 0.3) * scale
  } Q ${
    (Stage3LevelRect[1].left +
      radius * 1.3 +
      (Stage3LevelRect[2].left - Stage3LevelRect[1].left - radius * 1.3) / 2) *
      scale +
    offset
  } ${(Stage3LevelRect[2].top + radius) * scale} ${
    Stage3LevelRect[2].left * scale + offset
  } ${(Stage3LevelRect[2].top + radius) * scale}`,
  `M ${(Stage3LevelRect[2].left + radius) * scale + offset} ${
    (Stage3LevelRect[2].top + radius * 2) * scale
  } Q ${(Stage3LevelRect[2].left + radius) * scale + offset} ${
    (Stage3LevelRect[2].top +
      radius * 2 +
      (Stage3LevelRect[3].top +
        radius * 0.3 -
        Stage3LevelRect[2].top -
        radius * 2) /
        2) *
    scale
  } ${(Stage3LevelRect[3].left + radius * 0.1) * scale + offset} ${
    (Stage3LevelRect[3].top + radius * 0.3) * scale
  }`,
  `M ${(Stage3LevelRect[3].left + radius * 1.8) * scale + offset} ${
    (Stage3LevelRect[3].top + radius * 0.3) * scale
  } Q ${(Stage3LevelRect[4].left + radius * 0.8) * scale + offset} ${
    (Stage3LevelRect[4].top +
      radius * 2 +
      (Stage3LevelRect[3].top +
        radius * 0.3 -
        Stage3LevelRect[4].top -
        radius * 2) /
        2) *
    scale
  }  ${(Stage3LevelRect[4].left + radius * 0.8) * scale + offset} ${
    (Stage3LevelRect[4].top + radius * 2) * scale
  }`,
  `M ${(Stage3LevelRect[4].left + radius * 2) * scale + offset} ${
    (Stage3LevelRect[4].top + radius) * scale
  } Q ${Stage3LevelRect[5].left * scale + offset} ${
    (Stage3LevelRect[4].top + radius) * scale
  } ${(Stage3LevelRect[5].left + radius * 0.3) * scale + offset} ${
    (Stage3LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage3LevelRect[5].left + radius * 1.5) * scale + offset} ${
    Stage3LevelRect[5].top * scale
  } Q ${(Stage3LevelRect[5].left + radius * 1.7) * scale + offset} ${
    (Stage3LevelRect[6].top +
      radius * 2 +
      (Stage3LevelRect[5].top - Stage3LevelRect[6].top - radius * 2) / 2) *
    scale
  } ${(Stage3LevelRect[6].left + radius * 1.3) * scale + offset} ${
    (Stage3LevelRect[6].top + radius * 2) * scale
  }`,
];
const Stage4LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage4LevelRect[0].left + radius * 0.3) * scale + offset} ${
    (Stage4LevelRect[0].top + radius * 0.3) * scale
  } Q ${(Stage4LevelRect[0].left + radius * 0.3) * scale + offset} ${
    (Stage4LevelRect[1].top + radius * 2) * scale
  } ${Stage4LevelRect[1].left * scale + offset} ${
    (Stage4LevelRect[1].top + radius) * scale
  }`,
  `M ${(Stage4LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[1].top + radius) * scale
  } Q ${(Stage4LevelRect[2].left + radius) * scale + offset} ${
    (Stage4LevelRect[1].top + radius * 1.3) * scale
  } ${(Stage4LevelRect[2].left + radius) * scale + offset} ${
    Stage4LevelRect[2].top * scale
  }`,
  `M ${(Stage4LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[2].top + radius) * scale
  } Q ${Stage4LevelRect[3].left * scale + offset} ${
    (Stage4LevelRect[2].top + radius * 0.3) * scale
  } ${(Stage4LevelRect[3].left + radius * 0.3) * scale + offset} ${
    (Stage4LevelRect[3].top + radius * 2) * scale
  }`,
  `M ${(Stage4LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[3].top + radius) * scale
  } Q ${
    (Stage4LevelRect[3].left +
      radius * 2 +
      (Stage4LevelRect[4].left +
        radius * 0.3 -
        Stage4LevelRect[3].left -
        radius * 2) /
        2) *
      scale +
    offset
  } ${Stage4LevelRect[4].top * scale}  ${
    (Stage4LevelRect[4].left + radius * 0.3) * scale + offset
  } ${(Stage4LevelRect[4].top + radius * 0.3) * scale}`,
  `M ${(Stage4LevelRect[4].left + radius) * scale + offset} ${
    (Stage4LevelRect[4].top + radius * 2) * scale
  } Q ${Stage4LevelRect[4].left * scale + offset} ${
    (Stage4LevelRect[4].top +
      radius * 2 +
      (Stage4LevelRect[5].top +
        radius * 0.3 -
        Stage4LevelRect[4].top -
        radius * 2) /
        2) *
    scale
  } ${(Stage4LevelRect[5].left + radius * 1.3) * scale + offset} ${
    (Stage4LevelRect[5].top + radius * 0.3) * scale
  }`,
  `M ${(Stage4LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[5].top + radius) * scale
  } Q ${
    (Stage4LevelRect[5].left +
      radius * 2 +
      (Stage4LevelRect[6].left +
        radius * 0.3 -
        Stage4LevelRect[5].left +
        radius * 2) /
        2) *
      scale +
    offset
  } ${(Stage4LevelRect[5].top + radius) * scale} ${
    (Stage4LevelRect[6].left + radius * 0.3) * scale + offset
  } ${(Stage4LevelRect[6].top + radius * 0.3) * scale}`,
];
const Stage5LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage5LevelRect[0].left + radius) * scale + offset} ${
    (Stage5LevelRect[0].top + radius * 2) * scale
  } Q ${(Stage5LevelRect[0].left + radius) * scale + offset} ${
    (Stage5LevelRect[0].top +
      radius * 2 +
      (Stage5LevelRect[1].top - Stage5LevelRect[0].top - radius * 2) / 2) *
    scale
  } ${(Stage5LevelRect[1].left + radius) * scale + offset} ${
    Stage5LevelRect[1].top * scale
  }`,
  `M ${(Stage5LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[1].top + radius) * scale
  } Q ${
    (Stage5LevelRect[1].left +
      radius * 2 +
      (Stage5LevelRect[2].left - Stage5LevelRect[1].left - radius * 2) / 2) *
      scale +
    offset
  } ${(Stage5LevelRect[1].top + radius) * scale} ${
    Stage5LevelRect[2].left * scale + offset
  } ${(Stage5LevelRect[2].top + radius) * scale}`,
  `M ${(Stage5LevelRect[2].left + radius * 0.3) * scale + offset} ${
    (Stage5LevelRect[2].top + radius * 0.3) * scale
  } Q ${(Stage5LevelRect[3].left + radius) * scale + offset} ${
    (Stage5LevelRect[3].top +
      radius * 2 +
      (Stage5LevelRect[2].top +
        radius * 0.3 -
        Stage5LevelRect[3].top -
        radius * 2) /
        2) *
    scale
  }  ${(Stage5LevelRect[3].left + radius) * scale + offset} ${
    Stage5LevelRect[3].top * scale
  }`,
  `M ${(Stage5LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[3].top + radius) * scale
  } Q ${
    (Stage5LevelRect[3].left +
      radius * 2 +
      (Stage5LevelRect[4].left - Stage5LevelRect[3].left - radius * 2) / 2) *
      scale +
    offset
  } ${(Stage5LevelRect[4].top + radius) * scale}  ${
    Stage5LevelRect[4].left * scale + offset
  } ${(Stage5LevelRect[4].top + radius) * scale}`,
  `M ${(Stage5LevelRect[4].left + radius) * scale + offset} ${
    (Stage5LevelRect[4].top + radius * 2) * scale
  } Q ${(Stage5LevelRect[4].left + radius) * scale + offset} ${
    (Stage5LevelRect[4].top +
      radius * 2 +
      (Stage5LevelRect[5].top +
        radius * 0.3 -
        Stage5LevelRect[4].top -
        radius * 2) /
        2) *
    scale
  } ${(Stage5LevelRect[5].left + radius * 0.3) * scale + offset} ${
    (Stage5LevelRect[5].top + radius * 0.3) * scale
  }`,
  `M ${(Stage5LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[5].top + radius) * scale
  } Q ${(Stage5LevelRect[6].left + radius) * scale + offset} ${
    (Stage5LevelRect[6].top +
      radius * 2 +
      (Stage5LevelRect[5].top + radius - Stage5LevelRect[6].top - radius * 2) /
        2) *
    scale
  } ${(Stage5LevelRect[6].left + radius) * scale + offset} ${
    (Stage5LevelRect[6].top + radius * 2) * scale
  }`,
];
const Stage6LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage6LevelRect[0].left + radius) * scale + offset} ${
    (Stage6LevelRect[0].top + radius * 2) * scale
  } Q ${(Stage6LevelRect[0].left + radius) * scale + offset} ${
    (Stage6LevelRect[0].top +
      radius * 2 +
      (Stage6LevelRect[1].top - Stage6LevelRect[0].top - radius * 2) / 2) *
    scale
  } ${(Stage6LevelRect[1].left + radius) * scale + offset} ${
    Stage6LevelRect[1].top * scale
  }`,
  `M ${(Stage6LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[1].top + radius * 1.3) * scale
  } Q ${Stage6LevelRect[2].left * scale + offset} ${
    (Stage6LevelRect[1].top + radius * 1.3) * scale
  } ${(Stage6LevelRect[2].left + radius) * scale + offset} ${
    (Stage6LevelRect[2].top + radius * 2) * scale
  }`,
  `M ${(Stage6LevelRect[2].left + radius * 1.3) * scale + offset} ${
    (Stage6LevelRect[2].top + radius * 0.3) * scale
  } Q ${
    (Stage6LevelRect[2].left +
      radius * 1.3 +
      (Stage6LevelRect[3].left - Stage6LevelRect[2].left - radius * 1.3) / 2) *
      scale +
    offset
  } ${(Stage6LevelRect[3].top + radius) * scale} ${
    Stage6LevelRect[3].left * scale + offset
  } ${(Stage6LevelRect[3].top + radius) * scale}`,
  `M ${(Stage6LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[3].top + radius) * scale
  } Q ${(Stage6LevelRect[4].left + radius * 1.3) * scale + offset} ${
    (Stage6LevelRect[3].top + radius * 1.3) * scale
  }  ${(Stage6LevelRect[4].left + radius * 1.3) * scale + offset} ${
    Stage6LevelRect[4].top * scale
  }`,
  `M ${(Stage6LevelRect[4].left + radius) * scale + offset} ${
    (Stage6LevelRect[4].top + radius * 2) * scale
  } Q ${(Stage6LevelRect[4].left + radius) * scale + offset} ${
    (Stage6LevelRect[4].top +
      radius * 2 +
      (Stage6LevelRect[5].top + radius - Stage6LevelRect[4].top - radius * 2) /
        2) *
    scale
  } ${Stage6LevelRect[5].left * scale + offset} ${
    (Stage6LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage6LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[5].top + radius) * scale
  } Q ${(Stage6LevelRect[6].left + radius) * scale + offset} ${
    (Stage6LevelRect[6].top +
      radius * 2 +
      (Stage6LevelRect[5].top + radius - Stage6LevelRect[6].top - radius * 2) /
        2) *
    scale
  } ${(Stage6LevelRect[6].left + radius) * scale + offset} ${
    (Stage6LevelRect[6].top + radius * 2) * scale
  }`,
];

export const StageLevelPaths = [
  Stage1LevelPath,
  Stage2LevelPath,
  Stage3LevelPath,
  Stage4LevelPath,
  Stage5LevelPath,
  Stage6LevelPath,
];
