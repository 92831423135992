import produce from "immer";
import {
  ComponentType,
  ImageStyleProps,
  DefaultImageComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Stamp: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultImageComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_STAMP";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_STAMP";

    const initialWidth = 100;
    const initialHeight = 100;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultImage = ImageStyleProps;
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderRadius = 0;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.image = ImageStyleProps;
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderRadius = 0;

    draft.events = ["stamp_click_event"];
  }),
};

export default Stamp;
