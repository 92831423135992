import Blockly from "blockly";
import { theme } from "common/blockly";
import Constants from "common/constant";

export const createPreviewBlocklyOptions = (
  scale: number,
  toolbox: Blockly.utils.toolbox.ToolboxDefinition
) => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: true,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/game/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  trashcan: true,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});
