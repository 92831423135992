import { useMemo, useState, useEffect, PointerEvent } from "react";
import { useDrag } from "react-dnd";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyImage } from "react-dnd-html5-backend";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { IconEraser } from "features/creator/assets";
import { actions, selectAllMapModel } from "features/builder/slice";
import { AssetModel, AssetModelType } from "features/builder/types";

export const Asset = ({
  selected,
  asset,
  select,
  disabled,
}: {
  selected: boolean;
  asset?: AssetModel;
  select?: (asset: AssetModel) => void;
  disabled?: boolean;
}) => {
  const [click, setClick] = useState(false);
  const onClickStart = () => {
    setClick(true);
  };
  const onClickEnd = (e: PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (click && select && !disabled) {
      select(asset);
      setClick(false);
    }
  };
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: asset.type,
    item: asset,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (isDragging) {
      setClick(false);
      if (select && !disabled) {
        select(asset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  return (
    <div
      ref={select && !disabled ? drag : null}
      onPointerDown={onClickStart}
      onPointerUp={onClickEnd}
      className={`flex-col-el flex-center relative cursor-pointer shrink-0 w-[100px] h-[100px] rounded border-solid border-[2px] ${
        selected ? "border-blue/100" : "border-transparent"
      } ${disabled ? "bg-gray/40" : "bg-transparent"}`}
    >
      <img
        alt="asset capture"
        draggable="false"
        className="w-full h-full select-none"
        src={`${Constants.assetHost}/assets/models/${asset.name}.png`}
      />
    </div>
  );
};

export const ModelAsset = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const allMapModel = useSelector(selectAllMapModel);
  const actionMenu = useSelector(
    (state: RootState) => state.builder.actionMenu
  );
  const selectedSlotIndex = useSelector(
    (state: RootState) => state.builder.selectedSlotIndex
  );
  const selectedAssetModel = useSelector(
    (state: RootState) => state.builder.selectedAssetModel
  );
  const slot = useSelector((state: RootState) => state.builder.slot);

  const gem = useMemo(
    () => ({
      type: AssetModelType.GEM,
      name: "diamond_teamRed",
      filename: "diamond_teamRed.gltf.glb",
    }),
    []
  );
  const character = useMemo(
    () => ({
      type: AssetModelType.CHARACTER,
      name: "character_duck",
      filename: "character_duck.glb",
      rotation: true,
      offset: { x: 0, y: -0.4, z: 0 },
      scaling: { x: 1.2, y: 1.2, z: 1.2 },
    }),
    []
  );

  const handleSelectTap = () => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "tap", value: true, exclusive: true })
    );
    dispatch(actions.selectSlot(null));
    dispatch(actions.selectedAssetMesh(null));
  };
  const handleSelectEraser = () => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "eraser", value: true, exclusive: true })
    );
    dispatch(actions.selectSlot(null));
    dispatch(actions.selectedAssetMesh(null));
  };
  const handleSelectAsset = (slot?: number) => (asset: AssetModel) => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "asset", value: true, exclusive: true })
    );
    dispatch(actions.selectedAssetMesh(asset));
    if (slot) {
      handleSelectSlot(slot)();
    } else {
      dispatch(actions.selectSlot(null));
    }
  };
  const handleSelectSlot = (index: number) => () => {
    dispatch(actions.selectSlot(index));
    if (!slot[index]) {
      play();
      dispatch(
        actions.updateActionMenu({
          key: "assetList",
          value: true,
          exclusive: true,
        })
      );
      dispatch(actions.selectedAssetMesh(null));
    }
  };

  return createPortal(
    <div
      style={{
        transform: `scale(${scale})`,
        left: 12 * scale,
        bottom: 12 * scale,
        zIndex: actionMenu.assetList ? 10000 : 1,
      }}
      className="flex-col-el absolute origin-bottom-left pointer-events-auto"
    >
      <div className="flex-row-el border-solid rounded-[10px] border-[2px] border-white bg-white/60 backdrop-blur-[4px] p-3">
        <div className="flex-row-el h-[100px] gap-3">
          <div
            onClick={handleSelectTap}
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              actionMenu.tap ? "border-blue/100" : "border-white"
            }`}
          >
            <div className="flex-col-center w-[48px] h-[48px] text-textcolor/black">
              <TouchAppOutlinedIcon fontSize="large" />
            </div>
          </div>

          <div
            onClick={handleSelectEraser}
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              actionMenu.eraser ? "border-blue/100" : "border-white"
            }`}
          >
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconEraser />
            </div>
          </div>

          <div
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              character?.name === selectedAssetModel?.name
                ? "border-blue/100"
                : "border-white"
            }`}
          >
            <Asset
              disabled={
                allMapModel.filter((m) => m.type === AssetModelType.CHARACTER)
                  .length > 0
              }
              selected={character?.name === selectedAssetModel?.name}
              asset={character}
              select={handleSelectAsset()}
            />
          </div>

          <div
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              gem?.name === selectedAssetModel?.name
                ? "border-blue/100"
                : "border-white"
            }`}
          >
            <Asset
              selected={gem?.name === selectedAssetModel?.name}
              asset={gem}
              select={handleSelectAsset()}
            />
          </div>

          {slot.map((value, index) => (
            <div
              key={`${value?.name}_${index}`}
              onClick={handleSelectSlot(index)}
              className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
                selectedSlotIndex === index ? "border-blue/100" : "border-white"
              }`}
            >
              {value && (
                <Asset
                  key={index}
                  selected={value?.name === selectedAssetModel?.name}
                  asset={value}
                  select={handleSelectAsset(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};
