import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { actions } from "features/course/slice";
import { IconDrawer } from "features/creator/assets";
import { CourseTab } from "./constant";
import { CourseList } from "./CourseList";
import { Pagination } from "./Pagination";

export const Collection = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const { ratio, scale } = useScale();
  const [tab, setTab] = useState(CourseTab.COURSE);
  const showCourseList = useSelector(
    (state: RootState) => state.course.showCourseList
  );

  const handleClick = (newTab: CourseTab) => () => {
    play();
    if (showCourseList) {
      if (newTab === tab) {
        dispatch(actions.updateShowCourseList(false));
      } else {
        setPage(0);
        setTab(newTab);
      }
    } else {
      setTab(newTab);
      dispatch(actions.updateShowCourseList(true));
    }
  };

  return (
    <div
      style={{ transform: `scale(${scale}) translateX(1010px)` }}
      className={`flex-row-el items-start absolute bottom-0 right-0 will-change-auto ${
        ratio > 1 ? "origin-bottom-right" : "origin-right"
      }`}
    >
      <div className="flex-col-el -mr-4 mt-8">
        <div
          onClick={handleClick(CourseTab.COURSE)}
          className="flex-col-el shrink-0 z-50 flex-center relative w-[56px] h-full rounded-l-[24px] py-3 gap-4 cursor-pointer pointer-events-auto bg-orange/100"
        >
          <div className="flex-col-el flex-center">
            <p className="text-body text-white text-[28px] w-full leading-[32px] [writing-mode:vertical-rl] tracking-widest origin-center">
              {I18n.t("MSG_COURSE_TAG_TITLE")}
            </p>
          </div>

          <div>
            <IconDrawer />
          </div>
        </div>
      </div>

      <div className="flex-col-el relative">
        <div className="flex-col-center w-[1024px] h-[780px]">
          <img
            className="w-full h-full"
            src={`${Constants.assetHost}/assets/images/course_top_collection_bg.png`}
          />
        </div>

        <CourseList tab={tab} page={page} />
      </div>

      <Pagination tab={tab} page={page} setPage={setPage} />
    </div>
  );
};
