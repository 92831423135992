import "@babylonjs/loaders/glTF/2.0/index";
import {
  SceneLoader,
  ISceneLoaderAsyncResult,
} from "@babylonjs/core/Loading/sceneLoader";
import { Scene } from "@babylonjs/core/scene";
import "@babylonjs/core/Animations/animatable";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import {
  MapTile,
  AssetModelType,
  TileSetMapType,
  createOuterMesh,
  calCharacterDirection,
} from "common/model";
import tiles from "common/model/tiles";
import Constants from "common/constant";
import { Vector3D } from "common/types";

export const loadMesh = (
  scene: Scene,
  filename: string,
  offset?: Vector3D
): Promise<Mesh> =>
  new Promise(async (resolve, reject) => {
    const handleImportMesh = (result: ISceneLoaderAsyncResult) => {
      const mesh = result.meshes[0] as Mesh;
      const boundingInfo = mesh.getHierarchyBoundingVectors();
      mesh.position = new Vector3(
        0,
        (boundingInfo.min.y < 0 ? -boundingInfo.min.y : 0) + (offset?.y ?? 0),
        0
      );
      mesh.setBoundingInfo(
        new BoundingInfo(
          boundingInfo.min.y < 0
            ? new Vector3(boundingInfo.min.x, 0, boundingInfo.min.z)
            : boundingInfo.min,
          boundingInfo.min.y < 0
            ? new Vector3(
                boundingInfo.max.x,
                boundingInfo.max.y - boundingInfo.min.y + (offset?.y ?? 0),
                boundingInfo.max.z
              )
            : boundingInfo.max
        )
      );

      for (let i = 0; i < result.meshes.length; i++) {
        const subMesh = result.meshes[i];
        if (subMesh.material) {
          // @ts-ignore
          subMesh.material.specularIntensity = 0.3;
        }
      }

      resolve(mesh);
    };

    SceneLoader.ImportMeshAsync(
      "",
      `${Constants.assetHost}/assets/models/`,
      filename,
      scene
    )
      .then((result) => {
        handleImportMesh(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

const convertTitleSetToMap = (tileSetMap: TileSetMapType) => {
  const mapTileSets: MapTile[] = [];
  const layers = tileSetMap.landscape.length;
  const height = tileSetMap.landscape[0].length;
  const width = tileSetMap.landscape[0][0].length;

  for (let l = 0; l < layers; l++) {
    for (let z = 0; z < height; z++) {
      for (let x = 0; x < width; x++) {
        const tileTypeId = tileSetMap.landscape[l][z][x];
        const tile = tiles[tileTypeId];
        if (tile.type === AssetModelType.NONE) continue;

        let direction: Vector3D;
        if (tile.type === AssetModelType.CHARACTER) {
          direction = calCharacterDirection[tileSetMap.character.direction];
        } else {
          direction = tile.direction;
        }

        var modelPosition: Vector3 = Vector3.Zero();
        for (let i = 0; i < l; i++) {
          const mapTileTypeId = tileSetMap.landscape[i][z][x];
          const mapTile = tiles[mapTileTypeId];
          if (mapTile.type === AssetModelType.NONE) continue;
          const size = new Vector3(0, mapTile.size?.y ?? 2, 0);
          modelPosition = modelPosition.add(size);
        }

        if (tile.type === AssetModelType.CHARACTER) {
          for (let i = 1; i < 4; i++) {
            const characterModel = tiles[i];
            mapTileSets.push({
              position: {
                x: (width - x) * 2 - 1,
                y: modelPosition.y,
                z: z * 2 + 1,
              },
              direction: direction,
              name: characterModel.name,
              filename: characterModel.filename,
              type: characterModel.type,
              offset: characterModel.offset,
              scaling: characterModel.scaling,
              metadata: characterModel.metadata,
            });
          }
        } else {
          mapTileSets.push({
            position: {
              x: (width - x) * 2 - 1,
              y: modelPosition.y,
              z: z * 2 + 1,
            },
            direction: direction,
            name: tile.name,
            filename: tile.filename,
            type: tile.type,
            offset: tile.offset,
            scaling: tile.scaling,
            metadata: tile.metadata,
          });
        }
      }
    }
  }

  return mapTileSets;
};

export const loadMap = async (scene: Scene, tileSet: TileSetMapType) => {
  const mapTileSets = convertTitleSetToMap(tileSet);

  const gems: AbstractMesh[] = [];
  const goals: AbstractMesh[] = [];
  const characters: AbstractMesh[] = [];
  for (let i = 0; i < mapTileSets.length; i++) {
    const model = mapTileSets[i];
    const position = new Vector3(
      model.position.x,
      model.position.y,
      model.position.z
    );
    const mesh = await loadMesh(scene, model.filename, model.offset);
    mesh.getChildMeshes().forEach((subMesh) => {
      if (subMesh.name.indexOf("Cube") !== -1) {
        subMesh.isVisible = false;
      }
    });
    const boundingInfo = mesh.getBoundingInfo();
    const outer = createOuterMesh(scene, boundingInfo.boundingBox, position);
    outer.scaling = new Vector3(
      model.scaling?.x ?? 1,
      model.scaling?.y ?? 1,
      model.scaling?.z ?? 1
    );
    outer.rotation = new Vector3(
      0,
      model.direction
        ? Math.atan2(Number(model.direction.x), Number(model.direction.z))
        : 0,
      0
    );

    mesh.parent = outer;
    if (model.type === AssetModelType.CHARACTER) {
      outer.name = model.name;
      outer.setEnabled(false);
      characters.push(outer);
      scene.getAnimationGroupByName("ArmLeftAction")?.stop();
      scene.getAnimationGroupByName("ArmRightAction")?.stop();
      scene.getAnimationGroupByName("BodyAction")?.play(true);
    } else if (model.type === AssetModelType.GOAL) {
      outer.name = model.name;
      goals.push(outer);
    } else if (model.type === AssetModelType.GEM) {
      outer.name = model.name;
      gems.push(outer);
    } else if (model.type === AssetModelType.CONDITION) {
      outer.name = AssetModelType.CONDITION;
      outer.metadata = model.metadata;
    } else {
      outer.name = `${model.type}_asset`;
    }
  }

  return { characters, gems, goals };
};
