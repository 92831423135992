import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep } from "common/utils";
import { MAP_MESH_SIZE } from "features/builder/types";

export const performFalling = async (
  scene: Scene,
  character: AbstractMesh,
  fallingSound?: () => void,
  fastForward?: boolean
) => {
  const jumpStep = 3;
  const jumpHeight = 0.3;
  const frames = jumpStep * 2;

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  const characterMove = new Animation(
    "move",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: character.position
        .multiply(new Vector3(10, 10, 10))
        .add(
          new Vector3(
            ((i * (MAP_MESH_SIZE * character.forward.x)) / (jumpStep * 2)) * 10,
            jumpHeight * (i % 2) * 10,
            ((i * (MAP_MESH_SIZE * character.forward.z)) / (jumpStep * 2)) * 10
          )
        )
        .divide(new Vector3(10, 10, 10)),
    });
  }

  characterMove.setKeys(animationKeys);
  const characterMoveAnimation = scene.beginDirectAnimation(
    character,
    [characterMove],
    0,
    frames,
    false,
    fastForward ? 2 : 1
  );
  await characterMoveAnimation.waitAsync();

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  const characterFalling = new Animation(
    "characterFalling",
    "position.y",
    1,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const characterFallingFrames = [];
  characterFallingFrames.push({
    frame: 0,
    value: 0,
  });
  characterFallingFrames.push({
    frame: 1,
    value: -10,
  });

  characterFalling.setKeys(characterFallingFrames);
  const characterFallingAnimation = scene.beginDirectAnimation(
    character,
    [characterFalling],
    0,
    2,
    false,
    fastForward ? 2 : 1
  );
  if (fallingSound) {
    console.log("falling sound.");
    fallingSound();
  }
  await characterFallingAnimation.waitAsync();
};

export const performAppearance = async (
  scene: Scene,
  character: AbstractMesh,
  fastForward?: boolean
) => {
  const currentPosition = new Vector3().copyFrom(character.position);
  character.position.addInPlace(new Vector3(0, 10, 0));
  await sleep(300);
  character.setEnabled(true);

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  const characterFalling = new Animation(
    "characterFalling",
    "position.y",
    1,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const characterFallingFrames = [];
  characterFallingFrames.push({
    frame: 0,
    value: currentPosition.y + 10,
  });
  characterFallingFrames.push({
    frame: 1,
    value: currentPosition.y,
  });

  characterFalling.setKeys(characterFallingFrames);
  const characterFallingAnimation = scene.beginDirectAnimation(
    character,
    [characterFalling],
    0,
    2,
    false,
    fastForward ? 2 : 1
  );
  await characterFallingAnimation.waitAsync();
};
