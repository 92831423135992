import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Vector3D } from "common/types";
import {
  MapType,
  MapModel,
  BlockType,
  AssetModel,
  BuilderState,
  AssetModelType,
} from "features/builder/types";
import { ProjectEntity } from "features/creator/types";
import { getMapProjectContent } from "features/builder/api";

export const mapModelAdapter = createEntityAdapter<MapModel>();
export const {
  selectAll: selectAllMapModel,
  selectById: selectMapModelById,
  selectIds: selectMapModelIds,
} = mapModelAdapter.getSelectors((state: RootState) => state.builder.map);

const initialState: BuilderState = {
  loading: true,
  editingProjectId: null,
  project: null,
  size: { x: 8, y: 8, z: 8 },
  type: MapType.ALGORITHM,
  map: mapModelAdapter.getInitialState(),
  slot: Array(5).fill(null),
  blocks: {},
  undoStacks: [],
  redoStacks: [],
  actionMenu: {
    tap: true,
    asset: false,
    eraser: false,
    preview: false,
    assetList: false,
  },
  selectedSlotIndex: null,
  selectedAssetModel: null,
};

const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    updateLoading(state: BuilderState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateProject(state: BuilderState, action: PayloadAction<ProjectEntity>) {
      state.project = action.payload;
    },
    resetActionMenu(state: BuilderState) {
      Object.keys(state.actionMenu).forEach(
        (k) => (state.actionMenu[k] = false)
      );
      state.selectedSlotIndex = null;
      state.selectedAssetModel = null;
    },
    updateActionMenu(
      state: BuilderState,
      action: PayloadAction<{
        key: string;
        value: boolean;
        exclusive?: boolean;
      }>
    ) {
      const { key, value, exclusive } = action.payload;
      state.actionMenu[key] = value;
      if (exclusive) {
        Object.keys(state.actionMenu)
          .filter((k) => k != key)
          .forEach((k) => (state.actionMenu[k] = false));
      }
      if (key !== "eraser") {
        //　消しゴムが危険なので、絶対ずっと選択させる状態を避けるべき
        state.actionMenu.eraser = false;
      }
    },
    addModelToMap(state: BuilderState, action: PayloadAction<MapModel>) {
      mapModelAdapter.addOne(state.map, action.payload);
    },
    updateMapModel(
      state: BuilderState,
      action: PayloadAction<{
        id: string;
        changes: { [item: string]: string | Vector3D };
      }>
    ) {
      mapModelAdapter.updateOne(state.map, {
        id: action.payload.id,
        changes: action.payload.changes,
      });
    },
    removeModelFromMap(state: BuilderState, action: PayloadAction<string>) {
      mapModelAdapter.removeOne(state.map, action.payload);
    },
    updateSlot(state: BuilderState, action: PayloadAction<AssetModel[]>) {
      state.slot = action.payload;
    },
    selectSlot(state: BuilderState, action: PayloadAction<number | null>) {
      state.selectedSlotIndex = action.payload;
    },
    selectedAssetMesh(
      state: BuilderState,
      action: PayloadAction<AssetModel | null>
    ) {
      state.selectedAssetModel = action.payload;
    },
    updateBlocks(
      state: BuilderState,
      action: PayloadAction<{
        kind: string;
        type: BlockType;
        count: number;
        [key: string]: any;
      }>
    ) {
      state.blocks[action.payload.type] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMapProjectContent.fulfilled,
      (state: BuilderState, action: PayloadAction<any>) => {
        state.loading = true;
        state.slot = [
          {
            type: AssetModelType.CONDITION,
            name: "button_teamBlue",
            filename: "button_teamBlue.gltf.glb",
          },
          {
            type: AssetModelType.RIVER,
            name: "tileMedium_teamBlue",
            filename: "tileMedium_teamBlue.gltf.glb",
          },
          {
            type: AssetModelType.GROUND,
            name: "tileHigh_forest",
            filename: "tileHigh_forest.gltf.glb",
          },
          null,
          null,
        ];
        state.undoStacks = [];
        state.redoStacks = [];
        state.actionMenu = {
          tap: true,
          asset: false,
          eraser: false,
          preview: false,
          assetList: false,
        };

        state.selectedSlotIndex = null;
        state.selectedAssetModel = null;

        state.editingProjectId = action.payload.project_id;
        const { content } = action.payload;
        state.type = content.type;
        state.size = content.size;
        state.blocks = content.blocks;
        mapModelAdapter.setAll(state.map, content.map);
      }
    );
  },
});

export const actions = { ...builderSlice.actions };

export default builderSlice.reducer;
