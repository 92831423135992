import { sleep } from "common/utils";
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

const performRotation = async (
  scene: Scene,
  character: AbstractMesh,
  direction: Vector3,
  fastForward?: boolean
) => {
  const characterRotation = new Animation(
    "rotation",
    "rotation.y",
    2,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const animationKeys = [];
  animationKeys.push({
    frame: 0,
    value: character.rotation.y,
  });
  animationKeys.push({
    frame: 1,
    value: character.rotation.y + direction.y,
  });
  characterRotation.setKeys(animationKeys);
  const animation = scene.beginDirectAnimation(
    character,
    [characterRotation],
    0,
    1,
    false,
    fastForward ? 2 : 1
  );
  await animation.waitAsync();
  await sleep(1000 / (fastForward ? 2 : 1));
};

export const performTurnToLeft = async (
  scene: Scene,
  character: AbstractMesh,
  fastForward?: boolean
) => {
  await performRotation(
    scene,
    character,
    new Vector3(0, -Math.PI / 2, 0),
    fastForward
  );
};

export const performTurnToRight = async (
  scene: Scene,
  character: AbstractMesh,
  fastForward?: boolean
) => {
  await performRotation(
    scene,
    character,
    new Vector3(0, Math.PI / 2, 0),
    fastForward
  );
};
