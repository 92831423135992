import { useScale } from "common/utils";

export const ActionButton = ({
  fast,
  handleFastForward,
}: {
  fast: boolean;
  handleFastForward: () => void;
}) => {
  const { scale } = useScale();
  return (
    <div
      style={{ transform: `scale(${scale})` }}
      className="flex-col-el flex-center absolute right-24 bottom-5 origin-top  pointer-events-auto z-[10000]"
    >
      <div className="flex-row-el flex-center relative upsilon">
        <button
          onClick={handleFastForward}
          className={`shrink-0 btn ${
            fast ? "btn-blue-outlined-active" : "btn-blue-outlined"
          }`}
        >
          <div className="flex-row-center">
            <span
              className={`material-symbols-outlined text-[32px] ${
                fast ? "text-white" : "text-blue"
              }`}
            >
              fast_forward
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};
