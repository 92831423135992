import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";
import Constants from "common/constant";
import { isDebugMode, usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/course/slice";
import { PageCover } from "./PageCover";
import { CourseCardSlot } from "./CourseCardSlot";
import { courses, CourseTab, PAGE_LIMIT } from "./constant";

export const CourseList = ({ tab, page }: { tab: CourseTab; page: number }) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [animation, setAnimation] = useState(false);
  const selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  );
  const showCourseList = useSelector(
    (state: RootState) => state.course.showCourseList
  );
  const config = useSelector((state: RootState) => state.config.appConfig);
  const [localSelectedCourse, setLocalSelectedCourse] = useState<Course | null>(
    null
  );

  const cards = useMemo(
    () =>
      courses(isDebugMode, config?.enable_lab ?? false)
        [tab].filter((course) => course.show)
        .slice(page * PAGE_LIMIT, PAGE_LIMIT * (page + 1)),
    [tab, page, isDebugMode, config]
  );

  useEffect(() => {
    setLocalSelectedCourse(null);
  }, [tab]);

  useEffect(() => {
    setLocalSelectedCourse(null);
  }, [page]);

  useEffect(() => {
    if (!showCourseList) {
      setTimeout(() => setLocalSelectedCourse(null), 300);
      anime({
        targets: `#courses-soft-card-${selectedCourse}`,
        opacity: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished;
      anime({
        targets: `#courses-soft-card-${localSelectedCourse}`,
        translateY: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished;
    } else {
      setLocalSelectedCourse(selectedCourse);
    }
  }, [showCourseList]);

  const handleSelectCourse = (course: Course) => async () => {
    if (animation) return;
    play();
    setAnimation(true);
    if (localSelectedCourse === course) {
      Promise.all([
        anime({
          targets: `#courses-soft-card-${localSelectedCourse}`,
          opacity: 1,
          translateY: 0,
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
        anime({
          targets: `#courses-soft-card-${course}`,
          translateY: -25,
          opacity: [1, 0],
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
      ]).finally(() => {
        setAnimation(false);
        dispatch(actions.updateSelectedCourse({ course }));
        dispatch(actions.updateShowCourseList(false));
      });
    } else {
      Promise.all([
        anime({
          targets: `#courses-soft-card-${localSelectedCourse}`,
          opacity: 1,
          translateY: 0,
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
        anime({
          targets: `#courses-soft-card-${course}`,
          translateY: [0, -15],
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
      ]).finally(() => {
        setAnimation(false);
        setLocalSelectedCourse(course);
      });
      anime({
        targets: "#course-cover",
        opacity: [1, 0],
        duration: 400,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        anime({
          targets: "#course-cover",
          opacity: [0, 1],
          duration: 400,
          easing: "easeInOutQuad",
        });
      });
    }
  };

  const handleSelectCourseByCover = () => {
    if (animation) return;
    play();
    setAnimation(true);
    if (localSelectedCourse === selectedCourse) {
      setAnimation(false);
      dispatch(actions.updateShowCourseList(false));
    } else {
      Promise.all([
        anime({
          targets: `#courses-soft-card-${selectedCourse}`,
          opacity: 1,
          translateY: 0,
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
        anime({
          targets: `#courses-soft-card-${localSelectedCourse}`,
          translateY: -25,
          opacity: [1, 0],
          duration: 400,
          easing: "easeInOutQuad",
        }).finished,
      ]).finally(() => {
        setAnimation(false);
        dispatch(actions.updateSelectedCourse({ course: localSelectedCourse }));
        dispatch(actions.updateShowCourseList(false));
      });
    }
  };

  return (
    <div className="flex-row-el items-center justify-around gap-5 absolute w-full h-full">
      <div className="flex-row-el flex-wrap items-start justify-start w-[450px] h-full gap-16 px-4 py-32">
        {cards.map((page) => (
          <CourseCardSlot
            key={page.course}
            selected={selectedCourse === page.course}
            course={page.course}
            name={page.name}
            onClick={handleSelectCourse(page.course)}
          />
        ))}
        {Array(PAGE_LIMIT - cards.length)
          .fill(0)
          .map((_, index) => (
            <CourseCardSlot key={index} />
          ))}
      </div>

      <div
        onClick={handleSelectCourseByCover}
        className="flex-row-el relative w-[460px] h-[740px] mb-2 cursor-pointer"
      >
        {localSelectedCourse ? (
          <img
            alt="*"
            id="course-cover"
            className="w-full h-full"
            src={`${Constants.assetHost}/assets/images/course_cover_${localSelectedCourse}.png`}
          />
        ) : cards.filter((c) => c.course === selectedCourse).length > 0 ? (
          <img
            alt="*"
            id="course-cover"
            className="w-full h-full"
            src={`${Constants.assetHost}/assets/images/course_cover_${selectedCourse}.png`}
          />
        ) : (
          <div className="flex-row-el flex-wrap items-start justify-start w-[450px] h-full gap-16 px-4 py-32">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <CourseCardSlot key={index} />
              ))}
          </div>
        )}
      </div>

      <PageCover />
    </div>
  );
};
