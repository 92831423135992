import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import { css } from "@emotion/css";
import * as Blockly from "blockly";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { Dialog, DialogCreator } from "common/blockly";
import { Toolbox } from "features/creator/blockly/Toolbox";
import { ToolboxXml } from "features/creator/blockly/toolboxXml";
import { useBlocklyInit } from "features/creator/blockly/initial";
import { TabIndex, CreatorOptions } from "features/creator/types";
import { actions, selectScreenById } from "features/creator/slice";

export const BlocklyWorkspace = ({
  options,
  tabIndex,
  setTabIndex,
  workspace,
  setWorkspace,
}: {
  options: CreatorOptions;
  tabIndex: TabIndex;
  setTabIndex: (index: TabIndex) => void;
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void;
}) => {
  const dispatch = useDispatch();
  const { width, height, scale } = useScale();
  const { margin, componentListWidth, tabHeight } = options;
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedScreen = useSelector((state: RootState) =>
    selectScreenById(state, selectedScreenId)
  );
  const collapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.blocklyWorkspace
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  useBlocklyInit(scale, config.locale, workspace, setWorkspace, setTabIndex);

  const HEADER_HEIGHT = 32;

  const WORKSPACE_WIDTH = width - 160 * scale;
  const WORKSPACE_HEIGHT =
    height - (tabHeight + margin + 4) * 2 * scale - 20 * scale;

  const TOOLBOX_WIDTH = componentListWidth > 130 ? 130 : componentListWidth;
  const TOOLBOX_HEIGHT = 12 * 40 + 32 + 4;

  useEffect(() => {
    if (workspace && collapse) {
      workspace.setVisible(false);
    } else if (workspace && !collapse) {
      workspace.setVisible(true);
    }
  }, [workspace, collapse]);

  return (
    <>
      <Dialog workspace={workspace} limitLength={20} />
      <DialogCreator workspace={workspace} />

      <div
        className="z-[1000] flex-col-view !box-border drop-shadow-normal_show rounded-[12px] bg-beige/60 border-white border-solid !absolute overflow-hidden"
        style={{
          borderWidth: 4 * scale,
          padding: 24 * scale,
          right: margin * scale,
          bottom: (tabHeight + margin + 10) * scale,
          width: WORKSPACE_WIDTH,
          height: WORKSPACE_HEIGHT,
          pointerEvents: collapse ? "none" : "auto",
          visibility: collapse ? "hidden" : "visible",
        }}
      >
        <div className="flex-row-view w-full h-full">
          <Toolbox
            scale={scale}
            width={TOOLBOX_WIDTH}
            height={TOOLBOX_HEIGHT}
            header={HEADER_HEIGHT}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            workspace={workspace}
          />

          <div className="flex-col-view !flex-1">
            <div
              className="flex-row-view w-full bg-beige/alt"
              style={{
                height: HEADER_HEIGHT * scale,
                borderTopLeftRadius: 12 * scale,
                borderTopRightRadius: 12 * scale,
              }}
            >
              <div
                className="flex-row-view w-full origin-top-left"
                style={{
                  height: HEADER_HEIGHT,
                  transform: `scale(${scale})`,
                }}
              >
                <p className="text text-white !text-left !leading-[30px] !ml-[16px]">
                  {i18n.t("MSG_CREATOR_MENU_PROPERTY_LOGIC_TITLE", {
                    name: selectedScreen.name,
                  })}
                </p>
              </div>
            </div>
            <div
              id="blocklyDiv"
              className={`flex-col-view pointer-events-auto !flex-1 ${css`
                height: calc(100% - ${HEADER_HEIGHT * scale}px);
              `}`}
            />
          </div>
        </div>

        <ToolboxXml toolboxId="toolbox" />
      </div>
      <div
        className="flex-col-view w-full h-full !absolute top-0 left-0 z-[999]"
        onPointerDown={(e) => {
          dispatch(actions.handleBlocklyWorkspaceCollapse(true));
        }}
        style={{
          pointerEvents: collapse ? "none" : "auto",
          visibility: collapse ? "hidden" : "visible",
        }}
      />
    </>
  );
};
