import { Scene } from "@babylonjs/core/scene";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import {
  IconCircleZoomIn,
  IconCircleZoomOut,
  IconCircleZoomReset,
} from "common/assets";
import { useScale, usePlaySound } from "common/utils";

export const ZoomPanel = ({ scene }: { scene: Scene }) => {
  const play = usePlaySound();
  const { width, height, scale } = useScale();

  const handleSceneZoom = (zoom: number) => () => {
    const camera = scene.getCameraByName("camera") as ArcRotateCamera;
    if (camera) {
      play();
      if (zoom > 0) {
        camera.radius -= 1;
      } else if (zoom < 0) {
        camera.radius += 1;
      } else {
        camera.radius = width > height ? 22 : 25 / scale;
        camera.alpha = Math.PI / 4;
        camera.beta = Math.PI / 4;
      }
    }
  };

  return (
    <div className="flex-col-el absolute right-[20px] bottom-[25%] gap-8 z-[200000] pointer-events-auto">
      <div
        onClick={handleSceneZoom(1)}
        className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
      >
        <IconCircleZoomIn color="#FEFDFE" />
      </div>
      <div
        onClick={handleSceneZoom(0)}
        className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
      >
        <IconCircleZoomReset color="#FEFDFE" />
      </div>
      <div
        onClick={handleSceneZoom(-1)}
        className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
      >
        <IconCircleZoomOut color="#FEFDFE" />
      </div>
    </div>
  );
};
