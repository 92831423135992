const translations = {};

translations["MSG_GUIDE"] = "ガイド";
translations["MSG_NETWORK_ERROR_MESSAGE"] =
  "ページを ひょうじすることが できませんでした。\nもういちど ためしてみてください。";
translations["MSG_ERROR_NO_USER_TITLE"] = "ユーザーがありません";
translations["MSG_ERROR_NO_USER_MESSAGE"] = "ユーザーを ついかしてください";

translations["MSG_APP_ALL"] = "すべて";
translations["MSG_APP_BANNER_TITLE"] = "アプリ版『まなんでパズル』";
translations["MSG_APP_BANNER_SBUTITLE"] = "～子供も楽しくプログラミング教育～";
translations["MSG_APP_BANNER_INSTALL_BTN"] = "インストール";
translations["MSG_APP_BANNER_SHOW_BTN"] = "表示";

translations["MSG_APP_VERSION_UPDATE_WARNING_TITLE"] =
  "アプリを最新版にアップデートします。";
translations["MSG_APP_VERSION_UPDATE_WARNING_MESSAGE"] =
  "最新版のアプリがあります。\n最新の機能を利用する為にアップデートが必要です。\nアップデートはすぐに完了します。";

translations["MSG_APP_ERROR_SIGN_IN_FAILED"] =
  "メールアドレスかパスワードが正しくありません。";
translations["MSG_APP_ERROR_EMAIL_NOT_VALID"] =
  "有効なメールアドレスを入力してください。";
translations["MSG_APP_ERROR_EMAIL_ALREADY_IN_USE"] =
  "入力されたメールアドレスは既に使用されています。";
translations["MSG_APP_ERROR_PASSWORD_TOO_SHORT"] =
  "6文字以上のパスワードを入力してください。";
translations["MSG_APP_ERROR_PASSWORD_CONFIRM_NOT_VALID"] =
  "パスワードが一致していません。";

translations["MSG_SIGN_WITH_LINKED_PARENTS_ACCOUNT_NOTICE"] =
  "この端末のユーザーは既に下記のアカウントにリンクしているため、下記のアカウントでのみログインが可能です。";
translations["MSG_SIGN_WITH_LINKED_PARENTS_SSO_ACCOUNT_NOTICE"] =
  "この端末のユーザーは下記の他のサービスのアカウントでログイン連携されています。";
translations["MSG_SIGN_NOTICE"] =
  "お持ちのまなんでパズルアカウントにログインするか、アカウントを新しく作成してください";
translations["MSG_SIGN_INSTITUTION_NOTICE"] =
  "※ 教育機関の関係者の方\n（教育機関コードをお持ちの方）は\n下記よりログインしてください。";
translations["MSG_SIGN_NOT_SIGN_IN"] = "※アカウントをお持ちでない方";
translations["MSG_SIGN_ALREADY_SIGN_IN"] = "※すでにアカウントをお持ちの方";
translations["MSG_SIGN_IN_BTN"] = "ログイン";
translations["MSG_SIGN_UP_BTN"] = "新規登録";
translations["MSG_SIGN_IN_ACCOUNT_BTN"] = "アカウントログイン";
translations["MSG_SIGN_UP_ACCOUNT_BTN"] = "アカウント登録";
translations["MSG_SIGN_IN_INSTITUTION_BTN"] = "教育機関ログイン";
translations["MSG_SIGN_OTHER_ACCOUNT"] = "ソーシャルアカウントで続ける";
translations["MSG_SIGN_IN_OTHER_ACCOUNT"] = "ソーシャルアカウントでログイン";
translations["MSG_SIGN_UP_OTHER_ACCOUNT"] = "ソーシャルアカウントで登録";
translations["MSG_SIGN_IN_WITH_APPLE"] = "Appleで続ける";
translations["MSG_SIGN_UP_WITH_APPLE"] = "Appleで続ける";
translations["MSG_SIGN_IN_WITH_GOOGLE"] = "Googleで続ける";
translations["MSG_SIGN_UP_WITH_GOOGLE"] = "Googleで続ける";
translations["MSG_SIGN_IN_WITH_MICROSOFT"] = "Microsoftで続ける";
translations["MSG_SIGN_UP_WITH_MICROSOFT"] = "Microsoftで続ける";
translations["MSG_SIGN_IN_WITH_X"] = "Xで続ける";
translations["MSG_SIGN_UP_WITH_X"] = "Xで続ける";
translations["MSG_SIGN_IN_WITH_FACEBOOK"] = "Facebookで続ける";
translations["MSG_SIGN_UP_WITH_FACEBOOK"] = "Facebookで続ける";
translations["MSG_SIGN_IN_WITH_MAIL"] = "メールでログイン";
translations["MSG_SIGN_UP_WITH_MAIL"] = "メールで登録する";
translations["MSG_SIGN_USER_INSTITUTION_CODE"] = "教育機関コード";
translations["MSG_SIGN_USER_MAIL"] = "メールアドレス";
translations["MSG_SIGN_USER_PASSWORD"] = "パスワード";
translations["MSG_SIGN_USER_PASSWORD_CONFIRM"] = "パスワード再入力";
translations["MSG_SIGN_IN_SUCCESS_CONTENT"] = "ログイン成功しました。";
translations["MSG_SIGN_UP_SUCCESS_CONTENT"] = "アカウント登録成功しました。";
translations["MSG_SIGN_IN_FAILED_TITLE"] = "ログイン失敗";
translations["MSG_SIGN_IN_FAILED_MESSAGE"] =
  "メールアドレスかパスワードが間違っています。";
translations["MSG_SIGN_IN_FORGOT_PASSWORD"] = "パスワードが忘れた場合";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_TITLE"] = "パスワードリセット";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_SEND_BTN"] = "送信";
translations["MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_CONTENT"] =
  "指定したメールアドレスへパスワードリセットのリンクを送りました。";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_CURRENT_PASSWORD"] =
  "現在のパスワード";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD"] =
  "新しいパスワード";
translations["MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD_CONFIRM"] =
  "パスワードを確認";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_SEND_BTN"] = "変更";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_CONTENT"] =
  "パスワード変更成功しました。";
translations["MSG_SIGN_IN_CHANGE_PASSWORD_CURRENT_PASSWORD_WRONG"] =
  "入力したパスワードが間違っています。";
translations["MSG_SIGN_UP_EMAIL_ALREADY_EXISTS_FAILED_TITLE"] =
  "登録に失敗しました";
translations["MSG_SIGN_UP_EMAIL_ALREADY_EXISTS_FAILED_MESSAGE"] =
  "このメールアドレスは既に利用されます。";
translations["MSG_SIGN_LOGOUT"] = "ログアウトしました。";
translations["MSG_SIGN_LOGOUT_FAILED"] = "ログアウト失敗しました。";
translations["MSG_PARENTS_PROFILE_DEFAULT_NAME"] = "保護者";

translations["MSG_DATETIME_PERIOD"] = "きかん";
translations["MSG_DATETIME_PERIOD_RECENTLY"] = "さいきん";
translations["MSG_DATETIME_FORMAT"] = "YYYYねんMMがつDDにち";
translations["MSG_DATETIME_FORMAT_HOUR"] = "じ";
translations["MSG_DATETIME_FORMAT_MINUTE"] = "ふん";

translations["MSG_INEXUS_OFFICIAL"] = "まなんでパズル";
translations["MSG_INEXUS_OFFICIAL_CONTACT"] = "まなパズサポート";
translations["MSG_INEXUS_SYSTEM_NOTIFICATION"] = "システム";

translations["MSG_REVIEW_RATING_TITLE"] =
  "『まなんでパズル』の\nひょうかを\nおしえてください！";
translations["MSG_REVIEW_FEEDBACK_TITLE"] = "ごいけんばこ";
translations["MSG_REVIEW_TITLE_THANKYOU"] = "ありがとうございました！";
translations["MSG_REVIEW_GOTO_FEEDBACK"] = "ふまん";
translations["MSG_REVIEW_GOTO_REVIEW"] = "ひょうか";
translations["MSG_REVIEW_SEND_FEEDBACK_CANCEL"] = "キャンセル";
translations["MSG_REVIEW_SEND_RATING_CONFIRM"] = "おくる";
translations["MSG_REVIEW_SEND_FEEDBACK_CONFIRM"] = "ごいけんを おくる";

translations["MSG_REVIEW_THANKS_BUBBLE_TEXT"] = "ありがとう\nございました！";
translations["MSG_REVIEW_RATING_THANKS_TEXT"] =
  "これからも、『まなんでパズル』を\nよろしくおねがいします。";
translations["MSG_REVIEW_FEEDBACK_THANKS_TEXT"] =
  "これからも、『まなんでパズル』を\nよろしくおねがいします。";

translations["MSG_REVIEW_FEEDBACK_TYPE_APP"] = "アプリぜんたい";
translations["MSG_REVIEW_FEEDBACK_TYPE_COURSE"] = "『コースでまなぶ』";
translations["MSG_REVIEW_FEEDBACK_TYPE_CREATOR"] = "『じゆうにつくる』";
translations["MSG_REVIEW_FEEDBACK_TYPE_NONE"] = "ふまんはない";
translations["MSG_REVIEW_FEEDBACK_TYPE_OTHER"] = "そのた";
translations["MSG_REVIEW_FEEDBACK_TYPE_APP_HOW_TO_USE"] =
  "つかいかたが\nわからない";
translations["MSG_REVIEW_FEEDBACK_TYPE_APP_HARD_TO_USE"] = "つかいづらい";
translations["MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_DIFFICULT"] =
  "むずかし\nすぎる";
translations["MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_EASY"] = "かんたん\nすぎる";
translations["MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_MANY"] =
  "ステージが\nおおすぎる";
translations["MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_FEW"] =
  "ステージが\nすくなすぎる";
translations["MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_CREATE"] =
  "うまく\nつくれない";
translations["MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_USE"] =
  "つかいかたが\nむずかしい";
translations["MSG_REVIEW_FEEDBACK_PLACEHOLDER"] =
  "ここにじゆうに かいてください";

translations["MSG_USER_PROFILE_ADD_USER_BTN"] = "ついか";
translations["MSG_USER_PROFILE_ADD_USER_TITLE"] = "ユーザーを ついかします";
translations["MSG_USER_PROFILE_ADD_USER_MESSAGE"] =
  "あたらしいユーザーに なまえを つけてください。";
translations["MSG_USER_PROFILE_ADD_USER_SUCCESS"] =
  "ユーザーを　ついかしました";
translations["MSG_USER_PROFILE_ADD_USER_FAILED"] =
  "ユーザーの ついかに しっぱいしました";
translations["MSG_USER_PROFILE_SHOW_USER_LIST_BTN"] = "もっと";
translations["MSG_USER_PROFILE_CHANGE_USER"] = "ユーザーきりかえ";
translations["MSG_USER_PROFILE_CHANGE_USER_TITLE"] =
  "ユーザーを きりかえますか？";
translations["MSG_USER_PROFILE_CHANGE_USER_MESSAGE"] =
  "ユーザーを「%{name}」に きりかえます。";
translations["MSG_USER_PROFILE_CHANGE_USER_SUCCESS"] =
  "ユーザーを きりかえました";
translations["MSG_USER_PROFILE_CHANGE_USER_FAILED"] =
  "ユーザーきりかえしっぱい";
translations["MSG_USER_PROFILE_MAX_USER_TITLE"] =
  "ユーザーを ついか できません";
translations["MSG_USER_PROFILE_MAX_USER_MESSAGE"] =
  "げんざいは ユーザーは４にんまでしか つくれません。\n４にん いじょう ユーザーを つくれるように なりましたら、おしらせします。";
translations["MSG_USER_PROFILE_SIGN_TITLE"] = "ユーザーを ついか できません。";
translations["MSG_USER_PROFILE_SIGN_MESSAGE"] =
  "ユーザーを ついか するには ログインが ひつようです。\nログインは「ほごしゃのかたよう」ボタンから できます。";
translations["MSG_USER_PROFILE_USER_STATUS"] = "ステータス";
translations["MSG_USER_PROFILE_DEFAULT_NAME"] = "なまえ%{random}";
translations["MSG_USER_PROFILE_FIELD_NAME"] = "ニックネーム";
translations["MSG_USER_PROFILE_FIELD_NAME_PLACEHOLDER"] =
  "ニックネームをいれてください";
translations["MSG_USER_PROFILE_FIELD_GENDER"] = "せいべつ";
translations["MSG_USER_PROFILE_FIELD_GENDER_MALE"] = "おとこのこ";
translations["MSG_USER_PROFILE_FIELD_GENDER_FEMALE"] = "おんなのこ";
translations["MSG_USER_PROFILE_FIELD_GENDER_NONE"] = "なし";
translations["MSG_USER_PROFILE_FIELD_GENDER_ALL"] = "すべて";
translations["MSG_USER_PROFILE_FIELD_AGE"] = "ねんれい";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY"] = "たんじょうび";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_YEAR"] = "ねん";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH"] = "がつ";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_DAY"] = "にち";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE"] = "さい";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH_ERROR"] =
  "1 ~ 12のあいだのすうじ をつかってください";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_DAY_ERROR"] =
  "1 ~ 31のあいだのすうじ をつかってください";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE_ERROR"] =
  "0 ~ 99のあいだのすうじ をつかってください";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_NOT_SETTING"] = "ー";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH_PLACEHOLDER"] = "1";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_DATE_PLACEHOLDER"] = "1";
translations["MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE_PLACEHOLDER"] = "0";
translations["MSG_USER_PROFILE_STATUS_CREATED"] = "はじめたひ";
translations["MSG_USER_PROFILE_STATUS_PERIOD"] = "ログイン\nにっすう";
translations["MSG_USER_PROFILE_STATUS_DAY"] = "にち";
translations["MSG_USER_PROFILE_STATUS_COURSE_CLEARED"] = "クリアした\nコース";
translations["MSG_USER_PROFILE_STATUS_PROJECTS"] = "つくった\nアプリ";
translations["MSG_USER_PROFILE_STATUS_PUBLISHED_PROJECTS"] =
  "こうかいした\nアプリ";
translations["MSG_USER_PROFILE_STATUS_PROJECT_RECEIVED_FAVORITE"] =
  "もらった\nいいねのかず";
translations["MSG_USER_PROFILE_PROGRESS"] = "き\nろ\nく";
translations["MSG_USER_PROFILE_PROGRESS_NEW_TITLE"] = "New!";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_LEVEL_TITLE"] = "レベル";
translations["MSG_USER_PROFILE_PROGRESS_COURSE"] = "コースで\nまなぶ";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_CHART_DATE_TITLE"] = "にちめ";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_CHART_CLEARED_TITLE"] =
  "レベル\nクリア";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_CHART_NEW_CLEARED_TITLE"] =
  "あたらしい\nクリア";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_CLEARED_TITLE"] =
  "クリアしたかず";
translations["MSG_USER_PROFILE_PROGRESS_COURSE_PROGRESS_TITLE"] =
  "ここまでできたよ！";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR"] = "じゆうに\nつくる";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_VIEW_TITLE_START"] =
  "ごうけい";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_VIEW_TITLE_END"] = "にん";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_TITLE_START"] =
  "ごうけい";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_TITLE_END"] =
  "いいね";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_NEW_TITLE"] =
  "あたらしい\nいいね";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_AVG_TITLE"] =
  "いいね\nへいきん";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_VIEWED_TITLE"] =
  "みにきたひと";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_FAVORITED_TITLE"] =
  "いいねのかず";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_EMPTY"] =
  "アプリをつくりましょう！";
translations["MSG_USER_PROFILE_PROGRESS_CREATOR_GOTO_BTN"] =
  "『じゆうにつくる』へ";

translations["MSG_USER_PROFILE_INFO"] = "じぶん";
translations["MSG_USER_PROFILE_ICON"] = "しゃしん";
translations["MSG_USER_PROFILE_ICON_BTN"] = "アイコンをへんこう";
translations["MSG_USER_PROFILE_CASE_BTN"] = "ケースをへんこう";
translations["MSG_USER_PROFILE_CURRENT_ICON"] = "いまのアイコン";
translations["MSG_USER_PROFILE_STOCK"] = "そざい";
translations["MSG_USER_PROFILE_CASE"] = "もちもの";
translations["MSG_USER_PROFILE_CASE_TITLE"] = "ケースのいろ";
translations["MSG_USER_PROFILE_CURRENT_CASE"] = "いまのケース";
translations["MSG_USER_PROFILE_SETTING"] = "せってい";
translations["MSG_USER_PROFILE_NAME_ERROR_TITLE"] =
  "とうろくが できませんでした。";
translations["MSG_PARENTS_USER_PROFILE_NAME_ERROR_TITLE"] =
  "登録ができませんでした。";
translations["MSG_PARENTS_USER_PROFILE_NAME_ERROR_MESSAGE"] =
  "使う事のできない文字や文章が含まれています。もう一度やり直してください。";
translations["MSG_USER_PROFILE_NAME_ERROR_MESSAGE"] =
  "つかうことのできない もじや ぶんしょうが ふくまれています。もういちど やりなおしてください。";
translations["MSG_USER_PROFILE_MESSAGE"] = "ゆうびん";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_TITLE"] =
  "%{type}の おといあわせ";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_INPUT_AUTO_REPLY"] =
  "おといあわせ ありがとうございます！\nしたの にゅうりょくわくに おといあわせの ないようを かいてください。";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_INPUT_AUTO_REPLY"] =
  translations["MSG_USER_PROFILE_MESSAGE_CONTACT_INPUT_AUTO_REPLY"];
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_CONTENT"] =
  "しつもんないよう：\n";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_REPLY_TITLE"] =
  "おといあわせの　へんしんがありました。";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_REPLY_CONTENT"] =
  "へんしんないよう：\n";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_TAG"] = "おといあわせ";
translations["MSG_USER_PROFILE_MESSAGE_CONTACT_REPLY_TAG"] = "おといあわせ";
translations["MSG_USER_PROFILE_MESSAGE_PUSH_TAG"] = "おしらせ";
translations["MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TITLE"] =
  "『まなんでパズル』へ ようこそ！";
translations["MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_0"] =
  "%{name}さん、『まなんでパズル』へ ようこそ！";
translations["MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_1"] =
  "まなんでパズル』では、パズルのように たのしく あそびながら プログラミングを べんきょうできます。\nまずは、じゅんばんに あそびかたや べんきょうのほうほうを おぼえるために、『コースでまなぶ』からはじめて みてください！";
translations["MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_2"] =
  "アルゴリズムやプログラムの やりかたが だいたいわかったところで、『じゆうにつくる』で、じぶんのおもうままに アプリをつくってみてください。";
translations["MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_3"] =
  "さぁ、『まなんでパズル』を レッツトライ！";

translations["MSG_USER_PROFILE_MESSAGE_PARENTS_TALK_WELCOME_TEXT"] =
  "がさんかしました。";
translations["MSG_USER_PROFILE_MESSAGE_CHAT_SEND_BTN"] = "おくる";
translations["MSG_USER_PROFILE_MESSAGE_TYPE_IMAGE"] = "がぞう";
translations["MSG_USER_PROFILE_MESSAGE_PARENTS_WELCOME_TEXT"] =
  "トークしましょう";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_RATING"] =
  "のひょうかを おくりました。";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_RATING_AUTO_REPLY"] =
  "ひょうか ありがとうございました！";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_AUTO_REPLY"] =
  "ごいけん ありがとうございました！";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_TITLE"] = "ごいけん";
translations["MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_TYPE_OTHER"] =
  "・そのた：";
translations["MSG_USER_PROFILE_MESSAGE_CHAT_PLACEHOLDER"] =
  "ここにじゆうに かいてください";
translations["MSG_USER_PROFILE_MESSAGE_PUBLISHED_PROJECT_MESSAGE"] =
  "アプリ「%{name}」を公開しました。";

translations["MSG_TOP_TUTORIAL_FIRST_WELCOM_MESSAGE"] =
  "はじめてのひとは\nチュートリアルを\nみてね！";
translations["MSG_TOP_TUTORIAL_WELCOM_MESSAGE_1"] =
  "きょうもいっしょに\nがんばろう！";
translations["MSG_TOP_TUTORIAL_WELCOM_MESSAGE_2"] =
  "たのしくパズルで\nまなぼうね！";
translations["MSG_TOP_TUTORIAL_WELCOM_MESSAGE_3"] =
  "ぼくといっしょに\nプログラミングを\nまなぼう！";
translations["MSG_TOP_TUTORIAL_APP_START"] = "はじめる";
translations["MSG_TOP_TUTORIAL_START"] = "チュートリアルをみる";
translations["MSG_TOP_TUTORIAL_DONOT_SHOW"] = "次回から表示しない";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_1"] = "「まなんでパズル」\nについて";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_2"] = "「コースでまなぶ」\nについて";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_3"] = "「じゆうにつくる」\nについて";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_4"] = "ヘルプボタンと\nマイページ";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_5"] =
  "保護者の方用の\nメニューについて";
translations["MSG_TOP_TUTORIAL_STEP_TITLE_6"] = "アプリの設定を\n行う";
translations["MSG_TOP_TUTORIAL_CLOSE_PAGE"] = "この画面を閉じる";
translations["MSG_TOP_TUTORIAL_NEXT_PAGE"] = "次へ";
translations["MSG_TOP_TUTORIAL_START_APP"] = "『まなんでパズル』を始める";

translations["MSG_NOTIFICATION_DEFAULT_TITLE"] = "おしらせ";

translations["MSG_BACK_BTN"] = "もどる";
translations["MSG_CLOSE_BTN"] = "とじる";
translations["MSG_CANCEL_BTN"] = "キャンセル";
translations["MSG_CONFIRM_BTN"] = "けってい";
translations["MSG_STOP_BTN"] = "やめる";
translations["MSG_DELETE_BTN"] = "さくじょする";

translations["MSG_PARENTS_BACK_BTN"] = "戻る";
translations["MSG_PARENTS_CLOSE_BTN"] = "閉じる";
translations["MSG_PARENTS_CANCEL_BTN"] = "キャンセル";
translations["MSG_PARENTS_CONFIRM_BTN"] = "決定";
translations["MSG_PARENTS_DELETE_BTN"] = "削除";
translations["MSG_PARENTS_KIDS_USER_DELETE_BTN"] = "このユーザーの削除";

translations["MSG_ERROR_RELOAD_BTN"] = "やりなおす";
translations["MSG_ERROR_TITLE"] =
  "ただしく しょりを かんりょうすることが できませんでした。";
translations["MSG_ERROR_MESSAGE"] =
  "もういちど やりなおしてください。\nやりなおしても このがめんが でるばあいは、ほごしゃのひとに そうだんしてください。";
translations["MSG_DATE_STRING"] = "%{year}ねん%{month}がつ%{day}にち";

translations["MSG_TOAST_FAILED"] = "しっぱいしました";
translations["MSG_TOAST_SEND_FAILED"] = "そうしんしっぱいしました";
translations["MSG_TOAST_SUCCESS"] = "せいこうしました";
translations["MSG_TOAST_EDIT_SUCCESS"] = "へんこうしました";
translations["MSG_TOAST_DELETED_SUCCESS"] = "さくじょしました";
translations["MSG_TOAST_PUBLISHED_SUCCESS"] = "こうかいしました";
translations["MSG_TOAST_DISABLE_PUBLISHED_SUCCESS"] = "こうかいをやめました";
translations["MSG_TOAST_ENABLE_COPIED_SUCCESS"] = "コピーをきょかしました";
translations["MSG_TOAST_DISABLE_COPIED_SUCCESS"] =
  "コピーをきょかしないにしました";
translations["MSG_TOAST_RESTORE_SUCCESS"] = "アプリをもどしました";
translations["MSG_TOAST_COPY"] = "コピーしました。";
translations["MSG_TOAST_PAST"] = "ペーストしました。";
translations["MSG_TOAST_UNDO"] = "もとに もどしました。";
translations["MSG_TOAST_REDO"] = "やりなおしました。";
translations["MSG_TOAST_INSERT_ASSET_IMAGE_SUCCESS"] =
  "がぞうを ついかしました。";
translations["MSG_TOAST_INSERT_ASSET_TEMPLATE_SUCCESS"] =
  "テンプレートを ついかしました。";
translations["MSG_TOAST_CONTACT_SEND"] = "おといあわせを おくりました";

translations["MSG_SETTING_NOTIFICATION"] = "おしらせ";
translations["MSG_SETTING_TOP_TUTORIAL"] = "アプリのきどうがめん";
translations["MSG_SETTING_TOP_TUTORIAL_SHOW"] = "さいしょにひょうじ";
translations["MSG_SETTING_TOP_TUTORIAL_NOT_SHOW"] = "ひょうじしない";
translations["MSG_SETTING_APP_LANGUAGE"] = "アプリのひょうじげんご";
translations["MSG_SETTING_SOUND"] = "こうかおん";
translations["MSG_SETTING_BGM"] = "おんがく";
translations["MSG_SETTING_PARENT_GATE"] = "保護者の方用";
translations["MSG_SETTING_GO_TO_TOP"] = "トップページへもどる";
translations["MSG_SETTING_GO_TO_CONTACT"] = "おといあわせ";
translations["MSG_SETTING_GO_TO_FEEDBACK"] = "フィードバック";
translations["MSG_SETTING_GO_TO_REVIEW"] = "アプリのひょうか";
translations["MSG_SETTING_GO_TO_TUTORIAL"] = "チュートリアル";
translations["MSG_SETTING_GO_TO_PARENTS_LINK"] = "みまもりアプリ";
translations["MSG_SETTING_GO_TO_LAB"] = "企業認証コード入力";
translations["MSG_SETTING_GO_TO_MYPAGE_SETTING"] = "せっていページ";
translations["MSG_SETTING_CLOSE_BTN"] = "このがめんをとじる";
translations["MSG_SETTING_BACK_TO_BTN"] = "前の画面に戻る";

translations["MSG_SETTING_TITLE_TOP"] = "保護者メニュー";
translations["MSG_SETTING_CATEGORY_TITLE_APP_ACCOUNT"] = "アカウント";
translations["MSG_SETTING_CATEGORY_TITLE_APP_CONTENT"] = "コンテンツ";
translations["MSG_SETTING_CATEGORY_TITLE_APP_INFO"] = "アプリ";
translations["MSG_SETTING_CATEGORY_TITLE_APP_LINK"] = "追加のアプリ";
translations["MSG_SETTING_TITLE_APP_SETTING"] = "アプリの設定";
translations["MSG_SETTING_TITLE_DATA_TRANSFER"] = "データの同期";
translations["MSG_SETTING_TITLE_DATA_DELETE"] = "ユーザーの削除";
translations["MSG_SETTING_TITLE_COURSE_ANSWER"] = "コースの解答を見る";
translations["MSG_SETTING_TITLE_PARENTS_LINK"] = "みまもりアプリ";
translations["MSG_SETTING_TITLE_ENABLE_LAB"] = "コンテンツ認証";
translations["MSG_SETTING_TITLE_CLASSROOM"] = "クラスルーム";
translations["MSG_SETTING_TITLE_PARENTS_USER_ACCOUNT"] = "アカウントの管理";
translations["MSG_SETTING_TITLE_PARENTS_USER_PROFILE"] = "ユーザーの管理";
translations["MSG_SETTING_TITLE_SUBSCRIPTION"] = "プラン購入";
translations["MSG_SETTING_TITLE_SIGN"] = "まなんでパズルアカウント";
translations["MSG_SETTING_TITLE_SIGN_IN"] = "アカウントログイン";
translations["MSG_SETTING_TITLE_INSTITUTION_SIGN_IN"] = "教育機関ログイン";
translations["MSG_SETTING_TITLE_SIGN_UP"] = "アカウント登録";
translations["MSG_SETTING_TITLE_SIGN_ACCOUNT"] = "アカウントログイン/登録";
translations["MSG_SETTING_TITLE_SIGN_IN_MAIL"] = "アカウントログイン";
translations["MSG_SETTING_TITLE_SIGN_UP_MAIL"] = "アカウント登録";
translations["MSG_SETTING_TITLE_SIGN_PASSWORD_RESET"] = "パスワードリセット";
translations["MSG_SETTING_TITLE_SIGN_PASSWORD_CHANGE"] = "パスワード変更";
translations["MSG_SETTING_TITLE_SIGN_PROFILE"] = "プロフィール";
translations["MSG_SETTING_TITLE_PARENTS_USER_UNSUBSCRIBE"] = "アカウント削除";
translations["MSG_SETTING_TITLE_NOTIFICATION"] = "お知らせ";

translations["MSG_SETTING_MENU_APP_SETTING_BTN"] =
  translations["MSG_SETTING_TITLE_APP_SETTING"];
translations["MSG_SETTING_MENU_NOTIFICATION"] = "プッシュ通知設定";
translations["MSG_SETTING_MENU_PROJECT_PUBLISH"] = "アプリの公開を許可する";
translations["MSG_SETTING_MENU_PROJECT_COPIED"] =
  "アプリがコピーされることを許可する";
translations["MSG_SETTING_MENU_LANGUAGE"] = "アプリの表示言語";
translations["MSG_SETTING_MENU_DATA_TRANSFER_BTN"] =
  translations["MSG_SETTING_TITLE_DATA_TRANSFER"];
translations["MSG_SETTING_MENU_DATA_DELETE_BTN"] =
  translations["MSG_SETTING_TITLE_DATA_DELETE"];
translations["MSG_SETTING_MENU_COURSE_ANSWER_BTN"] = "コースの解答\nを見る";
translations["MSG_SETTING_MENU_NOTIFICATION_TITLE"] =
  translations["MSG_SETTING_TITLE_NOTIFICATION"];
translations["MSG_SETTING_MENU_PARENTS_USER_BTN"] =
  translations["MSG_SETTING_TITLE_PARENTS_USER_PROFILE"];
translations["MSG_SETTING_MENU_PARENTS_SUBSCRIPTION_BTN"] =
  translations["MSG_SETTING_TITLE_SUBSCRIPTION"];
translations["MSG_SETTING_MENU_SIGN_BTN"] =
  translations["MSG_SETTING_TITLE_SIGN"];
translations["MSG_SETTING_MENU_SIGN_WARNING_TITLE"] = "ログインが必要です";
translations["MSG_SETTING_MENU_SIGN_WARNING_MESSAGE"] =
  "この機能を使うには先にログインをしてください。";

translations["MSG_SETTING_FOOTER_GO_TO_SITE"] = "『まなんでパズル』のサイトへ";
translations["MSG_SETTING_FOOTER_TERMS_OF_SERVICE"] = "ご利用規約";
translations["MSG_SETTING_FOOTER_PRIVACY_POLICY"] = "プライバシーポリシー";
translations["MSG_SETTING_FOOTER_INQUIRY"] = "お問い合わせ";

translations["MSG_SETTING_APP_SETTING_LANGUAGE"] = "アプリの表示言語";
translations["MSG_SETTING_APP_SETTING_CONFIRM"] =
  "アプリの表示言語を\n「%{language}」に変更しますか？";
translations["MSG_SETTING_APP_SETTING_USER_ACTIVE"] = "このユーザーの有効化";

translations["MSG_SETTING_DATA_DELETE_CONFIRM_TITLE"] =
  "ユーザーを削除しますか？";
translations["MSG_SETTING_DATA_DELETE_CONFIRM_MESSAGE"] =
  "ユーザー「%{name}」を削除します。\n【注意】削除するとコースのクリアデータや作成したアプリなどのすべてのデータが削除されます。";
translations["MSG_SETTING_DATA_DELETE_TITLE"] = "この端末のユーザーを削除する";
translations["MSG_SETTING_DATA_DELETE_WARNING_TEXT"] =
  "上で選んだユーザーを削除すると、右に表示されているデータが全て削除されます。\n削除したユーザーは元に戻すことはできませんので、十分ご注意ください。\n※ 最初に作成したユーザーは削除できません。";
translations["MSG_SETTING_DATA_DELETE_DATA_PROGRESS"] = "こ ／%{total}こ";
translations["MSG_SETTING_DATA_DELETE_COURSE_TITLE"] = "コースでまなぶ";
translations["MSG_SETTING_DATA_DELETE_COURSE_CLEAR"] = "クリアした\nレベル";
translations["MSG_SETTING_DATA_DELETE_CREATOR_TITLE"] = "じゆうにつくる";
translations["MSG_SETTING_DATA_DELETE_DEFAULT_USER_TITLE"] =
  "ユーザーを削除できません。";
translations["MSG_SETTING_DATA_DELETE_DEFAULT_USER_MESSAGE"] =
  "最初に作成したユーザー「%{name}」は削除できません。";
translations["MSG_SETTING_DATA_DELETE_SUCCESS"] = "ユーザーの削除しました";
translations["MSG_SETTING_DATA_DELETE_BTN"] = "ユーザーを削除する";

translations["MSG_SETTING_PARENTS_ADD_KIDS_USER_TITLE"] =
  "ユーザーを取り込みます";
translations["MSG_SETTING_PARENTS_ADD_KIDS_USER_MESSAGE"] =
  "ログイン中のアカウントにこの端末のユーザを追加します。\nユーザーの追加を行うと、この端末のユーザーはログイン中のアカウントに移動します。";
translations["MSG_SETTING_PARENTS_MAX_USER_TITLE"] = "ユーザーを追加できません";
translations["MSG_SETTING_PARENTS_MAX_USER_MESSAGE"] =
  "現在はユーザーは４人までしかつくれません。\n４人以上ユーザーを作れるようになりましたら、お知らせします。";
translations["MSG_SETTING_PARENTS_LINKED_ACCOUNT_WRONG_TITLE"] =
  "ログインに失敗しました";
translations["MSG_SETTING_PARENTS_LINKED_ACCOUNT_WRONG_MESSAGE"] =
  "この端末のユーザーがリンクしているアカウントと異なるソーシャルアカウントが使用されました。\nもう一度正しいソーシャルアカウント選択してやり直してください。";
translations["MSG_SETTING_PARENTS_ENABLE_KIDS_USER_TITLE"] =
  "ユーザーの有効化を行います";
translations["MSG_SETTING_PARENTS_ENABLE_KIDS_USER_MESSAGE"] =
  "このユーザーを有効にします。";
translations["MSG_SETTING_PARENTS_DISABLE_KIDS_USER_TITLE"] =
  "ユーザーの無効化を行います";
translations["MSG_SETTING_PARENTS_DISABLE_KIDS_USER_MESSAGE"] =
  "このユーザーを無効にします。";
translations["MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MAX_WARNING_TITLE"] =
  "有効化できません";
translations["MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MAX_WARNING_MESSAGE"] =
  "有効化できるユーザーの数は最大４人までです。\nこのユーザーを有効化する場合には、ほかの不要なユーザーを無効化（もしくは削除）を行なってください。";
translations["MSG_SETTING_PARENTS_ADD_DEVICE_USER_MAX_WARNING_TITLE"] =
  "続いて追加したユーザーの有効化状態を確認してください";
translations["MSG_SETTING_PARENTS_ADD_DEVICE_USER_MAX_WARNING_MESSAGE"] =
  "ログイン中のアカウントのユーザー数が合計4名を超えました。\n同時に有効化できるユーザーの数は最大4名までです。\n今回追加したユーザーを有効化する場合には、ほかの不要なユーザーを無効化（もしくは削除）を行ってください。";
translations["MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MIN_WARNING_TITLE"] =
  "変更できません";
translations["MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MIN_WARNING_MESSAGE"] =
  "最低でも１名のユーザーが必要です。\nこれ以上ユーザーを減らすことはできません。";
translations["MSG_SETTING_PARENTS_REMOVE_KIDS_USER_TITLE"] =
  "ユーザーを削除します";
translations["MSG_SETTING_PARENTS_REMOVE_KIDS_USER_MESSAGE"] =
  "ユーザーを削除すると、このユーザーのデータは全て削除されます。\nもうこのユーザーを利用することはできなくなります。";
translations["MSG_SETTING_PARENTS_REMOVE_KIDS_MIN_WARNING_TITLE"] =
  "削除できません";
translations["MSG_SETTING_PARENTS_REMOVE_KIDS_MIN_WARNING_MESSAGE"] =
  "最低でも１名のユーザーが必要です。これ以上ユーザーを減らすことはできません。";
translations["MSG_SETTING_PARENTS_USER_SIGN_OUT_TITLE"] = "ログアウトします";
translations["MSG_SETTING_PARENTS_USER_SIGN_OUT_MESSAGE"] =
  "・アカウントをログアウトすると、今お使いのユーザーで遊ぶことができなくなります。\n・ユーザーの情報はアカウントに保存されますので、また同じユーザーで遊びたい場合は、このアカウントで再度ログインを行ってください。";
translations["MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_WARNING_TITLE"] =
  "【重要】ご注意ください";
translations["MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_WARNING_MESSAGE"] =
  "『まなんでパズル』からアカウント削除すると、下記のデータの全てが削除されます。\n・登録したプロフィール情報やアカウント情報。\n・登録した全員分のユーザー情報。\n・ユーザーが作成した「じゆうにつくる」のアプリ。\n・ユーザーがクリアした「コースでまなぶ」の全ての成績やクリア実績。\n・ユーザーが行ってきた「トーク履歴」等。\n※ 【重要】ログインしているアカウントの削除を行うと、本アカウントに関連するすべてのデータ削除されますので、十分にご注意ください。\n※ 【重要】この作業はいかなる場合も復旧することはできません。アカウントを削除する場合は十分にご注意ください。";
translations["MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_FORM_TITLE"] =
  "アカウント削除の理由を教えてください。";
translations["MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_TITLE"] =
  "本当にアカウント削除しますか？";
translations["MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_MESSAGE"] =
  "アカウント削除するとアカウント・ユーザーを含むすべてのデータが削除されます。\nアカウント削除処理は復旧することができません。ご注意ください。";
translations["MSG_SETTING_PARENTS_USER_PROFILE_NAME"] = "表示名";
translations["MSG_SETTING_PARENTS_USER_PROFILE_SAVE_BTN"] = "保存";
translations["MSG_SETTING_PARENTS_USER_PROFILE_EMAIL"] = "メールアドレス";
translations["MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED"] = "メール確認";
translations["MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED_DONE"] =
  "確認済み";
translations["MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED_NOT_YET"] =
  "認証リンクを送る";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT"] =
  "ソーシャルアカウント\nとの連携";
translations[
  "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_ALREADY_ERROR_TITLE"
] = "連携ができません";
translations[
  "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_ALREADY_ERROR_MESSAGE"
] = "ソーシャルアカウントはすでに別のアカウントと連携しました。";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_WITH_APPLE"] =
  "Appleと連携";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_WITH_GOOGLE"] =
  "Googleと連携";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_WITH_EMAIL"] =
  "パスワードを設定";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_UNLINK_WITH_APPLE"] =
  "Apple連携を解除";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_UNLINK_WITH_GOOGLE"] =
  "Google連携を解除";
translations["MSG_SETTING_PARENTS_USER_PROFILE_CREATED_AT"] =
  "アカウント登録日";
translations["MSG_SETTING_PARENTS_USER_KIDS_PROFILE_CREATED_AT"] = "登録日";
translations["MSG_SETTING_PARENTS_USER_PROFILE_LATEST_AT"] = "最後ログイン日";
translations["MSG_SETTING_PARENTS_USER_PROFILE_GOTO_CHANGE_PASSWORD_BTN"] =
  "パスワード変更";
translations["MSG_SETTING_PARENTS_USER_PROFILE_SIGN_OUT_BTN"] = "ログアウト";
translations["MSG_SETTING_PARENTS_USER_PROFILE_UNSUBSCRIBE_BTN"] =
  "アカウント削除";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ADD_USER"] = "新規ユーザー追加";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ADD_DEVICE_USER"] =
  "端末ユーザー追加";
translations["MSG_SETTING_PARENTS_USER_PROFILE_ADD_DEVICE_USER_TO_ACCOUNT"] =
  "アカウントに追加";
translations["MSG_SETTING_PARENTS_USER_PROFILE_DISPLAY_NAME_LIMIT"] =
  "%{limit}文字以内で入力してください。";

translations["MSG_SETTING_COURSE_ANSWER_COURSE_TITLE"] = "コースを選ぶ";
translations["MSG_SETTING_COURSE_ANSWER_STAGE_TITLE"] = "ステージを選ぶ";
translations["MSG_SETTING_COURSE_ANSWER_LEVEL_TITLE"] = "レベルを選ぶ";
translations["MSG_SETTING_COURSE_ANSWER_STAGE"] = "ステージ";
translations["MSG_SETTING_COURSE_ANSWER_LEVEL"] = "レベル";

translations["MSG_SETTING_PARENTS_LINK_DOWNLOAD_TITLE"] =
  "みまもりアプリをアプリストアからダウンロード";
translations["MSG_SETTING_PARENTS_LINK_DOWNLOAD_DESCRIPTION"] =
  "QRコードを利用せずにアプリをダウンロードする場合は、下記のアイコンより各アプリストアにてダウンロードしてください。";
translations["MSG_SETTING_PARENTS_LINK_ADD_USER_TITLE"] =
  "ユーザをみまもりりアプリに追加";
translations["MSG_SETTING_PARENTS_LINK_ADD_USER_DESCRIPTION"] =
  "QRコードを利用せずにユーザーを追加したい場合は、こちらの認証コードを『みまもりアプリ』に入力してください。";
translations["MSG_SETTING_PARENTS_LINK_ADD_USER_AUTH_CODE_TITLE"] =
  "認証コード";

translations["MSG_CONTACT_TITLE"] = "おといあわせ";
translations["MSG_CONTACT_TYPE_TITLE"] = "おといあわせの しゅるいを えらんでね";
translations["MSG_CONTACT_DETAIL_TITLE"] =
  "おといあわせの ないようを くわしく かいてね";
translations["MSG_CONTACT_TYPE_HOW_TO"] = "つかいかたが\nわからない";
translations["MSG_CONTACT_TYPE_BUG"] = "うまく\nうごかない";
translations["MSG_CONTACT_TYPE_REQUEST"] = "ついかして\nほしいきのう";
translations["MSG_CONTACT_TYPE_OTHER"] = "そのたの\nしつもん";
translations["MSG_CONTACT_SEND_BTN"] = "おといあわせを おくる";

translations["MSG_PARENT_GATE_WARNING"] =
  "このページは\nおとなのひとに\nかくにんして\nもらってくださいね";
translations["MSG_PARENT_GATE_TITLE"] = "保護者の方へ";
translations["MSG_PARENT_GATE_DESCRIPTION"] =
  "この先の画面は、保護者の方向けの設定・アカウント情報ペー\nジとなります。\n画面を表示するには、以下の画像を指示のとおりタップしてか\nら進むボタンを押してください。";
translations["MSG_PARENT_GATE_CLOSE_BTN"] =
  translations["MSG_SETTING_CLOSE_BTN"];
translations["MSG_PARENT_GATE_CONFIRM_BTN"] = "進む";
translations["MSG_PARENT_GATE_QUESTION_TITLE"] =
  "画像をこの順番でタップしてください:";
translations["MSG_PARENT_GATE_SHAPE_RECT"] = "四角";
translations["MSG_PARENT_GATE_SHAPE_STAR"] = "星型";
translations["MSG_PARENT_GATE_SHAPE_CIRCLE"] = "円形";
translations["MSG_PARENT_GATE_SHAPE_TRIANGLE"] = "三角";
translations["MSG_PARENTS_PROFILE_DEFAULT_NAME"] = "保護者";

translations["MSG_COURSE_COMING_SOON_WARNING_MESSAGE"] =
  "このカセットは まだあそべないよ。\nもうちょっとまってね！";
translations["MSG_COURSE_TAG_TITLE"] = "コースでまなぶ";
translations["MSG_COURSE_BUILDER_TAG_TITLE"] = "コースをつくる";
translations["MSG_COURSE_PAGINATION_PREV"] = "まえ";
translations["MSG_COURSE_PAGINATION_NEXT"] = "つぎ";
translations["MSG_COURSE_START_BTN"] = "スタート";
translations["MSG_COURSE_GOTO_MAP_BTN"] = "マップへ";
translations["MSG_COURSE_STAGE_CLEAR_TITLE"] = "ここまでクリア！";
translations["MSG_COURSE_DESCRIPTION"] =
  "あひるクンといっしょに、パズルでたのしくアルゴリズムをまなびましょう！";
translations["MSG_COURSE_LEVEL_OVERVIEW_SUMMARY"] = "このレベルでまなぶこと";
translations["MSG_COURSE_LEVEL_OVERVIEW_START_BTN"] = "はじめる";
translations["MSG_COURSE_GAME_TIPS_NEXT_BTN"] = "つぎへ";
translations["MSG_COURSE_GAME_TIPS_BACK_BTN"] = "まえにもどる";
translations["MSG_COURSE_GAME_TIPS_ACTION_BTN"] = "やってみる";
translations["MSG_COURSE_GAME_HINT_BTN"] = "ヒント";
translations["MSG_COURSE_GAME_CLEAR"] = "クリア";
translations["MSG_COURSE_GAME_SCORE_TEXT"] = "てにいれたアイテム";
translations["MSG_COURSE_GAME_SCORE_STATUS"] = "こ/%{total}こ";
translations["MSG_COURSE_GAME_START_BTN"] = "スタート！";
translations["MSG_COURSE_GAME_RESET_BTN"] = "やりなおす";
translations["MSG_COURSE_GAME_SUCCESS_TEXT"] =
  "おめでとう!このレベルをクリアしたよ!";
translations["MSG_COURSE_GAME_SUCCESS_REVIEW_BTN"] = "みなおす";
translations["MSG_COURSE_GAME_SUCCESS_NEXT_LEVEL_BTN"] = "つぎのレベルにすすむ";
translations["MSG_COURSE_GAME_SUCCESS_NEXT_STAGE_BTN"] =
  "つぎのステージにすすむ";
translations["MSG_COURSE_GAME_FAILED_TITLE"] = "ざんねん！";
translations["MSG_COURSE_GAME_FAILED_TEXT"] =
  "もういっかい やってみましょう！\n\nむずかしくて わからないときは、\nヒントを さんこうにしてください。";
translations["MSG_COURSE_GAME_FAILED_RESET_BTN"] = "もういっかいやる";
translations["MSG_COURSE_GAME_STAGE_CLEAR"] =
  "おめでとう!ステージをクリアしたよ!";
translations["MSG_COURSE_GAME_STAGE_CLEAR_OK"] = "OK";
translations["MSG_COURSE_ALL_GAME_STAGE_CLEAR"] =
  "おめでとう！\nいま ちょうせんできる\nすべての ステージを クリアしたよ！\nあたらしい ステージが ついか されるまで\nたのしみに まっていてね！";
translations["MSG_COURSE_PROGRAM_RUN"] = "スタート";
translations["MSG_COURSE_COORDINATE_X"] = "よこのいち（Xざひょう）";

translations["MSG_CREATOR_TOP_TUTORIAL_MENU_TITLE"] = "つかいかたのせつめい";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_NEXT_BTN"] = "つぎへ";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_LATER_BTN"] = "あとでみる";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_CONTINUE_BTN"] = "ガイドをつづける";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_BACK_BTN"] = "まえにもどる";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_ACTION_BTN"] = "やってみる";
translations["MSG_CREATOR_TOP_TUTORIAL_TIPS_CLOSE_BTN"] = "ガイドをおわる";
translations["MSG_CREATOR_DISPLAY_DEFAULT"] = "カセットをえらんでください";
translations["MSG_CREATOR_CREATE_APP"] = "あたらしくつくる";
translations["MSG_CREATOR_CREATE_APP_TITLE"] = "アプリをつくる";
translations["MSG_CREATOR_CREATE_APP_NAME"] =
  "アプリになまえをつけてください。";
translations["MSG_CREATOR_CREATE_APP_NAME_PREFIX"] = "アプリ";
translations["MSG_CREATOR_CREATE_TAG_TITLE"] = "タグをつくる";
translations["MSG_CREATOR_CREATE_TEMPLATE_NAME_PREFIX"] = "テンプレート";
translations["MSG_CREATOR_CREATE_APP_BTN"] = "つくる";
translations["MSG_CREATOR_CREATE_APP_LIMIT"] = "つくれるアプリのかず";
translations["MSG_CREATOR_CREATE_APP_LIMIT_PROGRESS"] = "/%{total}";
translations["MSG_CREATOR_APP_DETAIL_BTN"] = "くわしく";
translations["MSG_CREATOR_RUN_APP_BTN"] = "うごかす";
translations["MSG_CREATOR_APP_MY_APPS"] = "マイアプリ";
translations["MSG_CREATOR_CREATE_MORE_APP_BTN"] = "もっと\nつくる";
translations["MSG_CREATOR_APP_SHARED_APPS"] = "みんなのアプリ";
translations["MSG_CREATOR_APP_SHARED_APPS_EMPTY_MESSAGE"] =
  "このじょうけんのアプリがありませんでした。\nじょうけんをかえて、もういちど けんさくしてください。";
translations["MSG_CREATOR_APP_FAVORITED_APPS"] = "おきにいり";
translations["MSG_CREATOR_APP_DELETED_APPS"] = "ゴミばこ";
translations["MSG_CREATOR_APP_DELETED_APPS_MESSAGE"] =
  "「マイアプリ」に もどしたい アプリを\nえらんで ください。\n（さいきん さくじょした アプリが\n5つまで のこっています）";
translations["MSG_CREATOR_APP_PREVIEW_NOT_SELECTED"] =
  "アプリを せんたくすると ここにプレビューが ひょうじされるよ！";
translations["MSG_CREATOR_APP_SETTING_TITLE"] = "アプリのせってい";
translations["MSG_CREATOR_APP_SETTING_APP_NAME"] = "アプリのなまえ";
translations["MSG_CREATOR_APP_SETTING_APP_TAG"] = "タグ";
translations["MSG_CREATOR_APP_SETTING_APP_TAG_CREATE_BTN"] = "タグついか";
translations["MSG_CREATOR_APP_SETTING_PUBLISH_TITLE"] =
  "みんなのアプリにのせる";
translations["MSG_CREATOR_APP_SETTING_PUBLISH_STATUS_OFF"] = "ひこうかい";
translations["MSG_CREATOR_APP_SETTING_PUBLISH_STATUS_ON"] = "こうかい";
translations["MSG_CREATOR_APP_SETTING_ENABLE_COPIED_TITLE"] =
  "ほかのひとがコピーできる";
translations["MSG_CREATOR_APP_SETTING_ENABLE_COPIED_STATUS_OFF"] =
  "コピーを\nきんしする";
translations["MSG_CREATOR_APP_SETTING_ENABLE_COPIED_STATUS_ON"] =
  "コピーを\nきょかする";
translations["MSG_CREATOR_APP_SETTING_APP_CHANGE_COVER_PAGE_BTN"] =
  "ひょうしをへんこう";
translations["MSG_CREATOR_APP_DETAIL_TITLE"] = "くわしいじょうほう";
translations["MSG_CREATOR_APP_DETAIL_APP_AUTHOR"] = "はじめにつくったひと";
translations["MSG_CREATOR_APP_DETAIL_APP_EDITOR"] = "つくりかえたひと";
translations["MSG_CREATOR_APP_NAME_PLACEHOLDER"] = "なまえをいれてください";
translations["MSG_CREATOR_APP_DETAIL_DESCRIPTION_TITLE"] = "アプリのせつめい";
translations["MSG_CREATOR_APP_DETAIL_DESCRIPTION_NONE"] =
  "せつめいが ありません";
translations["MSG_CREATOR_APP_DETAIL_DESCRIPTION_PLACEHOLDER"] =
  "アプリの せつめいを にゅうりょく してください";
translations["MSG_CREATOR_APP_DETAIL_INPUT_LIMIT"] = "%{input}／%{total}もじ";
translations["MSG_CREATOR_APP_DETAIL_TITLE_APP_CODE"] = "アプリ\nコード";
translations["MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP"] = "みんなの\nアプリ";
translations["MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP_PUBLISHED"] =
  "こうかいちゅう";
translations["MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP_NOT_PUBLISHED"] =
  "ひこうかい";
translations["MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS"] = "ほかの\nひと";
translations["MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS_ENABLE"] =
  "コピーできる";
translations["MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS_DISABLE"] =
  "コピーできない";
translations["MSG_CREATOR_APP_DETAIL_APP_DELETED_AT"] = "さくじょ\nした日";

translations["MSG_CREATOR_APP_EDITED_DATE"] = "さいごに\nつくった日";
translations["MSG_CREATOR_APP_CREATED_DATE"] = "さいしょに\nつくった日";
translations["MSG_CREATOR_APP_LATEST_AUTHOR"] = "さいごに\nつくったひと";
translations["MSG_CREATOR_APP_ORIGINAL_AUTHOR"] = "さいしょに\nつくったひと";
translations["MSG_CREATOR_APP_EDITOR_BTN"] = "つくる";
translations["MSG_CREATOR_APP_COPY_EDITOR_BTN"] = "コピーしてつくる";
translations["MSG_CREATOR_APP_COMPLETELY_BTN"] = "かんぜんにけす";
translations["MSG_CREATOR_APP_RESTORE_BTN"] = "もとにもどす";
translations["MSG_CREATOR_APP_DELETE_WARNING_TITLE"] =
  "アプリをさくじょしますか？";
translations["MSG_CREATOR_APP_DELETE_WARNING_MESSAGE"] =
  "「%{name}」を さくじょします。\nアプリをへんしゅうできなくなります。\nアプリをつかうことはできません。";
translations["MSG_CREATOR_ASSET_DELETE_WARNING_TITLE"] =
  "がぞうを さくじょしますか？";
translations["MSG_CREATOR_ASSET_DELETE_WARNING_MESSAGE"] =
  "「%{name}」を さくじょします。\nがぞうをさくじょすると もとに もどせなくなります。";
translations["MSG_CREATOR_TAG_DELETE_WARNING_TITLE"] =
  "タグを さくじょしますか？";
translations["MSG_CREATOR_TAG_DELETE_WARNING_MESSAGE"] =
  "タグ「%{name}」を さくじょします。\nタグをさくじょすると もとに もどせなくなります。";
translations["MSG_CREATOR_CREATE_APP_PICKUP_NAME"] = "ピックアップ";
translations["MSG_CREATOR_CREATE_APP_PICKUP_MONTH_TITLE"] = "%{month}がつの";
translations["MSG_CREATOR_CREATE_APP_PICKUP_SUBTITLE"] =
  "きになるアプリをごしょうかい！";

translations["MSG_CREATOR_APP_PUBLISH_SETTING_TITLE"] = "こうかいせってい";
translations["MSG_CREATOR_APP_PUBLISH_BTN"] = "こうかい";
translations["MSG_CREATOR_APP_PUBLISH_TITLE"] =
  "「みんなのアプリ」に こうかい しますか？";
translations["MSG_CREATOR_APP_PUBLISH_DISABLED_TITLE"] =
  "アプリの こうかいが できません";
translations["MSG_CREATOR_APP_PUBLISH_DISABLED_MESSAGE"] =
  "アプリの のこうかいが きょかされていません。\nほごしゃメニューから きょかをしてください。";
translations["MSG_CREATOR_APP_DISABLE_PUBLISH_TITLE"] =
  "「みんなのアプリ」への こうかいを やめますか？";
translations["MSG_CREATOR_APP_PUBLISH_WARNING"] =
  "「%{name}」を「みんなのアプリ」に こうかいします。\nアプリを こうかい すると、ほかの ひとが この アプリを つかうことが できるように なります。";
translations["MSG_CREATOR_APP_DISABLE_PUBLISH_WARNING"] =
  "「%{name}」を「みんなのアプリ」に こうかいしない ようにします。\nこうかいを やめると、ほかのひとが このアプリを つかえなく なります。";
translations["MSG_CREATOR_APP_PUBLISH_COPIED_ENABLE"] =
  "コピーを きょかして こうかい";
translations["MSG_CREATOR_APP_PUBLISH_COPIED_DISABLE"] =
  "コピーを きょかしないで こうかい";
translations["MSG_CREATOR_APP_PUBLISH_COPIED_NOTICE"] =
  "※アプリは あとで こうかいを やめることも できます。";
translations["MSG_CREATOR_PUBLISH_CANCEL_BTN"] = "キャンセル";
translations["MSG_CREATOR_PUBLISH_CONFIRM_BTN"] = "こうかいする";
translations["MSG_CREATOR_DISABLE_PUBLISH_CONFIRM_BTN"] = "ひこうかいにする";
translations["MSG_CREATOR_APP_PUBLISHING"] = "こうかいちゅう";
translations["MSG_CREATOR_APP_NOT_PUBLISHING"] = "ひこうかい";
translations["MSG_CREATOR_APP_ENABLE_COPIED_TITLE"] =
  "このアプリのコピーのせってい";
translations["MSG_CREATOR_APP_DISABLE_COPIED_TITLE"] =
  "このアプリのコピーのせってい";
translations["MSG_CREATOR_APP_ENABLE_COPIED_MESSAGE"] =
  "ほかのひとが「%{name}」をコピーできるように、コピーをきょかします。";
translations["MSG_CREATOR_APP_DISABLE_COPIED_MESSAGE"] =
  "ほかのひとが「%{name}」をコピーできないように、コピーきんしにします。";
translations["MSG_CREATOR_APP_LIMIT_WARNING_TITLE"] = "アプリを つくれません";
translations["MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE"] =
  "いまは アプリは５こまでしか つくれません。\nあたらしく アプリを つくりたいときは、ふるいアプリを さくじょして ください。\n５こ いじょう アプリを つくれるように なったら、おしらせします。";
translations["MSG_CREATOR_ASSET_LIMIT_WARNING_TITLE"] =
  "このがぞうは りようできません";
translations["MSG_CREATOR_ASSET_LIMIT_WARNING_MESSAGE"] =
  "アプリについかできるがぞうは さいしんの５０こ だけです。\nそれいがいの がぞうも りよう できるように なりましたら、おしらせします。";
translations["MSG_CREATOR_TAG_LIMIT_WARNING_TITLE"] = "ついかできません";
translations["MSG_CREATOR_TAG_LIMIT_WARNING_MESSAGE"] =
  "タグを ついかできません。";
translations["MSG_CREATOR_APP_DELETE_PUBLISHED_WARNING_TITLE"] =
  "アプリを さくじょできません";
translations["MSG_CREATOR_APP_DELETE_PUBLISHED_WARNING_MESSAGE"] =
  "みんなのアプリにこうかいちゅうのアプリは さくじょ できません。\nこのアプリをさくじょしたいときは、さきに ひこうかいに してください。";
translations["MSG_CREATOR_TOP_TUTORIAL_APP_LIMIT_WARNING_TITLE"] =
  "「ガイド」を うごかせません";
translations["MSG_CREATOR_TOP_TUTORIAL_APP_LIMIT_WARNING_MESSAGE"] =
  "この「ガイド」を みるためには アプリの あきが 1つ ひつようです。\nこの「ガイド」を うごかすまえに アプリを1つ さくじょ してください。";
translations["MSG_CREATOR_APP_PUBLISH_DISABLE_WARNING_TITLE"] =
  "アプリを こうかいすることが できませんでした。";
translations["MSG_CREATOR_APP_PUBLISH_DISABLE_WARNING_MESSAGE"] =
  "アプリの こうかいは きょか されていません。\nアプリを こうかいしたい ときは、おとなのひとに おねがいしてね！";
translations["MSG_CREATOR_APP_SORT_FILTER_TITLE"] = "みつける";
translations["MSG_CREATOR_APP_PUBLISHED_FILTER_OFFICIAL"] =
  "こうしきだけひょうじ";
translations["MSG_CREATOR_APP_PUBLISHED_FILTER_SORT_DATE"] = "あたらしいじゅん";
translations["MSG_CREATOR_APP_PUBLISHED_FILTER_SORT_FAVORITED"] =
  "にんきじゅん";
translations["MSG_CREATOR_APP_PUBLISHED_FILTER_FAVORITED"] =
  "おきにいりだけひょうじ";
translations["MSG_CREATOR_APP_KEYWORD_SEARCH_TITLE"] = "ことばでみつける";
translations["MSG_CREATOR_APP_KEYWORD_SEARCH_PLACEHOLDER"] =
  "さがしたいことばをにゅうりょく";
translations["MSG_CREATOR_APP_SORT_TITLE"] = "ならべかえ";
translations["MSG_CREATOR_APP_SORT_FAVORITED"] = "にんき";
translations["MSG_CREATOR_APP_SORT_VIEWED"] = "にんき";
translations["MSG_CREATOR_APP_SORT_DATE"] = "じかん";
translations["MSG_CREATOR_APP_FILTER_TITLE"] = "しぼりこみ";
translations["MSG_CREATOR_APP_FILTER_OFFICIAL"] = "こうしき";
translations["MSG_CREATOR_APP_FILTER_FAVORITED"] = "いいね";
translations["MSG_CREATOR_APP_DISABLE_BTN"] = "できません";
translations["MSG_CREATOR_APP_COMPLETE_DELETE_CONFIRM_TITLE"] =
  "アプリをかんぜんにけしますか？";
translations["MSG_CREATOR_APP_COMPLETE_DELETE_CONFIRM_SUBTITLE"] =
  "「%{name}」を かんぜんに けします。\nかんぜんにけすと、アプリを もとにもどすことはできなくなります。";
translations["MSG_CREATOR_APP_RESTORE_CONFIRM_TITLE"] =
  "「マイアプリ」に もどしますか？";
translations["MSG_CREATOR_APP_RESTORE_CONFIRM_SUBTITLE"] =
  "「%{name}」を「マイアプリ」に もどしますか？";
translations["MSG_CREATOR_APP_RESTORE_CONFIRM_MESSAGE"] =
  "※アプリは 「ひこうかい」のじょうたいになります。\n   マイアプリにもどしたあと、「こうかい」にできます。";

translations["MSG_CREATOR_SCREEN_LIST_HEADER"] = "ページ";
translations["MSG_CREATOR_COMPONENT_LIST_HEADER"] = "せんたくちゅう";
translations["MSG_CREATOR_MENU_CONTEXT_DELETE"] = "さくじょ";
translations["MSG_CREATOR_MENU_CONTEXT_COPY"] = "コピー";
translations["MSG_CREATOR_MENU_RUN_APP_BTN"] = "アプリを\nうごかす";
translations["MSG_CREATOR_MENU_SET_APP_BTN"] = "アプリの\nせってい";
translations["MSG_CREATOR_MENU_PUBLISH_APP_BTN"] = "アプリを\nこうかい";
translations["MSG_CREATOR_MENU_STOP_PUBLISH_APP_BTN"] = "アプリを\nひこうかい";
translations["MSG_CREATOR_MENU_PUBLISH_TEMPLATE_BTN"] =
  "テンプレートを\nこうかい";
translations["MSG_CREATOR_MENU_STOP_PUBLISH_TEMPLATE_BTN"] =
  "テンプレートを\nひこうかい";
translations["MSG_CREATOR_MENU_CLOSE_BTN"] = "このがめんを\nとじる";
translations["MSG_CREATOR_MENU_GO_TO_EDITOR_BTN"] =
  "「アプリをつくる」\nのがめんへ";
translations["MSG_CREATOR_MENU_BACK_TO_LIST_BTN"] = "いちらんへ\nもどる";
translations["MSG_CREATOR_MENU_SHARE_BTN"] = "アプリを\nシェアする";
translations["MSG_CREATOR_MENU_COMPONENT_BTN"] = "パーツ";
translations["MSG_CREATOR_MENU_COMPONENT_ACTION_BTN"] = "おしごと";
translations["MSG_CREATOR_MENU_COMPONENT_SHAPE_BTN"] = "ずけい";
translations["MSG_CREATOR_MENU_COMPONENT_STAMP_BTN"] = "スタンプ";
translations["MSG_CREATOR_MENU_COMPONENT_STOCK_BTN"] = "どうぐばこ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_ACTION"] = "そうさパーツ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_SHAPE"] = "ずけいパーツ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP"] = "スタンプパーツ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_CHARACTER"] =
  "キャラクター";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_GAME"] = "あそび";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_ANIMAL"] = "いきもの";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_EVENT"] = "イベント";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_INTERNET"] =
  "インターネット";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_MONEY"] = "おかね";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_GOODS"] = "ざっか";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_NATURE"] = "しぜん";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_PERSON"] = "じんぶつ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_BUILDING"] = "たてもの";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_FOOD"] = "たべもの";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_ELECTRONIC"] = "でんき";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_SMILE"] = "にこちゃん";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_VEHICLE"] = "のりもの";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_STATIONARY"] =
  "ぶんぼうぐ";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_OTHERS"] = "そのほか";
translations["MSG_CREATOR_MENU_COMPONENT_PIECE_PARTS_ALL"] = "すべて";
translations["MSG_CREATOR_MENU_DRAWING_BTN"] = "おえかき";
translations["MSG_CREATOR_MENU_DRAWING_PEN"] = "ペン";
translations["MSG_CREATOR_MENU_DRAWING_ERASER"] = "けしごむ";
translations["MSG_CREATOR_MENU_DRAWING_SIZE"] = "ふとさ";
translations["MSG_CREATOR_MENU_DRAWING_SIZE_LIGHT"] = "ほそい";
translations["MSG_CREATOR_MENU_DRAWING_SIZE_NORMAL"] = "ふつう";
translations["MSG_CREATOR_MENU_DRAWING_SIZE_BOLD"] = "ふとい";
translations["MSG_CREATOR_MENU_DRAWING_SHAPE"] = "かたち";
translations["MSG_CREATOR_MENU_DRAWING_SHAPE_ROUND"] = "まる";
translations["MSG_CREATOR_MENU_DRAWING_SHAPE_SQUARE"] = "しかく";
translations["MSG_CREATOR_MENU_DRAWING_COLOR"] = "いろ";
translations["MSG_CREATOR_MENU_DRAWING_RESET"] = "やりなおす";
translations["MSG_CREATOR_MENU_DRAWING_FINISH"] = "かんせい";
translations["MSG_CREATOR_MENU_STOCK_PARTS_TITLE"] = "パーツセット";
translations["MSG_CREATOR_MENU_STOCK_PARTS_DETAIL_BTN"] = "くわたしく";
translations["MSG_CREATOR_MENU_STOCK_PARTS_USE_BTN"] = "ついか";
translations["MSG_CREATOR_MENU_COMPONENT_PREVIEW_TITLE"] =
  "%{name}のパーツいちらん";
translations["MSG_CREATOR_MENU_ASSET_CATEGORY_SYSTEM_TITLE"] = "アイコン";
translations["MSG_CREATOR_MENU_ASSET_CATEGORY_ALBUM_TITLE"] = "アルバム";
translations["MSG_CREATOR_MENU_ASSET_CATEGORY_DRAWING_TITLE"] =
  "スケッチブック";
translations["MSG_CREATOR_MENU_ASSET_CATEGORY_CREATE_TITLE"] = "そざいついか";

translations["MSG_CREATOR_MENU_PROPERTY_BTN"] = "デザイン";
translations["MSG_CREATOR_MENU_PROPERTY_BASIC"] = "きほん";
translations["MSG_CREATOR_MENU_PROPERTY_POSITION"] = "ばしょ";
translations["MSG_CREATOR_MENU_PROPERTY_SIZE"] = "サイズ";
translations["MSG_CREATOR_MENU_PROPERTY_SHAPE"] = "かたち";
translations["MSG_CREATOR_MENU_PROPERTY_APPEARANCE"] = "みため";
translations["MSG_CREATOR_MENU_PROPERTY_COLOR"] = "いろ";
translations["MSG_CREATOR_MENU_PROPERTY_LOGIC"] = "プログラム";
translations["MSG_CREATOR_MENU_PROPERTY_LOGIC_TITLE"] = "%{name}のプログラム";

translations["MSG_CREATOR_MENU_PROPERTY_NAME"] = "なまえ";
translations["MSG_CREATOR_MENU_PROPERTY_NAME_PLACEHOLDER"] =
  "なまえをつけてください";
translations["MSG_CREATOR_MENU_PROPERTY_SET_TOP_PAGE_TITLE"] = "トップページ";
translations["MSG_CREATOR_MENU_PROPERTY_SET_TOP_PAGE"] =
  "このページからはじめる";
translations["MSG_CREATOR_MENU_PROPERTY_FONT_FAMILY"] = "フォントのしゅるい";
translations["MSG_CREATOR_MENU_PROPERTY_TEXT"] = "ひょうじするないよう";
translations["MSG_CREATOR_MENU_PROPERTY_ZINDEX"] = "かさなり";
translations["MSG_CREATOR_MENU_PROPERTY_ZINDEX_FRONT"] = "いちばんうえへ";
translations["MSG_CREATOR_MENU_PROPERTY_ZINDEX_BACK"] = "いちばんしたへ";
translations["MSG_CREATOR_MENU_PROPERTY_POSITION_X_Y"] = "よことたてのばしょ";
translations["MSG_CREATOR_MENU_PROPERTY_X"] = "よこのばしょ";
translations["MSG_CREATOR_MENU_PROPERTY_Y"] = "たてのばしょ";
translations["MSG_CREATOR_MENU_PROPERTY_X_COORDINATE"] = "よこ";
translations["MSG_CREATOR_MENU_PROPERTY_Y_COORDINATE"] = "たて";
translations["MSG_CREATOR_MENU_PROPERTY_WIDTH"] = "よこはば";
translations["MSG_CREATOR_MENU_PROPERTY_HEIGHT"] = "たかさ";
translations["MSG_CREATOR_MENU_PROPERTY_BORDER"] = "わく";
translations["MSG_CREATOR_MENU_PROPERTY_SHADOW"] = "かげ";
translations["MSG_CREATOR_MENU_PROPERTY_TEXT_SIZE"] = "テキスト";
translations["MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW"] = "テキストのかげ";
translations["MSG_CREATOR_MENU_PROPERTY_OPACITY"] = "みえやすさ";
translations["MSG_CREATOR_MENU_PROPERTY_ROTATION"] = "かくど";
translations["MSG_CREATOR_MENU_PROPERTY_BORDER_RADIUS"] = "かどのまるさ";
translations["MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO"] = "がぞうのじょうほう";
translations["MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_NAME"] = "なまえ";
translations["MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_SIZE"] = "おおきさ";
translations["MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_FILE_SIZE"] =
  "ファイルサイズ";
translations["MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_BTN"] = "がぞうをえらぶ";
translations["MSG_CREATOR_MENU_PROPERTY_BACKGROUND_COLOR"] = "ぬりつぶし";
translations["MSG_CREATOR_MENU_PROPERTY_BORDER_COLOR"] = "わく";
translations["MSG_CREATOR_MENU_PROPERTY_SHADOW_COLOR"] = "かげ";
translations["MSG_CREATOR_MENU_PROPERTY_TEXT_COLOR"] = "テキスト";
translations["MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW_COLOR"] = "テキストのかげ";

translations["MSG_CREATOR_MENU_BLOCKLY_TITLE"] = "ブロック";
translations["MSG_CREATOR_MENU_BLOCKLY_EVENT"] = "イベント";
translations["MSG_CREATOR_MENU_BLOCKLY_ANIMATION"] = "うごき";
translations["MSG_CREATOR_MENU_BLOCKLY_LOGIC"] = "ロジック";
translations["MSG_CREATOR_MENU_BLOCKLY_CONTROL"] = "せいぎょ";
translations["MSG_CREATOR_MENU_BLOCKLY_MATH"] = "えんざん";
translations["MSG_CREATOR_MENU_BLOCKLY_VARIABLE"] = "へんすう";
translations["MSG_CREATOR_MENU_BLOCKLY_PROCEDURE"] = "かんすう";
translations["MSG_CREATOR_MENU_BLOCKLY_NONE"] = "つかえるブロックがありません";
translations["MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE"] =
  "%{placeholder}のあたい";

translations["MSG_CREATOR_COMPONENT_NAME_SCREEN"] = "ページ";
translations["MSG_CREATOR_COMPONENT_NAME_BUTTON"] = "ボタン";
translations["MSG_CREATOR_COMPONENT_NAME_LABEL"] = "ラベル";
translations["MSG_CREATOR_COMPONENT_NAME_FORM"] = "フォーム";
translations["MSG_CREATOR_COMPONENT_NAME_CHECKBOX"] = "チェックボックス";
translations["MSG_CREATOR_COMPONENT_NAME_SWITCH"] = "スイッチ";
translations["MSG_CREATOR_COMPONENT_NAME_IMAGE"] = "がぞう";
translations["MSG_CREATOR_COMPONENT_NAME_CIRCLE"] = "えん";
translations["MSG_CREATOR_COMPONENT_NAME_TRIANGLE"] = "さんかく";
translations["MSG_CREATOR_COMPONENT_NAME_RECT"] = "しかく";
translations["MSG_CREATOR_COMPONENT_NAME_STAR"] = "ほし";
translations["MSG_CREATOR_COMPONENT_NAME_LINE"] = "せん";
translations["MSG_CREATOR_COMPONENT_NAME_STAMP"] = "スタンプ";
translations["MSG_CREATOR_COMPONENT_NAME_DRAWING"] = "おえかき";

translations["MSG_CREATOR_COMPONENT_LABEL_SCREEN"] =
  translations["MSG_CREATOR_COMPONENT_NAME_SCREEN"];
translations["MSG_CREATOR_COMPONENT_LABEL_BUTTON"] =
  translations["MSG_CREATOR_COMPONENT_NAME_BUTTON"];
translations["MSG_CREATOR_COMPONENT_LABEL_LABEL"] =
  translations["MSG_CREATOR_COMPONENT_NAME_LABEL"];
translations["MSG_CREATOR_COMPONENT_LABEL_FORM"] =
  translations["MSG_CREATOR_COMPONENT_NAME_FORM"];
translations["MSG_CREATOR_COMPONENT_LABEL_CHECKBOX"] = "チェック\nボックス";
translations["MSG_CREATOR_COMPONENT_LABEL_SWITCH"] =
  translations["MSG_CREATOR_COMPONENT_NAME_SWITCH"];
translations["MSG_CREATOR_COMPONENT_LABEL_IMAGE"] = "がぞう\nボックス";
translations["MSG_CREATOR_COMPONENT_LABEL_CIRCLE"] =
  translations["MSG_CREATOR_COMPONENT_NAME_CIRCLE"];
translations["MSG_CREATOR_COMPONENT_LABEL_TRIANGLE"] =
  translations["MSG_CREATOR_COMPONENT_NAME_TRIANGLE"];
translations["MSG_CREATOR_COMPONENT_LABEL_RECT"] =
  translations["MSG_CREATOR_COMPONENT_NAME_RECT"];
translations["MSG_CREATOR_COMPONENT_LABEL_STAR"] =
  translations["MSG_CREATOR_COMPONENT_NAME_STAR"];
translations["MSG_CREATOR_COMPONENT_LABEL_LINE"] =
  translations["MSG_CREATOR_COMPONENT_NAME_LINE"];

translations["MSG_BLOCKLY_VARIABLES"] = "へんすう";
translations["MSG_BLOCKLY_INPUT_NUMBER_LIMIT"] = "すうじを つかって ください。";
translations["MSG_BLOCKLY_INPUT_LENGTH_LIMIT"] =
  "%{limit}もじ までに してください。";

translations["MSG_BUILDER_MENU_PROPERTY_ASSET_BTN"] = "アセット";
translations["MSG_BUILDER_MENU_PROPERTY_BLOCK_BTN"] = "ブロック";
translations["MSG_BUILDER_MENU_ACTION_RUN_PREVIEW_BTN"] = "うごかす";
translations["MSG_BUILDER_MENU_ACTION_PUBLISH_BTN"] = "こうかい";
translations["MSG_BUILDER_MENU_ACTION_STOP_PUBLISH_BTN"] = "ひこうかい";
translations["MSG_BUILDER_MENU_ACTION_UNDO_BTN"] = "とりけす";
translations["MSG_BUILDER_MENU_ACTION_REDO_BTN"] = "やりなおす";
translations["MSG_BUILDER_MENU_ACTION_CLEAR_BTN"] = "クリア";

translations["MSG_ASSET_ALBUM_TITLE"] = "アルバムの なかみ";
translations["MSG_ASSET_DRAWING_TITLE"] = "スケッチブックの なかみ";
translations["MSG_ASSET_ALBUM_ADD_TITLE"] = "がぞうついか";
translations["MSG_ASSET_ALBUM_ADD_MESSAGE"] =
  "がぞうのついか　ここから がぞう（しゃしん）のついかが できます。";
translations["MSG_ASSET_DRAWING_ADD_ALBUM_TITLE"] =
  "『まなんでパズル』でついかした、がぞうや しゃしんが ここについかされます。";
translations["MSG_ASSET_DRAWING_ADD_WARNING_TITLE"] =
  "『じゆうにつくる』の「おえかき」メニューでえをかくと、ここに ついかされます。";
translations["MSG_ASSET_ADD_TO_SCREEN_BTN"] = "アプリに\nついか";
translations["MSG_ASSET_APPLY_TO_COVER_PAGE_BTN"] = "ひょうしに\nせってい";
translations["MSG_ASSET_APPLY_TO_PROFILE_ICON_BTN"] = "アイコンに\nせってい";
translations["MSG_ASSET_DELETE_BTN"] = "がぞうを\nさくじょ";
translations["MSG_ASSET_ALBUM_EMPTY_MESSAGE"] =
  "まだ がぞうの データがありません。";
translations["MSG_ASSET_DRAWING_EMPTY_MESSAGE"] =
  "まだ おえかき のデータがありません。";

translations["MSG_ASSISTANT_WELCOME_MESSAGE"] =
  "こんにちは！アイロボ先生だよ！質問は何かな？話しかけてね！";

translations["MSG_REACTION_TAG_TITLE"] = "きもちスタンプ";
translations["MSG_REACTION_USERS"] = "みんなの\nきもち";
translations["MSG_REACTION_DIFFICULT"] = "てごわい！";
translations["MSG_REACTION_EASY"] = "かんたん";
translations["MSG_REACTION_COOL"] = "いいね！";
translations["MSG_REACTION_FUN"] = "たのしい";
translations["MSG_REACTION_AMAZING"] = "すごい";
translations["MSG_REACTION_BAD"] = "う〜ん？";

export default translations;
