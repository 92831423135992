import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { LevelClearIcon } from "features/courses/assets";

export const Success = (props: { scale: number; reset: () => void }) => {
  const { scale, reset } = props;
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleClose = () => {
    if (!disable) {
      setDisable(true);
      reset();
    }
  };

  return (
    <Dialog
      open
      fullScreen
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      sx={{ zIndex: 10001, backgroundColor: "transparent" }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-center !absolute w-full h-full bg-white/70">
        <div
          className="flex-col-center"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-center animate-fade-in">
            <div className="flex-col-view w-[696px] h-[264px] mb-[40px]">
              <LevelClearIcon />
            </div>
            <div className="flex-col-view">
              <svg width={608} height={60} viewBox="0 0 608 60">
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="central"
                  fill="#3F4A61"
                  fontSize="32"
                  fontFamily="MPLUSRounded1c-Bold"
                  stroke="#FEFDFE"
                  strokeWidth={8}
                  paintOrder="stroke"
                >
                  {I18n.t("MSG_COURSE_GAME_SUCCESS_TEXT")}
                </text>
              </svg>
            </div>

            <div className="flex-row-el flex-center gap-8 mt-10 alpha">
              <button className="btn btn-blue" onClick={handleClose}>
                <p className="text-body text-white text-[20px] !mb-[3px]">
                  {I18n.t("MSG_COURSE_GAME_SUCCESS_REVIEW_BTN")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
