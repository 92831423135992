import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { Scene } from "@babylonjs/core/scene";
import { Tools } from "@babylonjs/core/Misc/tools";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { RootState } from "app/store";
import {
  IconTrash,
  IconPlayerPlay,
  IconCornerUpLeft,
  IconCornerUpRight,
} from "common/assets";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { IconStock, IconPublish } from "features/creator/assets";
import { CameraIcon } from "features/creator/property/components";
import { actions, selectMapModelIds } from "features/builder/slice";
import { PublishProjectDialogMessage } from "features/creator/elements";
import { publishMapProject, stopPublishMapProject } from "features/builder/api";
import { ModelAsset } from "./ModelAsset";
import { ModelAssetList } from "./ModelAssetList";

export const ActionMenu = ({ scene }: { scene: Scene }) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [publish, setPublish] = useState(false);
  const mapModels = useSelector(selectMapModelIds);
  const actionMenu = useSelector(
    (state: RootState) => state.builder.actionMenu
  );
  const undoStacks = useSelector(
    (state: RootState) => state.builder.undoStacks
  );
  const redoStacks = useSelector(
    (state: RootState) => state.builder.redoStacks
  );
  const selectedSlotIndex = useSelector(
    (state: RootState) => state.builder.selectedSlotIndex
  );
  const slot = useSelector((state: RootState) => state.builder.slot);
  const user = useSelector((state: RootState) => state.user.appUser);
  const project = useSelector((state: RootState) => state.builder.project);

  const handleOpenAssetList = () => {
    play();
    console.log(selectedSlotIndex);
    if (isNaN(Number(selectedSlotIndex))) {
      let emptySlotIndex: number = 0;
      for (let i = 0; i < slot.length; i++) {
        const model = slot[i];
        if (!model) {
          emptySlotIndex = i;
          break;
        }
      }
      dispatch(actions.selectSlot(emptySlotIndex));
    } else {
      dispatch(actions.selectSlot(selectedSlotIndex));
    }
    dispatch(
      actions.updateActionMenu({
        key: "assetList",
        value: true,
        exclusive: true,
      })
    );
  };

  const handleOpenPreview = () => {
    if (mapModels.length > 0) {
      play();
      dispatch(actions.updateLoading(true));
      btnDelay(() =>
        dispatch(actions.updateActionMenu({ key: "preview", value: true }))
      );
    }
  };

  const handleOpenPublish = () => {
    play();
    btnDelay(() => setPublish(true));
  };
  const handlePublish = (enable_copied?: boolean) => {
    if (project.published) {
      dispatch(
        stopPublishMapProject({
          uid: user.active.uid,
          project_id: project.project_id,
        })
      );
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_DISABLE_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    } else {
      dispatch(
        publishMapProject({
          uid: user.active.uid,
          project_id: project.project_id,
          enable_copied,
        })
      );
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    }
    setPublish(false);
  };
  const handleClosePublish = () => {
    play();
    btnDelay(() => setPublish(false));
  };

  const handleCapture = async () => {
    const grounds = scene.getMeshesById("ground");
    for (let i = 0; i < grounds.length; i++) {
      const ground = grounds[i];
      ground.showBoundingBox = false;
    }

    setTimeout(async () => {
      const data = await Tools.CreateScreenshotUsingRenderTargetAsync(
        scene.getEngine(),
        scene.getCameraByName("camera") as ArcRotateCamera,
        { precision: 1 },
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        100
      );

      const downloadLink = document.createElement("a");
      downloadLink.href = data;
      downloadLink.download = "capture.png";
      downloadLink.click();

      for (let i = 0; i < grounds.length; i++) {
        const ground = grounds[i];
        ground.showBoundingBox = true;
      }
    }, 500);
  };

  const handleUndo = () => {
    if (undoStacks.length === 0) return;
    play();
    console.log("undo.");
  };
  const handleRedo = () => {
    if (redoStacks.length === 0) return;
    play();
    console.log("redo.");
  };
  const handleReset = () => {
    if (mapModels.length === 0) return;
    play();
    console.log("remove all.");
  };

  return (
    <>
      <PublishProjectDialogMessage
        name={project?.name}
        visible={publish}
        published={project?.published}
        enableCopied={project?.enable_copied}
        publish={handlePublish}
        close={handleClosePublish}
      />
      <ModelAssetList />

      <div
        style={{ transform: `scale(${scale})` }}
        className="flex-row-el flex-center absolute top-5 origin-top z-10 gap-5"
      >
        <div
          onClick={handleOpenAssetList}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            actionMenu.assetList ? "bg-green/altcolor" : "bg-beige/alt"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconStock />
            </span>
            {I18n.t("MSG_BUILDER_MENU_PROPERTY_ASSET_BTN")}
          </p>
        </div>

        <div
          onClick={handleOpenPreview}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            mapModels.length > 0
              ? "active:bg-green/altcolor bg-beige/alt"
              : "bg-gray/40"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconPlayerPlay color="white" width={2} />
            </span>
            {I18n.t("MSG_BUILDER_MENU_ACTION_RUN_PREVIEW_BTN")}
          </p>
        </div>
        <div
          onClick={handleOpenPublish}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            publish ? "bg-green/altcolor" : "bg-beige/alt"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconPublish fill="none" stroke="#FFF" />
            </span>
            {I18n.t("MSG_BUILDER_MENU_ACTION_PUBLISH_BTN")}
          </p>
        </div>
        <div
          onClick={handleCapture}
          className="flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 bg-white/60 active:opacity-70"
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px] text-center">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <CameraIcon />
            </span>
            キャプチャ（テスト）
          </p>
        </div>
      </div>

      <div
        style={{ transform: `scale(${scale})` }}
        className="flex-col-el flex-center absolute left-3 origin-left z-10 gap-10"
      >
        <div
          onClick={handleUndo}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            undoStacks.length > 0
              ? "active:opacity-80 bg-beige/alt"
              : "bg-gray/40"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconCornerUpLeft />
            </span>
            {I18n.t("MSG_BUILDER_MENU_ACTION_UNDO_BTN")}
          </p>
        </div>
        <div
          onClick={handleRedo}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            redoStacks.length > 0
              ? "active:opacity-80 bg-beige/alt"
              : "bg-gray/40"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconCornerUpRight />
            </span>
            {I18n.t("MSG_BUILDER_MENU_ACTION_REDO_BTN")}
          </p>
        </div>
        <div
          onClick={handleReset}
          className={`flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 ${
            mapModels.length > 0 ? "active:opacity-80 bg-danger" : "bg-gray/40"
          }`}
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px]">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <IconTrash width={2} />
            </span>
            {I18n.t("MSG_BUILDER_MENU_ACTION_CLEAR_BTN")}
          </p>
        </div>
      </div>

      <ModelAsset />
    </>
  );
};
