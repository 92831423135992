import produce from "immer";
import {
  ComponentType,
  StarStyleProps,
  BorderStyleType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Star: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_STAR";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_STAR";

    const initialWidth = 100;
    const initialHeight = 100;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultStar = StarStyleProps;
    draft.style.defaultView.borderWidth = 4;
    draft.style.defaultView.borderColor = "#4C9DF1";
    draft.style.defaultView.borderStyle = BorderStyleType.SOLID;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.star = StarStyleProps;
    draft.style.view.borderWidth = 4;
    draft.style.view.borderColor = "#4C9DF1";
    draft.style.view.borderStyle = BorderStyleType.SOLID;
  }),
};

export default Star;
