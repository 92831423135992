import { useRef, useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "lodash-es";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { useScale } from "common/utils";
import { useCanvas } from "features/courses/hook";
import { actions, selectAllMapModel } from "features/builder/slice";
import { useScene } from "./useScene";
import { useDndScene } from "./useDndScene";

export const BuilderScene = ({
  scene,
  setScene,
}: {
  scene: Scene | null;
  setScene: (scene: Scene) => void;
}) => {
  useDndScene(scene);
  const dispatch = useDispatch();
  const { scale, ratio } = useScale();
  const [reload, setReload] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const elementRef = useRef<HTMLDivElement>(null);
  const mapModels = useSelector(selectAllMapModel);
  const { initGroundMesh, loadMapModel } = useScene(scene);

  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload((pre) => !pre);
        },
        1000,
        { leading: false, trailing: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!isFirst) {
      handleResize();
    }
  }, [scale]);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
  }, []);

  const onSceneReady = useCallback(
    async (canvas: HTMLCanvasElement, engine: Engine, scene: Scene) => {
      setScene(scene);

      const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
      light.intensity = 1.5;

      const radius =
        ratio > 1 ? (scale > 0.7 ? 20 / scale : 40 * scale) : 20 / scale;
      const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 4,
        Math.PI / 4,
        radius,
        new Vector3(9, 0, 9),
        scene
      );
      camera.lowerBetaLimit = 0;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerRadiusLimit = radius - 10 / scale;
      camera.upperRadiusLimit = radius + 10 / scale;
      camera.attachControl(canvas, true);

      scene.clearColor = new Color4(0, 0, 0, 0);

      initGroundMesh(scene);
      await loadMapModel(scene, mapModels);
      dispatch(actions.updateLoading(false));

      engine.runRenderLoop(() => scene.render());
    },
    [reload]
  );

  useCanvas(
    elementRef,
    onSceneReady,
    (error) => {
      console.error(error);
    },
    reload
  );

  return (
    <div
      ref={elementRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
