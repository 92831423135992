import produce from "immer";
import {
  ComponentType,
  BorderStyleType,
  DefaultComponentProperty,
} from "common/components/types";
import DesignComponent from "./design";
import DragLayerComponent from "./drag";

const Line: ComponentType = {
  DragLayerComponent: DragLayerComponent,
  DesignComponent: DesignComponent,
  property: produce(DefaultComponentProperty, (draft) => {
    draft.name = "MSG_CREATOR_COMPONENT_NAME_LINE";
    draft.defaultName = "MSG_CREATOR_COMPONENT_NAME_LINE";

    const initialWidth = 100;
    const initialHeight = 5;

    draft.style.defaultLayout.width = initialWidth;
    draft.style.defaultLayout.height = initialHeight;
    draft.style.defaultView.backgroundColor = "#94C4F7";
    draft.style.defaultView.borderStyle = BorderStyleType.SOLID;

    draft.style.layout.width = initialWidth;
    draft.style.layout.height = initialHeight;
    draft.style.view.backgroundColor = "#94C4F7";
    draft.style.view.borderStyle = BorderStyleType.SOLID;
  }),
};

export default Line;
