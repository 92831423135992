import { useState } from "react";
import { Scene } from "@babylonjs/core/scene";
import { BuilderScene } from "./scene";
import { ActionMenu } from "./ActionMenu";
import { Controller } from "./Controller";

export const Canvas = () => {
  const [scene, setScene] = useState<Scene | null>(null);

  return (
    <>
      <ActionMenu scene={scene} />
      <Controller scene={scene} />
      <BuilderScene scene={scene} setScene={setScene} />
    </>
  );
};
